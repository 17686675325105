import { patchRequest } from 'utils/api/apiRequest'

export type Data = {
  email: string
}

export const patchForgotPassword = ({ email }: Data) =>
  patchRequest(`/users/password/reset`, {
    email,
  })
