import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

export const Plus = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 70 70">
    <path
      d="M66.38,32.9l-28.4-0.06l0-28.34c0-1.4-1.14-2.54-2.54-2.54c-1.4,0-2.54,1.14-2.54,2.54l0,28.4H4.5
	c-1.4,0-2.54,1.14-2.54,2.54c0,1.4,1.14,2.54,2.54,2.54h28.4l0,28.39c0,1.4,1.14,2.54,2.54,2.54c1.4,0,2.54-1.14,2.54-2.54l0-28.45
	l28.39,0.06c0.01,0,0.01,0,0.01,0c1.4,0,2.53-1.13,2.54-2.53C68.9,34.04,67.77,32.91,66.38,32.9z"
    />
  </SvgIcon>
)
