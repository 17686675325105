import { AxiosResponse } from 'axios'
import { postRequest } from 'utils/api/apiRequest'

export type WebOrderReference = {
  id: number
}

export interface CreateOrderResponse {
  is_lucky_bird_order: boolean
  promised_delivery_date: null
  requested_delivery_date: string
  order_customer_details: {
    first_name: string
    last_name: string
    email: string
    company_name: string | null
    street: string
    number: string
    box: string
    zip: string
    city: string
    country: {
      translation: Record<string, { name: string }>
      id: number
      code: string
    }
    phone: string
    contact_person_first_name: string | null
    contact_person_last_name: string | null
    contact_person_phone: string | null
    contact_person_email: string | null
    collection_point_name: string | null
    collection_point_number: string | null
  }
  order_items: [
    {
      tracking_number_url: string | null
      id: number
      code: string
      quantity: number
      product: {
        translation: Record<string, { name: string; description: string; extra: string }>
        code: 'M014911'
      }
      points: number
      sub_order: {
        uuid: string
        items_type_code: string
      }
      personalization_text: string[]
      is_cancelled: boolean
      tracking_number: string | null
    },
  ]
  sub_orders: [
    {
      uuid: string
      items_type_code: string
    },
  ]
  ext_web_order_reference: WebOrderReference
}

export const createOrder = () =>
  postRequest<undefined, AxiosResponse<CreateOrderResponse>>('/redemption-site/order', {})
