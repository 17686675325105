import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

export const News = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 70 70">
    <g>
      <path
        d="M45.23,14.71v35.51c0,1.41-1.52,2.29-2.75,1.6C36,48.11,26.03,43.91,20.09,42l-0.89-0.28
		c-0.02,1.36,0.02,2.72,0.12,4.07c0.08,1.12,0.18,1.97,0.32,2.85c0.12,0.73,0.23,1.28,0.58,2.92c1.35,5.83-3.79,6.7-4.84,6.4l0,0
		c0,0-3.17-0.74-3.39-3.5c-0.02-0.22-0.02-0.45,0-0.67c0.12-1.67,0.15-2.23,0.17-2.97c0.02-0.89,0-1.74-0.08-2.86
		c-0.16-2.29-0.48-4.57-0.96-6.84c-0.05-0.23-0.05-0.46-0.02-0.67C9.2,40.16,7.26,39.95,5.3,39.8c-0.96-0.07-1.7-0.87-1.7-1.83
		V26.95c0-0.98,0.76-1.78,1.74-1.83c5.9-0.31,12.07-1.51,18.51-3.59c6.46-2.08,12.66-4.88,18.61-8.4
		C43.68,12.4,45.23,13.28,45.23,14.71z M53.15,41.47l0.14,0.13l6.79,7.06c0.7,0.73,0.68,1.89-0.05,2.6
		c-0.69,0.66-1.75,0.68-2.46,0.08l-0.14-0.13l-6.79-7.06c-0.7-0.73-0.68-1.89,0.05-2.6C51.38,40.88,52.44,40.86,53.15,41.47z
		 M65.43,30.01c1.01,0,1.84,0.82,1.84,1.84c0,1.01-0.82,1.84-1.84,1.84h-9.8c-1.01,0-1.84-0.82-1.84-1.84
		c0-1.01,0.82-1.84,1.84-1.84H65.43z M60.03,13.38c0.73,0.7,0.75,1.87,0.05,2.6l-6.79,7.06c-0.7,0.73-1.87,0.75-2.6,0.05
		c-0.73-0.7-0.75-1.87-0.05-2.6l6.79-7.06C58.13,12.7,59.3,12.68,60.03,13.38z"
      />
    </g>
  </SvgIcon>
)
