import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import { Close } from 'components/ArteelIcons'
import { drawerContext } from 'components/DrawerSelect/drawerContext'
import { FilterProduct } from 'components/FilterProduct/FilterProduct'
import { useShowCategoriesTree } from 'components/ShopAll/useShowCategoriesTree'
import { BrandsDialog } from 'pages/HomeRedemptionSite/CategoriesAndFilters/components/BrandsDialog'
import { FilterWrapper } from 'pages/HomeRedemptionSite/CategoriesAndFilters/components/FilterWrapper'
import { Row } from 'pages/HomeRedemptionSite/CategoriesAndFilters/components/Row'
import { ROOT_CATEGORY_ID } from 'pages/HomeRedemptionSite/utils'
import qs from 'query-string'
import { useBrands } from 'utils/hooks/api/useBrands'
import { useSubCategories } from 'utils/hooks/api/useSubCategories'
import { useIsMobile } from 'utils/hooks/useBreakpoints'
import { usePointsName } from 'utils/hooks/usePointsName'

type Props = {
  showFilters: boolean
  selectedCategoryName?: string
}

export const CategoriesAndFilters = ({ showFilters, selectedCategoryName }: Props) => {
  const context = useContext(drawerContext)
  const [openBrandsDialog, setOpenBrandsDialog] = useState(false)
  const location = useLocation()
  const { categoryId } = useParams<{ categoryId: string }>()
  const { data: categoriesData, isLoading } = useSubCategories(Number(categoryId || ROOT_CATEGORY_ID))
  const { data: brandsData, isLoading: isLoadingBrands } = useBrands({ params: 'limit=1000&sort[name]=asc' })
  const pointsName = usePointsName(0)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const param = qs.parse(location.search.replace('?', ''))
  const checkedBrandIds = (param['filters[product.brand.id|eq]'] as string)
    ? [Number(param['filters[product.brand.id|eq]'] as string)]
    : (param['filters[product.brand.id]'] as string)?.split(',')?.map(Number) || []
  const displayBrands = brandsData?.data?.data
    ?.filter((brand) => !checkedBrandIds.includes(brand.id))
    .slice(0, 10 - checkedBrandIds.length > 0 ? 10 - checkedBrandIds.length : 0)
  const displayCheckedBrands = brandsData?.data?.data?.filter((brand) => checkedBrandIds.includes(brand.id))
  const isMobile = useIsMobile()
  const showCategoriesTree = useShowCategoriesTree()

  const params = (checked: number[]) =>
    qs.stringify({
      ...qs.parse(location.search),
      [`filters[product.brand.id|eq]`]: undefined,
      [`filters[product.brand.id]`]: undefined,
      [`filters[product.brand.id${checked.length === 1 ? '|eq' : ''}]`]: checked?.length
        ? checked.join(',')
        : undefined,
    })

  const hasSelectedCategory = ROOT_CATEGORY_ID !== Number(categoryId) && selectedCategoryName

  const onChangeBrand = (checked: number[]) => {
    setOpenBrandsDialog(false)
    navigate(`${location.pathname}?${params(checked)}`)
    window.scrollTo({ top: 0, behavior: 'smooth' })
    context?.closeDrawer()
  }

  return (
    <Box display="grid" gridTemplateColumns="1fr" mt={isMobile ? 0 : 8} px={isMobile ? 4 : 0} gap={8}>
      {showCategoriesTree && (
        <FilterWrapper title="Categories" isLoading={isLoading}>
          {hasSelectedCategory && (
            <Row checked primary>
              {selectedCategoryName}
            </Row>
          )}
          <Box sx={{ ml: hasSelectedCategory ? 3 : 0 }}>
            {categoriesData?.data?.subcategories?.map((category) => (
              <Row
                key={category.id}
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: 'smooth' })
                  navigate(`/catalog/categories/${category.id}`)
                  context?.closeDrawer()
                }}
              >
                {category?.name}
              </Row>
            ))}
          </Box>
        </FilterWrapper>
      )}

      {showFilters && (
        <FilterWrapper title={pointsName}>
          <Box sx={{ mt: 2 }}>
            <FilterProduct />
          </Box>
        </FilterWrapper>
      )}
      {showCategoriesTree && (
        <FilterWrapper title="Brands" isLoading={isLoadingBrands}>
          {displayCheckedBrands?.map((brand) => (
            <Row
              checked
              key={brand.id}
              onClick={() => {
                onChangeBrand(checkedBrandIds.filter((id) => id !== brand.id))
              }}
              endAdornment={
                <Close
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    onChangeBrand(checkedBrandIds.filter((id) => id !== brand.id))
                  }}
                  sx={{
                    fontSize: (theme) => theme.typography.h5.fontSize,
                    color: (theme) => theme.palette.primary.main,
                  }}
                />
              }
            >
              {brand?.name}
            </Row>
          ))}
          {displayBrands?.map((brand) => (
            <Row
              key={brand.id}
              onClick={() => {
                onChangeBrand([...checkedBrandIds, brand.id])
              }}
            >
              {brand?.name}
            </Row>
          ))}
          <Row
            onClick={() => {
              setOpenBrandsDialog(true)
            }}
          >
            <Box component="span" sx={{ color: (theme) => theme.palette.primary.main }}>
              {t('app.show_more')}
            </Box>
          </Row>
        </FilterWrapper>
      )}
      {!!openBrandsDialog && <BrandsDialog open={openBrandsDialog} onClose={onChangeBrand} />}
    </Box>
  )
}
