import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import { useIsLuckyBirdAuth } from 'pages/Login/LuckyBird/useIsLuckyBirdAuth'
import { getResponsiveSize } from 'theme/styles.utils'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    zIndex: 1,
    background: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  code: {
    fontSize: `${getResponsiveSize(5)}rem`,
    fontWeight: theme.typography.fontWeightBold,
  },
  error: {
    fontSize: theme.typography.h1.fontSize,
  },
}))

type Props = {
  withoutButton?: boolean
  noTranslation?: boolean
}

const NoMatch = ({ withoutButton, noTranslation }: Props) => {
  const { code, error, wrapper } = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const user = useCurrentUser(true)
  const isLuckBirdAuth = useIsLuckyBirdAuth()
  const redirect = () => {
    switch (true) {
      case !!user:
        return '/'
      case isLuckBirdAuth:
        return '/auth/luckybird'
      default:
        return '/auth'
    }
  }
  return (
    <div className={wrapper}>
      <div className={code}>404</div>
      <div className={error}>{noTranslation ? 'No match' : t('app.no_match')}</div>
      {!withoutButton && (
        <div>
          <Button variant="contained" color="primary" onClick={() => navigate(redirect())}>
            {t('app.back_to_home')}
          </Button>
        </div>
      )}
    </div>
  )
}
export default NoMatch
