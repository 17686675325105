import { alpha } from '@mui/material'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { RedeemButton } from 'pages/CMS/components/ContentBox/RedeemButton'
import { ShowPointsExpiryDate } from 'pages/CMS/components/ContentBox/ShowPointsExpiryDate'
import { Project } from 'shared/Site/api'
import { getResponsiveSize } from 'theme/styles.utils'
import { displayPoints } from 'utils/displayPoints'
import { usePointsExpirationDate } from 'utils/hooks/usePointsExpirationDate'
import { usePointsName } from 'utils/hooks/usePointsName'

type Props = {
  project: Project
}

export const ProjectPaper = ({ project }: Props) => {
  const { showExpirationDate, firstExpiredPoints, firstExpirationDate } = usePointsExpirationDate(project)

  return (
    <Paper
      elevation={0}
      sx={(theme) => ({
        background: alpha(theme.palette.background.default, 0.4),
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1.5),
      })}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" align="center">
            {project.name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h1"
            align="center"
            sx={(theme) => ({
              fontWeight: 900,
              color: theme.palette.grey['800'],
              lineHeight: 1,
              fontSize: `${getResponsiveSize(2.25, 'rem')}!important`,
            })}
          >
            {displayPoints(project?.available_points)}
          </Typography>
          <Typography
            align="center"
            sx={(theme) => ({
              fontSize: `${getResponsiveSize(1.5, 'rem')}!important`,
              fontWeight: theme.typography.fontWeightBold,
              color: theme.palette.grey['800'],
              lineHeight: 1,
            })}
          >
            {usePointsName(project?.available_points)}
          </Typography>
          {showExpirationDate && (
            <ShowPointsExpiryDate
              points={firstExpiredPoints}
              pointName={usePointsName(project?.available_points)}
              date={firstExpirationDate}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <RedeemButton project={project} />
        </Grid>
      </Grid>
    </Paper>
  )
}
