// 1. GlobalStyles.js

import { alpha } from '@mui/material'
import { fontFamilies, getResponsiveSize } from 'theme/styles.utils'
import { makeStyles } from 'utils/styles/makeStyles'
import { important } from 'utils/styles/stylesUtils'

const useStyles = makeStyles((theme) => ({
  '@global': {
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%',
      maxHeight: '100vh',
      '-webkit-overflow-scrolling': 'touch',
      fontSize: Math.min(getResponsiveSize(16), 20),
    },
    '.snackbar-custom': {
      top: important(getResponsiveSize(60, 'px')),
      '& .SnackbarItem-message': {
        fontSize: theme.typography.body1.fontSize,
      },
      '& svg': {
        fontSize: important(getResponsiveSize(20, 'px')),
      },
    },
    '*, *::before, *::after': {
      boxSizing: 'inherit',
    },
    body: {
      height: '100%',
      width: '100%',
      fontFamily: fontFamilies.cond,
      minWidth: '300px',
    },
    '#root': {
      height: '100%',
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      '#root': {
        maxWidth: '100vw',
      },
    },
    'input:-webkit-autofill, input:-webkit-autofill:hover,input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus':
      {
        fontSize: '1.125rem !important',
        fontWeight: 700,
        border: 0,
        '-webkit-text-fill-color': theme.palette.text.primary,
        '-webkit-box-shadow': `0 0 0px 1000px ${alpha(theme.palette.primary.light, 0)} inset`,
        transition: 'all 5000s ease-in-out 0s',
      },
  },
}))

// @ts-ignore
export const CustomGlobalStyles = () => {
  useStyles()

  return null
}
