import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

export const Points = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 70 70">
    <g>
      <path
        d="M58.4,28.9c-0.5,0-1.1-0.3-1.4-0.8c-1-1.7-2-3.3-3.4-4.6c-0.3-0.3-0.5-0.7-0.5-1.1V10.9l-7.9,6.9
		c-0.4,0.4-1,0.5-1.5,0.3c-1.7-0.5-3.5-0.8-5.3-1c0,0.2,0,0.4,0,0.6c0,0.5,0,0.9-0.1,1.4l0,0l0.1,0c0.8,0,1.5,0.7,1.5,1.6
		c0,0.4-0.2,0.8-0.5,1.1c-0.3,0.3-0.7,0.4-1.1,0.4c-7.2,0-13.6,2.7-18.1,7.6c-0.3,0.3-0.7,0.5-1.1,0.5c-0.4,0-0.8-0.1-1.1-0.4
		c-0.3-0.3-0.5-0.7-0.5-1.1c0-0.4,0.1-0.8,0.4-1.1c0.3-0.3,0.6-0.6,0.9-0.9c-0.6-0.5-1.1-1-1.6-1.6c-2.5,3-4,6.4-4.3,10
		c-4.8,0-8.7,3.7-8.7,8.2c0,0.9,0.7,1.6,1.5,1.6c0.4,0,0.8-0.2,1.1-0.4c0.3-0.3,0.5-0.7,0.5-1l0-0.1l0,0c0-2.8,2.4-5,5.5-5.1
		c0.4,4.6,2.7,8.9,6.5,12.2c0.3,0.3,0.5,0.7,0.5,1.2l0,0l0,0.1V62h7.3v-4.6c0-0.1,0-0.3,0-0.4c0.1-0.4,0.4-0.7,0.7-1
		c0.4-0.2,0.8-0.3,1.2-0.2c4.6,1.2,9.5,1.2,14,0c1-0.3,2,0.5,2,1.5V62h7.3V51.7c0-0.5,0.2-0.9,0.5-1.2c1.9-1.7,3.1-3.1,4.1-4.7
		c0.3-0.5,0.9-0.7,1.4-0.8h8.1V28.9H58.4z"
      />
      <path
        d="M21.3,24.7c4-3,8.7-4.9,13.9-5.5c0.1-0.6,0.2-1.1,0.2-1.6c0-0.6-0.1-1.2-0.2-1.7c0-0.1,0-0.2-0.1-0.3
		c0,0,0,0,0,0c-0.9-4-4.5-6.8-8.6-6.8c-4.9,0-8.8,4-8.8,8.8C17.8,20.5,19.1,23,21.3,24.7z"
      />
    </g>
  </SvgIcon>
)
