export type DataCyElements = typeof elements
export type DataCy<T extends Readonly<Record<string, Readonly<string[]>>> = DataCyElements> = {
  [K in keyof T]: K extends string ? `${K}-${T[K][number]}` : never
}[keyof T]
export type FilterDataCy<T extends string, K extends string> = T extends `${K}-${infer R}` ? T : never

const mapper = <T extends Readonly<string[]>, K extends string>(items: T, prefix: K) =>
  items.map((item) => `${prefix}-${item}` as `${K}-${T[number]}`)

const baseMenuItems = [
  'open',
  'home',
  'shopping-cart',
  'catalog',
  'wishlist',
  'my-account',
  'personal-messages',
  'language',
  'create-order',
] as const
const mainMenuItems = [
  ...baseMenuItems,
  'my-profile',
  'order-history',
  'points-history',
  'voucher-orders',
  'user-consent',
  'back-office',
  'my-team',
  'news',
  'points',
  'basket',
  'recognize',
  'reward',
  'nominate',
  'redeem',
  'service-request',
  'birthdays',
  'values',
  'write-message',
  'give-points',
  'settings',
  'logout',
] as const
const topBarMenu = mapper(baseMenuItems, 'topbar')
const mainMenu = mapper(mainMenuItems, 'main')

const elements = {
  button: [
    'wishlist-add',
    'product-add',
    'lb-redeem',
    'dialog-close',
    'cart-visit',
    'order-visit',
    'order-overview-show',
    'order-submit',
    'order-home-visit',
    'catalog-back',
    'logout-confirm',
    'logout',
  ],
  form: ['order'],
  image: ['lb-column', 'logo-subscriber'],
  menu: [...topBarMenu, ...mainMenu],
  container: ['main-menu', 'points-conclusion', 'charity', 'order-confirmation'],
  wrapper: ['points-amount'],
  label: ['consent', 'demo-user-order-confirmation'],
  node: [],
  product: ['1'],
  category: ['1'],
} as const

export const dataCy = <T extends DataCy>(name: T) => name
