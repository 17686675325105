import { TranslationsKeys } from 'utils/createTranslationKey'

export type FilterNames = 'week' | 'month' | 'year'

export interface Option {
  name: FilterNames
  label: TranslationsKeys
}

export const filterOptions: Option[] = [
  {
    name: 'week',
    label: 'filters.last_week',
  },
  {
    name: 'month',
    label: 'filters.last_month',
  },
  {
    name: 'year',
    label: 'filters.last_year',
  },
]
