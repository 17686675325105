import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import { ResponsivePaper } from 'components/ResponsivePaper'
import { Cell } from 'pages/MyAccount/components/Points/Cell'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'

import { Header } from './Header'

export const MyAccountPoints = () => {
  const { t } = useTranslation()
  const { profile, projects } = useCurrentUser()
  const totalPoints = Object.entries(profile?.points_overall || {})?.map(([key, value]) => value) || []
  const projectsPoints = Object.entries(projects || {})?.map(([_, project]) => project) || []

  const getIsEnabledExpiryDate = (pointsOverall: { projectId: number }) => {
    const project = projectsPoints.find((project) => project.id === pointsOverall.projectId)
    return project?.project_settings?.show_expiration_date
  }

  return (
    <Grid item xs={12}>
      <ResponsivePaper>
        <Box sx={{ py: 3, px: 6 }}>
          {totalPoints.map((pointsOverall, index, array) => (
            <Box key={pointsOverall.projectId}>
              <Header projectName={pointsOverall.projectName} availablePoints={pointsOverall?.availablePoints} />
              <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap={16} rowGap={1}>
                <Cell label="cms.points.awarded_points" value={pointsOverall?.awardedPoints} />
                <Cell
                  label="cms.points.redeemed_points"
                  value={pointsOverall?.redeemedPoints}
                  redirectTo="/my-account/order-history"
                />
                <Cell label="cms.points.transferred_points" value={pointsOverall?.transferredPoints} />
                {getIsEnabledExpiryDate(pointsOverall) ? (
                  <Cell label="cms.points.expired_points" value={pointsOverall?.expiredPoints} />
                ) : (
                  <Box />
                )}
              </Box>
              {array.length - 1 !== index && (
                <Box sx={{ py: 3 }}>
                  <Divider />
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </ResponsivePaper>
    </Grid>
  )
}
