import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { personalMessageKeys } from 'pages/PersonalMessages/api/keys'
import { PersonalMessage } from 'pages/PersonalMessages/types'
import { getRequest } from 'utils/api/apiRequest'

const getPersonalMessage = (id: number | string) => getRequest<PersonalMessage>(`/user/messages/${id}`)

export const useGetPersonalMessage = ({ isEdit }: { isEdit: boolean }) => {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const query = useQuery({
    queryKey: personalMessageKeys.getPersonalMessage([String(id)]),
    queryFn: () => getPersonalMessage(id),
    select: (data) => data.data,
  })

  useEffect(() => {
    if (query?.error) {
      enqueueSnackbar(t('personal_messages.get_message.fail'), { variant: 'error' })
      navigate(`/personal-messages`)
    }
  }, [query?.error])

  useEffect(() => {
    if (!query?.data?.editable && query?.data?.has_content) {
      navigate(`/personal-messages/view/${id}`)
    }
  }, [query?.data?.editable])

  return query
}
