import { ReactNode, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { styled } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { DepartmentTree } from 'components/DepartmentTree/DepartmentTree'
import { drawerContext } from 'components/DrawerSelect/drawerContext'
import {
  FilterFrom,
  FilterTo,
  FilterValues,
  ResetFiltersButton,
  SubmitFiltersButton,
} from 'pages/SocialWallPostFilters/FilterComponents'
import { usePostsFilters } from 'pages/SocialWallPostFilters/usePostsFilters'
import { TranslationsKeys } from 'utils/createTranslationKey'

const DialogTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h2.fontSize,
  fontWeight: 900,
  color: theme.palette.primary.main,
  width: '100%',
}))

interface AccordionWrapperProps {
  translation: TranslationsKeys
  children: ReactNode
}
const AccordionWrapper = ({ translation, children, ...props }: AccordionWrapperProps) => {
  const { t } = useTranslation()

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" {...props}>
        <DialogTitle align="center">{t(translation)}</DialogTitle>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}

export const PostsFiltersMobile = () => {
  const { closeDrawer } = useContext(drawerContext)
  const { resetFilters, onFilterChange, getFilterValue, disabledFilters, onFiltersSubmit } = usePostsFilters()

  return (
    <>
      <AccordionWrapper translation={'social_wall.filters.from'} data-cy="from-accordion">
        <FilterFrom
          value={getFilterValue('FROM_MYSELF')}
          onChange={onFilterChange}
          disableMyself={disabledFilters.fromMyself}
        />
      </AccordionWrapper>
      <AccordionWrapper translation={'social_wall.filters.to'} data-cy="to-accordion">
        <FilterTo
          value={getFilterValue('TO_MYSELF')}
          onChange={onFilterChange}
          disableMyself={disabledFilters.toMyself}
        />
      </AccordionWrapper>
      <AccordionWrapper translation={'social_wall.filters.values'} data-cy="values-accordion">
        <FilterValues value={getFilterValue('VALUES')} onChange={onFilterChange} />
      </AccordionWrapper>
      <AccordionWrapper translation={'social_wall.filters.organisation'} data-cy="department-accordion">
        <DepartmentTree
          selected={getFilterValue('DEPARTMENT')}
          setSelected={(departmentId) => onFilterChange('DEPARTMENT', departmentId)}
          centered={false}
        />
      </AccordionWrapper>
      <Box textAlign="center" p={1} display="grid" rowGap={1}>
        <ResetFiltersButton resetFilters={resetFilters} />
        <SubmitFiltersButton
          onFiltersSubmit={() => {
            onFiltersSubmit()
            closeDrawer()
          }}
        />
      </Box>
    </>
  )
}
