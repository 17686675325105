import { TranslationsKeys } from 'utils/createTranslationKey'

export const enum FieldType {
  Text = 'text',
  Select = 'select',
  Date = 'date',
  Object = 'object',
  Email = 'email',
  Autocomplete = 'autocomplete',
  Image = 'image',
}

interface FieldBase {
  position?: number
  required?: boolean
  'read-only'?: boolean
}
export interface FormFieldText extends FieldBase {
  type: FieldType.Text | FieldType.Email | FieldType.Image
  value?: string | number
}
export interface FormFieldSelect extends FieldBase {
  type: FieldType.Select | FieldType.Autocomplete
  value?: string | number
  options: {
    value: number | string
    translation_key?: string
    name?: string
    translation?: Record<string, { name: string }>
  }[]
}
export interface FormFieldDate extends FieldBase {
  type: FieldType.Date
  value?: string
}

export interface FormFieldObject<TExtended extends boolean> extends FieldBase {
  type: FieldType.Object
  value?: undefined
  fields: TExtended extends true ? FieldsExtended : Fields<string, FormField<false>>
}

export type FormField<TExtended extends boolean> =
  | FormFieldText
  | FormFieldSelect
  | FormFieldDate
  | FormFieldObject<TExtended>
export type FormFieldExtended<T extends FormField<true> = FormField<true>> = T & {
  name: string
  position: number
  translationKey: TranslationsKeys
  header?: TranslationsKeys
  size?: number
}

export type Fields<T extends string = string, U extends FormField<false> = FormField<false>> = Record<T, U>
export type FieldsExtended<T extends string = string> = Fields<T, FormFieldExtended>
