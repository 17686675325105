import { useQuery } from '@tanstack/react-query'
import { getVouchersLinksLB } from 'utils/api/ReceiveVoucher/getVouchersLinksLB'
import voucherQueryKeys from 'utils/api/ReceiveVoucher/voucherQueryKeys'
import { useIsLuckyBirdUser } from 'utils/hooks/useIsLuckyBirdUser'

export const useGetVouchersLinksLB = (webOrderId: string) => {
  const { isSingleLogic } = useIsLuckyBirdUser()

  return useQuery({
    queryKey: voucherQueryKeys.voucherLinksLB(webOrderId),
    queryFn: () => getVouchersLinksLB(webOrderId),
    enabled: isSingleLogic,
  })
}
