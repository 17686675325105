const orderHistoryQueryKeys = {
  all: ['all', 'orderHistory'] as const,

  orderHistoryLists: () => [...orderHistoryQueryKeys.all, 'orderHistoryList'] as const,
  orderHistoryList: (limit: string) => [...orderHistoryQueryKeys.orderHistoryLists(), { limit }] as const,

  voucherOrdersLists: () => [...orderHistoryQueryKeys.all, 'voucherOrdersList'] as const,
  voucherOrdersList: (limit: string) => [...orderHistoryQueryKeys.voucherOrdersLists(), { limit }] as const,
}

export default orderHistoryQueryKeys
