import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'

export const Voucher = ({ ...rest }: SvgIconProps) => {
  return (
    <SvgIcon {...rest} x="0px" y="0px" viewBox="0 0 100 100">
      <path
        d="M6.3,60.1c0-1,0.8-1.7,1.7-1.7c4.6,0,8.4-3.8,8.4-8.4s-3.7-8.4-8.4-8.4c-1,0-1.7-0.8-1.7-1.7V23.2
	c0-1,0.8-1.7,1.7-1.7h84c1,0,1.7,0.8,1.7,1.7v16.7c0,1-0.8,1.7-1.7,1.7c-4.6,0-8.4,3.8-8.4,8.4s3.7,8.4,8.4,8.4c1,0,1.7,0.8,1.7,1.7
	v16.7c0,1-0.8,1.7-1.7,1.7H8c-1,0-1.7-0.8-1.7-1.7V60.1z M69.8,39c1.4,0,2.6-1.2,2.5-2.7C72.2,35,71,34,69.7,34H30.4
	c-1.3,0-2.5,0.9-2.6,2.3c-0.1,1.5,1,2.7,2.5,2.7H69.8z M69.8,53.3c1.4,0,2.6-1.2,2.5-2.7c-0.1-1.3-1.3-2.3-2.6-2.3H30.4
	c-1.3,0-2.5,0.9-2.6,2.3c-0.1,1.5,1,2.7,2.5,2.7H69.8z M69.8,68.4c1.4,0,2.6-1.2,2.5-2.7c-0.1-1.3-1.3-2.3-2.6-2.3H30.4
	c-1.3,0-2.5,0.9-2.6,2.3c-0.1,1.5,1,2.7,2.5,2.7H69.8z"
      />
    </SvgIcon>
  )
}
