import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone'
import RadioButtonChecked from '@mui/icons-material/RadioButtonChecked'
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import { TreeItem, treeItemClasses } from '@mui/x-tree-view'
import { DepartmentTreeProps } from 'components/DepartmentTree/types'
import { UseDepartments } from 'components/DepartmentTree/useDepartments'

interface TreeItemsProps
  extends Pick<DepartmentTreeProps, 'selected' | 'setSelected'>,
    Pick<UseDepartments, 'data' | 'selectedAncestors'> {}

const Label = styled('span')(({ theme }) => ({
  color: theme.palette.grey[800],
  fontSize: theme.typography.h2.fontSize,
  fontWeight: theme.typography.fontWeightBold,
}))

export const DepartmentTreeItems = ({ data, selected, setSelected, selectedAncestors }: TreeItemsProps) => {
  return (
    <>
      {data.map((item) => (
        <TreeItem
          sx={{
            [`& .${treeItemClasses.iconContainer} svg`]: {
              fontSize: (theme) => theme.typography.h1.fontSize,
            },
          }}
          key={item.id}
          itemId={item.id.toString()}
          label={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                checked={selected === item.id.toString()}
                icon={<RadioButtonUnchecked />}
                color="primary"
                checkedIcon={<RadioButtonChecked />}
                indeterminate={selectedAncestors.includes(item.id.toString())}
                indeterminateIcon={<CircleTwoToneIcon />}
                onChange={(e) => {
                  if (e.currentTarget.checked) {
                    setSelected(item.id.toString())
                  } else {
                    setSelected(undefined)
                  }
                }}
                data-cy={`department-radio-${item.id}`}
              />
              <Label>{item.name}</Label>
            </Box>
          }
        >
          {item.__children.length > 0 && (
            <DepartmentTreeItems
              data={item.__children}
              selected={selected}
              setSelected={setSelected}
              selectedAncestors={selectedAncestors}
            />
          )}
        </TreeItem>
      ))}
    </>
  )
}
