import { format, startOfToday, sub } from 'date-fns'

type filterNames = 'week' | 'month' | 'year'

const getDateFromFilterName = (name: filterNames) => {
  const today = startOfToday()
  switch (name) {
    case 'week':
      return sub(today, { weeks: 1 })
    case 'month':
      return sub(today, { months: 1 })
    case 'year':
      return sub(today, { years: 1 })
  }
}

export const getFormattedDateFromFilterName = (name: filterNames) => {
  return format(getDateFromFilterName(name), 'y-M-d')
}
