import { styled } from '@mui/material'
import { Box } from '@mui/system'

interface Props {
  label: string
  color: string
  longestWordLength: number
}
const LabelContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'longestWordLength',
})<{ color: string; longestWordLength: number }>(({ theme, longestWordLength, color }) => ({
  boxSizing: 'content-box',
  backgroundColor: color,
  color: 'white',
  fontSize: '1.75rem',
  fontWeight: theme.typography.fontWeightBold,
  padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
  textAlign: 'center',
  width: `${longestWordLength}ch`,
  borderRadius: '2rem',
}))

export const Label = ({ label, color, longestWordLength }: Props) => {
  return (
    <LabelContainer color={color} longestWordLength={longestWordLength} data-cy="value-label">
      {label}
    </LabelContainer>
  )
}
