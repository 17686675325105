import { useQuery } from '@tanstack/react-query'
import { getRequest } from 'utils/api/apiRequest'
import categoriesQueryKeys from 'utils/api/Categories/categoriesQueryKeys'
import { Category } from 'utils/api/Categories/fetchCategories'

type ResponseData = {
  data: {
    category: Category
  }[]
}

export const useSpecialSubCategories = () => {
  return useQuery({
    queryKey: categoriesQueryKeys.specialSubcategories(),
    queryFn: () => getRequest<ResponseData>(`/redemption-site/special-subcategories`),
    select: (data) => data?.data,
  })
}
