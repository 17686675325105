import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { getRequest } from 'utils/api/apiRequest'
import { Country } from 'utils/api/country'
import { WithPagination } from 'utils/api/pagination'

export const availableCountriesKey = ['all', 'availableCountries']
export const availableCountriesByLangKey = (lang: string) => [...availableCountriesKey, lang]

export const useGetAvailableCountries = () => {
  const { i18n } = useTranslation()
  return useQuery({
    queryKey: availableCountriesByLangKey(i18n.language),
    queryFn: () =>
      getRequest<WithPagination<Country>>(`/user/available-countries?limit=1000&lang=${i18n.language || 'en'}`),
    select: (data) => data?.data,
  })
}
