import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { useAuthContext } from 'store/useAuthContext'
import { useLanguages } from 'utils/api/Languages/useLanguages'
import { patchUser } from 'utils/api/User'

export const useChangeLanguage = () => {
  const { user, updateUser } = useAuthContext()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { data: languages, isFetched } = useLanguages()
  const [selectedLanguage, setSelectedLanguage] = useState(user.profile.language?.id || 0)

  const onChange = async (value: number) => {
    const prevLang = selectedLanguage
    try {
      setSelectedLanguage(value)
      const res = await patchUser({ language_id: value })
      updateUser({ profile: res.data })
      enqueueSnackbar(t('profile.change_language.success'), { variant: 'success' })
    } catch {
      setSelectedLanguage(prevLang)
      enqueueSnackbar(t('profile.change_language.fail'), { variant: 'error' })
    }
  }

  return { onChange, selectedLanguage, languages }
}
