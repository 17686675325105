import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { ENVIRONMENT } from 'config/env'
import isEmpty from 'lodash/isEmpty'
import { getResponsiveSize } from 'theme/styles.utils'
import { CatalogProduct, productTypes } from 'utils/api/Products/CatalogProduct'
import { displayPoints } from 'utils/displayPoints'
import { getObjectTranslation } from 'utils/getObjectTranslation'
import { getProductName } from 'utils/getProductName'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { useBreadcrumbsCategories } from 'utils/hooks/api/useBreadcrumbsCategories'
import { useIsMobile } from 'utils/hooks/useBreakpoints'
import { useIsLuckyBirdUser } from 'utils/hooks/useIsLuckyBirdUser'
import { usePointsName } from 'utils/hooks/usePointsName'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    minHeight: getResponsiveSize(30),
  },
  brand: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.h2.fontSize,
    cursor: 'pointer',
  },
  points: {
    fontSize: theme.typography.h3.fontSize,
    color: theme.palette.grey[600],
  },
  name: {
    fontSize: theme.typography.h3.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[600],
    cursor: 'pointer',
  },
  category: {
    fontSize: theme.typography.h3.fontSize,
    cursor: 'pointer',
  },
}))

type Props = {
  catalogProduct: CatalogProduct
  topProduct?: boolean
}

export const ProductInfoBar = ({ catalogProduct, topProduct }: Props) => {
  const { root, brand, points, name, category } = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const pointsName = usePointsName(catalogProduct?.price_points)
  const { data: breadcrumbsCategories } = useBreadcrumbsCategories()
  const isMobile = useIsMobile()
  const lineHeight = valueOrUndefined(isMobile, 1.5)
  const { categoryId } = useParams<{ categoryId: string }>()
  const { isSingleLogic } = useIsLuckyBirdUser()

  const availableCategoryId = categoryId || catalogProduct?.product?.categories?.[0]?.id

  const brandLink = catalogProduct?.product?.brand
    ? () => navigate(`/catalog/brands/${catalogProduct.product.brand.id}`)
    : undefined
  const productLink = () =>
    navigate(
      availableCategoryId
        ? `/catalog/categories/${availableCategoryId}/products/${catalogProduct.product.id}`
        : `/catalog/products/${catalogProduct.product.id}`
    )
  const categoryLink = () =>
    navigate(`/catalog/categories/${catalogProduct.product?.categories?.[0]?.id || availableCategoryId}`)

  const haveProductVariants = !isEmpty(catalogProduct?.catalog_variants)
  const minPrice = haveProductVariants ? catalogProduct.catalog_variants[0]?.price : 0
  const maxPrice = haveProductVariants
    ? catalogProduct.catalog_variants[catalogProduct.catalog_variants.length - 1]?.price
    : 0
  const numberOfPoints = haveProductVariants
    ? minPrice === maxPrice
      ? displayPoints(minPrice)
      : `${displayPoints(minPrice)} - ${displayPoints(maxPrice)}`
    : displayPoints(catalogProduct?.price_points)

  const categoryName =
    getObjectTranslation(
      breadcrumbsCategories?.data?.data?.find(({ id }) => catalogProduct?.product?.categories?.[0]?.id === id)
        ?.translation
    )?.name ||
    catalogProduct?.product?.categories?.[0]?.name ||
    '-'

  return (
    <div className={root}>
      <Grid container direction="column">
        <Grid item>
          <Grid container spacing={isMobile ? 0 : 1}>
            <Tooltip title={t('link.brand')} followCursor placement="right-start">
              <Grid item xs={12} md onClick={brandLink}>
                <Typography lineHeight={lineHeight} className={brand}>
                  {catalogProduct?.product?.brand?.name || '-'}
                </Typography>
              </Grid>
            </Tooltip>
            {!isSingleLogic && !topProduct && (
              <Grid item sx={{ display: 'inline-flex', alignItems: 'center' }}>
                <Typography lineHeight={lineHeight} className={points}>
                  {numberOfPoints} {pointsName}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Tooltip title={t('link.product')} followCursor placement="right-start">
          <Grid item onClick={productLink}>
            <Typography lineHeight={lineHeight} className={name}>
              {getProductName(catalogProduct.product)}
            </Typography>
          </Grid>
        </Tooltip>
        <Tooltip title={t('link.category')} followCursor placement="right-start">
          <Grid item onClick={categoryLink}>
            <Typography lineHeight={lineHeight} className={category}>
              {categoryName}
            </Typography>
          </Grid>
        </Tooltip>
        {ENVIRONMENT === 'local' && !topProduct && (
          <Grid item>
            <Typography lineHeight={lineHeight} className={category}>
              {productTypes?.[catalogProduct?.product?.type]}
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  )
}
