import { ArteelLogo as ArteelLogoSvg } from 'components/ArteelIcons/ArteelLogo'

type Props = {
  size: string
  paddingVertical?: number
}

export const ArteelLogo = ({ size, paddingVertical }: Props) => {
  return (
    <ArteelLogoSvg
      sx={{
        fontSize: size,
        paddingTop: (theme) => theme.spacing(paddingVertical),
        paddingBottom: (theme) => theme.spacing(paddingVertical),
        height: 'auto',
      }}
    />
  )
}
