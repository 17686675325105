import { useTranslation } from 'react-i18next'
import { ButtonProps, paperClasses } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { TranslationsKeys } from 'utils/createTranslationKey'

type Props = {
  contentText?: TranslationsKeys
  openDialog?: boolean
  confirmAction: () => void
  discardAction?: () => void
  onClose: () => void
  showBackButton?: boolean
  confirmTranslation?: TranslationsKeys
  confirmColor?: ButtonProps['color']
}

const DialogConfirm = ({
  contentText,
  openDialog = true,
  confirmAction,
  discardAction,
  onClose,
  showBackButton = true,
  confirmTranslation = 'app.yes',
  confirmColor = 'primary',
}: Props) => {
  const { t } = useTranslation()

  return (
    <Dialog
      disableEscapeKeyDown
      open={openDialog}
      onClose={onClose}
      sx={(_) => ({
        zIndex: 1301,
        [`& .${paperClasses.root}`]: {
          borderRadius: '16px',
          p: 2,
        },
      })}
    >
      {contentText && (
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" variant="h3">
            {t(contentText)}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        {showBackButton && (
          <Button
            onClick={() => {
              if (discardAction) {
                discardAction()
              }
              onClose()
            }}
            variant="outlined"
            size="small"
          >
            {t('app.no')}
          </Button>
        )}
        <Button
          data-cy="confirm-button"
          onClick={() => {
            confirmAction()
            onClose()
          }}
          color={confirmColor}
          variant="outlined"
          size="small"
        >
          {t(confirmTranslation)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogConfirm
