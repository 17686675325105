import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { ResponsivePaper } from 'components/ResponsivePaper'
import { MyAccountHeader } from 'pages/MyAccount/components/MyAccountHeader'
import { ProfileDetails } from 'pages/MyAccount/components/ProfileDetails'
import { Form } from 'pages/MyAccountEdit/Form'
import { useIsMobile } from 'utils/hooks/useBreakpoints'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'

export const MyAccountEdit = () => {
  const user = useCurrentUser()
  const isMobile = useIsMobile()

  return (
    <ResponsivePaper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <MyAccountHeader />
                </Grid>
                <Grid item>
                  <Typography
                    variant="h3"
                    color="primary"
                    sx={(theme) => ({
                      fontWeight: 900,
                      textAlign: 'center',
                    })}
                  >
                    {user?.profile?.full_name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md="auto">
              <Divider orientation={isMobile ? 'horizontal' : 'vertical'} />
            </Grid>
            <Grid item xs={12} md="auto">
              <ProfileDetails user={user} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Form />
          <br />
        </Grid>
      </Grid>
    </ResponsivePaper>
  )
}
