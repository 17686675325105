import { useQuery } from '@tanstack/react-query'
import { extendFields } from 'components/JSONForm/extendFields'
import { Fields, FieldType } from 'components/JSONForm/types'
import { getRequest } from 'utils/api/apiRequest'
import { useLanguages } from 'utils/api/Languages/useLanguages'

type FieldNames = 'avatar' | 'email'

export interface GetActivationCodeFormResponse<T extends string> {
  fields: Fields<T>
}

interface GetActivationCodeFormPayload {
  code: string
  user: number
}

const getForm = ({ code, user }: GetActivationCodeFormPayload) =>
  getRequest<GetActivationCodeFormResponse<FieldNames>>(`/activation-codes/${code}/form/user/${user}`)

export const useGetActivationCodeForm = (args: GetActivationCodeFormPayload & { translationKey: string }) => {
  const { data: languages, isFetched } = useLanguages()
  const langsToOptions = languages?.data.map(({ language }) => ({ value: language.code, name: language.name }))
  const langValue = (value: string | number) => langsToOptions.find((lang) => lang.name === value)?.value || null

  return useQuery({
    queryFn: () => getForm({ code: args?.code, user: args?.user }),
    queryKey: [`activationCodeForm-${args?.user}-${args?.code}`],
    staleTime: 0,
    cacheTime: 0,
    select: (data) => {
      return extendFields(data.data.fields, {
        orderedKeys: ['company_name', 'street', 'number', 'box', 'zip', 'city', 'country'],
        translationKey: args.translationKey,
        overrideProperties: [
          { keys: ['email'], properties: { type: FieldType.Email } },
          { keys: ['home_address'], properties: { header: 'auth.activate_account.field.header.home_address' } },
          { keys: ['work_address'], properties: { header: 'auth.activate_account.field.header.work_address' } },
          { keys: ['company_name'], properties: { size: 12 } },
          { keys: ['company'], properties: { size: 12 } },
          { keys: ['street'], properties: { size: 6 } },
          { keys: ['number', 'box'], properties: { size: 3 } },
          { keys: ['zip'], properties: { size: 3 } },
          { keys: ['city'], properties: { size: 9 } },
          {
            keys: ['country'],
            properties: { type: FieldType.Autocomplete, size: 12 },
          },
          {
            keys: ['language'],
            properties: { type: FieldType.Select, options: langsToOptions, value: langValue },
          },
        ],
      })
    },
  })
}
