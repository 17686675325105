import { useTranslation } from 'react-i18next'
import { textLengthCounter } from 'components/Message/utils'
import * as Yup from 'yup'

const MIN_COMMENT_LENGTH = 1
export const MAX_COMMENT_LENGTH = 750

export const useValidationSchema = () => {
  const { t } = useTranslation()
  return {
    validationSchema: Yup.object().shape({
      content: Yup.string()
        .required()
        .test(
          'min-chars',
          t('recognize_someone.min_characters', { number: MIN_COMMENT_LENGTH }),
          (v) => textLengthCounter(v) >= MIN_COMMENT_LENGTH
        )
        .test(
          'max-chars',
          t('recognize_someone.max_characters', { number: MAX_COMMENT_LENGTH }),
          (v) => textLengthCounter(v) <= MAX_COMMENT_LENGTH
        ),
    }),
  }
}

export function spliceIntoChunks<T>(arr: T[], chunkSize: number) {
  const res: T[][] = []
  while (arr.length > 0) {
    const chunk = arr.splice(0, chunkSize)
    res.push(chunk)
  }
  return res
}
