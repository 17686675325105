import { Fragment } from 'react'
import Divider from '@mui/material/Divider'
import { CmsContent } from 'pages/CMS/CMS.types'
import { FCWithChildren } from 'utils/types/FCWithChildren'

import { ContentBox } from '.'

export interface LogicBoxContentProps {
  contents: CmsContent[]
  sectionName?: string
}

export const LogicBoxContent: FCWithChildren<LogicBoxContentProps> = ({ contents, sectionName = '' }) => (
  <>
    {contents.map((content, index, arr) => (
      <Fragment key={content.id}>
        <ContentBox content={content} sectionName={sectionName} />
        {index < arr.length - 1 && (
          <Divider variant="middle" sx={{ mt: 4, mb: 4, borderColor: (theme) => theme.palette.background.default }} />
        )}
      </Fragment>
    ))}
  </>
)
