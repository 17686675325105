import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import categoriesQueryKeys from 'utils/api/Categories/categoriesQueryKeys'
import { createOrder } from 'utils/api/Order/createOrder'
import { ErrorCodes, parseError } from 'utils/api/parseError'
import productsQueryKeys from 'utils/api/Products/productsQueryKeys'
import userQueryKeys from 'utils/api/User/userQueryKeys'

type Props = {
  onSuccess?: (id: number, email: string) => void
}

export const useCreateOrder = ({ onSuccess }: Props) => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation(createOrder, {
    retry: (failureCount, error) => {
      const e = parseError(error)
      // when backend returns 409, it means that order with same ext_web_order_reference already exists
      return e.status === ErrorCodes.CONFLICT && failureCount < 2
    },
    onSuccess: (data) => {
      if (onSuccess) {
        onSuccess(data?.data?.ext_web_order_reference?.id, data?.data?.order_customer_details?.email)
      }
      enqueueSnackbar(t('create_order.success'), { variant: 'success' })
      queryClient.resetQueries({ queryKey: productsQueryKeys.all })
      queryClient.resetQueries({ queryKey: categoriesQueryKeys.all })
      queryClient.invalidateQueries({ queryKey: userQueryKeys.me() })
    },
    onError: () => {
      enqueueSnackbar(t('create_order.fail'), { variant: 'error' })
    },
  })
}
