import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import { Image } from 'components/Image/Image'
import { DEFAULT_LANGUAGE } from 'config/constants'
import { TrackingCode } from 'pages/OrderHistory/components/OrderHistoryRow'
import { getResponsiveSize } from 'theme/styles.utils'
import { OrderItem } from 'utils/api/OrderHistory/getOrderHistory'
import { displayPoints } from 'utils/displayPoints'
import { useIsLuckyBirdUser } from 'utils/hooks/useIsLuckyBirdUser'
import { usePointsName } from 'utils/hooks/usePointsName'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles(() => ({
  image: {
    width: getResponsiveSize(140),
    height: getResponsiveSize(140),
  },
}))

type Props = {
  orderItem: OrderItem
}

export const OrderItemDetails = ({ orderItem }: Props) => {
  const { image } = useStyles()
  const { i18n } = useTranslation()
  const { isSingleLogic } = useIsLuckyBirdUser()

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs>
            <Grid container direction="column">
              <Grid item className="bold">
                {orderItem.product.brand?.name?.toUpperCase() || ''}
              </Grid>
              <Grid item className="bold-grey">
                {orderItem.product.translation[i18n.language]?.name ||
                  orderItem.product.translation[DEFAULT_LANGUAGE]?.name ||
                  ''}
              </Grid>
              <Grid item className="bold-grey">
                {orderItem.product.code}
              </Grid>
              {!isSingleLogic && (
                <Grid item className="bold">
                  {`${displayPoints(orderItem.points)} ${usePointsName(orderItem.points)} x ${
                    orderItem.quantity
                  } = ${displayPoints(orderItem.points * orderItem.quantity)} ${usePointsName(
                    orderItem.points * orderItem.quantity
                  )}`}
                </Grid>
              )}
              {orderItem.tracking_number && (
                <Grid item>
                  <TrackingCode
                    trackingNumber={orderItem.tracking_number}
                    trackingNumberUrl={orderItem.tracking_number_url}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item className={image}>
            <Image src={orderItem.product.media.find((image) => image.main)?.path} alt={orderItem.product.code} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
