import { createContext, useCallback, useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import * as Sentry from '@sentry/browser'
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosResponse, isAxiosError } from 'axios'
import { LoaderFullScreen } from 'components/LoaderFullScreen/LoaderFullScreen'
import { ENVIRONMENT } from 'config/env'
import isEmpty from 'lodash/isEmpty'
import { useSnackbar } from 'notistack'
import qs from 'query-string'
import { useSubdomainInfo } from 'shared/Site/api'
import { logout } from 'utils/api/apiClient'
import { getCurrentUser } from 'utils/api/User/getCurrentUser'
import userQueryKeys from 'utils/api/User/userQueryKeys'
import { getAccessToken, isLoggedIn } from 'utils/axios-jwt-cookies'
import { useIsCypress } from 'utils/hooks/useIsCypress'
import { User } from 'utils/hooks/userType'
import { userTypes } from 'utils/hooks/userTypes'
import { FCWithChildren } from 'utils/types/FCWithChildren'

type AuthContextType = {
  user: User
  logoutUser: () => Promise<void>
  isLoading: boolean
  refetch: (options?: RefetchOptions & RefetchQueryFilters) => Promise<QueryObserverResult<AxiosResponse<User>>>
  updateUser(params: Partial<User>): void
}

const AuthContext = createContext<AuthContextType | null>(null)
const { Provider, Consumer } = AuthContext
const notFoundRoute = '/404'

const AuthProvider: FCWithChildren = ({ children }) => {
  const [user, setUser] = useState<User | undefined>(undefined)
  const { enqueueSnackbar } = useSnackbar()
  // @ts-ignore
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { data: subdomainInfo, isFetching: isFetchingSubdomainInfo } = useSubdomainInfo()
  const params = qs.parse(location.search.replace('?', ''))
  const isNotFoundPage = window.location.pathname === notFoundRoute
  const { isCypress } = useIsCypress()
  const isLuckyBird = user?.type === userTypes.lucky_bird
  const queryClient = useQueryClient()

  if (isEmpty(subdomainInfo?.data) && !isNotFoundPage && !isFetchingSubdomainInfo) {
    window.location.assign(notFoundRoute)
  }

  const { isInitialLoading, refetch } = useQuery({
    queryKey: userQueryKeys.me(),
    queryFn: getCurrentUser,
    enabled: (!isNotFoundPage && !!getAccessToken()) || isCypress,
    retry: false,
    onSuccess: (data) => {
      setUser(data.data)
      Sentry.setUser({
        id: data?.data?.id?.toString() || 'no id',
        email: data?.data?.email || 'no email',
      })
      Sentry.setTag('domain', window.location.href)
      ReactGA.set({
        userId: data?.data?.id?.toString() || 'no id',
        environment: ENVIRONMENT,
        sessionCampaignName: subdomainInfo?.data?.subscriber?.name,
      })
      if (data?.data?.cypress_redirect) navigate(data?.data?.cypress_redirect)
    },
    onError: (error) => {
      if (isAxiosError(error)) {
        if (error?.config?.headers?.Authorization) {
          logout()
        }
      }
    },
  })

  const logoutUser = useCallback(async () => {
    await navigate(isLuckyBird ? '/auth/luckybird/logout' : '/auth/logout')
    await logout()
    await setUser(undefined)
    await queryClient.resetQueries(['all'])
    enqueueSnackbar(t('auth.logout.success'), { variant: 'success' })
  }, [enqueueSnackbar, navigate, t])

  useEffect(() => {
    if (isCypress) return
    const intervalId = setInterval(() => {
      // the moment of logging out on another tab
      if (!isLoggedIn() && !!user && !params?.token) {
        logoutUser()
      }
    }, 5000)

    return () => clearInterval(intervalId)
  }, [user, logoutUser, params])

  const updateUser: AuthContextType['updateUser'] = (params) => {
    setUser((u) => ({ ...u, ...params }))
  }

  return (
    <Provider
      value={{
        user,
        logoutUser,
        isLoading: isInitialLoading,
        refetch: () => {
          queryClient.invalidateQueries(userQueryKeys.all)
          return refetch()
        },
        updateUser,
      }}
    >
      {isCypress && (
        <Button
          name="force-login"
          sx={(_) => ({
            display: 'none',
          })}
          onClick={() => {
            queryClient.invalidateQueries({ queryKey: ['all'] })
            refetch()
          }}
        >
          Force login
        </Button>
      )}
      {isInitialLoading || isFetchingSubdomainInfo ? <LoaderFullScreen /> : children}
    </Provider>
  )
}

export { AuthProvider, Consumer as AuthConsumer }
export default AuthContext
