import { AxiosResponse } from 'axios'

export const isAxiosResponse = (response: unknown): response is AxiosResponse => {
  const axiosResponse = response as AxiosResponse
  return (
    typeof axiosResponse.status === 'number' &&
    typeof axiosResponse.statusText === 'string' &&
    axiosResponse.data !== undefined &&
    axiosResponse.headers !== undefined &&
    axiosResponse.config !== undefined
  )
}
