import { useState } from 'react'
import { BrowserRouter, useNavigate } from 'react-router-dom'
import LoadingButton from '@mui/lab/LoadingButton'
import { ThemeProvider } from '@mui/material'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { StyledEngineProvider } from '@mui/material/styles'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import Typography from '@mui/material/Typography'
import Zoom from '@mui/material/Zoom'
import { FallbackRender } from '@sentry/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ArteelLogo } from 'components/ArteelLogo/ArteelLogo'
import { ENVIRONMENT } from 'config/env'
import { queryClient } from 'config/queryConfig'
import { useArteelTheme } from 'theme/styles'
import { logout } from 'utils/api/apiClient'

const Page: FallbackRender = ({ resetError, error, componentStack }) => {
  const [showDialog, setShowDialog] = useState(false)
  const [isReloading, setIsReloading] = useState(false)
  const navigate = useNavigate()

  const logoutHandler = async () => {
    resetError()
    navigate('/')
    await logout()
  }

  const reloadHandler = () => {
    setIsReloading(true)
    window.location.reload()
  }

  const Buttons = () => (
    <Grid item container spacing={2} justifyContent="center">
      <Grid item>
        <Button variant="contained" onClick={logoutHandler}>
          Logout
        </Button>
      </Grid>
      <Grid item>
        <LoadingButton
          loading={isReloading}
          disabled={isReloading}
          variant="contained"
          color="primary"
          onClick={reloadHandler}
        >
          Reload page
        </LoadingButton>
      </Grid>
    </Grid>
  )
  return (
    <>
      <Container
        fixed
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Zoom in>
          <Paper elevation={24} sx={{ padding: 5 }}>
            <Grid container spacing={3} justifyContent="center" alignItems="center" flexDirection="column">
              <Grid item>
                <ArteelLogo size="20rem" />
              </Grid>
              <Grid item container spacing={2} justifyContent="center" alignItems="center" xs>
                <Grid item xs>
                  <Typography gutterBottom textAlign="center" variant="h3" variantMapping={{ h3: 'p' }}>
                    Something went wrong and an unexpect error occurred.
                  </Typography>
                  <Typography
                    gutterBottom
                    fontWeight={500}
                    textAlign="center"
                    variant="h3"
                    variantMapping={{ h3: 'p' }}
                  >
                    Please reload page or logout and try again.
                  </Typography>
                </Grid>
                {ENVIRONMENT !== 'prod' && (
                  <>
                    <Grid item xs={12}>
                      <TextareaAutosize disabled maxRows={18} defaultValue={error.message} style={{ width: '100%' }} />
                    </Grid>
                    <Grid item xs alignItems="center" justifyContent="center">
                      <Button fullWidth color="primary" onClick={() => setShowDialog(true)}>
                        Show detailed error
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
              <Buttons />
            </Grid>
          </Paper>
        </Zoom>
      </Container>
      <Dialog open={showDialog} fullScreen>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Detailed error
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs>
              <TextareaAutosize disabled defaultValue={componentStack} style={{ width: '100%' }} />
            </Grid>
            <Buttons />
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}

const ErrorPage: FallbackRender = (props) => {
  const subscriberMaterialTheme = useArteelTheme({})
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={subscriberMaterialTheme}>
            <Page {...props} />
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    </BrowserRouter>
  )
}
export default ErrorPage
