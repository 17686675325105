import DOMPurify, { Config } from 'dompurify'

const isAllowedDomain = (url: string) => {
  const allowedDomains = ['youtube.com', 'vimeo.com', 'arteel.com', 'localhost']

  const urlObj = new URL(url)
  const domain = urlObj.hostname

  // check if the domain is on the allowed list (including subdomains)
  return allowedDomains.some((allowedDomain) => {
    const regex = new RegExp(`(^|\\.)${allowedDomain.replace(/\./g, '\\.')}$`)
    return regex.test(domain)
  })
}

DOMPurify.addHook('beforeSanitizeElements', (node: any) => {
  // check if the element is an iframe or video
  if (node.tagName === 'IFRAME' || node.tagName === 'VIDEO') {
    const srcAttr = node.getAttribute('src') || node.getAttribute('data-src')

    // if domain is not allowed, remove the element
    if (srcAttr && !isAllowedDomain(srcAttr)) {
      node.parentNode?.removeChild(node)
    }
  }
})

export const safeHtml = (dirty: string, params: Config = {}) => {
  const HTML = DOMPurify.sanitize(dirty, {
    ...params,
    ADD_TAGS: ['iframe', 'style', ...(params?.ADD_TAGS ?? [])],
    ADD_ATTR: [
      'allowfullscreen',
      'style',
      'title',
      'src',
      'srcset',
      'allow',
      'frameborder',
      'height',
      'width',
      'target',
      'alt',
      'autoplay',
      'cite',
      'class',
      'className',
      'controls',
      'disabled',
      'href',
      'id',
      'maxlength',
      'media',
      'muted',
      'onabort',
      'oncanplay',
      'oncanplaythrough',
      'title',
      ...(params?.ADD_ATTR ?? []),
    ],
  })

  return {
    __html: HTML as string,
  }
}
