export const CONSTANTS = {
  drawerBleeding: 56,
  drawerEdgeWidth: 16,
  paperBorderRadius: {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  paperHorizontalPadding: 2,
  iOS: typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent),
}
