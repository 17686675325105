import React from 'react'
import { useTranslation } from 'react-i18next'
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker'
import { FormikValues, useFormikContext } from 'formik'
import isEmpty from 'lodash/isEmpty'
import { TranslationsKeys } from 'utils/createTranslationKey'
import { dateFormat } from 'utils/dateFormats'

type Props = Partial<DatePickerProps<Date> & React.RefAttributes<HTMLDivElement>> & {
  name: string
  label: TranslationsKeys
  disabled?: boolean
}

export const FormikDatePicker = ({ name, label, ...props }: Props) => {
  const { values, setFieldValue, getFieldMeta } = useFormikContext<FormikValues>()
  const { t } = useTranslation()
  const { touched, error } = getFieldMeta(name)
  const hasError = !isEmpty(error) && touched
  const errorText = touched && error && error
  const handleDateChange = (date: Date) => {
    if (date === null) {
      setFieldValue(name, null)
    } else {
      setFieldValue(name, date, true)
    }
  }

  return (
    <DatePicker
      views={['year', 'month', 'day']}
      format={dateFormat}
      onChange={handleDateChange}
      value={values[name]}
      label={t(label)}
      slotProps={{
        textField: {
          error: hasError,
          helperText: errorText,
          name,
        },
        popper: {
          placement: 'top-end',
        },
        inputAdornment: {
          'data-cy': 'open-calendar-button',
        },
      }}
      {...props}
    />
  )
}
