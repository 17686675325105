// @ts-nocheck
import ProximaNovaBlack from './Proxima-Nova-Alt-Black.otf'
import ProximaNovaBold from './Proxima-Nova-Alt-Bold.otf'
import ProximaNovaCondBlack from './Proxima-Nova-Alt-Cond-Black.otf'
import ProximaNovaCondBold from './Proxima-Nova-Alt-Cond-Bold.otf'
import ProximaNovaCond from './Proxima-Nova-Alt-Cond-Reg.otf'
import ProximaNova from './Proxima-Nova-Alt-Reg.otf'
// @ts-check

const proximaNova = {
  fontFamily: 'ProximaNova',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('ProximaNova'),
    local('ProximaNova-Regular'),
    url(${ProximaNova}) format('opentype')
  `,
}
const proximaNovaBlack = {
  fontFamily: 'ProximaNova',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 900,
  src: `
    local('ProximaNova-Black'),
     url(${ProximaNovaBlack}) format('opentype')
  `,
}
const proximaNovaBold = {
  fontFamily: 'ProximaNova',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local('ProximaNova-Bold'),
    url(${ProximaNovaBold}) format('opentype')
  `,
}

const proximaNovaCond = {
  fontFamily: 'ProximaNovaCond',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('ProximaNovaCond'),
    local('ProximaNovaCond-Regular'),
    url(${ProximaNovaCond}) format('opentype')
  `,
}
const proximaNovaCondBlack = {
  fontFamily: 'ProximaNovaCond',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 900,
  src: `
    local('ProximaNovaCond-Black'),
    url(${ProximaNovaCondBlack}) format('opentype')
  `,
}
const proximaNovaCondBold = {
  fontFamily: 'ProximaNovaCond',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local('ProximaNovaCond-Bold'),
    url(${ProximaNovaCondBold}) format('opentype')
  `,
}

export const fonts = `
          @font-face {
            font-family: 'Material Icons';
            font-style: normal;
            font-weight: 400;
            src: url(https://fonts.gstatic.com/s/materialicons/v109/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
          }
          @font-face {
            font-family: 'ProximaNova';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('ProximaNova'), local('ProximaNova-Regular'), url(${ProximaNova}) format('opentype');
          }
          @font-face {
            font-family: 'ProximaNova';
            font-style: normal;
            font-display: swap;
            font-weight: 900;
            src: local('ProximaNova-Black'), url(${ProximaNovaBlack}) format('opentype');
          }
          @font-face {
            font-family: 'ProximaNova';
            font-style: normal;
            font-display: swap;
            font-weight: 700;
            src: local('ProximaNova-Bold'), url(${ProximaNovaBold}) format('opentype');
          }
          @font-face {
            font-family: 'ProximaNovaCond';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('ProximaNovaCond'), local('ProximaNovaCond-Regular'), url(${ProximaNovaCond}) format('opentype');
          }
          @font-face {
            font-family: 'ProximaNovaCond';
            font-style: normal;
            font-display: swap;
            font-weight: 900;
            src: local('ProximaNovaCond-Black'), url(${ProximaNovaCondBlack}) format('opentype');
          }
          @font-face {
            font-family: 'ProximaNovaCond';
            font-style: normal;
            font-display: swap;
            font-weight: 700;
            src: local('ProximaNovaCond-Bold'), url(${ProximaNovaCondBold}) format('opentype');
          }
          `

export { proximaNova, proximaNovaBlack, proximaNovaBold, proximaNovaCond, proximaNovaCondBold, proximaNovaCondBlack }
