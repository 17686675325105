import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import { HeaderPage } from 'components/HeaderPage/HeaderPage'
import { OrderItemDetails } from 'pages/OrderHistory/components/OrderItemDetails'
import { fontFamilies, getResponsiveSize } from 'theme/styles.utils'
import { OrderHistoryRow as OrderHistoryPosition } from 'utils/api/OrderHistory/getOrderHistory'
import { displayPoints } from 'utils/displayPoints'
import { convertOrderHistoryToOrderValues, getOrderValue } from 'utils/getOrderValue'
import { useIsMobile } from 'utils/hooks/useBreakpoints'
import { useIsLuckyBirdUser } from 'utils/hooks/useIsLuckyBirdUser'
import { makeStyles } from 'utils/styles/makeStyles'
import { useDisplayDateInTimeZone } from 'utils/useDisplayDateInTimeZone'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      width: getResponsiveSize(900),
    },
  },
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: 12,
    },
  },
  dataRow: {
    paddingLeft: theme.spacing(17.5),
    fontSize: theme.typography.h2.fontSize,
    fontFamily: fontFamilies.default,
    '& .label': {
      color: theme.palette.grey[500],
    },
  },
  orderItems: {
    padding: theme.spacing(0, 17.5),
    fontFamily: fontFamilies.cond,
    fontSize: theme.typography.h2.fontSize,
    lineHeight: 1.2,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.h3.fontSize,
    },
    '& .bold': {
      fontWeight: theme.typography.fontWeightBold,
    },
    '& .bold-grey': {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.grey[500],
    },
  },
}))

type Props = {
  order: OrderHistoryPosition
  onClose: () => void
}

export const OrderDetails = ({ order, onClose }: Props) => {
  const [open, setOpen] = useState(!!order)
  const { container, dialog, dataRow, orderItems } = useStyles()
  const { t, i18n } = useTranslation()
  const fullScreen = useIsMobile()
  const { isSingleLogic } = useIsLuckyBirdUser()
  const { displayDateInTimeZone } = useDisplayDateInTimeZone()

  const orderValue = getOrderValue(convertOrderHistoryToOrderValues(order?.order_items || []))
  const orderItemAmount = order?.order_items?.reduce((prev, cur) => prev + cur?.quantity, 0) ?? 0

  useEffect(() => {
    if (order && !open) {
      setOpen(true)
    }
  }, [order, open])

  const handleClose = () => {
    onClose()
    setOpen(false)
  }

  return (
    <Dialog open={!!order} onClose={handleClose} fullWidth={fullScreen} maxWidth={false} className={dialog}>
      {order && (
        <div className={container}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <HeaderPage size="medium" text={`${t('order_history.order')}: ${order.ext_web_order_reference.id}`} />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={4} className={dataRow}>
                <Grid item xs={4}>
                  <span className="label">{t('order_history.confirmed_on')}</span>
                </Grid>
                <Grid item>{displayDateInTimeZone(order.created_at)}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={4} className={dataRow}>
                <Grid item xs={4}>
                  <span className="label">{t('order_history.delivery_to')}</span>
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      {order.order_customer_details.first_name} {order.order_customer_details.last_name}
                    </Grid>
                    <Grid item>
                      {order.order_customer_details.street} {order.order_customer_details.number}{' '}
                      {order.order_customer_details.box}
                    </Grid>
                    <Grid item>
                      {order.order_customer_details.zip} {order.order_customer_details.city}
                    </Grid>
                    <Grid item>{order.order_customer_details.country?.translation?.[i18n.language]?.name}</Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={4} className={dataRow}>
                <Grid item xs={4}>
                  <span className="label">{t('order_history.mail')}</span>
                </Grid>
                <Grid item>{order.order_customer_details.email}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={4} className={dataRow}>
                <Grid item xs={4}>
                  <span className="label">{t('order_history.phone')}</span>
                </Grid>
                <Grid item>{order.order_customer_details.phone}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <div className={orderItems}>
                {order.order_items.map((orderItem) => {
                  console.log(
                    'orderItem?.quantity !== orderItem?.cancelled_quantity, orderItem?.cancelled_quantity > 0 ',
                    orderItem?.quantity !== orderItem?.cancelled_quantity,
                    orderItem?.cancelled_quantity > 0
                  )
                  return orderItem?.quantity !== orderItem?.cancelled_quantity && orderItem?.cancelled_quantity > 0 ? (
                    [
                      <Box key={`order-item-${orderItem.id}`} pb={1}>
                        <OrderItemDetails orderItem={orderItem} />
                      </Box>,
                      <Box key={`order-item-${orderItem.id}-cancelled`} pb={1}>
                        <OrderItemDetails orderItem={orderItem} />
                      </Box>,
                    ]
                  ) : (
                    <Box key={`order-item-${orderItem.id}`} pb={1}>
                      <OrderItemDetails orderItem={orderItem} />
                    </Box>
                  )
                })}
              </div>
            </Grid>
            {!isSingleLogic && (
              <Grid item xs={12}>
                <div className={orderItems}>
                  <Grid container direction="column">
                    <Grid item className="bold">
                      {t('order_history.number')}: {orderItemAmount}
                    </Grid>
                    <Grid item className="bold">
                      {t('order_history.total_points')}: {displayPoints(orderValue)}
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            )}
          </Grid>
        </div>
      )}
    </Dialog>
  )
}
