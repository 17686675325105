import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useDispatchShoppingCart } from 'shared/ShoppingCart/slice'
import { removeItem } from 'utils/api/ShoppingCart/removeItem'

type Props = {
  onSuccess?: () => void
}

export const useRemoveCartItem = ({ onSuccess }: Props) => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const { refetchShoppingCart } = useDispatchShoppingCart()

  return useMutation(removeItem, {
    onSuccess: () => {
      refetchShoppingCart()
      onSuccess?.()
    },
    onError: () => {
      enqueueSnackbar(t('shopping_cart.remove_item.fail'), { variant: 'error' })
    },
  })
}
