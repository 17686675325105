import './index.css'

import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ErrorBoundary } from '@sentry/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { App } from 'App'
import ErrorPage from 'components/ErrorPage'
import { queryClient } from 'config/queryConfig'
import { MaintenanceProvider } from 'store/MaintenanceContext'

import * as serviceWorker from './serviceWorker'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <BrowserRouter>
    <ErrorBoundary fallback={(props) => <ErrorPage {...props} />}>
      <QueryClientProvider client={queryClient}>
        <MaintenanceProvider>
          <App />
        </MaintenanceProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  </BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
