import { useTranslation } from 'react-i18next'
import { useSubdomainInfo } from 'shared/Site/api'

export const usePointsName = (points: number) => {
  const { t, i18n } = useTranslation()
  const { data: subdomainInfo } = useSubdomainInfo()
  const isSingular = points === 1 || points === -1

  return (
    subdomainInfo?.data?.subscriber?.[isSingular ? 'points_name_singular' : 'points_name_plural']?.translation?.[
      i18n.language
    ]?.name || t('app.points')
  )
}
