import { ShoppingCartItems } from 'shared/ShoppingCart/api'
import { patchRequest } from 'utils/api/apiRequest'

export type PersonalizationLineProps = {
  cart_item_id: string
  text: string
  position: number
}

export const patchPersonalizationText = (data: PersonalizationLineProps) =>
  patchRequest<ShoppingCartItems, PersonalizationLineProps>(`/redemption-site/cart/personalize-text`, data)
