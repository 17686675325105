import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

export const Document = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 75 75">
    <g xmlns="http://www.w3.org/2000/svg">
      <path d="M20.4,50c0,1.9,1.5,3.4,3.4,3.4h27.3c1.9,0,3.4-1.5,3.4-3.4s-1.5-3.4-3.4-3.4H23.9C22,46.6,20.4,48.1,20.4,50z   " />
      <path d="M20.4,37.5c0,1.9,1.5,3.4,3.4,3.4h27.3c1.9,0,3.4-1.5,3.4-3.4s-1.5-3.4-3.4-3.4H23.9   C22,34.1,20.4,35.6,20.4,37.5z" />
      <path d="M23.9,21.6c-1.9,0-3.4,1.5-3.4,3.4s1.5,3.4,3.4,3.4h27.3c1.9,0,3.4-1.5,3.4-3.4s-1.5-3.4-3.4-3.4H23.9z" />
    </g>
  </SvgIcon>
)
