import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import { Arrow } from 'components/ArteelIcons'
import { useCategoriesDrawerContext } from 'theme/Mobile/CategoriesDrawer/hooks'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { useIsMobile } from 'utils/hooks/useBreakpoints'
import { important } from 'utils/styles'

export const CategoriesDrawerButton = () => {
  const { toggleCategoriesDrawer } = useCategoriesDrawerContext()
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  return (
    <Button
      sx={{
        fontWeight: 900,
        display: 'flex',
        alignItems: 'baseline',
        pr: 0,
        pl: { xs: 0, lg: 2 },
        py: valueOrUndefined(isMobile, 0),
      }}
      endIcon={<Arrow direction="right" sx={{ fontSize: important('.7rem'), mb: 0 }} />}
      size="small"
      onClick={() => toggleCategoriesDrawer(true)}
    >
      {t('catalog')}
    </Button>
  )
}
