import { useTranslation } from 'react-i18next'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import { getResponsiveSize } from 'theme/styles.utils'
import { TranslationsKeys } from 'utils/createTranslationKey'

interface HighlightedNumberProps {
  number: number
  word: TranslationsKeys
}

const HighlightedNumber = ({ number, word }: HighlightedNumberProps) => {
  const { t } = useTranslation()

  return (
    <Grid item container xs sx={(theme) => ({ textAlign: 'center', fontSize: theme.typography.h2.fontSize })}>
      <Grid
        item
        xs={12}
        sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, fontSize: getResponsiveSize(2.5, 'rem') })}
      >
        {number}
      </Grid>
      <Grid item xs={12}>
        {t(word)}
      </Grid>
    </Grid>
  )
}

interface Props {
  received: number
  given: number
}

export const HighlightedNumbers = ({ received, given }: Props) => {
  return (
    <>
      <HighlightedNumber number={received} word={'my_score.received'} />
      <Divider flexItem orientation="vertical" />
      <HighlightedNumber number={given} word={'my_score.given'} />
    </>
  )
}
