import isEmpty from 'lodash/isEmpty'
import { OrderItem } from 'utils/api/OrderHistory/getOrderHistory'

export type OrderItemValue = {
  points: number
  quantity: number
}
export const getOrderValue = (orderItems: OrderItemValue[]): number => {
  const orderItemsValues = orderItems.map((item) => item.points * item.quantity)
  return isEmpty(orderItemsValues) ? 0 : orderItemsValues.reduce((prev, current) => prev + current)
}

export const convertOrderHistoryToOrderValues = (orderItems: OrderItem[]): OrderItemValue[] => {
  return orderItems?.map((i) => ({ points: i.points, quantity: i.quantity })) || []
}
