import Grid from '@mui/material/Grid'
import { FCWithChildren } from 'utils/types/FCWithChildren'

import { SectionCarouselMobile } from './SectionCarouselMobile'

export const SectionsWrapper: FCWithChildren = ({ children }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <SectionCarouselMobile>{children}</SectionCarouselMobile>
      </Grid>
    </Grid>
  )
}
