import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  EMPTY_FILTER_VALUE,
  FILTERS,
  GetFilterValue,
  OnFilterChange,
  ParamsState,
} from 'pages/SocialWallPostFilters/utils'
import qs from 'query-string'
import { valueOrUndefined } from 'utils/getValueOrUndefined'

export const usePostsFilters = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const initParams = qs.parse(search, { decode: false })
  const [params, setParams] = useState<ParamsState>(initParams)
  const [disabledFilters, setDisabledFilters] = useState({ fromMyself: false, toMyself: false })
  const onFilterChange: OnFilterChange = (filterKey, filterValue = EMPTY_FILTER_VALUE) => {
    setParams((prev) => ({
      ...prev,
      [FILTERS[filterKey]]: valueOrUndefined(filterValue !== EMPTY_FILTER_VALUE, filterValue),
    }))
  }
  const onFiltersSubmit = (p = params) => {
    navigate(`${location.pathname}?${qs.stringify(p, { encode: false })}`)
  }
  const resetFilters = () => {
    setParams({})
    onFiltersSubmit({})
  }

  const getFilterValue: GetFilterValue = (k) => params[FILTERS[k]] || EMPTY_FILTER_VALUE

  useEffect(() => {
    setDisabledFilters({
      fromMyself: !!params['filters[recognition.user_recognitions.user_awarded.id]'],
      toMyself: !!params['filters[recognition.user_recognizing.id]'],
    })
  }, [params])

  return { onFilterChange, resetFilters, getFilterValue, disabledFilters, onFiltersSubmit }
}
