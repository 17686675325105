import { useMutation } from '@tanstack/react-query'
import { postRequest } from 'utils/api/apiRequest'
import { isAxiosResponse } from 'utils/isAxiosResponse'

type ActivateAccountData = {
  email: string
  avatar: string | null
  consent: boolean
  code: string
}
export type ActivateAccountOnSuccess = {
  email: string
}

const postActivateAccount = ({ code, ...rest }: ActivateAccountData) =>
  postRequest(`/activation-codes/${code}/activate-user`, rest)

export const useActivateAccount = (onSuccess: (data: ActivateAccountOnSuccess) => void) => {
  return useMutation(postActivateAccount, {
    onSuccess: (data, variables) => {
      if (isAxiosResponse(data)) {
        onSuccess({ email: variables?.email })
      }
    },
  })
}
