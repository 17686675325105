import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { TranslationsKeys } from 'utils/createTranslationKey'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  csLabel: {
    fontSize: theme.typography.h4.fontSize,
    color: theme.palette.grey[500],
  },
  csValue: {
    fontSize: theme.typography.h4.fontSize,
    lineBreak: 'anywhere',
    fontWeight: 'bold',
    color: theme.palette.grey[800],
  },
}))

type Props = {
  label: TranslationsKeys
  value: ReactNode
}

export const ProfileDetail = ({ label, value }: Props) => {
  const { csLabel, csValue } = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <Box className={csLabel}>{t(label)}:</Box>
      <Box className={csValue}>{value}</Box>
    </>
  )
}
