import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import categoriesQueryKeys from 'utils/api/Categories/categoriesQueryKeys'
import { fetchCategories } from 'utils/api/Categories/fetchCategories'

export const useCategories = (isDisabledCategories?: boolean) => {
  const { i18n } = useTranslation()

  return useQuery({
    queryKey: categoriesQueryKeys.categoriesList(i18n.language),
    queryFn: () => fetchCategories(i18n.language),
    enabled: !isDisabledCategories,
  })
}
