import { useQuery } from '@tanstack/react-query'
import { getSocialWallUser } from 'utils/api/SocialWall/getSocialWallUser'
import socialWallQueryKeys from 'utils/api/SocialWall/socialWallQueryKeys'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'

export const useGetSocialWallUser = () => {
  const { id } = useCurrentUser()
  return useQuery({
    queryKey: socialWallQueryKeys.user(id),
    queryFn: getSocialWallUser,
  })
}
