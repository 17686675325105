import isEmpty from 'lodash/isEmpty'
import { useAmountOfItemsInShoppingCart, useSelectShoppingCart } from 'shared/ShoppingCart/slice'
import { useIsLuckyBirdUser } from 'utils/hooks/useIsLuckyBirdUser'

export const useIsDisabledOrdering = () => {
  const { data: shoppingCartItems } = useSelectShoppingCart()
  const { isSingleLogic } = useIsLuckyBirdUser()
  const amountItems = useAmountOfItemsInShoppingCart()
  const haveOutOfStockProduct = shoppingCartItems?.data?.items?.some((item) => item.product.out_of_stock)

  return (
    !isEmpty(shoppingCartItems?.data?.warnings) ||
    isEmpty(shoppingCartItems?.data?.items) ||
    haveOutOfStockProduct ||
    (isSingleLogic && amountItems > 1)
  )
}
