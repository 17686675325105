import Grid from '@mui/material/Grid'
import { FormikButtons } from 'components/Formik/FormikButtons'
import { FormikMessage } from 'components/Formik/FormikMessage'
import { MessageTags } from 'components/Message/components/MessageTags'
import { Form, Formik, FormikConfig } from 'formik'
import { useValidationSchemaObject } from 'pages/RecognizeSomeone/configForm'
import { SocialWallPost } from 'utils/api/SocialWall/types'
import { useRecognizeSomeoneUpdate } from 'utils/hooks/api/SocialWall/useRecognizeSomeoneUpdate'
import * as Yup from 'yup'

interface Props {
  post: SocialWallPost
  onExit: () => void
}
type FormValues = {
  description: string
}

export const RecognitionEdit = ({ post, onExit }: Props) => {
  const {
    id: postId,
    recognition: { recognition_values, description, id },
  } = post
  const { isLoading, mutate } = useRecognizeSomeoneUpdate({ onSuccess: onExit, postId })
  const validationSchema = Yup.object().shape({ description: useValidationSchemaObject().description })

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = ({ description }) => {
    mutate({ id, description })
  }

  return (
    <Formik
      initialValues={{
        description,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialTouched={{ description: true }}
    >
      <Form data-cy="edit-post-form">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormikMessage name={`description`} authorSide="right" autoFocus />
          </Grid>
          <Grid item xs={12}>
            <MessageTags tags={recognition_values} />
          </Grid>
          <Grid item xs={12}>
            <FormikButtons
              onBack={onExit}
              isLoading={isLoading}
              textButtonCancel="app.cancel"
              textButtonSubmit="recognize_someone.update.submit"
              size="small"
              gridLayout
              cancelDataCy="recognition-post-cancel-button"
            />
          </Grid>
        </Grid>
      </Form>
    </Formik>
  )
}
