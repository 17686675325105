import { CategoryForBreadcrumbs } from 'utils/api/Categories/fetchCategoryBreadcrumbs'
import { WithPagination } from 'utils/api/pagination'

export const getCurrentCategoriesPath = (
  categoryId: number | undefined,
  breadcrumbsCategories: WithPagination<CategoryForBreadcrumbs>
) => {
  if (!categoryId) {
    return []
  }
  const currentCategoriesPath = []
  const currentCategory = breadcrumbsCategories?.data?.find((category) => category.id === categoryId)
  if (currentCategory) {
    currentCategoriesPath.push(currentCategory)
  }
  let parent = currentCategory?.parent
  while (parent) {
    if (parent.parent) {
      // skip root category
      currentCategoriesPath.unshift(parent)
    }
    parent = parent.parent
  }
  return currentCategoriesPath
}
