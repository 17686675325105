import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

export const Delivery = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 70 70">
    <g>
      <path
        d="M28.6,4.5c-6.6,0-13,2.5-17.9,7.2c-5.1,4.8-7.9,11.2-8,18.1c-0.1,6.9,2.4,13.4,7.2,18.5l16.4,18.4
		c0.5,0.9,1.5,0.9,2.1,0.9c0.7,0,1.5-0.3,2-0.8l1.8-2c0.4-0.5,0.3-1.3-0.3-1.6c-0.4-0.2-0.9-0.1-1.2,0.2l-1.8,2
		c-0.1,0.1-0.3,0.2-0.6,0.2c-0.1,0-0.3,0-0.4,0L11.4,47C7,42.3,4.6,36.3,4.8,29.9c0.1-6.3,2.7-12.3,7.4-16.7
		C16.6,9,22.6,6.6,28.6,6.6c11.5,0,21.3,8.2,23.2,19.3c0.1,0.5,0.5,0.8,1,0.8c0.6,0,1.1-0.6,1-1.2C51.7,13.5,41.2,4.5,28.6,4.5
		L28.6,4.5z"
      />
      <path
        d="M68.8,58.7c0.5-0.2,0.8-0.7,0.8-1.3v-1.3V39.5c0-0.1,0-0.4-0.2-0.7c-0.1-0.1-0.2-0.3-0.3-0.4
		c-0.2-0.2-0.4-0.3-0.4-0.3l-15.1-6.7c0,0-0.2-0.1-0.4-0.1c-0.5-0.1-1,0.1-1,0.1l-17.1,7c0,0-0.3,0.1-0.5,0.3
		c-0.4,0.3-0.4,1-0.4,1.1v17.9c0,0.3,0.1,0.6,0.3,0.8l0.1,0.1c0.1,0,0.1,0.1,0.1,0.1l17.5,7.5c0.5,0.2,1,0,1,0L68.8,58.7z M53,33.3
		l13.5,6l-6,2.6l-13.7-6.1L53,33.3z M51.4,64c-8.2-3.7-13.3-5.9-15.3-6.7V41l15.3,6.1V64z M52.5,45.5c-7.2-3-12.1-4.9-14.8-6
		l6.4-2.6L58,43L52.5,45.5z M53.6,63.9V47.3l5.9-2.6V47c0,0.1,0.1,1.2,1,1.2c1,0,1-1.2,1-1.2v-3c0,0,0-0.1,0-0.3l6.2-2.7v16.1"
      />
      <path
        d="M28.3,34.8c-4.3,0-7.7-3.5-7.7-7.7c0-4.3,3.5-7.7,7.7-7.7c4.3,0,7.7,3.5,7.7,7.7
		C36.1,31.3,32.6,34.8,28.3,34.8z M28.3,21.3c-3.1,0-5.7,2.6-5.7,5.7c0,3.1,2.6,5.7,5.7,5.7c3.1,0,5.7-2.6,5.7-5.7
		C34,23.9,31.5,21.3,28.3,21.3z"
      />
    </g>
  </SvgIcon>
)
