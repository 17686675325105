import Button from '@mui/material/Button'
import { useAuthContext } from 'store/useAuthContext'

export const CypressButtonLogout = () => {
  const { logoutUser } = useAuthContext()
  return (
    <Button
      id="force-logout"
      name="force-logout"
      sx={(_) => ({
        display: 'none',
      })}
      onClick={() => {
        logoutUser()
      }}
    >
      Force logout
    </Button>
  )
}
