import Login from '@mui/icons-material/Login'
import Phone from '@mui/icons-material/Phone'
import Grid from '@mui/material/Grid'
import { useIsLuckyBirdAuth } from 'pages/Login/LuckyBird/useIsLuckyBirdAuth'
import { useIsLuckyBirdGlobalAuth } from 'pages/Login/LuckyBird/useIsLuckyBirdGlobalAuth'
import { AuthMenuItem } from 'theme/Web/AuthMenu/AuthMenuItem'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles(() => ({
  authMenuWrapper: {
    height: '100%',
  },
}))

export const AuthMenu = ({ noIcons }: { noIcons?: boolean }) => {
  const { authMenuWrapper } = useStyles()
  const isLuckyBird = useIsLuckyBirdAuth()
  const isGlobalLuckyBird = useIsLuckyBirdGlobalAuth()

  const render = () => {
    switch (true) {
      case isGlobalLuckyBird:
        return (
          <>
            <AuthMenuItem icon={!noIcons && <Login />} text="auth.enter_code" link="/auth/luckybird" />
            {/* <AuthMenuItem icon={!noIcons && <Help />} text="auth.help" link="/auth/help" />*/}
            <AuthMenuItem icon={!noIcons && <Phone />} text="auth.faq" link="/auth/faq" />
          </>
        )
      case isLuckyBird:
        return (
          <>
            <AuthMenuItem icon={!noIcons && <Login />} text="auth.enter_code" link="/auth/luckybird" />
            {/* <AuthMenuItem icon={!noIcons && <Help />} text="auth.help" link="/auth/luckybird/help" />*/}
            <AuthMenuItem icon={!noIcons && <Phone />} text="auth.faq" link="/auth/luckybird/faq" />
          </>
        )
      default:
        return (
          <>
            <AuthMenuItem icon={!noIcons && <Login />} text="auth.login" link="/auth/login" />
            {/* <AuthMenuItem icon={!noIcons && <Help />} text="auth.help" link="/auth/help" />*/}
            <AuthMenuItem icon={!noIcons && <Phone />} text="auth.faq" link="/auth/faq" />
          </>
        )
    }
  }
  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" className={authMenuWrapper}>
      {render()}
    </Grid>
  )
}
