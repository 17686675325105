import { useMutation, useQueryClient } from '@tanstack/react-query'
import { patchRecognition } from 'utils/api/SocialWall/patchRecognition'
import { PostsQueryData, updatePostQueryData, useSWQueryHelpers } from 'utils/hooks/api/SocialWall/utils'

type Props = {
  onSuccess?: () => void
  postId: number
}

export const useRecognizeSomeoneUpdate = ({ onSuccess, postId }: Props) => {
  const { t, enqueueSnackbar, postsFilteredKey, invalidateAllPosts } = useSWQueryHelpers()
  const queryClient = useQueryClient()

  return useMutation(patchRecognition, {
    onSuccess: (res) => {
      onSuccess?.()
      queryClient.setQueryData<PostsQueryData>(
        postsFilteredKey,
        updatePostQueryData(postId, () => res.data)
      )
      invalidateAllPosts()
      enqueueSnackbar(t('recognize_someone.update.success'), { variant: 'success' })
    },
    onError: () => {
      enqueueSnackbar(t('recognize_someone.update.fail'), { variant: 'error' })
    },
  })
}
