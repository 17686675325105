import { MEDIA_URL } from 'config/env'
import { ProductMedia, VisualTypes } from 'utils/api/Products/CatalogProduct'

export const getYTId = (link: string) => {
  const regExp = new RegExp(/^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/)
  const ytId = link.match(regExp)
  return ytId && ytId[2] ? ytId[2] : ''
}

export const getVimeoId = (link: string) => {
  const regExp = new RegExp(/vimeo.*\/(\d+)/i)
  const vimeoId = link.match(regExp)
  return vimeoId && vimeoId[1] ? vimeoId[1] : ''
}

export const getYoutubeThumbnail = (ytId: string) => `https://img.youtube.com/vi/${ytId}/default.jpg`
export const getVimeoThumbnail = (vimeoId: string) => `https://vumbnail.com/${vimeoId}.jpg`

export const getThumbnail = (visual: ProductMedia) => {
  if (visual.type === VisualTypes.IMAGE) {
    const prefix = visual.path?.includes('http') || visual.path?.includes('base64') ? '' : MEDIA_URL

    return `${prefix}${visual?.path}`
    // TODO impossible with current infrastructure, backend refactoring needed
    // return `${prefix}/media/cache/resolve/small${visual?.path}`
  }
  if (visual.type === VisualTypes.VIDEO) {
    if (visual.path.includes('vimeo')) {
      return getVimeoThumbnail(getVimeoId(visual.path))
    }
    return getYoutubeThumbnail(getYTId(visual.path))
  }
  return ''
}

export const getThumbnailPath = (path: string) => {
  if (!path) return ''
  return path?.includes('http') || path?.includes('base64') ? path : `${MEDIA_URL}${path}`
}
