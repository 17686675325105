import ReactGA from 'react-ga4'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import { useSnackbar } from 'notistack'
import { DeliveryVariant } from 'pages/CreateOrder/deliveryOptions'
import { CreateOrderType2 } from 'pages/CreateOrder/types'
import { DeliveryData } from 'shared/ShoppingCart/api'
import { useDispatchShoppingCart } from 'shared/ShoppingCart/slice'
import { changeOrderDelivery } from 'utils/api/Order/changeOrderDelivery'

type Props = {
  onSuccess: () => void
}

export const useChangeDelivery2 = ({ onSuccess }: Props) => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const { refetchShoppingCart } = useDispatchShoppingCart()

  return useMutation((data: CreateOrderType2) => changeOrderDelivery(getData2(data)), {
    onSuccess: () => {
      onSuccess()
      refetchShoppingCart()
      window.scrollTo({ top: 0, behavior: 'smooth' })
      ReactGA.event('create_order', 'success')
    },
    onError: () => {
      enqueueSnackbar(t('create_order.change_delivery.fail'), { variant: 'error' })
      ReactGA.event('create_order', 'error')
    },
  })
}

const getData2 = (rawValues: CreateOrderType2): DeliveryData => {
  const shipping_data = {
    first_name: rawValues.first_name,
    last_name: rawValues.last_name,
    email: rawValues.email,
  }
  if (rawValues.delivery_address_type === DeliveryVariant.null) {
    throw new Error('delivery_address_type is null')
  }
  if (rawValues.delivery_address_type === DeliveryVariant.digital) {
    return {
      shipping_data,
      shipping_address: null,
      delivery_address_type: DeliveryVariant.digital,
      bpost_delivery_point_id: null,
      bpost_delivery_point_name: null,
      preset_address_id: null,
      phone_number: null,
    }
  }
  const values = rawValues?.[rawValues?.delivery_address_type]
  const shipping_address = {
    company_name: isEmpty(values.company_name) ? undefined : values.company_name,
    street: values.street,
    number: values.number,
    box: isString(values.box) && !isEmpty(values.box) ? values.box : null,
    zip: values.zip,
    city: values.city,
    country_id: isString(rawValues.country) ? undefined : Number(rawValues.country),
    phone_number: rawValues.phone_number,
  }
  if (rawValues.delivery_address_type === DeliveryVariant.preset && 'preset_address_id' in values) {
    return {
      shipping_data,
      shipping_address,
      preset_address_id: values.preset_address_id,
      delivery_address_type: DeliveryVariant.preset,
      phone_number: rawValues.phone_number,
      bpost_delivery_point_id: null,
      bpost_delivery_point_name: null,
    }
  }
  if (rawValues.delivery_address_type === DeliveryVariant.homeAddress) {
    return {
      shipping_data,
      shipping_address,
      phone_number: rawValues.phone_number,
      delivery_address_type: DeliveryVariant.homeAddress,
      bpost_delivery_point_id: null,
      bpost_delivery_point_name: null,
      preset_address_id: null,
    }
  }
  if (rawValues.delivery_address_type === DeliveryVariant.companyAddress) {
    return {
      shipping_data,
      shipping_address,
      phone_number: rawValues.phone_number,
      delivery_address_type: DeliveryVariant.companyAddress,
      bpost_delivery_point_id: null,
      bpost_delivery_point_name: null,
      preset_address_id: null,
    }
  }
  if (rawValues.delivery_address_type === DeliveryVariant.bpostParcelLocker && 'bpost_delivery_point_id' in values) {
    return {
      shipping_data,
      shipping_address: { ...shipping_address, phone_number: rawValues.phone_number },
      delivery_address_type: DeliveryVariant.bpostParcelLocker,
      bpost_delivery_point_id: values.bpost_delivery_point_id,
      bpost_delivery_point_name: values.bpost_delivery_point_name,
      preset_address_id: null,
      phone_number: rawValues.phone_number,
    }
  }
  if (rawValues.delivery_address_type === DeliveryVariant.bpostCollectionPoint && 'bpost_delivery_point_id' in values) {
    return {
      shipping_data,
      shipping_address: { ...shipping_address, phone_number: rawValues.phone_number },
      delivery_address_type: DeliveryVariant.bpostCollectionPoint,
      bpost_delivery_point_id: values.bpost_delivery_point_id,
      bpost_delivery_point_name: values.bpost_delivery_point_name,
      preset_address_id: null,
      phone_number: rawValues.phone_number,
    }
  }
  return {
    shipping_data,
    shipping_address: { ...shipping_address, phone_number: rawValues.phone_number },
    delivery_address_type: DeliveryVariant.onetime,
    is_company_address: !isEmpty(values.company_name),
    bpost_delivery_point_id: null,
    bpost_delivery_point_name: null,
    preset_address_id: null,
    phone_number: rawValues.phone_number,
  }
}
