export const contentBoxTypes = {
  html_box: 1,
  image_box: 2,
  link_box: 3,
  logic_box: 4,
  notification_box: 5,
  announcement_box: 6,
}

export const logicBoxTypes = {
  logic_box_profile: 1,
  logic_box_languages: 2,
  logic_box_value_codes: 3,
}
