import { FunctionComponent } from 'react'
import * as React from 'react'
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { AutocompleteUsersAvatar } from 'components/AutocompleteUsers/AutocompleteUsersAvatar'
import { OptionBaseParams, RenderOptionBaseParams } from 'components/AutocompleteUsers/types'
import { getResponsiveSize } from 'theme/styles.utils'
import { important } from 'utils/styles/stylesUtils'

export const autocompleteUsersRenderOption = <
  T extends OptionBaseParams,
  Multiple extends boolean,
  DisableClearable extends boolean,
  FreeSolo extends boolean,
  ChipComponent extends FunctionComponent<any>,
>(
  props: React.HTMLAttributes<HTMLLIElement>,
  option: T & RenderOptionBaseParams,
  { inputValue }: AutocompleteRenderOptionState
) => {
  if (option.isSelected) return null

  const matches = match(option.optionLabel, inputValue, { insideWords: true })
  const parts = parse(option.optionLabel, matches)

  return (
    <Box
      component="li"
      key={option.id}
      sx={{
        px: important(getResponsiveSize(8, 'px')),
      }}
      {...props}
    >
      <AutocompleteUsersAvatar
        avatar={option.avatar}
        full_name={option.full_name}
        sx={{
          mr: getResponsiveSize(1),
        }}
      />
      <Typography variant="body2">
        {parts.map((part, index) => (
          <Box
            component="span"
            key={index}
            sx={{
              fontWeight: part.highlight ? 600 : undefined,
            }}
          >
            {part.text}
          </Box>
        ))}
      </Typography>
    </Box>
  )
}
