import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

export const Reward = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 70 70">
    <g>
      <g>
        <g>
          <path
            d="M58.8,42L37.4,20.6c-0.1-0.1-0.2-0.2-0.3-0.2l-8-3.3l-4.5-1.7c-1.7-0.7-4.1-1.7-6,0.2l-6.6,6.6
				c-1.9,1.9-1.1,4.1-0.2,6l5,12.5c0,0.1,0.1,0.2,0.2,0.3l21.4,21.4c0.8,0.8,2,1.3,3.2,1.3c1.2,0,2.3-0.5,3.2-1.3l14.2-14.2
				c0.8-0.8,1.3-2,1.3-3.2C60.1,44,59.7,42.9,58.8,42z"
          />
          <path
            style={{ fill: '#FFFFFF' }}
            d="M34,49.4c-0.4,0-0.8-0.1-1-0.4c-0.6-0.6-0.6-1.5,0-2.1l10.2-10.2c0.6-0.6,1.5-0.6,2.1,0
				c0.6,0.6,0.6,1.5,0,2.1L35.1,49C34.8,49.3,34.4,49.4,34,49.4z"
          />
          <path
            style={{ fill: '#FFFFFF' }}
            d="M40.2,55.6c-0.4,0-0.8-0.1-1-0.4c-0.6-0.6-0.6-1.5,0-2.1l10.2-10.2c0.6-0.6,1.5-0.6,2.1,0
				c0.6,0.6,0.6,1.5,0,2.1L41.2,55.2C40.9,55.5,40.6,55.6,40.2,55.6z"
          />
          <path
            style={{ fill: '#FFFFFF' }}
            d="M21.9,26.6c-0.2,0-0.5,0-0.7,0c-0.5,0-0.9-0.5-0.8-0.9c0-0.5,0.5-0.9,0.9-0.8c1,0.1,2-0.1,3-0.4
				c2-0.7,3.7-2.1,4.7-4c1-1.9,1.2-4.1,0.5-6.1c-1.4-4.2-5.9-6.5-10.1-5.2c-0.5,0.1-1-0.1-1.1-0.6s0.1-1,0.6-1.1
				c5.1-1.6,10.7,1.2,12.3,6.3c0.8,2.5,0.6,5.1-0.6,7.5c-1.2,2.3-3.2,4-5.7,4.8C23.9,26.5,23,26.6,21.9,26.6z"
          />
          <path
            style={{ fill: '#FFFFFF' }}
            d="M22.3,30.6L22.3,30.6c-1.2,0-2.4-0.5-3.3-1.3c-0.9-0.9-1.3-2-1.3-3.3c0-1.2,0.5-2.4,1.3-3.3
				c1.7-1.7,4.8-1.7,6.5,0c1.8,1.8,1.8,4.7,0,6.5C24.6,30.1,23.5,30.6,22.3,30.6z M22.2,23.1c-0.8,0-1.5,0.3-2,0.8
				c-0.5,0.5-0.8,1.2-0.8,2c0,0.8,0.3,1.5,0.8,2c0.5,0.5,1.2,0.8,2,0.8c0,0,0,0,0,0c0.8,0,1.5-0.3,2-0.8c1.1-1.1,1.1-2.9,0-4
				C23.7,23.4,23,23.1,22.2,23.1z"
          />
        </g>
      </g>
      <path
        d="M30.8,18.8c0,0-0.1,0-0.1,0c-0.5-0.1-0.8-0.5-0.8-1c0.1-1.2,0-2.3-0.3-3.4c-1.4-4.2-5.9-6.5-10.1-5.2
		c-3.5,1.1-5.8,4.5-5.5,8.2c0,0.5-0.3,0.9-0.8,0.9c-0.5,0-0.9-0.3-0.9-0.8c-0.3-4.5,2.5-8.6,6.8-10c5.1-1.7,10.7,1.2,12.3,6.3
		c0.4,1.4,0.6,2.8,0.4,4.2C31.6,18.5,31.2,18.8,30.8,18.8z"
      />
    </g>
  </SvgIcon>
)
