import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { isAxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import { redirectOnLogin } from 'pages/Login/redirectOnLogin'
import { useAuthContext } from 'store/useAuthContext'
import { luckyBirdCodeLogin } from 'utils/api/apiClient'
import { useErrorSnackbar } from 'utils/hooks/useErrorSnackbar'

type Props = {
  onNextStep?: () => void
  setCode?: (code: string) => void
}

export const useLuckyBirdCodeLoginMutation = ({ setCode, onNextStep }: Props) => {
  const { refetch: refreshUser } = useAuthContext()
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const enqueueErrorSnackbar = useErrorSnackbar()

  return useMutation(luckyBirdCodeLogin, {
    onSuccess: async () => {
      try {
        const data = await refreshUser()
        redirectOnLogin(navigate, false, location?.search)
        await enqueueSnackbar(t('auth.login.success'), { variant: 'success' })
      } catch (e) {
        enqueueErrorSnackbar(e, { DEFAULT_ERROR: t('auth.login.error') })
      }
    },
    onError: async (e, data) => {
      if (isAxiosError(e) && e?.response?.status !== 400) {
        enqueueErrorSnackbar(e, { DEFAULT_ERROR: t('auth.login.error') })
      } else {
        setCode?.(data.code)
        onNextStep?.()
      }
    },
  })
}
