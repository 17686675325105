import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

export const WriteMessage = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 20 20">
    <g>
      <path
        d="M14.2,5.6L7.8,12c-0.1,0.1-0.2,0.3-0.2,0.4v1.8c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2H10
		c0.2,0,0.3-0.1,0.4-0.2l6.4-6.4c-0.4-0.3-0.7-0.6-0.7-0.7L14.2,5.6z"
      />
      <path
        d="M18.7,5.4l-1.8-1.8c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2L15,4.7l2.6,2.6l1.1-1.1h0
		c0.1-0.1,0.2-0.3,0.2-0.4S18.8,5.5,18.7,5.4L18.7,5.4z"
      />
      <path
        d="M10,15.9H8.2c-0.5,0-0.9-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3v-1.8c0-0.5,0.2-0.9,0.5-1.3l7.2-7.2V1.7
		c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.4-0.2H1.7c-0.2,0-0.3,0.1-0.4,0.2S1.1,1.5,1.1,1.7v16.6c0,0.2,0.1,0.3,0.2,0.4
		s0.3,0.2,0.4,0.2h11.9c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4v-5.8l-2.9,2.9C10.9,15.7,10.5,15.9,10,15.9L10,15.9z
		 M4.1,4.1h7.1c0.2,0,0.4,0.1,0.5,0.3c0.1,0.2,0.1,0.4,0,0.6c-0.1,0.2-0.3,0.3-0.5,0.3H4.1C3.9,5.3,3.7,5.1,3.6,5
		c-0.1-0.2-0.1-0.4,0-0.6C3.7,4.2,3.9,4.1,4.1,4.1z M3.5,7.6c0-0.2,0.1-0.3,0.2-0.4C3.8,7.1,3.9,7,4.1,7h3.6C7.8,7,8,7.1,8.1,7.3
		c0.1,0.2,0.1,0.4,0,0.6C8,8.1,7.8,8.2,7.6,8.2H4.1C3.9,8.2,3.8,8.2,3.7,8C3.5,7.9,3.5,7.8,3.5,7.6z"
      />
    </g>
  </SvgIcon>
)
