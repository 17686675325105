import { Column } from '@material-table/core'
import { TranslationsKeys } from 'utils/createTranslationKey'

export const funcOrString = <T>(arg: T, handler?: ((v: T) => TranslationsKeys) | TranslationsKeys) => {
  if (!handler) {
    return undefined
  }

  if (typeof handler !== 'string') {
    return handler(arg)
  }

  return handler
}

export const funcOrBool = <T>(arg: T, handler?: ((v: T) => boolean) | boolean) => {
  if (!handler) {
    return undefined
  }

  if (typeof handler !== 'boolean') {
    return handler(arg)
  }

  return handler
}

export const sizeParams = {
  small: {
    height: 30,
    padding: '5px',
    loaderSize: 20,
    buttonSize: 'small',
    fontSize: 'small',
  } as const,
  medium: {
    height: 40,
    padding: '8px',
    loaderSize: 24,
    buttonSize: 'medium',
    fontSize: 'medium',
  } as const,
  large: {
    height: 48,
    padding: '12px',
    loaderSize: 24,
    buttonSize: 'large',
    fontSize: 'medium',
  } as const,
}

export const filterColumns = <T extends {}>(arg: Column<T>[]) => arg.filter(Boolean)
