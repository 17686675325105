import { createContext } from 'react'
import { alpha } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { useQuery } from '@tanstack/react-query'
import Axios from 'axios'
import { MaintenancePage } from 'components/MaintenancePage/MaintenancePage'
import { MaintenanceContextType, MaintenanceWorks } from 'components/MaintenancePage/types'
import { ENVIRONMENT, EnvironmentType } from 'config/env'
import { isAfter, isBefore } from 'date-fns'
import { FCWithChildren } from 'utils/types/FCWithChildren'

export const MaintenanceContext = createContext<MaintenanceContextType | null>(null)

export const MaintenanceProvider: FCWithChildren = ({ children }) => {
  const bucketUrl: Record<EnvironmentType, string> = {
    local: 'http://localhost:9000/public-bucket',
    dev: 'https://s3.eu-west-1.amazonaws.com/arteel-backend-dev-mediabucketba2c0784-11g0glu7zdcbq',
    staging: 'https://s3.eu-west-1.amazonaws.com/arteel-backend-staging-mediabucketba2c0784-eopk4vp0ut64',
    prod: 'https://s3.eu-west-1.amazonaws.com/arteel-backend-prod-mediabucketba2c0784-7jt4l3h2og2w',
  }

  const { isInitialLoading, data } = useQuery({
    queryKey: ['maintenace'],
    queryFn: () =>
      Axios.get<MaintenanceWorks>(`${bucketUrl?.[ENVIRONMENT]}/app/public/files/maintenance/maintenance.json`),
    refetchInterval: 1000 * 60 * 5, // 5 minutes
    retry: 1,
  })

  const currentDate = new Date()
  const currentMaintenance = data?.data?.maintenanceWorks?.find(
    (m) => isAfter(currentDate, new Date(m.start)) && isBefore(currentDate, new Date(m.end))
  )

  if (isInitialLoading) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100vh',
          zIndex: 9999,
          background: alpha('#ecede4', 0.4),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ fontSize: '10rem' }}>
          <CircularProgress />
        </div>
      </div>
    )
  }
  if (currentMaintenance) {
    return <MaintenancePage currentMaintenance={currentMaintenance} />
  }
  return <MaintenanceContext.Provider value={{ currentMaintenance }}>{children}</MaintenanceContext.Provider>
}
