import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import LoadingButton from '@mui/lab/LoadingButton'
import Grid from '@mui/material/Grid'
import Zoom from '@mui/material/Zoom'
import qs from 'query-string'

type Props = {
  items: number
  allItems: number
  isLoading?: boolean
  onClick?: () => void
}

export const ShowMoreButton = ({ allItems, items, onClick, isLoading = false }: Props) => {
  const navigate = useNavigate()
  const { search, pathname } = useLocation()
  const { t } = useTranslation()

  const onShowMore = onClick
    ? onClick
    : () => {
        const currentSearchParams = qs.parse(search.replace('?', ''))
        const limit = currentSearchParams?.limit ? parseInt(currentSearchParams?.limit as string, 10) + 15 : 30
        navigate(`${pathname}?${qs.stringify({ ...currentSearchParams, limit }, { encode: false })}`)
      }

  return (
    <Zoom in={!!(allItems && items && items < allItems)} unmountOnExit mountOnEnter>
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Grid item>
            <LoadingButton
              variant="outlined"
              color="primary"
              onClick={onShowMore}
              loading={isLoading}
              disabled={isLoading}
            >
              {t('app.show_more')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Zoom>
  )
}
