import CancelIcon from '@mui/icons-material/Cancel'
import { chipClasses } from '@mui/material'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import { AutocompleteUsersAvatar } from 'components/AutocompleteUsers/AutocompleteUsersAvatar'
import { AutocompleteUsersChipProps } from 'components/AutocompleteUsers/types'
import { getResponsiveSize } from 'theme/styles.utils'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { useIsMobile } from 'utils/hooks/useBreakpoints'
import { makeSx } from 'utils/styles/makeSx'
import { important, inside } from 'utils/styles/stylesUtils'

export const AutocompleteUsersChip = ({ option, onDelete }: AutocompleteUsersChipProps) => {
  const isMobile = useIsMobile()

  const labelSx = makeSx(() => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }))
  const cancelSx = makeSx((t) => ({
    color: important(t.palette.primary.main),
    transform: valueOrUndefined(!isMobile, 'translate(-50%,-50%) scale(0.7)'),
    fontSize: important(getResponsiveSize(22, 'px')),
    marginRight: valueOrUndefined(isMobile, '28px!important'),
  }))
  const chipSx = makeSx(() => ({
    mr: valueOrUndefined(!isMobile, '100%'),
    borderWidth: 0,
    width: '100%',
    height: getResponsiveSize(32, 'px'),
    [inside(chipClasses.label)]: { width: valueOrUndefined(isMobile, '100%') },
  }))

  return (
    <>
      <Chip
        data-cy="select-user-chip"
        id={`select-user-chip-${option.id}`}
        variant="outlined"
        onDelete={(event) => {
          event.preventDefault()
          event.stopPropagation()
          onDelete(option.id)
        }}
        sx={chipSx}
        avatar={<AutocompleteUsersAvatar avatar={option.avatar} full_name={option.full_name} />}
        label={
          <Typography variant="body2" noWrap sx={labelSx}>
            {option.chipLabel}
          </Typography>
        }
        deleteIcon={<CancelIcon data-cy="select-user-chip-cancel-button" color="primary" sx={cancelSx} />}
      />
    </>
  )
}
