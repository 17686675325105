import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

export const Message = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 100 100">
    <path
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
      }}
      d="M1.1,48.6c0-24,22.3-42.8,48.9-42.8s48.9,18.9,48.9,42.8S76.6,91.4,50,91.4c-9.3,0-18.1-2.3-25.5-6.1L5.9,93.9   c-1.1,0.5-2.6,0.4-3.4-0.5S1.4,91,1.9,90l7.6-16.9C4.2,65.9,1.1,57.6,1.1,48.6L1.1,48.6z M31.6,42.6c-3.4,0-6.1,2.8-6.1,6.1   c0,3.4,2.8,6.1,6.1,6.1s6.1-2.8,6.1-6.1C37.7,45.3,35.2,42.6,31.6,42.6z M43.9,48.6c0-3.4,2.8-6.1,6.1-6.1c3.4,0,6.1,2.8,6.1,6.1   c0,3.4-2.8,6.1-6.1,6.1C46.6,54.9,43.9,52,43.9,48.6z M68.4,42.6c-3.4,0-6.1,2.8-6.1,6.1c0,3.4,2.8,6.1,6.1,6.1   c3.4,0,6.1-2.8,6.1-6.1C74.3,45.3,71.7,42.6,68.4,42.6z"
    />
  </SvgIcon>
)
