import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'

export const Codes = ({ ...rest }: SvgIconProps) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 100 100">
      <g>
        <path
          d="M28.7,29.2c-1.1-0.9-2.6-1.4-4-1.3c-1.5,0.1-2.8,0.8-3.7,2L7.1,46.5c-0.8,1-1.3,2.2-1.3,3.5s0.5,2.5,1.3,3.5
		l13.8,16.6c0.9,1.1,2.3,1.8,3.7,2c1.5,0.1,2.9-0.3,4-1.3c1.1-0.9,1.8-2.3,2-3.8c0.1-1.5-0.3-2.9-1.3-4L18.5,50l10.9-13
		c0.9-1.1,1.4-2.6,1.3-4C30.5,31.5,29.8,30.1,28.7,29.2L28.7,29.2z"
        />
        <path
          d="M56.6,17c-1.4-0.3-2.9,0-4.1,0.8c-1.2,0.8-2.1,2.1-2.4,3.5l-11,55.2c-0.3,1.4,0,2.9,0.8,4.1
		c0.8,1.2,2.1,2.1,3.5,2.3c0.4,0.1,0.7,0.1,1.1,0.1c1.3,0,2.5-0.4,3.5-1.3c1-0.8,1.7-1.9,1.9-3.2l11-55.2c0.3-1.4,0-2.9-0.8-4.1
		C59.3,18.1,58,17.3,56.6,17L56.6,17z"
        />
        <path
          d="M92.9,46.5L79.1,29.9c-0.9-1.1-2.3-1.8-3.7-2c-1.5-0.1-2.9,0.3-4,1.3c-1.1,0.9-1.8,2.3-2,3.8
		c-0.1,1.5,0.3,2.9,1.3,4l10.9,13L70.6,63c-0.9,1.1-1.4,2.6-1.3,4c0.1,1.5,0.8,2.8,2,3.8c1.1,0.9,2.6,1.4,4,1.3
		c1.5-0.1,2.8-0.8,3.7-2l13.8-16.6c0.8-1,1.3-2.2,1.3-3.5S93.7,47.4,92.9,46.5L92.9,46.5z"
        />
      </g>
    </SvgIcon>
  )
}
