import { useLocation } from 'react-router-dom'
import { useInfiniteQuery } from '@tanstack/react-query'
import { fetchProductsInCategory } from 'utils/api/Products/fetchProductsInCategory'
import productsQueryKeys from 'utils/api/Products/productsQueryKeys'

export const useProductsInCategory = (id = 1) => {
  const { search } = useLocation()

  return useInfiniteQuery(
    productsQueryKeys.productsInCategoryList(id, search),
    (context) => fetchProductsInCategory(id, search, context?.pageParam),
    {
      getPreviousPageParam: (firstPage) => firstPage.data?.page - 1,
      getNextPageParam: (lastPage) =>
        lastPage.data?.page < lastPage.data?.pages ? lastPage.data?.page + 1 : undefined,
      staleTime: 1200000,
    }
  )
}
