import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

export const Settings = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 70 70">
    <path
      d="M62,26.5h-2.7c-0.2-0.5-0.4-1.1-0.6-1.6l1.9-1.9c1-1,1.6-2.4,1.6-3.8s-0.6-2.8-1.6-3.8l-5.1-5.1
	c-1-1-2.4-1.6-3.8-1.6c-1.4,0-2.8,0.6-3.8,1.6L46,12.3c-0.5-0.2-1-0.5-1.5-0.6V8.9c0-3-2.4-5.4-5.4-5.4h-7.2c-3,0-5.4,2.4-5.4,5.4
	v2.7c-0.5,0.2-1,0.4-1.5,0.6L23,10.3c-1-1-2.4-1.6-3.8-1.6c-1.4,0-2.8,0.6-3.8,1.6l-5.1,5.1c-1,1-1.6,2.4-1.6,3.8S9.3,22,10.3,23
	l1.9,1.9c-0.2,0.5-0.4,1-0.6,1.6H8.9c-3,0-5.4,2.4-5.4,5.4V39c0,3,2.4,5.4,5.4,5.4h2.8c0.2,0.6,0.4,1.1,0.6,1.5l-1.9,1.9
	c-2.1,2.1-2.1,5.5,0,7.6l5.1,5.1c2.1,2.1,5.5,2.1,7.6,0l1.9-1.9c0.5,0.2,1,0.5,1.5,0.6V62c0,3,2.4,5.4,5.4,5.4H39
	c3,0,5.4-2.4,5.4-5.4v-2.8c0.5-0.2,1-0.4,1.5-0.6l1.9,1.9c2.1,2.1,5.5,2.1,7.6,0l5.1-5.1c2.1-2.1,2.1-5.5,0-7.6L58.6,46
	c0.2-0.5,0.4-0.9,0.6-1.5H62c3,0,5.4-2.4,5.4-5.4v-7.2C67.4,28.9,65,26.5,62,26.5z M35.5,50.9C26.9,50.9,20,44,20,35.5
	S26.9,20,35.5,20c8.5,0,15.4,6.9,15.4,15.4S44,50.9,35.5,50.9z"
    />
  </SvgIcon>
)
