export const fitVideos = (videosWrapperClassName: string, htmlContentElementId: string) => {
  const players = ['iframe[src*="youtube.com"]', 'iframe[src*="vimeo.com"]']
  const iframeElements = document.querySelectorAll(
    players.map((player) => `#${htmlContentElementId} ${player}`).join(',')
  )

  iframeElements.forEach((element) => {
    const width = parseInt(element.getAttribute('width'), 10)
    const height = parseInt(element.getAttribute('height'), 10)
    const aspectRatio = height / width
    const parentElement = element.parentNode

    const wrapper = document.createElement('div')
    wrapper.className = videosWrapperClassName
    wrapper.style.paddingBottom = `${aspectRatio * 100}%`
    parentElement.insertBefore(wrapper, element)
    element.remove()
    wrapper.appendChild(element)

    element.removeAttribute('height')
    element.removeAttribute('width')
  })
}
