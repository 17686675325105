import { getRequest } from 'utils/api/apiRequest'
import { Country } from 'utils/api/country'
import { WithPagination } from 'utils/api/pagination'
import { ProductInOrder } from 'utils/api/Products/CatalogProduct'

import { WebOrderReference } from '../Order/createOrder'

export type OrderItem = {
  id: number
  code: string
  quantity: number
  product: ProductInOrder
  points: number
  sub_order: SubOrder
  is_cancelled: boolean
  cancelled_quantity: number
  tracking_number: string | null
  tracking_number_url: string | null
}

export type OrderCustomerDetails = {
  first_name: string
  last_name: string
  email: string
  phone_number?: string
  company_name: string | null
  street: string | null
  number: string | null
  box: string | null
  zip: string | null
  city: string | null
  country: Country | null
  phone: string | null
}
export type OrderHistoryRow = {
  id: number
  created_at: string
  promised_delivery_date: string | null
  requested_delivery_date: string | null
  order_items: OrderItem[]
  sub_orders: SubOrder[]
  ext_web_order_reference: WebOrderReference
  order_customer_details: OrderCustomerDetails
}

export type SubOrder = {
  uuid: string
}

export const getOrderHistory = (limit = '3') =>
  getRequest<WithPagination<OrderHistoryRow>>(`/cms/order-history?limit=${limit}`)
