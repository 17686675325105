import { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { userConsentQueryKeys } from 'shared/UserConsent'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'
import { FCWithChildren } from 'utils/types/FCWithChildren'

import { UserConsent } from './UserConsent'

export const UserConsentProvider: FCWithChildren = ({ children }) => {
  const user = useCurrentUser()
  const queryClient = useQueryClient()

  useEffect(
    () => () => {
      queryClient.invalidateQueries(userConsentQueryKeys.all)
    },
    []
  )

  if (!user) return <>{children}</>
  if (user?.consent_accepted) return <>{children}</>
  return <UserConsent />
}
