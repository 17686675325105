import { FunctionComponent } from 'react'
import { CmsContent } from 'pages/CMS/CMS.types'
import { getThumbnailPath } from 'utils/getThumbnail'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles(() => ({
  image: {
    display: 'block',
    width: '100%',
    height: 'auto',
  },
}))

type Props = {
  content: Pick<CmsContent, 'image_path'>
  dataCy?: string
}

export const ImageContent: FunctionComponent<Props> = ({ content, dataCy }) => {
  const { image } = useStyles()

  return (
    <figure data-cy={valueOrUndefined(!!dataCy, dataCy)}>
      <img src={getThumbnailPath(content.image_path)} className={image} alt="" />
    </figure>
  )
}
