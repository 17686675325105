import { useQuery } from '@tanstack/react-query'
import { personalMessageKeys } from 'pages/PersonalMessages/api/keys'
import { getRequest } from 'utils/api/apiRequest'
import { WithPagination } from 'utils/api/pagination'

export type PersonalTemplate = {
  id: number
  name: string
  content: string
}

export type Occasion = {
  id: number
  translation: Record<string, { name: string }>
  message_templates_user: PersonalTemplate[]
}

const getPersonalTemplatesByOccasionId = (occasionId: number | string) =>
  getRequest<WithPagination<Occasion>>(`/users/message-templates/grouped?limit=1000&filters[id]=${occasionId}`)

export const useGetPersonalTemplatesByOccasionId = (occasionId: number) => {
  return useQuery({
    enabled: !!occasionId,
    queryKey: personalMessageKeys.getPersonalTemplatesByOccasionId([occasionId?.toString()]),
    queryFn: () => getPersonalTemplatesByOccasionId(occasionId),
    refetchOnMount: true,
  })
}
