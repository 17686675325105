import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { styled, tabsClasses } from '@mui/material'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { PostsFiltersDesktop } from 'pages/SocialWallPostFilters/PostsFiltersDesktop'
import { useThemeContext } from 'store/ThemeContext'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { useIsTablet } from 'utils/hooks/useBreakpoints'
import { useIsRoute } from 'utils/hooks/useIsRoute'
import { useResize } from 'utils/hooks/useResize'
import { inside } from 'utils/styles/stylesUtils'

const Label = styled('div')(({ theme }) => ({
  fontSize: theme.typography.h2.fontSize,
  textTransform: 'uppercase',
  fontWeight: theme.typography.fontWeightBold,
}))

const FiltersContainer = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  height: '100%',
}))

export const SocialWallSwitcher = () => {
  const { t } = useTranslation()
  const { routeMatch } = useIsRoute(['/social-wall', '/'])
  const currentTab = routeMatch?.pathname
  const isTablet = useIsTablet()
  const [ref, setRef] = useState(null)
  const { height } = useResize(ref)
  const { setSwSwitcherHeight } = useThemeContext()

  useEffect(() => {
    if (!height) return
    setSwSwitcherHeight(height)
  }, [height])

  return (
    <Box ref={setRef} pt={0.5} pb={valueOrUndefined(!isTablet, 0.5)} px={0.25}>
      <Box position="relative">
        <Tabs
          value={currentTab}
          variant="fullWidth"
          data-cy="social-wall-switcher"
          sx={(theme) => ({
            boxShadow: `inset 0 -3px 0 0 ${theme.palette.text.disabled}`,
            [inside(tabsClasses.indicator)]: {
              height: '3px',
              borderRadius: '1px',
            },
          })}
        >
          <Tab
            data-cy="tab-personal"
            label={<Label>{t('social_wall.personal')}</Label>}
            value="/"
            to="/"
            component={Link}
          />
          <Tab
            data-cy="tab-social"
            sx={{ position: 'relative' }}
            label={<Label sx={{ textAlign: 'right' }}>{t('social_wall.social')}</Label>}
            value="/social-wall"
            to="/social-wall"
            component={Link}
            onClick={valueOrUndefined(currentTab === '/social-wall', (e) => e.preventDefault())}
          />
        </Tabs>
        {!isTablet && (
          <FiltersContainer>
            <PostsFiltersDesktop />
          </FiltersContainer>
        )}
      </Box>
    </Box>
  )
}
