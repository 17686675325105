import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import { lighten } from '@mui/system'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  contact: {
    fontWeight: theme.typography.fontWeightBold,
    color: lighten(theme.palette.common.black, 0.2),
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(-3.5),
  },
}))
export const FooterConclusionPoints = () => {
  const { contact, link } = useStyles()
  const { t } = useTranslation()

  return (
    <Typography variant="h3" align="center" className={contact}>
      {t('shopping_cart.contact_1')}{' '}
      <span className={link} onClick={() => window.open(`mailto:${t('shopping_cart.mail')}`)}>
        {t('shopping_cart.mail')}
      </span>{' '}
      {t('shopping_cart.contact_2')}{' '}
      <span className={link} onClick={() => window.open(`tel:${t('shopping_cart.phone')}`)}>
        {t('shopping_cart.phone')}
      </span>
      .
    </Typography>
  )
}
