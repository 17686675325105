import { createContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import debounce from 'lodash/debounce'
import qs from 'query-string'
import { FCWithChildren } from 'utils/types/FCWithChildren'

type Value = {
  hasSearching: boolean
  setSearching: (data: boolean) => void
  onCloseSearch: () => void
  onToggleSearch: () => void
  currentValueSearch: string | string[]
  onChangeSearch: (...args: any[]) => any
  setSearchParam: (data: string) => void
}

const SearchContext = createContext<Value>(null)
const { Provider, Consumer } = SearchContext

const SearchProvider: FCWithChildren = ({ children }) => {
  const { i18n } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = qs.parse(location.search.replace('?', ''))
  const [hasSearching, setSearching] = useState(!!params?.searchWidget)
  const [searchParam, setSearchParam] = useState('searchWidget')

  useEffect(() => {
    setSearching(false)
  }, [location.pathname])

  const currentValueSearch = params?.searchWidget || ''
  const onChangeSearch = debounce((value) => {
    navigate(
      `${location.pathname}?${qs.stringify(
        { ...params, searchWidget: value || undefined, lang: i18n.language },
        { encode: false }
      )}`
    )
  }, 500)

  const onCloseSearch = () => {
    setSearching(false)
    navigate(`${location.pathname}?${qs.stringify({ ...params, searchWidget: undefined }, { encode: false })}`)
  }

  const onToggleSearch = () => setSearching((prev) => !prev)

  return (
    <Provider
      value={{
        hasSearching,
        setSearching,
        onCloseSearch,
        onToggleSearch,
        currentValueSearch,
        onChangeSearch,
        setSearchParam,
      }}
    >
      {children}
    </Provider>
  )
}
export { SearchProvider, Consumer as SearchConsumer }
export default SearchContext
