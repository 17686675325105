import { useInfiniteQuery } from '@tanstack/react-query'
import { fetchNotifications } from 'utils/api/CMSNotifications/fetchNotifications'
import notificationsQueryKeys from 'utils/api/CMSNotifications/notificationsQueryKeys'

export const useCMSNotifications = () => {
  return useInfiniteQuery(
    notificationsQueryKeys.notificationsList(),
    (context) => fetchNotifications(context?.pageParam),
    {
      keepPreviousData: true,
      getPreviousPageParam: (firstPage) => firstPage.data?.page - 1,
      getNextPageParam: (lastPage) => lastPage.data?.page + 1,
    }
  )
}
