import { useTranslation } from 'react-i18next'
import { CardSearch } from 'components/CardSearch/CardSearch'
import { Birthday } from 'utils/hooks/api/Birthdays/useBirthdays'
import { useMonths } from 'utils/hooks/useMonths'

interface Props {
  birthdays: Birthday[]
}

export const SearchBirthday = ({ birthdays }: Props) => {
  const { t } = useTranslation()
  const { months } = useMonths()

  return (
    <CardSearch
      scrollOptions={months.map((month) => ({
        text: month.shortName,
        elementId: month.elementId,
        isVisible: birthdays.find((birthday) => birthday.month === month.month)?.persons?.length > 0,
      }))}
      searchText={t('birthdays.search')}
      searchKey="searchWidget"
      justify="space-evenly"
      removeMargin
      searchButtonSx={{
        color: (theme) => theme.palette.text.disabled,
      }}
    />
  )
}
