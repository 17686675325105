import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

export const Bus = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 75 75">
    <g>
      <path d="M23.88,49.63H21.3c-1.39,0-2.58-1.14-2.58-2.53V24.03c0-1.39,1.14-2.53,2.53-2.53h20.44   c1.39,0,2.53,1.14,2.53,2.58v3.82h7.05c0.3,0,0.79,0.2,1.04,0.5l6.85,8.58c0.2,0.25,0.4,0.74,0.4,1.14v8.98   c0,1.39-1.14,2.53-2.58,2.53h-2.58c0-2.83-2.33-5.11-5.11-5.11c-2.78,0-5.11,2.33-5.11,5.11H33.95c0-2.83-2.33-5.11-5.11-5.11   S23.88,46.8,23.88,49.63L23.88,49.63z M44.32,30.43v7.69h12.8l-6.4-7.69H44.32z M49.43,53.5c-2.08,0-3.82-1.74-3.82-3.82   s1.74-3.82,3.82-3.82c2.08,0,3.82,1.74,3.82,3.82C53.3,51.77,51.56,53.5,49.43,53.5z M28.99,53.5c-2.08,0-3.82-1.74-3.82-3.82   s1.74-3.82,3.82-3.82s3.82,1.74,3.82,3.82C32.81,51.77,31.12,53.5,28.99,53.5z" />
    </g>
  </SvgIcon>
)
