import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'

export const GiftHistory = ({ ...rest }: SvgIconProps) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 100 100">
      <g>
        <path
          d="M87.9,64.2c0.2-0.5,0.4-1.1,0.6-1.6c0.3-0.7,0.5-1.4,0.7-2.2c0-0.1,0.1-0.2,0.1-0.3c0.9-3.2,1.4-6.6,1.4-10.1
		c0-6.5-1.7-12.7-4.7-18.1l0,0l0,0c-6.5-11.6-19-19.4-33.2-19.4l0,0c-15.1,0-28.2,8.8-34.3,21.5l-1.1-2c-0.4-0.7-0.9-1.3-1.6-1.7
		c-0.7-0.4-1.5-0.6-2.3-0.6c-1.5,0-2.9,0.9-3.6,2.2c-0.7,1.3-0.7,2.9,0,4.2l5.9,10.5l0,0c0.5,1,1.4,1.7,2.5,2s2.2,0.2,3.2-0.3
		L32,42.9c1.1-0.5,1.9-1.4,2.3-2.5c0.4-1.1,0.3-2.3-0.2-3.4c-0.5-1-1.5-1.8-2.6-2.2c-1.1-0.3-2.3-0.2-3.3,0.4l-0.9,0.5
		c5-8.8,14.6-14.9,25.7-14.9s20.6,6,25.6,14.8c0,0.1,0.1,0.1,0.1,0.2c4.2,7.6,4.6,16.4,1.8,24c-0.1,0.3-0.2,0.6-0.3,0.8
		c-0.1,0.3-0.3,0.7-0.5,1c-4.5,9.9-14.8,16.9-26.6,17c-0.1,0,0.1,0,0,0c-10.1-0.1-19.8-5.5-25-14.9c-1.1-2.1-3.8-2.8-5.8-1.7
		c-2.1,1.1-2.8,3.8-1.7,5.8c6.8,12.3,19.6,19.3,32.8,19.3c6.1,0,12.3-1.5,18.1-4.7c7.5-4.1,13-10.5,16.1-17.8
		C87.6,64.8,87.7,64.5,87.9,64.2z"
        />
        <path d="M52.1,28.5L52.1,28.5L52.1,28.5z" />
        <path
          d="M47.9,32.8V50c0,1.3,0.6,2.5,1.6,3.3l10.7,8.6c1.2,1,2.8,1.2,4.2,0.6c1.4-0.6,2.4-1.8,2.7-3.3
		c0.2-1.5-0.4-3-1.6-4l-9.1-7.3V32.8l0,0c0-1.2-0.4-2.3-1.3-3.1c-0.8-0.8-1.9-1.3-3.1-1.3l0,0c-1.1,0-2.2,0.5-3,1.3
		C48.3,30.6,47.9,31.7,47.9,32.8z"
        />
      </g>
    </SvgIcon>
  )
}
