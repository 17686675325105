import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useDispatchShoppingCart } from 'shared/ShoppingCart/slice'
import { changeQuantityItem } from 'utils/api/ShoppingCart/changeQuantityItem'
import { useErrorSnackbar } from 'utils/hooks/useErrorSnackbar'

type Props = {
  onFail?: () => void
}

export const useChangeQuantityItem = ({ onFail }: Props) => {
  const { enqueueSnackbar } = useSnackbar()
  const enqueueErrorSnackbar = useErrorSnackbar()
  const { t } = useTranslation()
  const { refetchShoppingCart } = useDispatchShoppingCart()

  return useMutation(changeQuantityItem, {
    onSuccess: () => {
      refetchShoppingCart()
      enqueueSnackbar(t('shopping_cart.change_quantity.success'), { variant: 'success' })
    },
    onError: (error) => {
      refetchShoppingCart()
      if (onFail) {
        onFail()
      }
      enqueueErrorSnackbar(error, { DEFAULT_ERROR: t('shopping_cart.change_quantity.fail') })
    },
  })
}
