import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Zoom from '@mui/material/Zoom'
import { useGetAvailableCountries } from 'components/CountryContextDialog/hooks/useGetAvailableCountries'
import { FormikTextField } from 'components/Formik/FormikTextField'
import { useFormikContext } from 'formik'
import { DeliveryVariant } from 'pages/CreateOrder/deliveryOptions'
import { getNestedName } from 'pages/CreateOrder/helper/getNestedName'
import { CreateOrderType2 } from 'pages/CreateOrder/types'
import { Country } from 'utils/api/country'

export const PersonalDeliveries = () => {
  const { t, i18n } = useTranslation()
  const { getFieldProps, getFieldMeta, values } = useFormikContext<CreateOrderType2>()
  const delivery_variant = getFieldProps('delivery_address_type').value
  const isDeliveryToCompany = delivery_variant === DeliveryVariant.companyAddress
  const addressFromProfile =
    delivery_variant === DeliveryVariant.companyAddress || delivery_variant === DeliveryVariant.homeAddress
  const { data, isFetching } = useGetAvailableCountries()
  const deliveryCountries: Country[] = data?.data || []
  const getName = (name: Parameters<typeof getNestedName>[1]) => getNestedName(values.delivery_address_type, name)

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Zoom in={isDeliveryToCompany} unmountOnExit mountOnEnter>
            <Grid item xs={12}>
              <FormikTextField
                name={getName('company_name')}
                inputLabel="create_order.company_name"
                disabled={addressFromProfile}
              />
            </Grid>
          </Zoom>
          <Grid item xs={12} lg={6}>
            <FormikTextField name={getName('street')} inputLabel="create_order.street" disabled={addressFromProfile} />
          </Grid>
          <Grid item xs={12} lg>
            <FormikTextField name={getName('number')} inputLabel="create_order.number" disabled={addressFromProfile} />
          </Grid>
          <Grid item xs={12} lg>
            <FormikTextField name={getName('box')} inputLabel="create_order.box" disabled={addressFromProfile} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            <FormikTextField name={getName('zip')} inputLabel="create_order.zip" disabled={addressFromProfile} />
          </Grid>
          <Grid item xs={12} lg>
            <FormikTextField name={getName('city')} inputLabel="create_order.city" disabled={addressFromProfile} />
          </Grid>
          <Grid item xs={12} lg>
            <FormikTextField
              select
              center
              variant="outlined"
              name="country"
              onChange={(event) => {}}
              inputLabel="create_order.country"
              disabled
              sx={{ '& svg': { display: 'none' } }}
            >
              {deliveryCountries?.map((country) => (
                <MenuItem key={country.id} value={country.id}>
                  {country.translation?.[i18n.language]?.name}
                </MenuItem>
              ))}
            </FormikTextField>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
