import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'

export const Profiles = ({ ...rest }: SvgIconProps) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 100 100">
      <g>
        <path
          d="M30.6,31.4c0,10.7,8.6,19.5,19.5,19.5s19.5-8.6,19.5-19.5s-8.8-19.3-19.6-19.3C39.2,12.1,30.6,20.7,30.6,31.4z
		"
        />
        <path
          d="M82.6,74.3c-0.1-3.8-1.2-7.3-3.1-10.5c-1.8-3.1-4.2-5.7-7.5-7.9c-2.3-1.8-5.3-3.1-8.2-4.1
		c-4,2.6-8.8,4.2-13.9,4.2S40,54.4,36,51.8c-3.1,1-5.9,2.3-8.2,4.1c-3.1,2.2-5.6,4.8-7.5,7.9c-1.9,3.2-2.9,6.7-3.1,10.5
		c0,0.6,0,8.2,0,8.3c0,2.9,2.6,5.3,6,5.3h53.4c3.4,0,6-2.3,6-5.3C82.7,82.5,82.7,74.9,82.6,74.3z"
        />
      </g>
    </SvgIcon>
  )
}
