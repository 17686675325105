import { useLocation, useParams } from 'react-router-dom'
import { useInfiniteQuery } from '@tanstack/react-query'
import qs from 'query-string'
import { fetchProductsInBrand } from 'utils/api/Products/fetchProductsInBrand'
import productsQueryKeys from 'utils/api/Products/productsQueryKeys'

export const useBrandProducts = () => {
  const { brandId } = useParams<{ brandId: string }>()
  const location = useLocation()
  const paramsUrl = qs.parse(location?.search?.replace('?', ''))

  return useInfiniteQuery(
    productsQueryKeys.productsInBrandList(brandId, JSON.stringify(paramsUrl)),
    (context) => fetchProductsInBrand(brandId, context?.pageParam, location?.search),
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage) =>
        lastPage.data?.page < lastPage.data?.pages ? lastPage.data?.page + 1 : undefined,
      getPreviousPageParam: (firstPage) => firstPage.data?.page - 1,
    }
  )
}
