import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

export const ArrowDown = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 70 70">
    <path
      d="M67.18,12.55c-1.89-1.41-4.58-1.05-6.02,0.86l-25.72,34.3L9.71,13.41c-1.44-1.91-4.12-2.28-6.02-0.86
	c-1.9,1.42-2.28,4.12-0.86,6.02L32,57.46c0.1,0.13,0.25,0.18,0.36,0.3c0.17,0.19,0.29,0.41,0.5,0.57c0.09,0.07,0.21,0.04,0.3,0.1
	c0.7,0.45,1.46,0.76,2.27,0.76c0.81,0,1.58-0.31,2.27-0.76c0.09-0.06,0.21-0.03,0.3-0.1c0.21-0.16,0.33-0.38,0.5-0.57
	c0.11-0.12,0.26-0.17,0.36-0.3l29.17-38.89C69.47,16.67,69.08,13.98,67.18,12.55z"
    />
  </SvgIcon>
)
