import {
  ClassNameMap,
  createStyles as materialCreateStyles,
  makeStyles as makeMuiStyles,
  Styles,
  WithStylesOptions,
} from '@mui/styles'
import { Theme } from 'theme/styles'

export const makeStyles = <Props extends {} | undefined = undefined, ClassKey extends string = string>(
  styles: Styles<Theme, Props, ClassKey>,
  options?: Omit<WithStylesOptions<Theme>, 'withTheme'>
) => {
  return makeMuiStyles(styles, options) as keyof Props extends never // `makeStyles` where the passed `styles` do not depend on props
    ? (props?: any) => ClassNameMap<ClassKey> // `makeStyles` where the passed `styles` do depend on props
    : (props: Props) => ClassNameMap<ClassKey>
}

export const createStyles = materialCreateStyles
