import Grid from '@mui/material/Grid'
import { useIsMobile } from 'utils/hooks/useBreakpoints'
import { useSocialWall } from 'utils/hooks/useSocialWall'

import { UpcomingBirthdays } from './SocialWall/UpcomingBirthdays'
import { ValuesWidget } from './SocialWall/ValuesWidget'

export const SocialWallColumn = () => {
  const isMobile = useIsMobile()
  const { isBirthdayWidgetActive, isValuesWidgetActive } = useSocialWall()

  return (
    <Grid container spacing={isMobile ? 2 : 4}>
      <Grid item xs={12}>
        {isValuesWidgetActive && <ValuesWidget />}
      </Grid>
      <Grid item xs={12}>
        {isBirthdayWidgetActive && <UpcomingBirthdays />}
      </Grid>
    </Grid>
  )
}
