import { JSX, ReactElement, ReactNode } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { getResponsiveSize } from 'theme/styles.utils'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  card: ({ onClick, dangerColor }: Props) => {
    const getColor = (hover?: boolean) => {
      if (!onClick) return theme.palette.grey[500]
      if (dangerColor && hover) return theme.palette.error.dark
      if (dangerColor) return theme.palette.error.main
      if (hover) return theme.palette.primary.dark
      return theme.palette.primary.main
    }

    return {
      padding: theme.spacing(1.75, 3),
      borderRadius: getResponsiveSize(24),
      background: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
      cursor: onClick ? 'pointer' : undefined,
      minHeight: getResponsiveSize(112),
      minWidth: getResponsiveSize(112),
      display: 'flex',
      alignItems: 'flex-end',
      fontSize: theme.typography.h1.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      color: getColor(),
      lineHeight: getResponsiveSize(1.65, 'rem'),
      position: 'relative',
      transition: '0.3s all',
      '&:hover': {
        color: getColor(true),
        boxShadow: theme.shadows[5],
      },
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
        textAlign: 'center',
        lineHeight: 1,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h2.fontSize,
      },
    }
  },
  csIcon: {
    position: 'absolute',
    top: theme.spacing(1.25),
    right: theme.spacing(1.75),
    '& svg': {
      fontSize: getResponsiveSize(2.125, 'rem'),
    },
    [theme.breakpoints.down('md')]: {
      right: '50%',
      transform: 'translateX(50%)',
      '& svg': {
        fontSize: getResponsiveSize(2.7, 'rem'),
      },
    },
  },
}))

type Props = {
  title?: string
  icon?: ReactNode
  onClick?: () => void
  dangerColor?: boolean
  Wrapper?: (props: { children: ReactElement }) => JSX.Element
  dataCy?: string
}

export const MenuCard = ({ title, onClick, icon, dangerColor, Wrapper = Box, dataCy }: Props) => {
  const { card, csIcon } = useStyles({ onClick, dangerColor })
  return (
    <Grid item xs={6} md={3} onClick={onClick} data-cy={dataCy}>
      <Wrapper>
        <Grid container>
          <Grid item className={card} xs>
            {title}
            {icon && <div className={csIcon}>{icon}</div>}
          </Grid>
        </Grid>
      </Wrapper>
    </Grid>
  )
}
