import { captureMessage } from '@sentry/browser'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import { BpostPointType } from 'utils/hooks/api/Bpost/bpostAxios'
import { ElementCompact, xml2js } from 'xml-js'

export const getBpostPointFromXml = (xml: string): BpostPointType[] => {
  const parsedPoints = (xml2js(xml, { compact: true }) as ElementCompact)?.TaxipostLocator?.PoiList?.Poi || []
  if (isArray(parsedPoints)) {
    return parsedPoints
  }
  if (isObject(parsedPoints) && typeof (parsedPoints as BpostPointType)?.Record?.Zip?._text === 'string') {
    return [parsedPoints as BpostPointType]
  }
  captureMessage(`Problem with parsed points: ${JSON.stringify(parsedPoints)}`)
  return []
}
