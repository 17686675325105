import { useMutation } from '@tanstack/react-query'
import { getRequest } from 'utils/api/apiRequest'
import { User } from 'utils/hooks/userType'
import { isAxiosResponse } from 'utils/isAxiosResponse'
import { TranslationObj } from 'utils/types/TranslationObj'

type EnterCodeData = {
  code: string
}
export type EnterCodeDataResponse = {
  user: User
  status: 0 | 1 | 2
  personal_id_hint?: TranslationObj<'content'>
}

const patchEnterCode = (data: EnterCodeData) =>
  getRequest<EnterCodeDataResponse>(`/activation-codes/${data?.code}/validate`)

export const useEnterCodeMutation = (onSuccess: (data: any) => void) => {
  return useMutation(patchEnterCode, {
    onSuccess: (data, variables) => {
      if (isAxiosResponse(data) && data?.data?.status === 0) {
        onSuccess({ ...data?.data, code: variables?.code })
      }
    },
  })
}
