import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useAuthContext } from 'store/useAuthContext'
import { changePassword } from 'utils/api/User/changePassword'

type Props = {
  resetForm: () => void
}

export const useChangePassword = ({ resetForm }: Props) => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const { logoutUser } = useAuthContext()

  return useMutation(changePassword, {
    onSuccess: async () => {
      resetForm()
      logoutUser()
      enqueueSnackbar(t('profile.change_password.success'), { variant: 'success' })
    },
    onError: async (error) => {
      enqueueSnackbar(error.toString(), { variant: 'error' })
    },
  })
}
