import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { fontFamilies } from 'theme/styles.utils'

type Props = {
  projectName: string
  availablePoints: number
}

export const Header = ({ projectName, availablePoints }: Props) => {
  const { t } = useTranslation()

  return (
    <Box display="grid" gridTemplateColumns="1fr auto" columnGap={2} alignItems="center" sx={{ mb: 3 }}>
      <Box>
        <Typography align="left" variant="h2" fontFamily={fontFamilies.cond} fontWeight={900}>
          {projectName}
        </Typography>
      </Box>
      <Box>
        <Typography align="right" variant="body1" fontFamily={fontFamilies.cond} fontWeight={900}>
          {t('cms.points.available_points')}: {availablePoints?.toLocaleString()}
        </Typography>
      </Box>
    </Box>
  )
}
