import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { ShoppingCartItem } from 'shared/ShoppingCart/api'
import { useSelectWishlist } from 'shared/Wishlist/slice'
import { useRemoveCartItem } from 'utils/hooks/api/ShoppingCart/useRemoveCartItem'
import { useAddItemToWishlist } from 'utils/hooks/api/Wishlist/useAddItemToWishlist'
import { useIsMobile } from 'utils/hooks/useBreakpoints'
import { useIsLuckyBirdUser } from 'utils/hooks/useIsLuckyBirdUser'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  buttonText: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.h3.fontSize,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.h4.fontSize,
    },
  },
  buttonsWrapper: {
    marginLeft: theme.spacing(-0.625),
    [theme.breakpoints.down('md')]: {
      order: 3,
    },
  },
}))
type Props = {
  item: ShoppingCartItem
  disabledChange?: boolean
}

export const Buttons = ({ item, disabledChange }: Props) => {
  const { buttonsWrapper, buttonText } = useStyles()
  const { t } = useTranslation()
  const { handleAddItemToWishlist } = useAddItemToWishlist()
  const { data: wishlistItems } = useSelectWishlist()
  const { mutate: onRemoveCartItem } = useRemoveCartItem({})
  const isMobile = useIsMobile()
  const { isLuckyBird } = useIsLuckyBirdUser()

  const isInWishlist = !!wishlistItems?.data?.data?.find((i) => i.product.id === item.catalog_product.product.id)

  return (
    <Grid item xs={12} className={buttonsWrapper}>
      <Grid container spacing={1} alignItems="center" wrap="nowrap" justifyContent={isMobile ? 'center' : 'flex-start'}>
        {!disabledChange && (
          <Grid item>
            <Button size="small" color="primary" className={buttonText} onClick={() => onRemoveCartItem(item.id)}>
              {t('app.remove')}
            </Button>
          </Grid>
        )}
        {!isLuckyBird && !disabledChange && (
          <>
            <Grid item>|</Grid>
            <Grid item>
              <Button
                disabled={!!isInWishlist}
                size="small"
                color="primary"
                className={buttonText}
                onClick={() => handleAddItemToWishlist(item.catalog_product.product.id)}
              >
                {t(isInWishlist ? 'product.is_in_wishlist' : 'product.save_for_later')}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  )
}
