import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { useSubdomainInfo } from 'shared/Site/api'
import { SiteType } from 'shared/Site/siteType'
import { getRequest } from 'utils/api/apiRequest'
import { WithPagination } from 'utils/api/pagination'
import { useErrorSnackbar } from 'utils/hooks/useErrorSnackbar'

export const providerTypes = {
  custom_jwt: 0,
  okta: 1,
  azure: 2,
  van_moer: 3,
} as const

export type SSOProvider = {
  id: number
  type: (typeof providerTypes)[keyof typeof providerTypes]
  client_id: string
  authority: string
  redirect_uri: string
  secret: string
  issuer: string
  domain: string
  audience: string
  translation: Record<string, { login_page: string }>
}

export const useGetSSOProviders = () => {
  const enqueueErrorSnackbar = useErrorSnackbar()
  const { t } = useTranslation()
  const { data } = useSubdomainInfo()

  return useQuery({
    enabled: data?.data?.type === SiteType.cms && !!data?.data?.subscriber?.has_enabled_sso,
    queryKey: ['getSSOProviders'],
    queryFn: () => getRequest<WithPagination<SSOProvider>>('/auth-providers'),
    onError: (e) => {
      enqueueErrorSnackbar(e, { DEFAULT_ERROR: t('sso.get_providers.fail') })
    },
  })
}
