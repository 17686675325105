import { JSX } from 'react'
import Loader from 'components/Loader/Loader'
import { LinkBoxContentDialog } from 'pages/CMS/components/LinkBox'
import { SectionsGrid } from 'pages/CMS/components/Sections'
import { useIsMobile } from 'utils/hooks/useBreakpoints'
import { useSections } from 'utils/hooks/useSections'

import { CenterColumn, LeftColumn, RightColumn } from './components'
import { SocialWallColumn } from './components/SocialWallColumn'

interface Props {
  middleColumnContent?: JSX.Element
  socialWallColumns?: boolean
  socialWallSwitcher?: boolean
}

export const CMS = ({ middleColumnContent, socialWallColumns = false, socialWallSwitcher = false }: Props) => {
  const isMobile = useIsMobile()
  const { sections, pageData, isLoading: isLoadingCMSTheme } = useSections()

  return (
    <Loader isLoading={isLoadingCMSTheme} objects={sections}>
      <SectionsGrid
        socialWallSwitcher={socialWallSwitcher}
        LeftColumnComponent={
          <LeftColumn
            section={sections.find((section) => section.name === 'left_column')}
            socialWallColumns={socialWallColumns}
          />
        }
        MiddleColumnComponent={
          <CenterColumn
            page={pageData}
            middleColumnContent={middleColumnContent}
            section={sections.find((section) => section.name === 'center_column')}
          />
        }
        RightColumnComponent={
          socialWallColumns ? (
            <SocialWallColumn />
          ) : (
            <RightColumn section={sections.find((section) => section.name === 'right_column')} />
          )
        }
      />
      {isMobile && <LinkBoxContentDialog />}
    </Loader>
  )
}
