import { ReactNode } from 'react'
import Typography from '@mui/material/Typography'
import { fontFamilies } from 'theme/styles.utils'

export const DisplayValue = ({ value }: { value: ReactNode }) => {
  return (
    <Typography
      component="div"
      variant="h3"
      fontFamily={fontFamilies.default}
      sx={{ color: (theme) => theme.palette.grey[900] }}
    >
      {value}
    </Typography>
  )
}
