import { FieldsExtended, FormFieldExtended } from 'components/JSONForm/types'
import mapValues from 'lodash/mapValues'

interface SchemaProperty {
  type: string
  required?: boolean
  nullable?: boolean
  format?: string
  properties?: Record<string, SchemaProperty>
}

const mapToSchemaProperty = (f: FormFieldExtended): SchemaProperty => {
  if (f.type === 'text')
    return {
      type: 'string',
      required: f.required,
      nullable: true,
    }
  if (f.type === 'email')
    return {
      type: 'string',
      format: 'email',
      required: f.required,
    }
  if (f.type === 'select')
    return {
      type: 'string',
      required: f.required,
    }
  if (f.type === 'autocomplete')
    return {
      type: 'string',
      required: f.required,
      nullable: true,
    }
  if (f.type === 'date')
    return {
      type: 'date',
      required: f.required,
    }
  if (f.type === 'image')
    return {
      type: 'string',
      required: f.required,
      nullable: true,
    }
  if (f.type === 'object')
    return {
      type: 'object',
      properties: mapValues(f.fields, mapToSchemaProperty),
    }
}
export const mapJSONToYupSchema = (fields: FieldsExtended): SchemaProperty => ({
  type: 'object',
  properties: mapValues(fields, mapToSchemaProperty),
})
