import { DeliveryVariant, OneOfDeliveryVariant } from 'pages/CreateOrder/deliveryOptions'
import { getRequest } from 'utils/api/apiRequest'
import { Country } from 'utils/api/country'
import { CatalogProduct, Product } from 'utils/api/Products/CatalogProduct'
import { TranslationsKeys } from 'utils/createTranslationKey'

export enum DeliveryTypes {
  physical = 1,
  digital = 2,
  mixed = 3,
}

export type ShoppingCartItem = {
  id: string
  catalog_product: CatalogProduct
  product: Product
  quantity: number
  points?: number
  single_product_point?: number
  personalization_texts: string[]
}

export type ShippingData = {
  first_name: string
  last_name: string
  email: string
}

export type ShippingAddress = {
  street?: string
  number?: string
  country?: Country | null
  country_id?: number
  zip?: string
  city?: string
  company_name?: string
  box?: string
  preset?: number
  phone_number?: string // TODO check if this is needed
  phone?: string
}

export type DigitalDeliveryData = {
  shipping_data: ShippingData
  shipping_address: null
  delivery_address_type: typeof DeliveryVariant.digital
  bpost_delivery_point_id: null
  bpost_delivery_point_name: null
  preset_address_id: null
  phone_number: null
}

export type PresetDeliveryData = {
  shipping_data: ShippingData
  shipping_address: ShippingAddress
  preset_address_id: number
  delivery_address_type: typeof DeliveryVariant.preset
  phone_number: string
  bpost_delivery_point_id: null
  bpost_delivery_point_name: null
}

export type HomeDeliveryData = {
  shipping_data: ShippingData
  shipping_address: ShippingAddress
  phone_number: string
  delivery_address_type: typeof DeliveryVariant.homeAddress
  bpost_delivery_point_id: null
  bpost_delivery_point_name: null
  preset_address_id: null
}

export type CompanyDeliveryData = {
  shipping_data: ShippingData
  shipping_address: ShippingAddress
  phone_number: string
  delivery_address_type: typeof DeliveryVariant.companyAddress
  bpost_delivery_point_id: null
  bpost_delivery_point_name: null
  preset_address_id: null
}

export type BpostCollectionPointDeliveryData = {
  shipping_data: ShippingData
  shipping_address: ShippingAddress
  delivery_address_type: typeof DeliveryVariant.bpostCollectionPoint
  bpost_delivery_point_id: string
  bpost_delivery_point_name: string
  phone_number: string
  preset_address_id: null
}

export type BpostParcelLockerDeliveryData = {
  shipping_data: ShippingData
  shipping_address: ShippingAddress
  delivery_address_type: typeof DeliveryVariant.bpostParcelLocker
  bpost_delivery_point_id: string
  bpost_delivery_point_name: string
  phone_number: string
  preset_address_id: null
}

export type OnetimeDeliveryData = {
  shipping_data: ShippingData
  shipping_address: ShippingAddress
  delivery_address_type: typeof DeliveryVariant.onetime
  is_company_address: boolean
  phone_number: string
  bpost_delivery_point_id: null
  bpost_delivery_point_name: null
  preset_address_id: null
}

export type DeliveryDataType<T extends OneOfDeliveryVariant, D> = D & {
  delivery_address_type: T
}

export type DeliveryData =
  | DeliveryDataType<typeof DeliveryVariant.digital, DigitalDeliveryData>
  | DeliveryDataType<typeof DeliveryVariant.preset, PresetDeliveryData>
  | DeliveryDataType<typeof DeliveryVariant.homeAddress, HomeDeliveryData>
  | DeliveryDataType<typeof DeliveryVariant.companyAddress, CompanyDeliveryData>
  | DeliveryDataType<typeof DeliveryVariant.bpostCollectionPoint, BpostCollectionPointDeliveryData>
  | DeliveryDataType<typeof DeliveryVariant.bpostParcelLocker, BpostParcelLockerDeliveryData>
  | DeliveryDataType<typeof DeliveryVariant.onetime, OnetimeDeliveryData>

export enum DeliveryMethods {
  standard = '5ec8f990-e94a-4361-911f-68474a5262e3',
  date = 'f01648eb-a76c-4f32-bbe5-bb750c9824ed',
  groupedAndDate = '1751c52a-9204-41a6-8756-fb9a79ae2380',
}

export type ShoppingCartItems = {
  charity: null | number
  code: string
  delivery_data: DeliveryData
  delivery_method_name: DeliveryMethods
  delivery_type: number
  items: ShoppingCartItem[]
  minimal_order_value: number
  minimal_order_value_in_points: number
  total_order_value: number
  warnings: TranslationsKeys[]
}

export const getShoppingCart = () => getRequest<ShoppingCartItems>('/redemption-site/cart?limit=1000')
