import { getRequest } from 'utils/api/apiRequest'

export type Category = {
  id: number
  lft: number
  rgt: number
  lvl: number
  name: string
  image: string
  translation: {
    [key: string]: {
      name: string
    }
  }
  __children: Omit<Category, 'parent'>[]
  parent_id: number | null
  parent_name: string | null
  default: boolean
}

export const fetchCategories = (lang: string) =>
  getRequest<{ categories_tree: Category[] }>(`/redemption-site/categories?sort[name]=asc&limit=1000&lang=${lang}`)
