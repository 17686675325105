import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TypographyProps } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import { DepartmentSearchField } from 'components/DepartmentTree/DepartmentSearchField'
import { DepartmentTreeView } from 'components/DepartmentTree/DepartmentTreeView'
import { DepartmentTreeProps } from 'components/DepartmentTree/types'
import { useDepartments } from 'components/DepartmentTree/useDepartments'
import { DrawerSelect } from 'components/DrawerSelect'
import { drawerContext } from 'components/DrawerSelect/drawerContext'
import {
  FilterFrom,
  FilterTo,
  FilterValues,
  ResetFiltersButton,
  SubmitFiltersButton,
} from 'pages/SocialWallPostFilters/FilterComponents'
import { usePostsFilters } from 'pages/SocialWallPostFilters/usePostsFilters'
import { TranslationsKeys } from 'utils/createTranslationKey'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { useCountFilters } from 'utils/hooks/useCountFilters'
import { useIsRoute } from 'utils/hooks/useIsRoute'
import { useResize } from 'utils/hooks/useResize'
import { important } from 'utils/styles/stylesUtils'
import { Z_INDEX } from 'utils/styles/zIndex'

const Label = ({ translation, ...props }: { translation: TranslationsKeys } & TypographyProps) => {
  const { t } = useTranslation()
  return (
    <Typography textTransform="uppercase" fontWeight={600} fontSize={(t) => t.typography.h1.fontSize} {...props}>
      {t(translation)}
    </Typography>
  )
}

export const DepartmentTreeDesktop = ({ setSelected, selected }: DepartmentTreeProps) => {
  const [{ input, tree }, setFocusedElements] = useState({ input: false, tree: false })
  const anchorRef = React.useRef(null)

  const { data, isLoading, expanded, selectedAncestors, handleToggle, handleSetSearch, selectedNode } = useDepartments({
    setSelected,
    selected,
  })
  const width = useResize(anchorRef.current).width || 'auto'
  return (
    <>
      <Grid item xs={12}>
        <DepartmentSearchField
          ref={anchorRef}
          handleSetSearch={handleSetSearch}
          setSelected={setSelected}
          selectedNode={selectedNode}
          searchProps={{
            iconProps: { color: 'disabled' },
            placeholder: 'social_wall.filters.organisation',
            searchSx: {
              '& .MuiOutlinedInput-root': {
                background: (theme) => theme.palette.background.paper.concat('!important'),
              },
            },
          }}
          onClick={() => setFocusedElements((prev) => ({ ...prev, input: true }))}
          onBlur={() => {
            setFocusedElements((prev) => ({ ...prev, input: false }))
          }}
        />
      </Grid>
      <Popover
        open={input || tree}
        anchorEl={anchorRef.current}
        disableAutoFocus
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        disablePortal
        disableRestoreFocus
        slotProps={{
          root: {
            slotProps: {
              backdrop: {
                sx: {
                  pointerEvents: 'none',
                },
              },
            },
          },
          paper: {
            sx: {
              width,
              mt: 2,
              py: 1,
              borderRadius: (t) => t.spacing(1.5),
            },
          },
        }}
        onFocus={() => setFocusedElements((prev) => ({ ...prev, tree: true }))}
        onBlur={() => setFocusedElements((prev) => ({ ...prev, tree: false }))}
      >
        <Box>
          <DepartmentTreeView
            selected={selected}
            setSelected={(id) => {
              setFocusedElements((prev) => ({ ...prev, tree: false, input: false }))
              setSelected(id)
            }}
            isLoading={isLoading}
            selectedAncestors={selectedAncestors}
            data={data}
            handleToggle={handleToggle}
            expanded={expanded}
          />
        </Box>
      </Popover>
    </>
  )
}

const PostsFiltersDesktopContainer = () => {
  const { closeDrawer } = useContext(drawerContext)
  const { resetFilters, onFilterChange, getFilterValue, disabledFilters, onFiltersSubmit } = usePostsFilters()

  return (
    <Container sx={{ padding: 4, pt: 8 }} maxWidth="md" data-cy="filters-container">
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={4}>
          <FormControl>
            <Label translation="social_wall.filters.from" />
            <FilterFrom
              value={getFilterValue('FROM_MYSELF')}
              onChange={onFilterChange}
              disableMyself={disabledFilters.fromMyself}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl>
            <Label translation="social_wall.filters.to" />
            <FilterTo
              value={getFilterValue('TO_MYSELF')}
              onChange={onFilterChange}
              disableMyself={disabledFilters.toMyself}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Label translation="social_wall.filters.values" />
          <FilterValues value={getFilterValue('VALUES')} onChange={onFilterChange} />
        </Grid>
        <Grid container item xs={12} md={8} spacing={3} justifyContent="center" display="flex">
          <DepartmentTreeDesktop
            selected={getFilterValue('DEPARTMENT')}
            setSelected={(departmentId) => onFilterChange('DEPARTMENT', departmentId)}
          />
          <Grid item xs={12} sm={6}>
            <ResetFiltersButton resetFilters={resetFilters} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SubmitFiltersButton
              onFiltersSubmit={() => {
                onFiltersSubmit()
                closeDrawer()
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export const PostsFiltersDesktop = () => {
  const { t } = useTranslation()
  const { routeMatch } = useIsRoute(['/social-wall', '/'])
  const currentTab = routeMatch?.pathname
  const { countFilters } = useCountFilters()

  return (
    <DrawerSelect
      label={t('app.filters')}
      countFilters={countFilters}
      keepMounted={false}
      buttonProps={{
        disabled: currentTab !== '/social-wall',
        size: 'small',
        sx: {
          height: '100%',
          px: 1,
          fontWeight: 'bold',
          ['& svg']: {
            fontSize: important('.8rem'),
            margin: 0,
          },
        },
        'data-cy': 'show-filters-button',
      }}
      buttonWrapperProps={(open) => ({
        sx: {
          height: '100%',
          position: 'relative',
          zIndex: valueOrUndefined(open, Z_INDEX.APP_BAR),
        },
      })}
    >
      <PostsFiltersDesktopContainer />
    </DrawerSelect>
  )
}
