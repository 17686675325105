import { getRequest } from 'utils/api/apiRequest'
import { WithPagination } from 'utils/api/pagination'

export type CategoryForBreadcrumbs = {
  id: number
  name: string
  image: string
  translation: {
    [key: string]: {
      name: string
    }
  }
  parent: CategoryForBreadcrumbs
}

export const fetchCategoryBreadcrumbs = () =>
  getRequest<WithPagination<CategoryForBreadcrumbs>>(
    '/redemption-site/categories/breadcrumbs?sort[name]=asc&limit=1000'
  )
