import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteLike } from 'utils/api/SocialWall/deleteLike'
import { postLike } from 'utils/api/SocialWall/postLike'
import { PostsQueryData, updatePostQueryData, useSWQueryHelpers } from 'utils/hooks/api/SocialWall/utils'

type Props = {
  postId: number
}
export const useLike = ({ postId }: Props) => {
  const {
    postsFilteredKey,
    profile: { full_name, first_name, last_name, id },
    userId,
    invalidateAllPosts,
    t,
    enqueueSnackbar,
  } = useSWQueryHelpers()
  const queryClient = useQueryClient()

  const addLike = useMutation(() => postLike({ post: postId }), {
    onMutate: () => {
      const prevQuery = queryClient.getQueryData(postsFilteredKey)
      queryClient.setQueryData<PostsQueryData>(
        postsFilteredKey,
        updatePostQueryData(postId, (postToUpdate) => ({
          likes: [
            {
              id: -1,
              user: {
                id: userId,
                profile: {
                  full_name,
                  first_name,
                  last_name,
                  id,
                },
              },
            },
            ...postToUpdate.likes,
          ],
        }))
      )
      return prevQuery
    },
    onSuccess: (res) => {
      queryClient.setQueryData<PostsQueryData>(
        postsFilteredKey,
        updatePostQueryData(postId, () => ({
          likes: res.data.likes,
        }))
      )
      invalidateAllPosts()
    },
    onError: (err, _, prevQuery) => {
      queryClient.setQueryData(postsFilteredKey, prevQuery)
      enqueueSnackbar(t('recognition_post.like.add.fail'), { variant: 'error' })
    },
  })
  const removeLike = useMutation(() => deleteLike({ post: postId }), {
    onMutate: () => {
      const prevQuery = queryClient.getQueryData(postsFilteredKey)
      queryClient.setQueryData<PostsQueryData>(
        postsFilteredKey,
        updatePostQueryData(postId, (postToUpdate) => ({
          likes: postToUpdate.likes.filter((like) => like.user.id !== userId),
        }))
      )
      return prevQuery
    },
    onSuccess: () => {
      invalidateAllPosts()
    },
    onError: (err, _, prevQuery) => {
      queryClient.setQueryData(postsFilteredKey, prevQuery)
      enqueueSnackbar(t('recognition_post.like.remove.fail'), { variant: 'error' })
    },
  })
  return { addLike, removeLike }
}
