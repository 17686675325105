import { JSX } from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { getResponsiveSize } from 'theme/styles.utils'
import { TranslationsKeys } from 'utils/createTranslationKey'

type RecognizeSomeoneSectionProps = {
  sectionName?: TranslationsKeys
  SectionComponent: JSX.Element
}
export const RecognizeSomeoneSection = ({ sectionName, SectionComponent }: RecognizeSomeoneSectionProps) => {
  const { t } = useTranslation()
  return (
    <>
      <Grid item xs={12} md={2}>
        <Typography variant="body2" fontWeight={800} pl={getResponsiveSize(18, 'px')} data-cy={sectionName}>
          {t(sectionName)}
        </Typography>
      </Grid>
      <Grid item xs={12} md={10}>
        {SectionComponent}
      </Grid>
    </>
  )
}
