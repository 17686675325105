import { forwardRef, ReactElement, useEffect } from 'react'
import { IMaskInput } from 'react-imask'
import debounce from 'lodash/debounce'

export interface CustomProps {
  disabled?: boolean
  onChange: (event: { target: { name: string; value: string } }) => void
  onComplete?: (value: string) => void
  name: string
  value: string
  autoComplete?: boolean
  visibleValue?: boolean
}

export const TextMaskInput = forwardRef<ReactElement, CustomProps>((props, ref) => {
  const { onChange, onComplete, autoComplete = true, ...other } = props
  const onCompleteDebounced = debounce((value: string) => onComplete?.(value), 500)

  useEffect(() => {
    if (other?.disabled) return
    if (!autoComplete) return
    if (other?.value?.length === 12) onCompleteDebounced(other?.value)
  }, [other?.value, other?.disabled, autoComplete])

  return (
    <IMaskInput
      {...other}
      value={other?.disabled && !other.visibleValue ? '' : other?.value}
      mask="####-####-##"
      definitions={{
        '#': /[a-zA-Z0-9]/,
      }}
      // @ts-ignore
      inputRef={ref}
      onAccept={(value: string) => onChange({ target: { name: props.name, value: value?.toUpperCase() } })}
      overwrite
    />
  )
})
