import { JSX, ReactElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Badge from '@mui/material/Badge'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { Box } from '@mui/system'
import {
  BackOffice,
  Birthday,
  Gift,
  Heart,
  Home,
  Logout,
  Message,
  MyProfile,
  MyTeam,
  News,
  Nominate,
  Pin,
  Points,
  Reward,
  Settings,
  ShoppingBasket,
  Values,
} from 'components/ArteelIcons'
import { GiftHistory } from 'components/ArteelIcons/GiftHistory'
import { GivePoints } from 'components/ArteelIcons/GivePoints'
import { Language } from 'components/ArteelIcons/Language'
import { UserConsent } from 'components/ArteelIcons/UserConsent'
import { Voucher } from 'components/ArteelIcons/Voucher'
import { WriteMessage } from 'components/ArteelIcons/WriteMessage'
import { ADMIN_PANEL_URL, MAIN_DOMAIN, PROTOCOL } from 'config/env'
import isEmpty from 'lodash/isEmpty'
import { MenuCard } from 'pages/MainMenu/MenuCard'
import { useSelectShoppingCart } from 'shared/ShoppingCart/slice'
import { useSubdomainInfo } from 'shared/Site/api'
import { SiteType } from 'shared/Site/siteType'
import { SelectLanguage } from 'theme/SelectLanguage'
import { useLanguages } from 'utils/api/Languages/useLanguages'
import { Role } from 'utils/api/User'
import { TranslationsKeys } from 'utils/createTranslationKey'
import { dataCy } from 'utils/cypressUtils'
import { getCatalogUrl } from 'utils/getCatalogUrl'
import { useIsMobile, useIsTablet } from 'utils/hooks/useBreakpoints'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'
import { useIsLuckyBirdUser } from 'utils/hooks/useIsLuckyBirdUser'
import { useLogout } from 'utils/hooks/useLogout'
import { useLBCodeStatus } from 'utils/hooks/useLuckyBirdCodeStatus'
import { useSocialWall } from 'utils/hooks/useSocialWall'

type MenuCardType = {
  title: TranslationsKeys
  icon: ReactNode
  onClick?: () => void
  socialWallCard?: boolean
  dangerColor?: boolean
  Wrapper?: (props: { children: ReactElement }) => JSX.Element
  hidden?: boolean
  dataCy?: string
}

interface MainMenuProps {
  onPushClick?: () => void
}
export const MainMenu = ({ onPushClick }: MainMenuProps) => {
  const isTablet = useIsTablet()
  const isMobile = useIsMobile()
  const theme = useTheme()
  const { onClickLogout, DialogLogout } = useLogout()
  const navigateRoute = useNavigate()
  const { t } = useTranslation()
  const { data: subdomainInfo } = useSubdomainInfo()
  const cmsUrl = subdomainInfo?.data?.subscriber?.sites?.find((site) => site.type === 2)?.subdomain
  const { data: shoppingCartItems } = useSelectShoppingCart()
  const quantityArray = shoppingCartItems?.data?.items?.map((value) => value.quantity)
  const { data: languages, isFetched } = useLanguages()
  const user = useCurrentUser()
  const hasBOPermission = !isEmpty(user?.permissions)
  const statusLB = useLBCodeStatus()
  const { isLuckyBird } = useIsLuckyBirdUser()

  const isSingleLanguage = languages?.data?.length < 2
  const isAuthor = user?.roles?.includes(Role.ROLE_AUTHOR)
  const isActiveTransferPoints = Object.values(user?.projects || {})?.find(
    (project) => project.is_transfer_points_active
  )

  const { isValuesWidgetActive, isBirthdayWidgetActive, isSocialWallActive } = useSocialWall()
  const isTabletOrMobile = isMobile || isTablet
  const navigate = (route: string) => {
    navigateRoute(route)
    onPushClick?.()
  }

  const projectsArray = Object.entries(user?.projects || {}).map(([key, value]) => value)
  const menuCardsCMS = () => {
    const createMenuCards = (cards: MenuCardType[]) => {
      let filteredCards = cards.filter((card) => card?.onClick)

      if (!isSocialWallActive) {
        filteredCards = filteredCards.filter((card) => !card.socialWallCard)
      }

      return filteredCards
    }

    const projectWithCatalog = projectsArray?.filter((p) => p.is_points_project)

    const catalogs: MenuCardType[] = projectWithCatalog?.map((site) => ({
      title: t('menu.redeem').concat(` - ${site.name}`) as TranslationsKeys,
      icon: <Gift />,
      hidden: isLuckyBird,
      onClick: () => {
        window.location.assign(getCatalogUrl(site.subdomain))
        onPushClick?.()
      },
    }))

    return createMenuCards([
      {
        title: 'menu.home',
        icon: <Home />,
        onClick: () => navigate(`${isSocialWallActive ? '/social-wall' : '/'}`),
        dataCy: dataCy('menu-main-home'),
      },
      {
        title: 'menu.my_profile',
        icon: <MyProfile />,
        onClick: () => navigate('/my-account'),
        hidden: isLuckyBird,
        dataCy: dataCy('menu-main-my-profile'),
      },
      {
        title: 'menu.order_history',
        icon: <GiftHistory />,
        onClick: () => navigate('/my-account/order-history'),
        dataCy: dataCy('menu-main-order-history'),
      },
      {
        title: 'menu.points_history',
        icon: <Points />,
        onClick: () => navigate('/my-account/history-points'),
        hidden: isLuckyBird,
        dataCy: dataCy('menu-main-points-history'),
      },
      {
        title: 'menu.voucher_orders',
        icon: <Voucher />,
        onClick: () => navigate('/my-account/voucher-orders'),
        dataCy: dataCy('menu-main-voucher-orders'),
      },
      {
        title: 'menu.user_consent',
        icon: <UserConsent />,
        onClick: () => navigate('/my-account/user-consent'),
        hidden: isLuckyBird,
        dataCy: dataCy('menu-main-user-consent'),
      },
      {
        title: 'menu.back_office',
        icon: <BackOffice />,
        onClick:
          hasBOPermission && !isTabletOrMobile
            ? () => window.open(`${PROTOCOL}://${ADMIN_PANEL_URL}`, '_blank')
            : undefined,
        dataCy: dataCy('menu-main-back-office'),
      },
      { title: 'menu.my_team', icon: <MyTeam />, dataCy: dataCy('menu-main-my-team') },
      { title: 'menu.news', icon: <News />, dataCy: dataCy('menu-main-news') },
      { title: 'menu.points', icon: <Points />, dataCy: dataCy('menu-main-points') },
      ...catalogs,
      { title: 'menu.basket', icon: <ShoppingBasket />, dataCy: dataCy('menu-main-basket') },
      { title: 'menu.recognize', icon: <Heart />, dataCy: dataCy('menu-main-recognize') },
      { title: 'menu.reward', icon: <Reward />, dataCy: dataCy('menu-main-reward') },
      { title: 'menu.nominate', icon: <Nominate />, dataCy: dataCy('menu-main-nominate') },
      {
        title: 'menu.redeem',
        icon: <Gift />,
        onClick: () => navigate('/catalog'),
        hidden: !isLuckyBird || statusLB('fullyRedeemed'),
        dataCy: dataCy('menu-main-redeem'),
      },
      {
        title: 'menu.shopping_cart',
        onClick: () => navigate('/shopping-cart'),
        hidden: !isLuckyBird || statusLB('fullyRedeemed'),
        dataCy: dataCy('menu-main-shopping-cart'),
        icon: (
          <Badge
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            max={99}
            badgeContent={isEmpty(quantityArray) ? 0 : quantityArray.reduce((prev, curr) => prev + curr) || 0}
            color="primary"
          >
            <ShoppingBasket />
          </Badge>
        ),
      },
      {
        title: 'menu.faq',
        icon: <Message />,
        onClick: () => navigate('/faq'),
        dataCy: dataCy('menu-main-service-request'),
      },
      {
        title: 'menu.birthdays',
        icon: <Birthday />,
        onClick: isBirthdayWidgetActive ? () => navigate('/birthdays') : undefined,
        socialWallCard: true,
        dataCy: dataCy('menu-main-birthdays'),
      },
      {
        title: 'menu.values',
        icon: <Values />,
        onClick: isValuesWidgetActive ? () => navigate('/values') : undefined,
        socialWallCard: true,
        dataCy: dataCy('menu-main-values'),
      },
      { title: 'menu.pinned_recognitions', icon: <Pin /> },
      {
        title: 'menu.write_message',
        icon: <WriteMessage />,
        onClick: () => navigate('/personal-messages'),
        hidden: !isAuthor,
        dataCy: dataCy('menu-main-write-message'),
      },
      {
        title: 'menu.give_points',
        icon: <GivePoints />,
        onClick: () => navigate('/transfer-points'),
        hidden: !isActiveTransferPoints,
        dataCy: dataCy('menu-main-give-points'),
      },
      {
        title: 'menu.languages',
        icon: <Language />,
        Wrapper: ({ children }) => <SelectLanguage CustomButton={() => children} />,
        onClick: () => undefined,
        hidden: isSingleLanguage,
        dataCy: dataCy('menu-main-language'),
      },
      { title: 'menu.settings', icon: <Settings />, dataCy: dataCy('menu-main-settings') },
      {
        title: 'menu.logout',
        icon: <Logout />,
        onClick: onClickLogout,
        dangerColor: true,
        dataCy: dataCy('menu-main-logout'),
      },
    ])
  }
  const menuCardsRS = (): MenuCardType[] => [
    {
      title: 'app.tooltip.home',
      icon: <Home />,
      onClick: () =>
        window.location.assign(`${PROTOCOL}://${cmsUrl}.${MAIN_DOMAIN}${isSocialWallActive ? '/social-wall' : ''}`),
      dataCy: dataCy('menu-main-home'),
    },
    {
      title: 'app.tooltip.wishlist',
      icon: <Heart active />,
      onClick: () => navigate('/wishlist'),
      dataCy: dataCy('menu-main-wishlist'),
    },
    {
      title: 'app.tooltip.catalog',
      icon: <Gift />,
      onClick: () => navigate('/catalog'),
      dataCy: dataCy('menu-main-catalog'),
    },
    {
      title: 'app.tooltip.shopping_cart',
      icon: (
        <Badge
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          max={99}
          badgeContent={isEmpty(quantityArray) ? 0 : quantityArray.reduce((prev, curr) => prev + curr) || 0}
          color="primary"
        >
          <ShoppingBasket />
        </Badge>
      ),
      onClick: () => navigate('/shopping-cart'),
      dataCy: dataCy('menu-main-shopping-cart'),
    },
    {
      title: 'app.tooltip.language',
      icon: <Language />,
      Wrapper: ({ children }) => <SelectLanguage CustomButton={() => children} />,
      onClick: () => undefined,
      hidden: isSingleLanguage,
      dataCy: dataCy('menu-main-language'),
    },

    {
      title: 'menu.logout',
      icon: <Logout />,
      onClick: onClickLogout,
      dangerColor: true,
      dataCy: dataCy('menu-main-logout'),
    },
  ]

  return (
    <Container
      maxWidth="md"
      sx={{ [theme.breakpoints.up(1921)]: { maxWidth: 1800 } }}
      data-cy={dataCy('container-main-menu')}
    >
      <DialogLogout />
      <Box
        display="grid"
        gap={4}
        sx={{
          p: 4,
          [theme.breakpoints.down('lg')]: {
            py: 4,
            px: 0,
          },
        }}
      >
        <Grid container spacing={2} wrap="wrap">
          {(subdomainInfo?.data?.type === SiteType.cms ? menuCardsCMS() : menuCardsRS())
            .filter((card) => !card.hidden)
            .map((card) => (
              <MenuCard
                key={card.title}
                title={t(card.title)}
                icon={card.icon}
                onClick={card.onClick}
                dangerColor={card.dangerColor}
                Wrapper={card.Wrapper}
                dataCy={card.dataCy}
              />
            ))}
        </Grid>
      </Box>
    </Container>
  )
}
