import { useState } from 'react'
import HelpOutline from '@mui/icons-material/HelpOutline'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import { fontFamilies } from 'theme/styles.utils'
import { useIsMobile } from 'utils/hooks/useBreakpoints'
import { safeHtml } from 'utils/safeHtml'

const StyledTooltip = styled(Paper)(({ theme }) => ({
  maxWidth: '40vw',
  borderRadius: theme.spacing(1.5),
  filter: 'drop-shadow(0px 2px 5px rgba(0,0,0,0.40))',
  marginBottom: '12px',
  marginLeft: '-10px',
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    bottom: -12,
    left: 15,
    zIndex: 0,
    width: 0,
    height: 0,
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
    borderTop: `12px solid ${theme.palette.background.paper}`,
  },
}))

interface Props {
  hint: string
}

export const HelpTooltip = ({ hint }: Props) => {
  const isMobile = useIsMobile()
  const [open, setOpen] = useState(false)

  const renderHint = () => (
    <Box
      dangerouslySetInnerHTML={safeHtml(hint)}
      data-cy="value-description"
      sx={(t) => ({
        fontFamily: fontFamilies.default,
        lineHeight: 1.3,
        '& p': { paddingBottom: t.spacing(3) },
        '& strong': { fontWeight: 700 },
      })}
    />
  )

  if (isMobile)
    return (
      <>
        <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
          <DialogContent>{renderHint()}</DialogContent>
        </Dialog>
        <HelpOutline onClick={() => setOpen(true)} sx={{ cursor: 'pointer' }} />
      </>
    )

  return (
    <Tooltip
      slots={{ tooltip: StyledTooltip }}
      title={
        <Box
          sx={(t) => ({
            padding: t.spacing(4),
            paddingBottom: t.spacing(1),
            maxHeight: '70vh',
            overflow: 'auto',
          })}
        >
          {renderHint()}
        </Box>
      }
      placement="top-start"
    >
      <HelpOutline />
    </Tooltip>
  )
}
