import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import { FormikButtons } from 'components/Formik/FormikButtons'
import { FormikTextField } from 'components/Formik/FormikTextField'
import { useGetSubscriberTemplates } from 'pages/PersonalMessages/api/useGetSubscriberTemplates'
import { getObjectTranslation } from 'utils/getObjectTranslation'

type Props = {
  onBack: () => void
  isLoading: boolean
}

export const AddEditTemplateForm = ({ onBack, isLoading }: Props) => {
  const { data } = useGetSubscriberTemplates()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormikTextField name="name" inputLabel="personal_messages.templates.name" size="small" />
      </Grid>
      <Grid item xs={12}>
        <FormikTextField name="occasion" inputLabel="personal_messages.templates.occasion" select size="small">
          {data?.data?.data?.map((occasion) => (
            <MenuItem key={occasion.id} value={occasion.id?.toString()}>
              {getObjectTranslation(occasion?.translation)?.name}
            </MenuItem>
          ))}
        </FormikTextField>
      </Grid>
      <Grid item xs={12}>
        <FormikTextField multiline rows={4} name="message" inputLabel="personal_messages.templates.message" />
      </Grid>
      <Grid item xs={12}>
        <FormikButtons onBack={onBack} isLoading={isLoading} />
      </Grid>
    </Grid>
  )
}
