import { isAxiosError } from 'axios'
import isPlainObject from 'lodash/isPlainObject'
import { isBackendError } from 'utils/isBackendError'

export enum ErrorCodes {
  NOT_FOUND = 404,
  CONFLICT = 409,
  UNPROCESSABLE = 422,
  INTERNAL_ERROR = 500,
  DEFAULT_ERROR = 9999,
}
type ErrorCodesKeys = keyof typeof ErrorCodes
export type ErrorMessages = Record<ErrorCodesKeys, string>

const errorMessages: Partial<ErrorMessages> = {
  NOT_FOUND: 'Item was not found',
  INTERNAL_ERROR: 'Unexpected error occurred',
  DEFAULT_ERROR: 'An API connection error occurred',
}
export const parseError = (e: unknown, messages: Partial<ErrorMessages> = {}) => {
  // eslint-disable-next-line import/no-named-as-default-member
  if (!isAxiosError(e)) {
    return {
      isCode: (s: keyof typeof ErrorCodes) => ErrorCodes.DEFAULT_ERROR === ErrorCodes[s],
      status: ErrorCodes.DEFAULT_ERROR,
      message: errorMessages.DEFAULT_ERROR,
      mappedErrors: [],
    }
  }

  const mergedMessages = { ...errorMessages, ...messages }
  const status = (e.response?.status as ErrorCodes) || ErrorCodes.DEFAULT_ERROR
  const isCode = (s: ErrorCodesKeys) => status === ErrorCodes[s]
  let message = mergedMessages[ErrorCodes[status] as ErrorCodesKeys] || errorMessages.DEFAULT_ERROR
  const mappedErrors: [string, string][] = []
  if (isCode('UNPROCESSABLE') || isCode('NOT_FOUND')) {
    message = mergedMessages.DEFAULT_ERROR
    if (isBackendError(e.response?.data)) {
      message = e.response.data.message
      if (isPlainObject(e.response.data.errors)) {
        Object.entries(e.response.data.errors).forEach(([k, v]) => {
          mappedErrors.push([k, v])
        })
      }
    }
    if (mergedMessages.UNPROCESSABLE) {
      message = mergedMessages.UNPROCESSABLE
    }
  }
  return { status, message, isCode, mappedErrors }
}
