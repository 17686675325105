import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

export const MyTeam = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 70 70">
    <g>
      <path
        d="M39.9,38.9c-0.2-0.2-0.4-0.4-0.6-0.6c0,0,0,0,0,0c-2.8-2.6-6.6-3-7.5-3.1c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0
		c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0-0.3,0-0.9-0.1c-0.2,0-0.4,0-0.6,0c3.2-1.9,5.4-5.5,5.2-9.6c-0.3-5.3-4.6-9.6-9.9-9.8
		c-0.3,0-0.5,0-0.8,0c-0.2,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.3,0-0.5,0.1c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1
		c-0.2,0.1-0.3,0.1-0.5,0.2c-0.2,0.1-0.3,0.1-0.5,0.2c-0.2,0.1-0.3,0.1-0.5,0.2c-0.1,0.1-0.3,0.1-0.4,0.2c-0.2,0.1-0.3,0.2-0.5,0.3
		c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.3,0.2-0.4,0.4c-0.1,0.1-0.2,0.2-0.3,0.3
		c-0.1,0.1-0.3,0.3-0.4,0.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.2-0.3,0.4c-0.1,0.2-0.2,0.3-0.3,0.5
		c-0.1,0.1-0.2,0.3-0.2,0.4c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.4c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.1-0.1,0.3-0.2,0.4
		c-0.1,0.2-0.1,0.4-0.2,0.6c0,0.1-0.1,0.3-0.1,0.4c0,0.2-0.1,0.5-0.1,0.7c0,0.1,0,0.2-0.1,0.3c0,0.4-0.1,0.7-0.1,1.1
		c0,0.2,0,0.5,0,0.7c0.1,2.1,0.9,4.1,2.2,5.6c0.1,0.2,0.3,0.3,0.4,0.5c0.5,0.6,1.1,1.1,1.8,1.6c0.2,0.2,0.5,0.4,0.8,0.5h0
		c0,0,0,0,0,0c-0.5,0-1,0.1-1.5,0.1c-0.4,0-0.8,0.1-1.2,0.2c-5.9,1-8.3,4.9-9,7.4C7.1,44.5,7,48.9,6.9,52c0,0.9,0.3,1.8,1,2.4
		s1.4,1,2.4,1h29.4c1.9,0,3.4-1.5,3.3-3.4c0-0.3,0-0.5,0-0.8c0-0.1,0-0.1,0-0.2c0-0.3,0-0.5,0-0.8c0,0,0-0.1,0-0.1
		c0-0.6-0.1-1.3-0.1-1.9c-0.2-2.7-0.6-5.5-1.4-7.1C40.9,40.2,40.4,39.5,39.9,38.9z"
      />
      <path
        d="M62.9,38.3c-2-3.9-6.8-4.1-6.8-4.1s-0.4,0-1.1-0.1c2.2-1.3,3.7-3.8,3.6-6.6c-0.2-3.6-3.2-6.6-6.9-6.8
		c-4.1-0.2-7.6,3.1-7.6,7.2c0,2.6,1.4,5,3.6,6.2c-0.3,0-0.7,0.1-1,0.1c-2.9,0.3-4.8,1.6-5.8,2.9c0.8,0.8,1.6,1.8,2.2,3.1
		c0.8,1.6,1.4,4.3,1.6,8h17c1.3,0,2.3-1.1,2.3-2.3C64,43.5,63.8,40,62.9,38.3z"
      />
    </g>
  </SvgIcon>
)
