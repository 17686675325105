import { postRequest } from 'utils/api/apiRequest'
import { PostComment } from 'utils/api/SocialWall/types'

export type PostCommentPayload = {
  content: string
  post: number
}

export type PostCommentResponse = PostComment
export const postRecognitionComment = (data: PostCommentPayload) =>
  postRequest<PostCommentResponse>(`/social-wall/posts/comments`, data)
