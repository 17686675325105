import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { UpdateValues } from 'pages/Login/LuckyBird/types'
import { useIsLuckyBirdGlobalAuth } from 'pages/Login/LuckyBird/useIsLuckyBirdGlobalAuth'
import { useLuckyBirdCodeLoginMutation } from 'pages/Login/LuckyBird/useLuckyBirdCodeLoginMutation'
import { redirectLuckyBird } from 'pages/Login/LuckyBird/utils'
import { redirectOnLogin } from 'pages/Login/redirectOnLogin'
import { useAuthContext } from 'store/useAuthContext'
import { postRequest } from 'utils/api/apiRequest'
import { LuckyBirdCodeStatus, luckyBirdCodeStatus } from 'utils/hooks/luckyBirdCodeStatus'
import { useErrorSnackbar } from 'utils/hooks/useErrorSnackbar'

type Props = {
  onNextStep?: () => void
  updateValues: UpdateValues
  setIsRedirecting?: () => void
}

export interface PostLuckyBirdCodeStatusPayload {
  code: string
}
export interface PostLuckyBirdCodeStatusResponse {
  personal_data: {
    email?: string
    first_name?: string
    last_name?: string
  } | null
  project?: {
    subscriber: {
      cms_site: {
        subdomain: string
      }
    }
  }
  status: LuckyBirdCodeStatus
}
const postLuckyBirdCodeStatus = (params: PostLuckyBirdCodeStatusPayload) =>
  postRequest<PostLuckyBirdCodeStatusResponse>('/lucky-bird/status', params)

export const useLuckyBirdCodeStatusMutation = ({ updateValues, onNextStep, setIsRedirecting }: Props) => {
  const { refetch: refreshUser } = useAuthContext()
  const isGlobalLB = useIsLuckyBirdGlobalAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const enqueueErrorSnackbar = useErrorSnackbar()
  const { mutateAsync: login } = useLuckyBirdCodeLoginMutation({})

  return useMutation(postLuckyBirdCodeStatus, {
    onSuccess: async (data, variables) => {
      const { status, personal_data, project } = data.data
      const subdomain = project?.subscriber?.cms_site?.subdomain
      const isStatus = (statuses: (keyof typeof luckyBirdCodeStatus)[] = []) =>
        !!statuses.find((s) => luckyBirdCodeStatus[s] === status)

      if (subdomain && isGlobalLB) {
        setIsRedirecting?.()
        return redirectLuckyBird(subdomain, variables.code)
      }

      if (isStatus(['new'])) {
        updateValues?.({
          code: variables.code,
          email: personal_data?.email ?? '',
          first_name: personal_data?.first_name ?? '',
          last_name: personal_data?.last_name ?? '',
          subdomain,
        })
        onNextStep?.()
      } else {
        try {
          await login({ code: variables.code })
          await refreshUser()
          redirectOnLogin(navigate, false, location?.search)
        } catch (e) {
          enqueueErrorSnackbar(e, { DEFAULT_ERROR: t('auth.login.error') })
        }
      }
    },
    onError: async (e) => {
      enqueueErrorSnackbar(e, { DEFAULT_ERROR: t('auth.login.error') })
    },
  })
}
