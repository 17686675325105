import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { myAccountKeys } from 'pages/MyAccount/api/keys'
import { transferPointsKeys } from 'pages/TransferPoints/api/keys'
import { postRequest } from 'utils/api/apiRequest'
import { WithPagination } from 'utils/api/pagination'
import { Profile } from 'utils/api/User'
import userQueryKeys from 'utils/api/User/userQueryKeys'
import { useErrorSnackbar } from 'utils/hooks/useErrorSnackbar'

type Data = {
  receiver: number
  project_id: number
  points: number
  reason: string
}

export const postTransferPoints = (data: Data) => postRequest<WithPagination<Profile>>(`/transfer-points`, data)

export const usePostTransferPoints = () => {
  const { t } = useTranslation()
  const enqueueErrorSnackbar = useErrorSnackbar()
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: transferPointsKeys.postTransferPoints(),
    mutationFn: postTransferPoints,
    onSuccess: () => {
      queryClient.invalidateQueries(userQueryKeys.all)
      queryClient.invalidateQueries(myAccountKeys.all)
    },
    onError: (e) => {
      enqueueErrorSnackbar(e, { DEFAULT_ERROR: t('transfer_points.fail') })
    },
  })
}
