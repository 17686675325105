import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { MenuItem } from 'components/FooterMenu/MenuItem'
import { MenuSpacer } from 'components/FooterMenu/MenuSpacer'
import { APP_VERSION, ENVIRONMENT } from 'config/env'
import { useCookiesContext } from 'store/useCookiesContext'
import { useLogout } from 'utils/hooks/useLogout'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(3, 0),
  },
}))

type Props = {
  vertical?: boolean
}

export const Footer = ({ vertical }: Props) => {
  const copyrightYear = new Date().getFullYear()
  const { footer } = useStyles()
  const { t } = useTranslation()
  const { DialogLogout, onClickLogout } = useLogout()
  const navigate = useNavigate()
  const { openDetails } = useCookiesContext()

  const footerItems = [
    {
      name: 'contact',
      content: (
        <MenuItem color="primary" onClick={() => navigate(`/faq`)}>
          {t('app.faq')}
        </MenuItem>
      ),
      onMobile: false,
    },
    {
      name: 'privacyTerms',
      content: (
        <MenuItem color="primary" onClick={() => navigate(`/privacy-and-terms`)}>
          {t('app.privacy_terms')}
        </MenuItem>
      ),
      onMobile: true,
    },
    {
      name: 'cookiesManagement',
      content: (
        <MenuItem color="primary" onClick={() => openDetails()}>
          {t('cookies.management')}
        </MenuItem>
      ),
      onMobile: true,
    },
    {
      name: 'logout',
      content: (
        <MenuItem color="primary" onClick={onClickLogout}>
          {t('app.logout')}
        </MenuItem>
      ),
      onMobile: false,
    },
    {
      name: 'copyright',
      content: <MenuItem>&copy; Arteel {copyrightYear}</MenuItem>,
      onMobile: true,
    },
    {
      ...(ENVIRONMENT !== 'prod' && {
        name: 'version',
        content: <MenuItem>{APP_VERSION}</MenuItem>,
        onMobile: false,
      }),
    },
  ]

  if (!vertical) {
    return (
      <Grid container spacing={2} justifyContent="center" className={footer}>
        {footerItems.map((item, index, arr) => (
          <Fragment key={item.name}>
            {item.content}
            {index !== arr.length - 1 && <MenuSpacer />}
          </Fragment>
        ))}
        <DialogLogout />
      </Grid>
    )
  }

  return (
    <Grid container justifyContent="center" className={footer}>
      {footerItems.map((item) => (
        <Grid item xs={12} key={item.name}>
          <Typography align="center" color="primary">
            {item.content}
          </Typography>
        </Grid>
      ))}
      <DialogLogout />
    </Grid>
  )
}
