import { AutocompleteUsers } from 'components/AutocompleteUsers'
import { AutocompleteUsersProps, OptionBaseParams } from 'components/AutocompleteUsers/types'
import { useFormikContext } from 'formik'

export interface FormikSelectUsersProps<T extends OptionBaseParams>
  extends Pick<AutocompleteUsersProps<T>, 'getOptionLabel' | 'maxOptions' | 'options' | 'getChipLabel' | 'isLoading'> {
  name: string
}

export const FormikAutocompleteUsers = <T extends OptionBaseParams>({ name, ...props }: FormikSelectUsersProps<T>) => {
  const { setFieldValue, getFieldMeta } = useFormikContext()
  const { touched, error } = getFieldMeta(name)
  const errorText = touched && error

  return <AutocompleteUsers error={errorText} onChange={(v) => setFieldValue(name, v)} {...props} />
}
