import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { usePatchPersonalTemplate } from 'pages/PersonalMessages/api/useEditPersonalTemplate'
import { Occasion, PersonalTemplate } from 'pages/PersonalMessages/api/useGetPersonalTemplatesByOccasionId'
import { usePostPersonalTemplate } from 'pages/PersonalMessages/api/usePostPersonalTemplate'
import { AddEditTemplateForm } from 'pages/PersonalMessages/Templates/AddEditTemplate/AddEditTemplateForm'
import { containEmoji } from 'utils/formik/containEmoji'
import * as Yup from 'yup'

type Props = {
  template?: PersonalTemplate & { occasionId: Occasion['id'] }
  onBack: () => void
}

export const AddEditTemplate = ({ template, onBack }: Props) => {
  const { t } = useTranslation()
  const { mutate: add, isLoading: addIsLoading } = usePostPersonalTemplate()
  const { mutate: edit, isLoading: editIsLoading } = usePatchPersonalTemplate()

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const initialValues = {
    name: template?.name ?? '',
    occasion: template?.occasionId?.toString(),
    message: template?.content ?? '',
  }
  const validationSchema = Yup.object().shape({
    name: Yup.string().required().test('no-emoji', t('personal_messages.add.validation.no_emojis'), containEmoji),
    message: Yup.string()
      .required()
      .min(1)
      .max(65535)
      .test('no-emoji', t('personal_messages.add.validation.no_emojis'), containEmoji),
    occasion: Yup.number().required().nullable(),
  })

  const onSubmit = (values: typeof initialValues) => {
    if (template?.id) {
      edit({
        id: template.id,
        name: values.name,
        content: values.message,
        occasion: Number(values.occasion),
        onSuccess: onBack,
      })
    } else {
      add({ name: values.name, content: values.message, occasion: Number(values.occasion), onSuccess: onBack })
    }
  }

  return (
    <Formik initialValues={initialValues} enableReinitialize validationSchema={validationSchema} onSubmit={onSubmit}>
      <Form>
        <AddEditTemplateForm onBack={onBack} isLoading={addIsLoading || editIsLoading} />
      </Form>
    </Formik>
  )
}
