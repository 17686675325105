import { Component } from 'react'
import { createPortal } from 'react-dom'
import Container from '@mui/material/Container'
import isEmpty from 'lodash/isEmpty'

import { Notification } from './Notification'

export interface Message {
  variant: 'success' | 'error' | 'warning' | 'info'
  id?: string
  message: string
  open?: boolean
}

type State = {
  messages: Message[]
}

type messageFunc = (message: Message) => void

const MessagesEmiter = {
  events: {} as any,
  dispatch(eventName: string, messageConfig: Message) {
    if (!this.events[eventName]) return
    this.events[eventName].forEach((callback: messageFunc) => callback(messageConfig))
  },
  subscribe(eventName: string, callback: messageFunc) {
    if (!this.events[eventName]) this.events[eventName] = []
    this.events[eventName].push(callback)
  },
}

const ADD_MESSAGE = 'addMessage'
const REMOVE_MESSAGE = 'removeMessage'

class MessagesContainer extends Component<{}, State> {
  state: State = {
    messages: [],
  }

  componentDidMount() {
    MessagesEmiter.subscribe(ADD_MESSAGE, this.addMessage)
    MessagesEmiter.subscribe(REMOVE_MESSAGE, this.removeMessage)
  }

  addMessage = (message: Message) =>
    this.setState(
      ({ messages }) => ({
        messages: [...messages, message],
      }),
      () => {
        setTimeout(() => MessagesEmiter.dispatch(REMOVE_MESSAGE, message), this.state.messages.length * 5000)
      }
    )

  removeMessage = (message: Message) =>
    this.setState(({ messages }) => ({
      messages: messages.filter(({ id }) => id !== message.id),
    }))

  render() {
    const { messages } = this.state
    return createPortal(
      <Container>
        {!isEmpty(messages) && <Notification message={messages[0]?.message} variant={messages[0].variant} />}
      </Container>,
      document.querySelector('body')!
    )
  }
}

export default MessagesContainer

let currentMessageId = 0
export const message = ({ message, variant }: Message = { variant: 'info', message: '' }) => {
  const messageConfig = { id: String(currentMessageId++), message, variant }
  MessagesEmiter.dispatch(ADD_MESSAGE, messageConfig)
}
