import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Loader from 'components/Loader/Loader'
import { PersonalizationLine } from 'pages/ShoppingCart/PersonalizationLine'
import { ShoppingCartItem } from 'shared/ShoppingCart/api'
import { fontFamilies } from 'theme/styles.utils'
import { usePersonalizationText } from 'utils/hooks/api/ShoppingCart/usePersonalizationText'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 7),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0),
    },
  },
}))

type Props = {
  item: ShoppingCartItem
  disabledChange?: boolean
}

export const PersonalizationTexts = ({ item, disabledChange }: Props) => {
  const { container } = useStyles()
  const { t } = useTranslation()
  const { mutate, isLoading } = usePersonalizationText()

  if (!item?.product?.personalization) {
    return null
  }

  return (
    <Grid item xs={12} sx={{ order: 4 }}>
      <Loader isLoading={isLoading} visibleChildren>
        <Grid container spacing={2} justifyContent="center" className={container} sx={{ pl: 7, pr: 7 }}>
          <Grid item xs={12}>
            <Typography variant="h3" align="center" fontFamily={fontFamilies.black} fontWeight={900}>
              {/* // RP-5201 */}
              {t('shopping_cart.personalization')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" align="center" fontFamily={fontFamilies.default}>
              {/* // RP-5201 */}
              {t('shopping_cart.personalization.description')}
            </Typography>
          </Grid>
          {[...Array(item.quantity).keys()].map((position) => (
            <Grid item xs={12} key={`item-personalization-${position}`}>
              <PersonalizationLine position={position} item={item} onChange={mutate} disabledChange={disabledChange} />
            </Grid>
          ))}
        </Grid>
      </Loader>
    </Grid>
  )
}
