import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'

import { IconActive } from './IconActive'

export const MyProfile = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 30 30">
    <g xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M7.6,7.8c0,4.1,3.4,7.5,7.5,7.5s7.5-3.4,7.5-7.5s-3.4-7.5-7.5-7.5C11,0.3,7.6,3.8,7.6,7.8z" />
      </g>
      <g>
        <path d="M27.7,24.4c0-1.5-0.5-2.9-1.2-4.1c-0.7-1.2-1.7-2.2-2.9-3.1c-0.9-0.7-2-1.2-3.2-1.5c-1.5,1-3.4,1.7-5.5,1.7    s-3.9-0.7-5.5-1.7c-1.2,0.3-2.2,0.9-3.2,1.5C5,18.1,4,19.1,3.3,20.3c-0.7,1.2-1.2,2.6-1.2,4.1c0,0.2,0,3.2,0,3.2c0,1.2,1,2,2.4,2    h20.6c1.4,0,2.4-0.9,2.4-2C27.7,27.6,27.7,24.7,27.7,24.4z" />
      </g>
    </g>
  </SvgIcon>
)
