import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { TextField } from 'components/TextField/TextField'
import { useFormik } from 'formik'
import { getTextFieldParams } from 'utils/formik/getTextFieldProps'
import { useChangePassword } from 'utils/hooks/api/useChangePassword'
import { makeStyles } from 'utils/styles/makeStyles'
import * as Yup from 'yup'

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginTop: theme.spacing(3),
  },
  formWrapper: {
    marginTop: theme.spacing(3),
  },
}))
export const Form = () => {
  const { buttons, formWrapper } = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const validationSchema = Yup.object().shape({
    current_password: Yup.string().label(t('profile.current_password')).required().min(1),
    password_first: Yup.string()
      .label(t('profile.password_first'))
      .required()
      .matches(/^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/, t('app.validations.password'))
      .matches(
        // eslint-disable-next-line no-useless-escape
        /[\!\@\#\$\%\^\&\*\(\)\_\+\-\=\[\]\{\};:\",\.<>\/?]/,
        t('app.validations.hasSpecialCharacter').replace('$path', t('profile.password_first'))
      ),
    password_second: Yup.string()
      .label(t('profile.password_first'))
      .required()
      .oneOf([Yup.ref('password_first')], t('app.validations.confirm_password')),
  })

  const { handleSubmit, getFieldMeta, getFieldProps, resetForm } = useFormik({
    enableReinitialize: true,
    initialValues: {
      current_password: '',
      password_first: '',
      password_second: '',
    },
    validationSchema,
    onSubmit: (values) =>
      changePassword({
        current_password: values.current_password,
        password: {
          first: values.password_first,
          second: values.password_second,
        },
      }),
  })
  const { mutate: changePassword } = useChangePassword({ resetForm })

  return (
    <form onSubmit={handleSubmit}>
      <Grid container className={formWrapper} spacing={2} justifyContent="center">
        <Grid item xs={12} sm={9}>
          <TextField
            variant="outlined"
            type="password"
            size="medium"
            {...getTextFieldParams('current_password', 'profile.', getFieldMeta, getFieldProps)}
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          <TextField
            variant="outlined"
            type="password"
            {...getTextFieldParams('password_first', 'profile.', getFieldMeta, getFieldProps)}
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          <TextField
            variant="outlined"
            type="password"
            {...getTextFieldParams('password_second', 'profile.', getFieldMeta, getFieldProps)}
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          <Grid container className={buttons} justifyContent="center" spacing={2}>
            <Grid item>
              <Button variant="contained" color="inherit" type="button" onClick={() => navigate(`/my-account`)}>
                {t('app.back')}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" type="submit">
                {t('app.save')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}
