import { DeliveryPresetAddress } from 'utils/api/DeliveryPresetAddress/getDeliveryPresetAddresses'

export const getAddress = (address: DeliveryPresetAddress, lang: string) => {
  const concatPart = (...args: (string | undefined)[]) => args.filter(Boolean).join(' ')

  return [
    address?.company_name,
    concatPart(address?.street, address?.number, address?.box),
    concatPart(address?.zip, address?.city),
    address?.country?.translation?.[lang]?.name,
  ]
    .filter(Boolean)
    .join(', ')
}
