import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js'
import { ENVIRONMENT } from 'config/env'
import { providerTypes, SSOProvider } from 'pages/Login/api/useGetSSOProviders'
export type OktaConfig = {
  oidc: OktaAuthOptions & {
    disableHttpsCheck: boolean
    useInteractionCode: boolean
  }
}
export const oktaConfig = (provider?: SSOProvider): OktaConfig => ({
  oidc: {
    clientId: provider?.secret ?? process.env.REACT_APP_OKTA_CLIENT_ID,
    issuer: provider?.issuer ?? process.env.REACT_APP_OKTA_ISSUER,
    redirectUri: provider?.redirect_uri ?? process.env.REACT_APP_OKTA_REDIRECT_URI,
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    pkce: true,
    disableHttpsCheck: ['local', 'dev'].includes(ENVIRONMENT),
    useInteractionCode: true,
    devMode: false,
  },
})
export const oktaAuth = (providers: SSOProvider[]) => {
  const provider = providers.find((provider) => provider.type === providerTypes.okta)
  return new OktaAuth(oktaConfig(provider).oidc)
}
