import { JSX } from 'react'
import { useTranslation } from 'react-i18next'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import { getResponsiveSize } from 'theme/styles.utils'
import { TranslationsKeys } from 'utils/createTranslationKey'
import { dataCy } from 'utils/cypressUtils'
import { displayPoints } from 'utils/displayPoints'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'
import { usePointsName } from 'utils/hooks/usePointsName'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  conclusion: {
    margin: 'auto',
    fontSize: theme.typography.h1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    '& .grey': {
      color: theme.palette.grey[500],
    },
    maxWidth: getResponsiveSize(650),
    [theme.breakpoints.down('md')]: {
      maxWidth: 450,
      fontSize: theme.typography.h3.fontSize,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 350,
    },
  },
}))

type Props = {
  currentValue: number
  currentValueLabel?: TranslationsKeys
}

export const ConclusionPoints = ({ currentValue, currentValueLabel }: Props) => {
  const { conclusion } = useStyles()
  const { t } = useTranslation()
  const actualUserPoints = useCurrentUser()?.available_points || 0
  const remainingPoints = actualUserPoints - currentValue > 0 ? actualUserPoints - currentValue : 0

  const ConclusionWrapper = ({ children }: { children: JSX.Element[] }) => (
    <Grid container item spacing={2} xs={12}>
      <Grid item xs={12} className={conclusion}>
        <Grid container spacing={2} justifyContent="space-between">
          {children}
        </Grid>
      </Grid>
    </Grid>
  )

  return (
    <Grid container spacing={2} data-cy={dataCy('container-points-conclusion')}>
      <ConclusionWrapper>
        <Grid item xs>
          {t('wishlist.available_points')}:
        </Grid>
        <Grid item className="grey">
          {displayPoints(actualUserPoints)} {usePointsName(actualUserPoints)}
        </Grid>
      </ConclusionWrapper>
      <ConclusionWrapper>
        <Grid item xs>
          {t(currentValueLabel)}:
        </Grid>
        <Grid item>
          {displayPoints(currentValue)} {usePointsName(currentValue)}
        </Grid>
      </ConclusionWrapper>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <ConclusionWrapper>
        <Grid item xs>
          {t('wishlist.remaining_points')}:
        </Grid>
        <Grid item className="grey">
          {displayPoints(remainingPoints)} {usePointsName(remainingPoints)}
        </Grid>
      </ConclusionWrapper>
    </Grid>
  )
}
