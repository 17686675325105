import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import { Breadcrumbs, Segment } from 'components/Breadcrumbs'
import { HeaderPage } from 'components/HeaderPage/HeaderPage'
import Loader from 'components/Loader/Loader'
import { WhitePaper } from 'components/WhitePaper/WhitePaper'
import { TFunction } from 'i18next'
import { BrandsGrid } from 'pages/Brands/compontents/BrandsGrid'
import { SearchBrand } from 'pages/Brands/compontents/SearchBrand'
import { useBrands } from 'utils/hooks/api/useBrands'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  spacer: {
    height: theme.spacing(2),
    width: '100%',
  },
}))

const getBreadcrumbsSegments = (t: TFunction): Segment[] => [
  {
    id: 'catalog',
    label: t('catalog'),
    url: '/catalog',
  },
  {
    id: 'brands',
    label: t('brands'),
  },
]

export const Brands = () => {
  const { spacer } = useStyles()
  const { t } = useTranslation()
  const { isFetching, data } = useBrands()

  const brands = data?.data

  return (
    <WhitePaper header={<Breadcrumbs segments={getBreadcrumbsSegments(t)} />}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <HeaderPage text={t('brands')} size="medium" />
        </Grid>
        <Grid item xs={12}>
          <SearchBrand />
        </Grid>
        <Grid item xs={12}>
          <div className={spacer} />
        </Grid>
        <Grid item xs={12}>
          <Loader isLoading={isFetching} objects={brands?.data}>
            <BrandsGrid brands={brands} />
          </Loader>
        </Grid>
      </Grid>
    </WhitePaper>
  )
}
