import { FunctionComponent } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import Box from '@mui/material/Box'
import BreadcrumbsMaterial from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { Arrow } from 'components/ArteelIcons'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { makeStyles } from 'utils/styles/makeStyles'

import { Segment } from './types'

const useStyles = makeStyles((theme) => ({
  lastSegment: {
    fontWeight: 900,
    color: theme.palette.primary.main,
  },
}))

type Props = {
  segments: Segment[]
}

export const MobileBreadcrumbs: FunctionComponent<Props> = ({ segments }) => {
  const classes = useStyles()
  const segment = segments?.length === 1 ? segments[0] : segments[segments?.length - 2]

  if (!segment) return <Box />

  return (
    <BreadcrumbsMaterial sx={(t) => ({ color: t.palette.grey[800] })} arial-label="Breadcrumb">
      {segment.url ? (
        <Link component={RouterLink} color="inherit" to={segment.url} key={segment.id}>
          <Typography variant="h4" component="span" lineHeight={1} className={classes.lastSegment}>
            <Arrow direction="left" sx={{ mr: 0.5, fontSize: '0.75rem' }} />
            {segment.label}
          </Typography>
        </Link>
      ) : (
        <Typography variant="h4" component="span" color="primary" key={segment.id} className={classes.lastSegment}>
          {segment.label}
        </Typography>
      )}
    </BreadcrumbsMaterial>
  )
}
export const Breadcrumbs: FunctionComponent<Props> = ({ segments }) => {
  const classes = useStyles()

  return (
    <BreadcrumbsMaterial
      sx={(t) => ({ color: t.palette.grey[800], py: 1, pl: { xs: 0, lg: 2 } })}
      arial-label="Breadcrumb"
      separator={<NavigateNextIcon fontSize="small" />}
    >
      {segments.map((segment, i, arr) => {
        return segment.url ? (
          <Link component={RouterLink} color="inherit" to={segment.url} key={segment.id}>
            <Typography
              variant="h3"
              component="span"
              className={valueOrUndefined(arr.length === i + 1, classes.lastSegment)}
            >
              {segment.label}
            </Typography>
          </Link>
        ) : (
          <Typography variant="h3" component="span" color="primary" key={segment.id} className={classes.lastSegment}>
            {segment.label}
          </Typography>
        )
      })}
    </BreadcrumbsMaterial>
  )
}
