import Box from '@mui/material/Box'
import { CommentBubble } from 'components/ArteelIcons/Comment'
import { PostActionsInfoLikes } from 'pages/SocialWall/components/PostActionsInfoLikes'
import { TypographyWithIcon } from 'pages/SocialWall/components/TypographyWithIcon'
import { SocialWallPost } from 'utils/api/SocialWall/types'

interface Props {
  likes: SocialWallPost['likes']
  comments: SocialWallPost['comments']
  onClickComments: () => void
}
export const PostActionsInfo = ({ likes = [], comments = [], onClickComments }: Props) => {
  return (
    <Box display="flex" justifyContent="space-between" py={1}>
      <PostActionsInfoLikes likes={likes} />
      <TypographyWithIcon data-cy="comments-number-text" onClick={onClickComments} sx={{ cursor: 'pointer' }}>
        {comments.length} <CommentBubble sx={{ ml: 0.5, mb: 0.25, fontSize: 'larger' }} />
      </TypographyWithIcon>
    </Box>
  )
}
