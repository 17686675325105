import { Language, LanguageSubscriber } from 'utils/api/Languages/types'
import { WithPagination } from 'utils/api/pagination'

/** hardcoded limiting languages for LuckyBird global page,
 *  waits for more space for improvements */
export const mapLangsToLBGlobalPage = (langs: WithPagination<Language>): WithPagination<LanguageSubscriber> => {
  const availableCodes = ['en', 'fr', 'nl']
  const d = langs.data
    .filter((l) => availableCodes.includes(l.code))
    .map((l) => ({
      default: l.code === 'en',
      language: l,
    }))
  return { ...langs, data: d }
}
