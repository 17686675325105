import { CardGrid } from 'components/CardGrid/CardGrid'
import isEmpty from 'lodash/isEmpty'
import { BrandViews } from 'pages/Brands/compontents/BrandView'
import { getResponsiveSize } from 'theme/styles.utils'
import { Brand } from 'utils/api/Brands/fetchBrands'
import { WithPagination } from 'utils/api/pagination'

type Props = {
  brands: WithPagination<Brand>
}

export const BrandsGrid = ({ brands }: Props) => {
  const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('')

  const getBrandsBeginnerLetter = (letter: string) =>
    brands?.data.filter((b) => b.name.toUpperCase().startsWith(letter.toUpperCase()))

  const isEmptyBrands = (letter: string) => isEmpty(getBrandsBeginnerLetter(letter))

  return (
    <CardGrid
      headerSx={{ fontSize: `${getResponsiveSize(3)}rem` }}
      rows={alphabet.map((letter) => ({
        id: `letter-${letter}`,
        text: letter.toUpperCase(),
        content: <BrandViews brands={getBrandsBeginnerLetter(letter)} />,
        isEmpty: isEmptyBrands(letter),
      }))}
    />
  )
}
