import { ReactNode, useState } from 'react'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { Close } from 'components/ArteelIcons'
import { makeStyles } from 'utils/styles/makeStyles'

const BUTTON_WIDTH = 40
const useStyles = makeStyles((theme) => ({
  container: {
    borderTop: `1px solid ${theme.palette.background.default}`,
  },
  headerWrapper: {
    position: 'relative',
    marginLeft: BUTTON_WIDTH,
    marginRight: BUTTON_WIDTH,
    '& button': {
      padding: 6,
      position: 'absolute',
      top: '50%',
      left: -BUTTON_WIDTH,
      transform: 'translateY(-50%)',
    },
  },
  header: {
    fontWeight: theme.typography.fontWeightBold,
    cursor: 'pointer',
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2),
  },
  csContent: {
    maxWidth: '100%',
    transition: '0.5s all',
  },
}))

type Props = {
  content: ReactNode
  title: string
  initialValue?: boolean
}
export const Panel = ({ content, title, initialValue }: Props) => {
  const [isOpen, setOpen] = useState(initialValue || false)
  const { container, header, headerWrapper, csContent } = useStyles()
  return (
    <Grid container className={container}>
      <Grid item xs={12} className={headerWrapper}>
        <Typography variant="h1" align="center" className={header} onClick={() => setOpen((prev) => !prev)}>
          <IconButton color="primary" size="large">
            <Close
              fontSize="medium"
              sx={{
                transform: `rotate(${isOpen ? 0 : 180 - 45}deg)`,
                transition: 'all 0.3s',
              }}
            />
          </IconButton>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} className={csContent}>
        <Collapse in={isOpen} timeout={300}>
          {content}
        </Collapse>
      </Grid>
    </Grid>
  )
}
