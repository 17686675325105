import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { FormikButtons } from 'components/Formik/FormikButtons'
import { FormikTextField } from 'components/Formik/FormikTextField'
import Loader from 'components/Loader/Loader'
import { TextMaskInput } from 'components/TextMaskInput/TextMaskInput'
import { Form, Formik } from 'formik'
import { UpdateValues } from 'pages/Login/LuckyBird/types'
import { useLuckyBirdCodeStatusMutation } from 'pages/Login/LuckyBird/useLuckyBirdCodeStatus'
import qs from 'query-string'
import { useCookiesContext } from 'store/useCookiesContext'
import * as Yup from 'yup'

type Props = {
  onNextStep: () => void
  updateValues: UpdateValues
}

export const Step1 = ({ onNextStep, updateValues }: Props) => {
  const { t } = useTranslation()
  const [isRedirecting, setIsRedirecting] = useState(false)
  const { isCookieBannerVisible } = useCookiesContext()

  const { mutateAsync, isLoading } = useLuckyBirdCodeStatusMutation({
    onNextStep,
    updateValues,
    setIsRedirecting: () => setIsRedirecting(true),
  })
  const { search } = useLocation()
  const params = qs.parse(search)

  const onSubmit = (values: { code: string }) => mutateAsync(values)

  const validationSchema = Yup.object().shape({
    code: Yup.string().label(t('auth.login.code')).required(),
  })

  const initialValues = {
    code: params?.luckyBirdCode?.toString() ?? '',
  }
  useEffect(() => {
    if (params?.luckyBirdCode) {
      mutateAsync(initialValues)
    }
  }, [])

  return (
    <Formik initialValues={initialValues} enableReinitialize validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <Loader
          isLoading={isRedirecting || (!!params?.luckyBirdCode && isLoading)}
          visibleChildren={!params?.luckyBirdCode}
          fullHeight
        >
          <Form style={{ height: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormikTextField
                  autoFocus={!isCookieBannerVisible}
                  center
                  name="code"
                  inputLabel="auth.login.code"
                  placeholder="XXXX-XXXX-XX"
                  size="small"
                  InputProps={{
                    inputComponent: TextMaskInput as any,
                    inputProps: {
                      onComplete: handleSubmit,
                      autoComplete: !params?.luckyBirdCode as any,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikButtons textButtonSubmit="auth.login" isLoading={isLoading} />
              </Grid>
            </Grid>
          </Form>
        </Loader>
      )}
    </Formik>
  )
}
