import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

export const Show = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 200 200">
    <path
      d="M100,42.3c-57.4,0-85.1,57.7-85.1,57.7s27.6,57.7,85.1,57.7s85.1-57.7,85.1-57.7S157.4,42.3,100,42.3z M100,144.6
	c-24.7,0-44.6-20.1-44.6-44.6c0-24.7,20.1-44.6,44.6-44.6c24.6,0,44.6,20.1,44.6,44.6C144.6,124.7,124.7,144.6,100,144.6z"
    />
    <path
      d="M121.1,91.7c-7,0-12.7-5.7-12.7-12.7c0-2.2,0.5-4.3,1.6-6.1c-3-1.1-6.5-1.8-9.9-1.8c-16,0-29,12.9-29,28.9s12.9,29,29,29
	c16,0,28.9-12.9,28.9-29c0-3.4-0.5-6.8-1.8-9.9C125.5,91.1,123.4,91.7,121.1,91.7L121.1,91.7z"
    />
  </SvgIcon>
)
