import { Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Loader from 'components/Loader/Loader'
import { WhitePaper } from 'components/WhitePaper/WhitePaper'
import isEmpty from 'lodash/isEmpty'
import { Header } from 'pages/Wishlist/Header'
import { WishlistItemView } from 'pages/Wishlist/WishlistItemView'
import { useDispatchWishlist, useSelectWishlist } from 'shared/Wishlist/slice'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  whitePaper: {
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(0),
    },
  },
  contact: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[800],
  },
  divider: {
    marginTop: theme.spacing(2),
  },
}))

export const Wishlist = () => {
  const { whitePaper, contact, divider } = useStyles()
  const { t } = useTranslation()
  const { data: wishlistItems, isFetching } = useSelectWishlist()
  const { fetchWishlist } = useDispatchWishlist()

  useEffect(() => {
    fetchWishlist()
  }, [])

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} xl={8} className={whitePaper}>
        <WhitePaper changeViewDisable header={<Header quantity={wishlistItems?.data?.items} />}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {wishlistItems?.data?.data.map((item) => (
              <Fragment key={item.id}>
                <Grid item xs={12}>
                  <Loader isLoading={isFetching} objects={wishlistItems?.data?.data}>
                    <WishlistItemView item={item} />
                  </Loader>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Fragment>
            ))}
            <Grid item xs={12}>
              <Collapse in={isEmpty(wishlistItems?.data)} unmountOnExit mountOnEnter>
                <>
                  <Typography variant="h2" align="center" className={contact}>
                    {t('wishlist.empty')}
                  </Typography>
                  <Divider className={divider} />
                </>
              </Collapse>
            </Grid>
          </Grid>
        </WhitePaper>
      </Grid>
    </Grid>
  )
}
