import React from 'react'
import { CategoriesDrawerContext } from 'theme/Mobile/CategoriesDrawer/CategoriesDrawerContext'

export function useCategoriesDrawerContext() {
  const context = React.useContext(CategoriesDrawerContext)
  if (context === undefined) {
    throw new Error('useCategoriesDrawerContext must be used within a CategoriesDrawerContext')
  }
  return context
}
