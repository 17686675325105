export interface Pagination {
  page: number
  pages: number
  items: number
  itemsPerPage: number
}

export type WithPagination<T> = Pagination & {
  data: T[]
  isLoading: boolean
}

export const initialPagination = <T>(): WithPagination<T> => ({
  data: [],
  items: 0,
  itemsPerPage: 10,
  page: 1,
  pages: 1,
  isLoading: false,
})
