import { fontFamilies, getResponsiveSize } from 'theme/styles.utils'
import { makeStyles } from 'utils/styles/makeStyles'

export const useStyles = makeStyles((theme) => ({
  paper: {
    background: theme.palette.background.default,
    borderRadius: theme.spacing(4),
    padding: theme.spacing(4),
    color: theme.palette.grey[800],
    fontFamily: fontFamilies.default,
    '& .bold': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  labelSelectCollectionPoint: {
    fontFamily: fontFamilies.default,
  },
  divider: {
    background: theme.palette.background.paper,
    width: '100%',
    height: getResponsiveSize(2),
  },
  radio: {
    '& .MuiSvgIcon-fontSizeSmall': {
      fontSize: getResponsiveSize(1.3, 'rem'),
    },
  },
  wrapperCollectionPoint: {
    padding: theme.spacing(0, 4.5),
  },
}))
