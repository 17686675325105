import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router-dom'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { GoBackButton } from 'components/GoBackButton/GoBackButton'
import { HeaderPage } from 'components/HeaderPage/HeaderPage'
import Loader from 'components/Loader/Loader'
import { ResponsivePaper } from 'components/ResponsivePaper'
import { getResponsiveSize } from 'theme/styles.utils'
import { getObjectTranslation } from 'utils/getObjectTranslation'
import { useRecognitionValues } from 'utils/hooks/api/Values/useRecognitionValues'
import { useSocialWall } from 'utils/hooks/useSocialWall'
import { safeHtml } from 'utils/safeHtml'

import { Label } from './components/Label'

const Image = styled('img')(() => ({
  maxWidth: '5rem',
  maxHeight: '5rem',
}))

const TextContainer = styled('div')(({ theme }) => ({
  fontSize: theme.typography.h2.fontSize,
  '& img': {
    maxWidth: '100%',
    height: 'auto',
  },
}))

export const Values = () => {
  const { data, isFetching } = useRecognitionValues()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isValuesWidgetActive } = useSocialWall()

  const values = data?.data?.data || []

  const longestWordLength = values.reduce(
    (prev, curr) => Math.max(prev, getObjectTranslation(curr.translation).name.length),
    0
  )

  if (!isValuesWidgetActive) return <Navigate to={`/404`} />

  return (
    <ResponsivePaper data-cy="values-desktop">
      <Box position="absolute" right={getResponsiveSize(10, 'px')} top={0}>
        <GoBackButton translation="social_wall.return" noPx onClick={() => navigate('/social-wall')} />
      </Box>
      <Grid container gap={6}>
        <Grid item xs>
          <HeaderPage data-cy="values-header" text={t('our_values')} color="medium" />
        </Grid>
        <Loader isLoading={isFetching} objects={values}>
          <Grid item container gap={5}>
            {values.map((value) => {
              const translation = getObjectTranslation(value.translation)

              return (
                <Grid
                  item
                  container
                  key={value.id}
                  flexDirection="column"
                  alignItems="center"
                  gap={2}
                  data-cy={`value-${value.id}`}
                >
                  {translation.image && (
                    <Grid item>
                      <Image src={translation.image} alt={translation.name} data-cy="value-image" />
                    </Grid>
                  )}
                  <Grid item>
                    <Label label={translation.name} color={value.color} longestWordLength={longestWordLength} />
                  </Grid>
                  <Grid item>
                    <TextContainer
                      dangerouslySetInnerHTML={safeHtml(translation.description)}
                      data-cy="value-description"
                    />
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        </Loader>
      </Grid>
    </ResponsivePaper>
  )
}
