import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { DialogShoppingCartList } from 'pages/ProductCard/components/AddItemToCart/DialogShoppingCartList'
import { Product } from 'utils/api/Products/CatalogProduct'
import { useAddItemToCart } from 'utils/hooks/api/ShoppingCart/useAddItemToCart'

import { Variant } from './Variant'

type Props = {
  variants: Product[]
}

export const ProductVariants = ({ variants }: Props) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const { mutate: handleAddItemToShoppingCart, isLoading } = useAddItemToCart({
    toggleDialog: setOpen,
  })

  const sortedVariants = useMemo(() => variants.sort((a, b) => a.price - b.price), [variants])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={10} xl={8}>
        <Typography variant="h2" align="center">
          {t('product.variants')}
        </Typography>
      </Grid>
      <DialogShoppingCartList open={open} onCancel={() => setOpen(false)} isAdding={isLoading} />
      {sortedVariants.map((variant) => (
        <Variant
          key={`product-variant-${variant.id}`}
          variant={variant}
          handleAddItemToShoppingCart={handleAddItemToShoppingCart}
        />
      ))}
    </Grid>
  )
}
