import qs from 'query-string'
import { getRequest } from 'utils/api/apiRequest'
import { WithPagination } from 'utils/api/pagination'

export enum PointsOperationType {
  awarded = 1,
  redeened = 2,
}

export type PointsOperation = {
  id: number
  type: number
  value: number
  date: string
  occasion_name: string
}

export const fetchCurrentUserPointsHistory = (search: string) => {
  const paramsUrl = qs.parse(search.replace('?', ''))

  return getRequest<WithPagination<PointsOperation>>(
    `/me/points-history?${qs.stringify(
      {
        limit: 1000,
        'sort[name]': 'asc',
        // TODO: fix this
      },
      { encode: false }
    )}`
  )
}
