import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import { fontFamilies } from 'theme/styles.utils'
import { TranslationsKeys } from 'utils/createTranslationKey'

export const Label = ({ translationKey }: { translationKey: TranslationsKeys }) => {
  const { t } = useTranslation()
  return (
    <Typography
      component="div"
      variant="h3"
      fontFamily={fontFamilies.default}
      sx={{ color: (theme) => theme.palette.grey[800] }}
    >
      {t(translationKey)}:
    </Typography>
  )
}
