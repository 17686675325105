import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { EnterCodeForm } from 'pages/Login/ActivateAccount/EnterCodeForm'
import { EnterCodeDataResponse, useEnterCodeMutation } from 'pages/Login/api/useEnterCodeMutation'
import * as Yup from 'yup'

type Props = {
  onSuccess: (data: EnterCodeDataResponse & { code?: string }) => void
  disabled?: boolean
  code?: string
}

export const EnterCode = ({ onSuccess, disabled, code }: Props) => {
  const { mutate, error, variables, isLoading, isError } = useEnterCodeMutation(onSuccess)
  const { t } = useTranslation()

  const initialValues = {
    code: '',
  }

  const validationSchema = Yup.object().shape({
    code: Yup.string().label(t('auth.activate_account.field.code')).notRequired().nullable(),
  })

  const onSubmit = (values: typeof initialValues) => mutate(values)

  return (
    <Formik initialValues={initialValues} enableReinitialize validationSchema={validationSchema} onSubmit={onSubmit}>
      <Form>
        <EnterCodeForm
          disabled={disabled}
          variables={variables}
          isLoading={isLoading}
          isError={isError}
          error={error}
        />
      </Form>
    </Formik>
  )
}
