import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { ResponsivePaper } from 'components/ResponsivePaper'
import { NotificationType } from 'shared/Site/api'
import { fontFamilies, getResponsiveSize } from 'theme/styles.utils'
import { CmsNotification } from 'utils/api/CMSNotifications/fetchNotifications'
import { getCatalogUrl } from 'utils/getCatalogUrl'
import { getObjectTranslation } from 'utils/getObjectTranslation'
import { safeHtml } from 'utils/safeHtml'
import { makeStyles } from 'utils/styles/makeStyles'

export const useStyles = makeStyles((theme) => ({
  buttonLabel: {
    minWidth: getResponsiveSize(90, 'px'),
    padding: theme.spacing(0.5, 3),
    position: 'relative',
    top: getResponsiveSize(0.05, 'rem'),
    textTransform: 'uppercase',
    fontSize: theme.typography.h2.fontSize,
    lineHeight: 1.3,
  },
  notificationHeader: {
    fontSize: theme.typography.h1.fontSize,
    lineHeight: 'normal',
    textDecoration: 'none',
  },
  notificationBody: {
    paddingTop: theme.spacing(1),
    textDecoration: 'none',
  },
  notificationFooter: {
    paddingTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

type Props = {
  notification: CmsNotification
}

export const NotificationBox: FunctionComponent<Props> = ({ notification }) => {
  const { buttonLabel, notificationFooter, notificationHeader } = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleClick = () => {
    const subdomain = notification?.project?.site?.subdomain
    const catalogUrl = getCatalogUrl(subdomain)
    if (subdomain && window.location.hostname.includes(subdomain)) {
      navigate('/catalog')
    }
    if (catalogUrl) {
      window.location.assign(catalogUrl)
    }
  }
  const showRedeem =
    notification?.type === NotificationType.pointsAwards && !notification?.project?.point_saving_attribute

  return (
    <ResponsivePaper className={`${notification.is_read ? '' : ' new'}`}>
      <header className={notificationHeader}>
        <Grid container spacing={1} alignItems="center">
          {!notification.is_read && (
            <Grid item>
              <Chip color="primary" size="small" label="new" />
            </Grid>
          )}
          <Grid item xs={12}>
            {getObjectTranslation(notification?.translation)?.heading || t('app.missingTranslation')}
          </Grid>
          <Grid item xs>
            <Typography
              variant="body2"
              component="div"
              fontFamily={fontFamilies.default}
              dangerouslySetInnerHTML={safeHtml(
                getObjectTranslation(notification?.translation)
                  ?.message?.split('\n')
                  .map((text) => `<p>${text}</p>`)
                  .join('') || ''
              )}
            />
          </Grid>
        </Grid>
      </header>
      {showRedeem && (
        <div className={notificationFooter}>
          <Button className={buttonLabel} variant="contained" color="primary" size="small" onClick={handleClick}>
            {t('cms.redeem')}
          </Button>
        </div>
      )}
    </ResponsivePaper>
  )
}
