import { useInfiniteQuery } from '@tanstack/react-query'
import { getPointsHistory } from 'pages/MyAccount/api/getPointsHistory'
import { myAccountKeys } from 'pages/MyAccount/api/keys'

export const useGetPointsHistory = () => {
  return useInfiniteQuery({
    queryKey: myAccountKeys.getPointsHistory([]),
    queryFn: (context) => getPointsHistory(`limit=5&page=${context.pageParam || 1}`),
    keepPreviousData: true,
    getNextPageParam: (lastPage) => (lastPage.data?.page < lastPage.data?.pages ? lastPage.data?.page + 1 : undefined),
    getPreviousPageParam: (firstPage) => firstPage.data?.page - 1,
  })
}
