import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material'
import Grid from '@mui/material/Grid'
import { CountrySelectButton } from 'components/CountryContextDialog/CountrySelectButton'
import { DrawerSelect } from 'components/DrawerSelect'
import { ShopAll } from 'components/ShopAll'
import { useShowCategoriesTree } from 'components/ShopAll/useShowCategoriesTree'
import { WhitePaperProps } from 'components/WhitePaper/types'
import { fontFamilies, getResponsiveSize } from 'theme/styles.utils'
import { displayPoints } from 'utils/displayPoints'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'
import { useIsLuckyBirdUser } from 'utils/hooks/useIsLuckyBirdUser'
import { usePointsName } from 'utils/hooks/usePointsName'
import { useResize } from 'utils/hooks/useResize'

const ToolbarWrapper = styled(Grid)(({ theme }) => ({
  fontSize: theme.typography.h2.fontSize,
  color: theme.palette.primary.main,
  padding: `${theme.spacing(0.5, 2, 0, 2)} !important`,
  position: 'relative',
  width: '100%',
}))

export const PointsWrapper = styled(Grid)(({ theme }) => ({
  fontWeight: 900,
  fontSize: theme.typography.h1.fontSize,
  fontFamily: fontFamilies.cond,
  color: theme.palette.text.secondary,
  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.h4.fontSize,
  },
}))

type Props = Pick<WhitePaperProps, 'FilterComponent' | 'SortComponent' | 'changeViewDisable' | 'showPointsDisable'>

export const Toolbar = ({ changeViewDisable, FilterComponent, SortComponent, showPointsDisable }: Props) => {
  const { t } = useTranslation()
  const user = useCurrentUser()
  const pointsName = usePointsName(user?.available_points)
  const [toolbarEl, setToolbarEl] = useState(null)
  const { height, topOffset } = useResize(toolbarEl, { debounceTime: 300, manualTrigger: false })
  const gutterTop = height + topOffset - getResponsiveSize(2)
  const { isSingleLogic } = useIsLuckyBirdUser()
  const showCategoriesTree = useShowCategoriesTree()

  return (
    <ToolbarWrapper item xs={12} lg={10} ref={setToolbarEl}>
      <Grid container spacing={2} alignItems="center" sx={{ minHeight: 64, pl: 1, maxWidth: '100%' }}>
        <Grid item xs>
          {!changeViewDisable && showCategoriesTree && (
            <DrawerSelect label={t('shop_all')} gutterTop={gutterTop}>
              <ShopAll />
            </DrawerSelect>
          )}
        </Grid>
        {!showPointsDisable && (
          <Grid item>
            <CountrySelectButton />
          </Grid>
        )}
        {FilterComponent && (
          <Grid item>
            <FilterComponent gutterTop={gutterTop} />
          </Grid>
        )}
        {SortComponent && (
          <Grid item>
            <SortComponent gutterTop={gutterTop} />
          </Grid>
        )}
        {!(showPointsDisable || isSingleLogic) && (
          <PointsWrapper item>
            {displayPoints(user?.available_points)} {pointsName}
          </PointsWrapper>
        )}
      </Grid>
    </ToolbarWrapper>
  )
}
