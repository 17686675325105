import { FunctionComponent } from 'react'
import Grid from '@mui/material/Grid'
import Avatar from 'components/Avatar'
import { RedeemButton } from 'pages/CMS/components/ContentBox/RedeemButton'
import { MyPointsText } from 'pages/CMS/components/MyPointsText'
import { getThumbnailPath } from 'utils/getThumbnail'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'
import { makeStyles } from 'utils/styles/makeStyles'

import { PointsByProjectList } from './PointsByProjectList'

const useStyles = makeStyles((theme) => ({
  userName: {
    paddingTop: theme.spacing(1.5),
    fontSize: theme.typography.h1.fontSize,
    lineHeight: '1.25',
    textAlign: 'center',
    fontWeight: 900,
    color: theme.palette.primary.main,
  },
}))

export const UserProfileContent: FunctionComponent = () => {
  const { userName } = useStyles()
  const user = useCurrentUser()
  const pointsByProject = Object.values(user?.projects || {})?.filter((project) => project.is_points_project)

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Grid item>
            <Avatar
              fullName={user?.profile?.full_name}
              image={user?.profile?.avatar ? getThumbnailPath(user?.profile?.avatar) : undefined}
            />
          </Grid>
          <Grid item xs={12}>
            <div className={userName}>{user?.profile?.full_name}</div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {pointsByProject.length === 1 ? (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <MyPointsText project={pointsByProject?.[0]} />
            </Grid>
            <Grid item xs container justifyContent="center">
              <RedeemButton project={pointsByProject[0]} />
            </Grid>
          </Grid>
        ) : (
          <PointsByProjectList pointsByProject={pointsByProject} />
        )}
      </Grid>
    </Grid>
  )
}
