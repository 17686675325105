import { useTranslation } from 'react-i18next'
import Clear from '@mui/icons-material/Clear'
import LocalSee from '@mui/icons-material/LocalSee'
import FormHelperText from '@mui/material/FormHelperText'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import { Avatar } from 'components/Avatar/Avatar'
import { FormikValues, useFormikContext } from 'formik'
import isEmpty from 'lodash/isEmpty'
import { useSnackbar } from 'notistack'
import { FileReaderEvent } from 'pages/CMS/components/ContentBox/ServiceRequest/ServiceRequestUploadFile'
import { makeSx } from 'utils/styles'

const MAX_IMAGE = 5242880

type Props = {
  name: string
}

export const ProfileAvatar = ({ name }: Props) => {
  const { setFieldValue, values, getFieldMeta } = useFormikContext<FormikValues>()
  const { touched, error } = getFieldMeta(name)
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const hasAvatar = !isEmpty(values?.avatar)
  const accept = 'image/*'

  const button = makeSx((theme) => ({
    position: 'absolute',
    right: hasAvatar ? -12 : 0,
    bottom: hasAvatar ? 12 : 0,
    background: theme.palette.primary.main,
    border: `2px solid ${theme.palette.common.white}`,
    transition: 'all 0.3s ease',
    '&:hover': {
      background: theme.palette.primary.light,
    },
    '& svg': {
      color: theme.palette.common.white,
      fontSize: '1rem',
    },
  }))
  const buttonRemove = makeSx((theme) => ({
    position: 'absolute',
    right: 12,
    bottom: -12,
    background: theme.palette.error.main,
    border: (theme) => `2px solid ${theme.palette.common.white}`,
    '&:hover': {
      background: theme.palette.error.light,
    },
    '& svg': {
      color: theme.palette.common.white,
      fontSize: '1rem',
    },
  }))

  const handleCapture = async ({ target }: FileReaderEvent) => {
    if (!isEmpty(target.files)) {
      if (target.files[0].size <= MAX_IMAGE) {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(target.files[0])
        fileReader.onload = (event) => {
          setFieldValue(name, event.target?.result)
        }
      } else {
        enqueueSnackbar(t('service_request.upload_file.too_big'), { variant: 'error' })
      }
    }
  }

  const fullName = `${values?.first_name || ''} ${values?.last_name || ''}`.trimEnd()

  return (
    <Grid container justifyContent="center">
      <Grid item sx={{ position: 'relative' }}>
        <input id="icon-button-file" onChange={handleCapture} type="file" hidden accept={accept} />
        <label htmlFor="icon-button-file">
          <Avatar image={values?.[name] || ''} fullName={fullName} />
          <IconButton component="span" sx={button} size="small">
            <LocalSee />
          </IconButton>
        </label>
        {hasAvatar && (
          <IconButton component="span" sx={buttonRemove} size="small" onClick={() => setFieldValue(name, '')}>
            <Clear />
          </IconButton>
        )}
      </Grid>
      {touched && (
        <Grid item xs={12}>
          <FormHelperText color="error" sx={{ textAlign: 'center', color: (t) => t.palette.error.main }}>
            {error}
          </FormHelperText>
        </Grid>
      )}
    </Grid>
  )
}
