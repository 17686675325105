import { postRequest } from 'utils/api/apiRequest'
import { SocialWallPost } from 'utils/api/SocialWall/types'

export type PostRecognitionPayload = {
  values: number[]
  users_awarded: number[]
  description: string
}
export type PostRecognitionResponse = SocialWallPost
export const postRecognition = (data: PostRecognitionPayload) =>
  postRequest<PostRecognitionResponse>(`/social-wall/recognitions`, data)
