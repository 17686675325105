import { getResponsiveSize } from 'theme/styles.utils'
import { makeStyles } from 'utils/styles/makeStyles'

const imageSize = getResponsiveSize(100)

export const useStyles = makeStyles((theme) => ({
  cover: {
    width: imageSize,
    height: imageSize,
    cursor: 'pointer',
  },
  variantInfoWrapper: {
    cursor: 'pointer',
  },
  variantInfo: {
    padding: theme.spacing(1.5, 0),
  },
  brand: {
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.2,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.h3.fontSize,
    },
  },
  name: {
    fontSize: theme.typography.h3.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[600],
    lineHeight: 1.2,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.h4.fontSize,
    },
  },
  shoppingCartInactive: {
    '& path': {
      stroke: theme.palette.primary.main,
    },
  },
}))
