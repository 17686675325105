const socialWallQueryKeys = {
  all: ['all', 'socialWall'] as const,
  recognitionProfiles: () => [...socialWallQueryKeys.all, 'recognitionProfiles'] as const,
  postsAll: () => [...socialWallQueryKeys.all, 'posts'] as const,
  postsFiltered: (filters = '') => [...socialWallQueryKeys.all, 'posts', filters] as const,
  comments: (postId: number) => [...socialWallQueryKeys.all, `comments-${postId}`] as const,
  user: (id: number) => [...socialWallQueryKeys.all, 'user', id] as const,
}

export default socialWallQueryKeys
