import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

export const MicrosoftLogo = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 1528 1528">
    <g id="Layer">
      <path id="Layer" style={{ fill: '#f25022' }} d="M0 0L724.96 0L724.96 725.8L0 725.8L0 0Z" />
      <path id="Layer" style={{ fill: '#7fba00' }} d="M801.27 0L1526.24 0L1526.24 725.8L801.27 725.8L801.27 0Z" />
      <path id="Layer" style={{ fill: '#00a4ef' }} d="M0 802.2L724.96 802.2L724.96 1528L0 1528L0 802.2Z" />
      <path
        id="Layer"
        style={{ fill: '#ffb900' }}
        d="M801.27 802.2L1526.24 802.2L1526.24 1528L801.27 1528L801.27 802.2Z"
      />
    </g>
  </SvgIcon>
)
