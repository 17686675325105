export const personalMessageKeys = {
  all: ['all', 'personalMessage'],
  getPersonalMessages: (params: string[]) => [...personalMessageKeys.all, 'getPersonalMessages', ...params],
  getPersonalMessage: (params: string[]) => [...personalMessageKeys.all, 'getPersonalMessage', ...params],
  patchPersonalMessage: (params: string[]) => [...personalMessageKeys.all, 'patchPersonalMessage', ...params],
  getHistoryPersonalMessages: (params: string[]) => [
    ...personalMessageKeys.all,
    'getHistoryPersonalMessages',
    ...params,
  ],
  allTemplates: () => [...personalMessageKeys.all, 'allTemplates'],
  getSubscriberTemplatesByOccasionId: (params: string[]) => [
    ...personalMessageKeys.allTemplates(),
    'getSubscriberTemplatesByOccasionId',
    ...params,
  ],
  getSubscriberTemplates: (params: string[]) => [
    ...personalMessageKeys.allTemplates(),
    'getSubscriberTemplates',
    ...params,
  ],
  getPersonalTemplates: (params: string[]) => [
    ...personalMessageKeys.allTemplates(),
    'getPersonalTemplates',
    ...params,
  ],
  getPersonalTemplatesByOccasionId: (params: string[]) => [
    ...personalMessageKeys.allTemplates(),
    'getPersonalTemplatesByOccasionId',
    ...params,
  ],
  postPersonalTemplate: () => [...personalMessageKeys.all, 'postPersonalTemplate'],
  patchPersonalTemplate: () => [...personalMessageKeys.all, 'patchPersonalTemplate'],
  deletePersonalTemplate: () => [...personalMessageKeys.all, 'deletePersonalTemplate'],
} as const
