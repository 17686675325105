import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { alpha } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import { TranslationsKeys } from 'utils/createTranslationKey'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { makeStyles } from 'utils/styles/makeStyles'
import { FCWithChildren } from 'utils/types/FCWithChildren'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.typography.h4.fontSize,
  },
  grid: {
    position: 'relative',
  },
  wrapperLoader: {
    zIndex: 1000,
    background: alpha(theme.palette.common.white, 0.3),
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

type Props = {
  isLoading: boolean
  objects?: any[]
  emptyLabel?: TranslationsKeys | ReactNode
  fullHeight?: boolean
  visibleChildren?: boolean
}

const Loader: FCWithChildren<Props> = ({
  isLoading,
  objects,
  emptyLabel = 'app.no_elements',
  fullHeight,
  children,
  visibleChildren,
}) => {
  const { title, grid, wrapperLoader } = useStyles()
  const { t } = useTranslation()

  const getViewElement = () => {
    if (isLoading) {
      // refetch objects
      if ((objects && !isEmpty(objects)) || visibleChildren)
        return (
          <Grid item xs className={grid}>
            {children}
            <div className={wrapperLoader}>
              <CircularProgress data-cy="loader" />
            </div>
          </Grid>
        )

      // first fetch
      return (
        <Grid
          item
          sx={valueOrUndefined(fullHeight, {
            alignItems: 'center',
            display: 'flex',
          })}
        >
          <CircularProgress data-cy="loader" />
        </Grid>
      )
    }
    // after fetch, no elements
    if (objects && isEmpty(objects)) {
      return <Grid item>{isString(emptyLabel) ? t(emptyLabel as TranslationsKeys) : emptyLabel}</Grid>
    }

    return null
  }

  const view = getViewElement()

  return (
    <Grid container justifyContent="center" className={title} height={valueOrUndefined(fullHeight, '100%')}>
      {view || (
        <Grid item xs>
          {children}
        </Grid>
      )}
    </Grid>
  )
}

export default Loader
