import { TypographyProps } from '@mui/material'
import Typography from '@mui/material/Typography'
import { formatDistanceToNowStrict } from 'date-fns'
import { useDateLocale } from 'utils/hooks/useDateLocale'

interface Props extends Partial<TypographyProps> {
  created_at: string
}

export const TimeStamp = ({ created_at, ...props }: Props) => {
  const { locale } = useDateLocale()

  return (
    <Typography
      sx={{
        display: 'flex',
        alignItems: 'center',
        overflow: 'initial',
        color: (t) => t.palette.grey['600'],
      }}
      noWrap
      {...props}
    >
      {formatDistanceToNowStrict(new Date(created_at), {
        addSuffix: true,
        // @ts-ignore
        locale,
      })}
    </Typography>
  )
}
