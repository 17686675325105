import { useRef } from 'react'
import { MessageEdit } from 'components/Message/components/MessageEdit'
import { MessageWrapper } from 'components/Message/components/MessageWrapper'
import { MessageBaseProps } from 'components/Message/types'
import { FormikValues, useFormikContext } from 'formik'
import isEmpty from 'lodash/isEmpty'

export type MessageActiveProps = Pick<
  MessageBaseProps,
  | 'size'
  | 'isLoading'
  | 'bottomBarText'
  | 'placeholder'
  | 'maxLength'
  | 'minRows'
  | 'authorSide'
  | 'name'
  | 'autoFocus'
  | 'variant'
>

export const MessageActive = ({
  size,
  authorSide,
  isLoading,
  bottomBarText,
  placeholder,
  maxLength,
  minRows,
  name,
  autoFocus,
  variant = 'default',
}: MessageActiveProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>()
  const { getFieldMeta } = useFormikContext<FormikValues>()
  const { touched, error } = getFieldMeta(name)
  const errorTextField = !isEmpty(error) && touched
  const helperText = touched && error

  const focus = () => textareaRef.current?.focus()

  return (
    <MessageWrapper
      fullWidth
      size={size}
      authorSide={authorSide}
      isLoading={isLoading}
      error={errorTextField}
      helperText={helperText}
      onClick={focus}
      variant={variant}
    >
      <MessageEdit
        name={name}
        size={size}
        autoFocus={autoFocus}
        textareaRef={textareaRef}
        bottomBarText={bottomBarText}
        placeholder={placeholder}
        maxLength={maxLength}
        minRows={minRows}
        variant={variant}
      />
    </MessageWrapper>
  )
}
