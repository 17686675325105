import { FunctionComponent } from 'react'
import Grid from '@mui/material/Grid'
import { ProjectPaper } from 'pages/CMS/components/ContentBox/ProjectPaper'
import { Project } from 'shared/Site/api'

type Props = {
  pointsByProject: Project[]
}

export const PointsByProjectList: FunctionComponent<Props> = ({ pointsByProject }) => {
  return (
    <Grid container spacing={2}>
      {pointsByProject.map((projectPoints) => (
        <Grid item xs={12} key={projectPoints.slug}>
          <ProjectPaper project={projectPoints} />
        </Grid>
      ))}
    </Grid>
  )
}
