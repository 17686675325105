import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

export const Birthday = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 70 70">
    <g>
      <path
        d="M63.4,61h-3.22V48.65c-1.43-0.47-2.71-1.3-3.73-2.4c-1.04-1-1.66-1.6-3.12-1.6c-1.46,0-2.09,0.6-3.13,1.6
		c-1.18,1.14-2.81,2.69-5.82,2.69c-3.01,0-4.63-1.56-5.82-2.69c-1.04-1-1.66-1.6-3.13-1.6c-1.46,0-2.09,0.6-3.13,1.6
		c-1.18,1.14-2.81,2.69-5.82,2.69c-3.01,0-4.63-1.56-5.82-2.69c-1.04-1-1.67-1.6-3.13-1.6c-1.46,0-2.09,0.6-3.13,1.6
		c-1.02,1.11-2.31,1.93-3.74,2.4V61H7.47c-1.16,0-2.1,0.94-2.1,2.1s0.94,2.1,2.1,2.1H63.4c1.16,0,2.1-0.94,2.1-2.1S64.56,61,63.4,61
		z"
      />
      <path
        d="M35.44,14.07c1.64,0,2.97-1.33,2.97-2.97c0,0,0,0,0,0c0-1.64-2.97-5.41-2.97-5.41s-2.97,3.77-2.97,5.41
		C32.47,12.74,33.8,14.07,35.44,14.07C35.44,14.07,35.44,14.07,35.44,14.07z"
      />
      <path
        d="M20.62,14.07c1.64,0,2.97-1.33,2.97-2.97c0,0,0,0,0,0c0-1.64-2.97-5.41-2.97-5.41s-2.97,3.77-2.97,5.41
		C17.65,12.74,18.98,14.07,20.62,14.07z"
      />
      <path
        d="M49.99,14.07c1.64,0,2.97-1.33,2.97-2.97c0,0,0,0,0,0c0-1.64-2.97-5.41-2.97-5.41s-2.97,3.77-2.97,5.41
		C47.01,12.74,48.34,14.07,49.99,14.07z"
      />
      <path
        d="M17.55,40.46c3.01,0,4.63,1.56,5.82,2.69c1.04,1,1.66,1.6,3.13,1.6c1.46,0,2.09-0.6,3.13-1.6
		c1.18-1.14,2.81-2.69,5.82-2.69c3.01,0,4.63,1.56,5.82,2.69c1.04,1,1.67,1.6,3.13,1.6c1.46,0,2.09-0.6,3.12-1.6
		c1.18-1.14,2.81-2.69,5.82-2.69c3.01,0,4.63,1.56,5.82,2.69c0.33,0.32,0.67,0.62,1.04,0.9v-6.89c0.09-3.77-2.88-6.9-6.65-6.99
		h-1.45V18.95c0-1.16-0.94-2.1-2.1-2.1s-2.1,0.94-2.1,2.1v11.22H37.54V18.95c0-1.16-0.94-2.1-2.1-2.1c-1.16,0-2.1,0.94-2.1,2.1
		v11.22H22.72V18.95c0-1.16-0.94-2.1-2.1-2.1c-1.16,0-2.1,0.94-2.1,2.1v11.22h-1.19c-3.77,0.1-6.74,3.23-6.65,6.99v6.9
		c0.37-0.28,0.72-0.59,1.05-0.91C12.92,42.01,14.54,40.46,17.55,40.46z"
      />
    </g>
  </SvgIcon>
)
