import { useAuthContext } from 'store/useAuthContext'
import { userTypes } from 'utils/hooks/userTypes'

export const useCurrentUser = (disabledSecurity?: boolean) => {
  const context = useAuthContext(disabledSecurity)

  if (!context && !disabledSecurity) {
    throw new Error('useCurrentUser must be used within an AuthProvider')
  }

  return context?.user
}

export const useIsStandardUser = () => {
  const user = useCurrentUser()

  return user?.type === userTypes.standard
}
