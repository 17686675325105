import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import Link from '@mui/material/Link'
import { LanguageName } from 'components/LanguageName'
import { fontFamilies } from 'theme/styles.utils'
import { useLanguages } from 'utils/api/Languages/useLanguages'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: theme.typography.h3.fontSize,
  },
  activeItem: {
    color: theme.palette.primary.main,
    fontFamily: fontFamilies.default,
  },
  linkContainer: {
    textAlign: 'center',
    lineHeight: 1.8,
  },
  buttonLink: {
    fontSize: theme.typography.h3.fontSize,
    fontFamily: fontFamilies.cond,
  },
}))

export const LanguagesContent: FunctionComponent = () => {
  const { activeItem, buttonLink, linkContainer, root } = useStyles()
  const { i18n } = useTranslation()
  const { data: languages } = useLanguages()

  const handleChangeLanguage = (language: string) => {
    i18n.changeLanguage(language)
  }

  return (
    <div className={root}>
      {languages?.data?.map((l) => (
        <div key={l.language.code} className={linkContainer} data-cy="language-link-container">
          {i18n.language === l.language.code ? (
            <span className={activeItem}>
              <LanguageName code={l.language.code} />
            </span>
          ) : (
            <Link
              className={buttonLink}
              component="button"
              color="inherit"
              onClick={() => handleChangeLanguage(l.language.code)}
            >
              <LanguageName code={l.language.code} />
            </Link>
          )}
        </div>
      ))}
    </div>
  )
}
