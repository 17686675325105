import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useMsal } from '@azure/msal-react'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { captureMessage } from '@sentry/browser'
import { isAxiosError } from 'axios'
import { MicrosoftLogo } from 'components/ArteelIcons/MicrosoftLogo'
import { loginRequest } from 'pages/Login/SSO/msalConfig'

export const LoginAzure = () => {
  const { instance, accounts, inProgress } = useMsal()
  const { t } = useTranslation()

  const handleAzureLogin = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    try {
      localStorage.setItem('azureLoginRequest', 'true')
      instance.loginRedirect(loginRequest).catch((error) => {
        if (isAxiosError(error)) {
          const data = error.response?.data
          if (data?.error === 'interaction_required') {
            instance.loginRedirect(loginRequest)
          }
        }
      })
    } catch (e) {
      captureMessage('[Azure login error] ' + JSON.stringify(e))
    }
  }

  return (
    <ListItem disablePadding>
      <ListItemButton onClick={handleAzureLogin}>
        <ListItemAvatar sx={{ display: 'flex' }}>
          <MicrosoftLogo sx={{ fontSize: 24 }} />
        </ListItemAvatar>
        <ListItemText primary={t('auth.login_with_azure')} />
      </ListItemButton>
    </ListItem>
  )
}
