import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import Autocomplete from '@mui/material/Autocomplete'
import { AutocompleteProps, AutocompleteRenderInputParams } from '@mui/material/Autocomplete/Autocomplete'
import Box from '@mui/material/Box'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import { menuPaperSx } from 'components/CustomPopover/CustomPopover'
import { TextField } from 'components/TextField/TextField'
import { useFormikContext } from 'formik'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import { TranslationsKeys } from 'utils/createTranslationKey'
import { getObjectTranslation } from 'utils/getObjectTranslation'

type ObjectWithName = {
  value: number | string
  name: string
}

type ObjectWithTranslation = {
  value: number | string
  translation?: {
    [key: string]: {
      [key: string]: string
    }
  }
}

type ExtendedObject = ObjectWithName | ObjectWithTranslation

export const isObjectWithName = (object: any): object is ObjectWithName =>
  (typeof (object as ObjectWithName)?.value === 'number' || typeof (object as ObjectWithName)?.value === 'string') &&
  typeof (object as ObjectWithName)?.name === 'string' &&
  !object?.country

export const isObjectWithTranslation = (object: any): object is ObjectWithTranslation => {
  const objectWithTranslation = object as ObjectWithTranslation
  if (
    typeof objectWithTranslation !== 'object' ||
    (typeof objectWithTranslation?.translation !== 'object' &&
      !(typeof objectWithTranslation?.value === 'number' || typeof objectWithTranslation?.value === 'string'))
  ) {
    return false
  }
  const translation = getObjectTranslation(objectWithTranslation?.translation)
  return (
    !object?.country &&
    (typeof translation?.name === 'string' ||
      typeof translation?.title === 'string' ||
      typeof translation?.header === 'string' ||
      typeof translation?.heading === 'string')
  )
}

type Props<
  T extends ExtendedObject,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> & {
  name: string
  translationLabel?: TranslationsKeys
  textFieldProps?: TextFieldProps
  additionalInputElement?: ReactNode
  renderInput?: (params: AutocompleteRenderInputParams) => ReactNode
  autoSelectSingleValue?: boolean
  disabled?: boolean
}

export const JSONAutocomplete = <
  T extends ExtendedObject,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  textFieldProps,
  additionalInputElement,
  options,
  autoSelectSingleValue,
  disabled,
  translationLabel,
  ...props
}: Props<T, false, DisableClearable, false>) => {
  const { t } = useTranslation()
  const { getFieldProps, setFieldValue, getFieldMeta } = useFormikContext()
  const { touched, error } = getFieldMeta(name)
  const { value, onBlur } = getFieldProps(name)
  const errorTextField = !isEmpty(error) && touched
  const helperText = touched && error && error

  const handleChange: Props<T, false, DisableClearable, false>['onChange'] = (...args) => {
    if (isString(args[1]) || args[1] === null) return setFieldValue(name, args[1])

    setFieldValue(name, args[1].value)
  }

  return (
    <Autocomplete
      disabled={disabled}
      autoHighlight
      isOptionEqualToValue={(option, valueToEqual) => option.value === (valueToEqual as unknown as string | number)}
      getOptionLabel={(rawOption) => getOptionLabel(rawOption, options)}
      onChange={handleChange}
      onBlur={onBlur}
      value={value}
      slotProps={{
        paper: {
          sx: menuPaperSx,
        },
      }}
      options={options}
      renderInput={(params) => {
        return (
          <Box sx={{ position: 'relative' }}>
            <TextField
              variant="outlined"
              {...params}
              name={name}
              error={errorTextField}
              helperText={helperText}
              label={t(translationLabel)}
              disabled={disabled}
              {...textFieldProps}
              inputProps={{
                autoComplete: Math.random().toString(36).slice(2),
                ...params.inputProps,
                ...textFieldProps?.inputProps,
              }}
            />
            {additionalInputElement}
          </Box>
        )
      }}
      {...props}
    />
  )
}

const getOptionLabel = (rawOption: unknown, options: readonly { value: number | string }[]): string => {
  const option = options.find((el) => {
    if (typeof rawOption === 'string' || typeof rawOption === 'number') return el.value === rawOption
    if (isObjectWithTranslation(rawOption)) return el.value === rawOption.value
  })

  if (isString(option)) {
    return option ?? ''
  }
  if (isObjectWithTranslation(option)) {
    return getTranslationTitle(getObjectTranslation(option?.translation))
  }
  if (isObjectWithName(option)) {
    return option.name
  }
  return ''
}

const getTranslationTitle = (
  translation: ObjectWithTranslation['translation'][keyof ObjectWithTranslation['translation']]
) => translation?.name || translation?.title || translation?.header || translation?.heading
