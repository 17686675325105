import { useLocation } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import qs from 'query-string'
import { getOrderHistory } from 'utils/api/OrderHistory/getOrderHistory'
import orderHistoryQueryKeys from 'utils/api/OrderHistory/orderHistoryQueryKeys'

export const useOrderHistory = () => {
  const { search } = useLocation()

  const params = qs.parse(search.replace('?', ''))
  const limit = (params?.limit || '3').toString()

  return useQuery({
    queryKey: orderHistoryQueryKeys.orderHistoryList(limit),
    queryFn: () => getOrderHistory(limit),
  })
}
