import { Project } from 'shared/Site/api'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'

export const usePointsExpirationDate = (project: Project) => {
  const user = useCurrentUser()
  const pointsOverall = user?.profile?.points_overall?.[project?.id]

  return {
    showExpirationDate: project?.project_settings?.show_expiration_date || false,
    firstExpirationDate: pointsOverall?.firstExpirationDate || null,
    firstExpiredPoints: pointsOverall?.firstExpiredPoints || 0,
  }
}
