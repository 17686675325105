export const EMPTY_FILTER_VALUE = '0'
export const FILTERS = {
  FROM_MYSELF: 'filters[recognition.user_recognizing.id]',
  TO_MYSELF: 'filters[recognition.user_recognitions.user_awarded.id]',
  VALUES: 'filters[recognition.recognition_values.value.id]',
  DEPARTMENT: 'department',
} as const

type FilterKeys = keyof typeof FILTERS
// eslint-disable-next-line prettier/prettier
type FilterValues = (typeof FILTERS)[FilterKeys]

export type OnFilterChange = (filterKey: FilterKeys, filterValue?: string) => void
export type GetFilterValue = (k: FilterKeys) => string
export type ParamsState = Partial<Record<FilterValues, string>>
