import { getRequest } from 'utils/api/apiRequest'
import { Country } from 'utils/api/country'
import { WithPagination } from 'utils/api/pagination'

export type ContactPerson = {
  name: string
  surname: string
  phone: string
}

export type DeliveryPresetAddress = {
  id: number
  company_name?: string
  street?: string
  number: string
  box?: string
  zip: string
  city: string
  country: Country
  contact_person?: ContactPerson
  additional_information?: string
}

export const getDeliveryPresetAddresses = () =>
  getRequest<WithPagination<DeliveryPresetAddress>>('/redemption-site/delivery-address/preset')
