import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

export const Pin = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 70 70">
    <path
      d="M33.83,48.28H19.38c-0.89,0-1.61-0.72-1.61-1.6c0,0,0,0,0,0l0-6.42c0-0.43,0.17-0.84,0.47-1.14l5.95-5.95
	l0-23.42l-1.61,0c-0.89,0-1.61-0.72-1.61-1.61c0-0.89,0.72-1.61,1.61-1.61l0,0l3.21,0l0.01,0l19.25,0h0h0l3.21,0
	c0.89,0,1.61,0.72,1.61,1.61c0,0.89-0.72,1.61-1.61,1.61l-1.61,0v23.42l5.95,5.95c0.3,0.3,0.47,0.71,0.47,1.14l0,6.42
	c0,0.89-0.72,1.61-1.6,1.61c0,0,0,0,0,0H37.04l0,14.44c0,0.89-0.72,1.61-1.61,1.61s-1.61-0.72-1.61-1.61L33.83,48.28L33.83,48.28z
	 M35.53,45.07"
    />
  </SvgIcon>
)
