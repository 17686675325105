import { JSX, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { styled, SxProps } from '@mui/material'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Theme } from 'theme/styles'

const HeaderGrid = styled(Grid)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  borderRight: `1px solid ${theme.palette.background.default}`,
  paddingTop: theme.spacing(2),
}))

interface Row {
  id: string
  text: ReactNode
  content: JSX.Element
  isEmpty: boolean
}

interface Props {
  headerSx?: SxProps<Theme>
  rows: Row[]
  headerAreaSize?: {
    xs?: number
    md?: number
    lg?: number
  }
  hideHeaderRow?: boolean
  noRecords?: boolean
}

export const CardGrid = ({ rows, headerSx, headerAreaSize = { xs: 1 }, hideHeaderRow = false, noRecords }: Props) => {
  const { t } = useTranslation()
  return (
    <Grid
      container
      sx={(theme) => ({
        '& > div': {
          ...(!hideHeaderRow && { borderBottom: `1px solid ${theme.palette.background.default}` }),
          '&:last-child': {
            borderBottom: 'none',
          },
        },
      })}
    >
      {noRecords ? (
        <Grid item xs={12}>
          <Typography textAlign="center" variant="body2">
            {t('app.filters.no_found')}
          </Typography>
        </Grid>
      ) : (
        rows.map(
          (row) =>
            !row.isEmpty && (
              <Grid item xs={12} key={row.id}>
                <Grid container>
                  {!hideHeaderRow && (
                    <HeaderGrid id={row.id} item sx={headerSx} {...headerAreaSize}>
                      {row.text}
                    </HeaderGrid>
                  )}
                  {row.content}
                </Grid>
              </Grid>
            )
        )
      )}
    </Grid>
  )
}
