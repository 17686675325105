import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { MyAccountPointsHistory } from 'pages/MyAccount/api/getPointsHistory'
import { usePointsName } from 'utils/hooks/usePointsName'

type Props = {
  row?: MyAccountPointsHistory
}

export const PointsCell = ({ row }: Props) => {
  const pointName = usePointsName(row?.value || 0)

  return row ? (
    <Typography align="right" sx={{ whiteSpace: 'nowrap' }}>
      {row.value < 0 ? `- ${row.value * -1} ${pointName}` : `+ ${row.value} ${pointName}`}
    </Typography>
  ) : (
    <Skeleton width="100%" />
  )
}
