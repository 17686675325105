import { useNavigate } from 'react-router-dom'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { fontFamilies } from 'theme/styles.utils'
import { RecognitionValue } from 'utils/api/Values/fetchValues'
import { getObjectTranslation } from 'utils/getObjectTranslation'

export const generateValuesStrings = (v: { value: RecognitionValue }[] = []): string[] =>
  v.map((el) => `#${getObjectTranslation(el.value.translation).name}`)

type TagsProps = {
  tags: { value: RecognitionValue }[]
  clickable?: boolean
}

export const MessageTags = ({ tags = [], clickable }: TagsProps) => {
  const navigate = useNavigate()
  const parsedTags = generateValuesStrings(tags)

  return clickable ? (
    <>
      {tags.map((el, i) => (
        <Link
          key={el.value.id}
          component="button"
          variant="body2"
          underline="none"
          onClick={() => navigate('/values')}
          sx={{ pr: 1 }}
          fontFamily={fontFamilies.default}
        >
          {parsedTags[i]}
        </Link>
      ))}
    </>
  ) : (
    <Typography textAlign="center" variant="body2" fontFamily={fontFamilies.default}>
      {parsedTags.join(' ')}
    </Typography>
  )
}
