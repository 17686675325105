import { ParsedQuery } from 'query-string'

export const points = 'price'
export const urlParams = {
  enoughPoints: 'enough_points',
  filtersGt: `filters[${points}|virtual|gt]`,
  filtersLt: `filters[${points}|virtual|lt]`,
}

export const getParamKeys = (parsedQuery: ParsedQuery, name: string) =>
  Object.keys(parsedQuery).filter((p) => p.startsWith(name))
