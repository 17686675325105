import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

export const BackOffice = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 30 30">
    <g>
      <path
        d="M5.3,13L7,14.2c0,0.3,0,0.4,0,0.7c0,0.3,0,0.5,0,0.7l-1.7,1.3l-0.1,0.1C5,17,4.7,17.3,4.7,17.5s0,0.5,0.1,0.8
		L7,21.9c0.1,0.3,0.4,0.4,0.7,0.5c0.3,0.1,0.5,0.1,0.8,0l2-0.9l0,0c0.4,0.3,0.8,0.5,1.2,0.8l0.3,2.2c0,0.3,0.1,0.5,0.4,0.8
		c0.3,0.3,0.5,0.4,0.8,0.4h4c0.3,0,0.7-0.1,0.8-0.3c0.3-0.3,0.4-0.5,0.4-0.8l0.3-2.2l0,0c0.4-0.3,0.8-0.4,1.2-0.8l2,0.9h0.1l0,0
		c0.3,0.1,0.5,0.1,0.8,0c0.3-0.1,0.5-0.3,0.7-0.5l2.1-3.5l0,0c0.1-0.3,0.1-0.7,0-1c-0.1-0.3-0.3-0.4-0.5-0.5L23,15.7l0,0
		c0-0.3,0-0.4,0-0.7c0-0.3,0-0.5,0-0.7l1.7-1.3l0.1-0.1c0.1-0.1,0.4-0.4,0.4-0.7c0-0.3,0-0.5-0.1-0.8L23,8c-0.1-0.3-0.4-0.4-0.7-0.5
		c-0.3-0.1-0.5-0.1-0.8,0l-2.1,0.9l0,0c-0.4-0.3-0.8-0.5-1.2-0.8l-0.3-2.2c0-0.3-0.1-0.5-0.4-0.8c-0.1-0.1-0.4-0.3-0.8-0.3h-4
		c-0.3,0-0.7,0.1-0.8,0.3c-0.3,0.3-0.4,0.5-0.4,0.8l-0.3,2.2l0,0C11,7.9,10.6,8,10.2,8.4L8.4,7.5H8.3l0,0C8,7.4,7.8,7.4,7.5,7.5
		C7.2,7.5,7,7.8,6.8,8l-2.1,3.5l0,0c-0.1,0.3-0.1,0.7,0,1C4.7,12.7,5,13,5.3,13L5.3,13z M14.9,10c1.3,0,2.6,0.5,3.5,1.4
		c0.9,0.9,1.4,2.2,1.4,3.5s-0.5,2.6-1.4,3.5c-0.9,0.9-2.2,1.4-3.5,1.4s-2.6-0.5-3.5-1.4c-0.9-0.9-1.4-2.2-1.4-3.5s0.5-2.6,1.4-3.5
		C12.3,10.5,13.6,10,14.9,10z"
      />
      <circle cx="14.9" cy="14.9" r="3" />
    </g>
  </SvgIcon>
)
