import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import { ComponentProps, JSX, useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import { Breakpoints, darken, useMediaQuery, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { Arrow } from 'components/ArteelIcons'

const translate = 30
const sx = { position: 'absolute', top: '50%' }

const NextArrow = ({ onClick, topProducts, width }: { onClick?: () => void; topProducts?: boolean; width: number }) => {
  return (
    !!onClick && (
      <IconButton
        color={topProducts ? 'default' : 'primary'}
        size={topProducts ? 'medium' : 'large'}
        onClick={onClick}
        sx={{
          ...sx,
          top: width > 0 ? (width - 16) / 2 : '50%',
          right: 0,
          zIndex: 1,
          transform: `translate(${translate}%, -50%)`,
          border: topProducts ? '1px solid' : undefined,
          background: (theme) => (topProducts ? darken(theme.palette.background.default, 0.05) : undefined),
          '&:hover': {
            background: (theme) => (topProducts ? darken(theme.palette.background.default, 0.3) : undefined),
          },
        }}
      >
        <Arrow fontSize={topProducts ? 'medium' : 'large'} sx={{ fontSize: topProducts ? '1.25rem' : undefined }} />
      </IconButton>
    )
  )
}
const PrevArrow = ({ onClick, topProducts, width }: { onClick?: () => void; topProducts?: boolean; width: number }) => {
  return (
    !!onClick && (
      <IconButton
        color={topProducts ? 'default' : 'primary'}
        size={topProducts ? 'medium' : 'large'}
        onClick={onClick}
        sx={{
          ...sx,
          transform: `translate(-${translate}%, -50%)`,
          top: width > 0 ? (width - 16) / 2 : '50%',
          left: 0,
          zIndex: 1,
          border: topProducts ? '1px solid' : undefined,
          background: (theme) => (topProducts ? darken(theme.palette.background.default, 0.05) : undefined),
          '&:hover': {
            background: (theme) => (topProducts ? darken(theme.palette.background.default, 0.3) : undefined),
          },
        }}
      >
        <Arrow
          fontSize={topProducts ? 'medium' : 'large'}
          direction="left"
          sx={{ fontSize: topProducts ? '1.25rem' : undefined }}
        />
      </IconButton>
    )
  )
}

export interface HorizontalSliderProps {
  children: JSX.Element[]
  lineGrid?: boolean
  topProducts?: boolean
  disableArrow?: boolean
  responsive?: Record<keyof Breakpoints['values'] | 'xxl', number>
}

// eslint-disable-next-line complexity
export const HorizontalSlider = ({
  children,
  lineGrid,
  topProducts,
  responsive,
  disableArrow,
}: HorizontalSliderProps) => {
  const [width, setWidth] = useState<number | undefined>(0)
  const [isScrolled, setIsScrolled] = useState(false)
  const {
    breakpoints: {
      values: { xs, sm, md, lg, xl },
      down,
    },
  } = useTheme()
  const isMobile = useMediaQuery(down('sm'))
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref?.current?.offsetWidth) {
      setWidth(ref?.current?.offsetWidth)
    }
  }, [ref?.current?.offsetWidth])

  const settings: ComponentProps<typeof Slider> = {
    accessibility: false,
    adaptiveHeight: false,
    arrows: !isMobile && !disableArrow,
    infinite: topProducts,
    swipeToSlide: isMobile,
    slidesToShow: 1,
    speed: 200,
    draggable: !disableArrow,
    nextArrow: <NextArrow topProducts={topProducts} width={width} />,
    prevArrow: <PrevArrow topProducts={topProducts} width={width} />,
    responsive: [
      {
        breakpoint: 4000 - 1,
        settings: {
          slidesToShow: responsive?.xxl || 3,
          infinite: children?.length > responsive?.xxl && topProducts,
        },
      },
      {
        breakpoint: 1980 - 1,
        settings: {
          slidesToShow: responsive?.xxl || 3,
          infinite: children?.length > responsive?.xxl && topProducts,
        },
      },
      {
        breakpoint: xl - 1,
        settings: {
          slidesToShow: responsive?.xl || 3,
          infinite: children?.length > responsive?.xl && topProducts,
        },
      },
      {
        breakpoint: lg - 1,
        settings: {
          slidesToShow: responsive?.lg || 3,
          infinite: children?.length > responsive?.lg && topProducts,
        },
      },
      {
        breakpoint: md,
        settings: {
          slidesToShow: responsive?.md || 2,
          infinite: children?.length > responsive?.md && topProducts,
          draggable: children?.length > responsive?.md && topProducts,
        },
      },
      {
        breakpoint: sm,
        settings: {
          slidesToShow: responsive?.sm || 1,
          infinite: children?.length > responsive?.sm && topProducts,
          draggable: children?.length > responsive?.sm && topProducts,
          className: children?.length > responsive?.sm && !isScrolled && 'mobile-padding',
        },
      },
      {
        breakpoint: xs,
        settings: {
          slidesToShow: responsive?.xs || 1,
          infinite: children?.length > responsive?.xs && topProducts,
          draggable: children?.length > responsive?.xs && topProducts,
          className: children?.length > responsive?.xs && !isScrolled && 'mobile-padding',
        },
      },

      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1.5,
          infinite: children?.length > 1.5 && topProducts,
          className: children?.length > 1.5 && !isScrolled && 'mobile-padding',
        },
      },
    ],
  }

  const mappedChildren = children?.map((child, i) => (
    <Box
      ref={i === 0 ? ref : undefined}
      key={i}
      sx={(t) => ({
        p: topProducts ? 0 : 3,
        px: topProducts ? 1 : 3,
      })}
    >
      {child}
    </Box>
  ))
  return (
    <Box
      sx={(t) =>
        lineGrid
          ? {
              '& .slick-slide.slick-active': {
                borderLeft: !isMobile ? `1px solid ${t.palette.divider}` : undefined,
                '&.slick-current': {
                  borderLeft: 'none',
                },
              },
            }
          : {
              '& .slick-track': {
                float: 'left',
              },
            }
      }
    >
      <Slider
        {...settings}
        beforeChange={(current, next) => {
          if (!isScrolled) {
            setIsScrolled(true)
          }
        }}
      >
        {mappedChildren}
      </Slider>
    </Box>
  )
}
