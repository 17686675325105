import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { getResponsiveSize } from 'theme/styles.utils'
import { useIsMobile } from 'utils/hooks/useBreakpoints'

type Props = {
  title: string
  rightComponent?: React.ReactNode
}

export const NewHeader = ({ title, rightComponent }: Props) => {
  const isMobile = useIsMobile()
  return (
    <Box display="grid" gridTemplateColumns="1fr auto" alignItems="center" mb={3} width="100%">
      <Typography
        component="span"
        fontWeight={900}
        sx={{ fontSize: `${getResponsiveSize(isMobile ? 1.5 : 2, 'rem')}!important` }}
      >
        {title}
        <Box component="span" sx={{ color: 'primary.main' }}>
          .
        </Box>
      </Typography>
      {rightComponent}
    </Box>
  )
}
