import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { personalMessageKeys } from 'pages/PersonalMessages/api/keys'
import { patchRequest } from 'utils/api/apiRequest'
import { useErrorSnackbar } from 'utils/hooks/useErrorSnackbar'

type Data = {
  content: string
}
const patchPersonalMessage = (id: number | string, data: Data) => patchRequest(`/user/messages/${id}`, data)

export const usePatchPersonalMessage = (onBack: () => void) => {
  const { id } = useParams<{ id: string }>()
  const enqueueErrorSnackbar = useErrorSnackbar()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: personalMessageKeys.patchPersonalMessage([id]),
    mutationFn: (data: Data) => patchPersonalMessage(id, data),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: personalMessageKeys.all })
      onBack()
    },
    onError: (err) => {
      enqueueErrorSnackbar(err, { DEFAULT_ERROR: t('personal_messages.write_message.fail') })
    },
  })
}
