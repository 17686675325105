import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import { GoBackButton } from 'components/GoBackButton/GoBackButton'
import { HeaderPage } from 'components/HeaderPage/HeaderPage'
import { ResponsivePaper } from 'components/ResponsivePaper'
import capitalize from 'lodash/capitalize'
import { ExpiresPointsHistoryList } from 'pages/MyAccount/components/PointsHistory/ExpiresPointsHistoryList'
import { PointsHistoryList } from 'pages/MyAccount/components/PointsHistory/PointsHistoryList'
import { getResponsiveSize } from 'theme/styles.utils'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'
import { useIsRoute } from 'utils/hooks/useIsRoute'
import { usePointsName } from 'utils/hooks/usePointsName'

export const MyAccountPointsHistory = () => {
  const { routeMatch } = useIsRoute(['/my-account/history-points', '/my-account/history-points/expires'])
  const currentTab = routeMatch?.pathname
  const { t } = useTranslation()
  const pointName = usePointsName(0)
  const user = useCurrentUser()
  const pointsByProject = Object.values(user?.projects || {})?.filter((project) => project.is_points_project)
  const isAvailableExpiresTab = pointsByProject.find((project) => project.project_settings?.show_expiration_date)
  const navigate = useNavigate()

  return (
    <Grid item xs={12}>
      <ResponsivePaper>
        <Box position="absolute" right={getResponsiveSize(10, 'px')} top={0}>
          <GoBackButton
            translation="cms.points.history.back_to_my_account"
            noPx
            onClick={() => navigate('/my-account')}
          />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <HeaderPage size="medium" text={capitalize(pointName)} />
          </Grid>
          <Grid item xs={12}>
            <Tabs value={currentTab} textColor="primary" indicatorColor="primary" centered>
              <Tab
                value="/my-account/history-points"
                component={Link}
                to="/my-account/history-points"
                label={
                  <Typography variant="h4" fontWeight="bold">
                    {t('cms.points.history')}
                  </Typography>
                }
              />
              {isAvailableExpiresTab && (
                <Tab
                  value="/my-account/history-points/expires"
                  component={Link}
                  to="/my-account/history-points/expires"
                  label={
                    <Typography variant="h4" fontWeight="bold">
                      {t('cms.points.expires')}
                    </Typography>
                  }
                />
              )}
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={currentTab === '/my-account/history-points'} mountOnEnter unmountOnExit>
              <PointsHistoryList />
            </Collapse>
            {isAvailableExpiresTab && (
              <Collapse in={currentTab === '/my-account/history-points/expires'} mountOnEnter unmountOnExit>
                <ExpiresPointsHistoryList />
              </Collapse>
            )}
          </Grid>
        </Grid>
      </ResponsivePaper>
    </Grid>
  )
}
