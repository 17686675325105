import { useRef } from 'react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Clear from '@mui/icons-material/Clear'
import { buttonClasses } from '@mui/material'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import { Box } from '@mui/system'
import { useFormikContext } from 'formik'
import { useSnackbar } from 'notistack'
import { ServiceRequestFormValues } from 'utils/api/ServiceRequest/postServiceRequest'
import { getExtension } from 'utils/getExtension'
import { useUploadFileServiceRequest } from 'utils/hooks/useSendServiceRequest'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'none',
  },
  maxSize: {
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.grey[500],
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(0.5),
  },
  button: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
    position: 'relative',
    [`&.${buttonClasses.root}`]: {
      display: 'block !important',
    },
  },
}))

export type FileReaderEventTarget = EventTarget & {
  result: string
  files: FileList
  accept: string
}

export type FileReaderEvent = React.ChangeEvent<HTMLInputElement> & {
  target: FileReaderEventTarget
}

type Props = {
  width: number
}

export const ServiceRequestUploadFile = ({ width }: Props) => {
  const { input, maxSize, button } = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const inputFile1 = useRef(null)
  const inputFile2 = useRef(null)
  const { mutateAsync: handleUploadFile, isLoading } = useUploadFileServiceRequest()
  const { setFieldValue, values } = useFormikContext<ServiceRequestFormValues>()
  const accept = 'image/png, image/jpeg, image/gif'

  const handleCapture =
    (align: string) =>
    async ({ target }: FileReaderEvent) => {
      if (!target?.files?.[0]) {
        return
      }
      if (['jpg', 'jpeg', 'png', 'gif'].includes(getExtension(target.files[0].name))) {
        const formData = new FormData()
        formData.append('file', target.files[0])
        formData.append('fileName', target.files[0].name)
        const file: any = await handleUploadFile({
          formData,
        })
        setFieldValue(align, file?.data)
        setFieldValue(`${align}_name`, target.files[0].name)
        target.value = ''
      } else {
        enqueueSnackbar(t('service_request.upload_file.invalid_extension'), { variant: 'error' })
      }
    }

  const handleClear = (align: string) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    setFieldValue(align, '')
    setFieldValue(`${align}_name`, '')
  }

  return (
    <Box sx={{ width: width }}>
      <Grid container spacing={2} wrap="nowrap">
        <input ref={inputFile1} className={input} onChange={handleCapture('file_id_1')} type="file" accept={accept} />
        <input ref={inputFile2} className={input} onChange={handleCapture('file_id_2')} type="file" accept={accept} />
        <Grid item xs={12} lg={6}>
          <Grid container>
            <Grid item xs={12}>
              <Button
                className={button}
                fullWidth
                variant="outlined"
                color="primary"
                onClick={() => inputFile1.current.click()}
                disabled={isLoading}
              >
                {values.file_id_1_name || t('service_request.select_file_1')}
                {values.file_id_1_name && (
                  <IconButton
                    size="small"
                    color="error"
                    sx={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}
                    onClick={handleClear('file_id_1')}
                  >
                    <Clear />
                  </IconButton>
                )}
              </Button>
            </Grid>
            <Grid item xs={12} className={maxSize}>
              {t('service_request.upload_file.max_size')}
              <br />
              {t('service_request.upload_file.allowed_ext')}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container>
            <Grid item xs={12}>
              <Button
                className={button}
                fullWidth
                variant="outlined"
                color="primary"
                onClick={() => inputFile2.current.click()}
                disabled={isLoading}
              >
                {values.file_id_2_name || t('service_request.select_file_2')}
                {values.file_id_2_name && (
                  <IconButton
                    size="small"
                    color="error"
                    sx={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}
                    onClick={handleClear('file_id_2')}
                  >
                    <Clear />
                  </IconButton>
                )}
              </Button>
            </Grid>
            <Grid item xs={12} className={maxSize}>
              {t('service_request.upload_file.max_size')}
              <br />
              {t('service_request.upload_file.allowed_ext')}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
