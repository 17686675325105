import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Loader from 'components/Loader/Loader'
import { ExpiresPointsHistoryRow } from 'pages/MyAccount/components/PointsHistory/ExpiresPointsHistoryRow'
import { useGetExpiresPointsHistory } from 'pages/MyAccount/hooks/useGetExpiresPointsHistory'

export const ExpiresPointsHistoryList = () => {
  const { t } = useTranslation()
  const { data, isInitialLoading } = useGetExpiresPointsHistory()
  const pointsHistoryRows = data?.data || []

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Loader isLoading={isInitialLoading} emptyLabel={t('app.no_elements')} objects={pointsHistoryRows}>
          <Box
            display="grid"
            gridTemplateColumns="1fr auto"
            alignItems="center"
            sx={{
              px: 4,
              '& > :not(:nth-last-of-type(-n+2))': {
                borderBottom: '1px solid #E0E0E0',
              },
            }}
          >
            {pointsHistoryRows.map((row) => (
              <ExpiresPointsHistoryRow key={row.date} row={row} />
            ))}
          </Box>
        </Loader>
      </Grid>
    </Grid>
  )
}
