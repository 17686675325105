import { useQuery } from '@tanstack/react-query'
import { transferPointsKeys } from 'pages/TransferPoints/api/keys'
import { getRequest } from 'utils/api/apiRequest'
import { WithPagination } from 'utils/api/pagination'
import { Profile } from 'utils/api/User'

export const getTransferPointsProfiles = (id?: number, searchParam?: string) =>
  getRequest<WithPagination<Profile>>(`/transfer-points/profiles/${id}?filters[full_name]=${searchParam || ''}`)

export const useGetTransferPointsProfiles = (id?: number, searchParam?: string) => {
  return useQuery({
    enabled: !!id,
    queryKey: transferPointsKeys.getTransferPointsProfiles([id?.toString(), searchParam]),
    queryFn: () => getTransferPointsProfiles(id, searchParam),
  })
}
