import { JSX } from 'react'
import Badge from '@mui/material/Badge'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import { Filter } from 'components/ArteelIcons'
import { DrawerSelect } from 'components/DrawerSelect'
import { useCountFilters } from 'utils/hooks/useCountFilters'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  wrapperAccordion: {
    width: '100%',
    padding: theme.spacing(1, 0),
    '& > .MuiAccordion-root': {
      borderRadius: 0,
      boxShadow: 'none',
      margin: theme.spacing(0, 0, 1, 0),
      '&:before': {
        display: 'none',
      },
      '&:last-child': {
        margin: 0,
      },
    },
  },
}))

interface Props {
  filters?: JSX.Element
  search?: JSX.Element
  basket?: JSX.Element
  showFiltersBadge?: boolean
}

export const FilterAndSearchMobile = ({ filters, search, showFiltersBadge = true, basket }: Props) => {
  const { wrapperAccordion } = useStyles()
  const { countFilters } = useCountFilters()

  return (
    <Grid container alignItems="center" spacing={1} wrap="nowrap">
      {basket && <Grid item>{basket}</Grid>}
      {search && <Grid item>{search}</Grid>}
      {filters && (
        <Grid item>
          <DrawerSelect
            keepMounted={false}
            CustomButton={({ onClick }) => (
              <IconButton onClick={onClick} size="large" data-cy="toggle-mobile-filters-button">
                <Badge badgeContent={showFiltersBadge ? countFilters : 0} color="primary">
                  <Filter color="primary" />
                </Badge>
              </IconButton>
            )}
          >
            <div className={wrapperAccordion} data-cy="filters-container">
              {filters}
            </div>
          </DrawerSelect>
        </Grid>
      )}
    </Grid>
  )
}
