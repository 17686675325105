import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import isEmpty from 'lodash/isEmpty'
import { useSnackbar } from 'notistack'
import { ShoppingCartItems } from 'shared/ShoppingCart/api'
import { useDispatchShoppingCart } from 'shared/ShoppingCart/slice'
import { getRequest } from 'utils/api/apiRequest'

const getValidateShoppingCart = () => getRequest<ShoppingCartItems>(`/redemption-site/cart/validate`)

export const useValidateShoppingCart = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { refetchShoppingCart } = useDispatchShoppingCart()

  return useMutation(getValidateShoppingCart, {
    onSuccess: (data) => {
      if (isEmpty(data?.data?.warnings)) {
        navigate('/create-order')
      } else {
        data.data.warnings?.forEach((warn) => enqueueSnackbar(t(warn), { variant: 'error' }))
        refetchShoppingCart()
      }
    },
  })
}
