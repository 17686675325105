import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { useMutation } from '@tanstack/react-query'
import { AuthLoggedUser } from 'components/AuthLoggedUser/AuthLoggedUser'
import { FormikButtons } from 'components/Formik/FormikButtons'
import { FormikTextField } from 'components/Formik/FormikTextField'
import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import { patchForgotPassword } from 'pages/Login/api/patchForgotPassword'
import { WrapperLoginForm } from 'pages/Login/WrapperLoginForm'
import { isLoggedIn } from 'utils/axios-jwt-cookies'
import { useErrorSnackbar } from 'utils/hooks/useErrorSnackbar'
import * as Yup from 'yup'

type FormValues = {
  email: string
}

export const ForgotPassword = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const enqueueErrorSnackbar = useErrorSnackbar()

  const { mutate, isLoading } = useMutation(patchForgotPassword, {
    onSuccess: async () => {
      navigate('/auth/login')
      enqueueSnackbar(t('auth.forgot_password.success'), { variant: 'success' })
    },
    onError: async (error) => {
      enqueueErrorSnackbar(error, { DEFAULT_ERROR: t('auth.forgot_password.error') })
    },
  })

  const onSubmit = (values: FormValues) => mutate(values)

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().label(t('auth.forgot_password.email')).required(),
  })

  if (isLoggedIn()) return <AuthLoggedUser />

  return (
    <Formik initialValues={{ email: '' }} validationSchema={validationSchema} enableReinitialize onSubmit={onSubmit}>
      <Form style={{ height: '100%' }}>
        <WrapperLoginForm>
          <Grid item xs={12}>
            <FormikTextField
              variant="outlined"
              name="email"
              label={t('auth.forgot_password.email')}
              center
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <FormikButtons
              isLoading={isLoading}
              size="medium"
              textButtonSubmit="auth.forgot_password.button"
              onBack={() => navigate(`/auth/login`)}
              uppercase
            />
          </Grid>
        </WrapperLoginForm>
      </Form>
    </Formik>
  )
}
