import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { useAmountOfItemsInShoppingCart } from 'shared/ShoppingCart/slice'
import { dataCy } from 'utils/cypressUtils'
import { useValidateShoppingCart } from 'utils/hooks/api/ShoppingCart/useValidateShoppingCart'
import { useIsTablet } from 'utils/hooks/useBreakpoints'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.typography.h1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: theme.typography.h1.fontSize,
    },
  },
  header: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 2, 2, 8),
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(0),
      padding: theme.spacing(1, 0, 2, 1),
    },
  },
  buttonsWrapper: {
    '& button': {
      [theme.breakpoints.down('lg')]: {
        fontSize: theme.typography.h3.fontSize,
        padding: theme.spacing(1, 4),
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h4.fontSize,
        padding: theme.spacing(0.4, 2),
      },
    },
  },
}))

export const Header = ({ disabledOrder = false }) => {
  const { title, header, buttonsWrapper } = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const isTablet = useIsTablet()
  const quantity = useAmountOfItemsInShoppingCart()

  const { mutate, isLoading } = useValidateShoppingCart()

  return (
    <Grid container spacing={2} alignItems="center" className={header}>
      <Grid item xs={12} md className={title}>
        {t('shopping_cart')} ({quantity})
      </Grid>
      <Grid item xs={12} md="auto">
        <Grid container spacing={isTablet ? 2 : 4} className={buttonsWrapper} justifyContent="center" wrap="nowrap">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate('/catalog')}
              data-cy={dataCy('button-catalog-back')}
            >
              {t('shopping_cart.back_to_catalog')}
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={() => mutate()}
              disabled={disabledOrder}
              loading={isLoading}
              data-cy={dataCy('button-order-visit')}
            >
              {t('shopping_cart.continue_to_order')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
