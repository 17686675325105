import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Typography from '@mui/material/Typography'
import { useGetAvailableCountries } from 'components/CountryContextDialog/hooks/useGetAvailableCountries'
import Loader from 'components/Loader/Loader'
import { AVAILABLE_BPOST_COUNTRIES_CODE } from 'config/constants'
import { useFormikContext } from 'formik'
import isEmpty from 'lodash/isEmpty'
import { DeliveryVariants } from 'pages/CreateOrder/components/DeliveryVariants'
import { SelectCountry } from 'pages/CreateOrder/components/SelectCountry'
import { SelectedAddress } from 'pages/CreateOrder/components/SelectedAddress'
import { useDeliveryAddressPreset } from 'pages/CreateOrder/components/useDeliveryAddressPreset'
import { useDeliveryVariants } from 'pages/CreateOrder/components/useDeliveryVariants'
import {
  deliveryOptions,
  DeliveryVariant,
  digitalDeliveries,
  OneOfDeliveryVariant,
} from 'pages/CreateOrder/deliveryOptions'
import { ErrorVariant } from 'pages/CreateOrder/ErrorVariant'
import { filteringEnabledOptions } from 'pages/CreateOrder/helper/filteringEnabledOptions'
import { CreateOrderType2 } from 'pages/CreateOrder/types'
import { DeliveryMethods } from 'shared/ShoppingCart/api'
import { useSelectShoppingCart } from 'shared/ShoppingCart/slice'
import { fontFamilies, getResponsiveSize } from 'theme/styles.utils'
import { Country } from 'utils/api/country'
import { DeliveryPresetAddress } from 'utils/api/DeliveryPresetAddress/getDeliveryPresetAddresses'
import { useChangeDelivery2 } from 'utils/hooks/api/Order/useChangeDelivery'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'
import { User } from 'utils/hooks/userType'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[800],
    fontFamily: fontFamilies.default,
  },
  csOption: {
    fontSize: theme.typography.h3.fontSize,
    color: theme.palette.grey[800],
    fontFamily: fontFamilies.default,
  },
  radio: {
    '& .MuiSvgIcon-fontSizeSmall': {
      fontSize: getResponsiveSize(1.3, 'rem'),
    },
  },
}))

type Props = {
  isSelected: boolean
  onBack: () => void
}

export const SelectAddress = ({ isSelected, onBack }: Props) => {
  const { data: shoppingCartItems, isFetching } = useSelectShoppingCart()
  const { label, csOption, radio } = useStyles()
  const { t } = useTranslation()
  const user: User = useCurrentUser()
  const { getFieldProps, setFieldValue, values, setValues } = useFormikContext<CreateOrderType2>()
  const delivery_variant = values.delivery_address_type
  const selectedCountryId = getFieldProps('country').value
  const isSelectedCountry = selectedCountryId !== ''

  const { data, isFetching: isLoadingDeliveryCountries } = useGetAvailableCountries()
  const deliveryCountries: Country[] = data?.data || []
  const selectedCountryCode = deliveryCountries.find(
    (deliveryCountry) => deliveryCountry?.id === selectedCountryId
  )?.code
  const enabledBpost = !!selectedCountryCode && !!AVAILABLE_BPOST_COUNTRIES_CODE.includes(selectedCountryCode)
  const isDigital = digitalDeliveries.includes(delivery_variant)
  const { isFetching: isFetchingCountryDeliveryVariants, data: countryDeliveryVariants } = useDeliveryVariants()
  const { isLoading: isLoadingPresetAddresses, data: presetAddressesData } = useDeliveryAddressPreset()
  const presetAddresses: DeliveryPresetAddress[] = presetAddressesData?.data?.data || []

  let filteredByCountryPresetAddresses: DeliveryPresetAddress[] = []
  if (presetAddresses.length > 0) {
    filteredByCountryPresetAddresses = presetAddresses?.filter((p) => p.country?.id === selectedCountryId) || []
  }

  const enabledOptions = filteringEnabledOptions(
    deliveryOptions,
    user,
    shoppingCartItems?.data?.delivery_type,
    countryDeliveryVariants?.data?.data || [],
    !isEmpty(filteredByCountryPresetAddresses),
    selectedCountryId,
    enabledBpost
  )
  const selectedOption = deliveryOptions.find((option) => option.value === delivery_variant)?.label || undefined

  const isGroupAndDate = shoppingCartItems?.data?.delivery_method_name === DeliveryMethods.groupedAndDate
  const { mutate } = useChangeDelivery2({ onSuccess: () => {} })

  const deps = JSON.stringify(presetAddresses?.map((el) => el.id) || [])
  useEffect(() => {
    if (isGroupAndDate) {
      mutate(values)
    }
  }, [deps, delivery_variant])

  return (
    <Loader isLoading={isFetching} visibleChildren>
      {isSelected ? (
        <Loader isLoading={isLoadingPresetAddresses}>
          <SelectedAddress
            isDigital={delivery_variant === DeliveryVariant.digital}
            selectedOptionLabel={selectedOption}
            onBack={onBack}
          />
        </Loader>
      ) : (
        <Grid container spacing={4} justifyContent="center">
          <SelectCountry isDigital={isDigital} />
          {(isSelectedCountry || digitalDeliveries.includes(delivery_variant)) && (
            <>
              <Grid item xs={12}>
                <Typography align="center" className={label}>
                  {t('create_order.select_address.delivery_variant')}
                </Typography>
              </Grid>
              <Grid item xs={isFetchingCountryDeliveryVariants && isEmpty(enabledOptions) ? 12 : undefined}>
                <RadioGroup
                  name="delivery_address_type"
                  value={delivery_variant?.toString()}
                  onChange={(e, v) => setFieldValue('delivery_address_type', Number(v) as OneOfDeliveryVariant)}
                >
                  {enabledOptions.map((option) => (
                    <FormControlLabel
                      key={option.value}
                      value={option.value?.toString()}
                      control={<Radio color="primary" size="small" className={radio} />}
                      label={<Typography className={csOption}>{t(option.label)}</Typography>}
                    />
                  ))}
                </RadioGroup>
              </Grid>
              <ErrorVariant />
              <Grid item xs={12}>
                <DeliveryVariants
                  presetAddresses={filteredByCountryPresetAddresses}
                  isLoadingPresetAddresses={isLoadingPresetAddresses}
                  selectedCountryCode={selectedCountryCode}
                />
              </Grid>
            </>
          )}
        </Grid>
      )}
    </Loader>
  )
}
