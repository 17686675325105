import { JSX, PropsWithChildren } from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { ResponsivePaper } from 'components/ResponsivePaper'
import { valueOrUndefined } from 'utils/getValueOrUndefined'

interface Props {
  title: string
  icon: JSX.Element
  link?: string
  filters?: JSX.Element
  dataCy?: string
}

export const ContentCard = ({ children, icon, title, link, filters, dataCy }: PropsWithChildren<Props>) => {
  const navigate = useNavigate()
  return (
    <ResponsivePaper data-cy={dataCy}>
      <Grid container gap={3}>
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography
              textTransform="capitalize"
              color="primary"
              onClick={() => navigate(link)}
              sx={{ cursor: valueOrUndefined(!!link, 'pointer') }}
              fontWeight="bold"
              variant="h3"
              display="inline-flex"
              lineHeight={1}
              alignItems="center"
              data-cy="header-link"
            >
              <Box component="span" pr={1}>
                {icon}
              </Box>
              {title}
            </Typography>
          </Grid>
          <Grid item justifyContent="flex-end" data-cy="header-filters">
            {filters}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </ResponsivePaper>
  )
}
