import { useTranslation } from 'react-i18next'
import { textLengthCounter } from 'components/Message/utils'
import { RecognitionProfile } from 'utils/api/SocialWall/getRecognitionProfiles'
import { PostRecognitionPayload } from 'utils/api/SocialWall/postRecognition'
import * as Yup from 'yup'

export interface FormValues {
  values: number[]
  users_awarded: RecognitionProfile[]
  description: string
}

export const initialValues: FormValues = {
  users_awarded: [],
  values: [],
  description: '',
}

export const field = (name: keyof FormValues) => name

export const MIN_DESCRIPTION_LENGTH = 20
export const MAX_DESCRIPTION_LENGTH = 750

export const mapValues = (v: FormValues): PostRecognitionPayload => ({
  description: v.description,
  values: v.values,
  users_awarded: v.users_awarded.map((el) => el.user.id),
})

export const mapId = (v: RecognitionProfile[] = []) => {
  return v.map((el) => ({ id: el.user.id, ...el }))
}

export const useValidationSchemaObject = () => {
  const { t } = useTranslation()
  return {
    users_awarded: Yup.array(Yup.mixed()).required().min(1, t('recognize_someone.select_one_person')),
    values: Yup.array(Yup.number()).required().min(1, t('recognize_someone.select_one_value')),
    description: Yup.string()
      .required()
      .test(
        'min-chars',
        t('recognize_someone.min_characters', { number: MIN_DESCRIPTION_LENGTH }),
        (v) => textLengthCounter(v) >= MIN_DESCRIPTION_LENGTH
      )
      .test(
        'max-chars',
        t('recognize_someone.max_characters', { number: MAX_DESCRIPTION_LENGTH }),
        (v) => textLengthCounter(v) <= MAX_DESCRIPTION_LENGTH
      ),
  }
}
