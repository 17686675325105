import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { BackendSvgIcon } from 'components/BackendSvgIcon/BackendSvgIcon'
import { MessageTopUsers } from 'components/Message/components/MessageUsersTop'
import { getResponsiveSize } from 'theme/styles.utils'
import { SocialWallPost } from 'utils/api/SocialWall/types'
import { safeHtml } from 'utils/safeHtml'

export interface Props {
  post: SocialWallPost
}

export const SpecialMoment = ({ post }: Props) => {
  const {
    special_moment: { user_awarded, icon, content },
  } = post

  return (
    <>
      <MessageTopUsers recognitionUsers={[user_awarded]} />
      <Grid container spacing={2} justifyContent="center">
        {!!icon && (
          <Grid item xs={12}>
            <Box
              sx={(t) => ({
                margin: 'auto',
                width: getResponsiveSize(140, 'px'),
                height: getResponsiveSize(140, 'px'),
                borderRadius: '100%',
                background: t.palette.background.default,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              })}
            >
              <BackendSvgIcon svgUrl={icon} color="primary" sx={{ fontSize: getResponsiveSize(5, 'rem') }} />
            </Box>
          </Grid>
        )}

        {!!content && (
          <Grid item xs={12}>
            <Typography
              variant="h1"
              textAlign="center"
              color="primary"
              fontWeight="bold"
              sx={{
                img: {
                  maxWidth: '100%',
                  height: 'auto',
                  m: 'auto',
                },
              }}
              dangerouslySetInnerHTML={safeHtml(content, { FORBID_ATTR: ['style'], FORBID_TAGS: ['style'] })}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}
