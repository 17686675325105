import { postRequest } from 'utils/api/apiRequest'

export const exchangeTokenTypes = {
  okta: 1,
  azure: 2,
} as const

type ExchangeTokenType = (typeof exchangeTokenTypes)[keyof typeof exchangeTokenTypes]

export type ExchangeTokenData = {
  token: string
  type: ExchangeTokenType
}

export type ExchangeTokenResponse = {
  data: {
    access_token: string
    refresh_token: string
  }
}

export const exchangeToken = (data: ExchangeTokenData) =>
  postRequest<ExchangeTokenData, ExchangeTokenResponse>(`/token/exchange`, data)
