import { Brand } from 'utils/api/Brands/fetchBrands'
import { Category } from 'utils/api/Categories/fetchCategories'

export enum VisualTypes {
  VIDEO = 'video',
  IMAGE = 'image',
}

export type CatalogProduct = {
  id: number
  price_points: number
  catalog_variants: Product[]
  product: Product
}
export const productTypes = {
  1: 'Product',
  2: 'Charity',
  3: 'Voucher',
  4: 'Value code',
} as const

export type StockStatus = 'standard' | 'do_not_show' | 'show_out_of_stock'

export type Product = {
  id: number
  code: number
  inventory: number
  main_product: Product | null
  navision_name: string
  description: string | null
  navision_description: string | null
  personalization: boolean
  personalization_max_length: number
  categories: Category[]
  brand: Brand
  stock_status: StockStatus | null
  media: ProductMedia[]
  translation: {
    [kay: string]: {
      name: string
      description: string
      extra?: string
    }
  }
  available_stock: number
  points?: number
  out_of_stock?: boolean
  price?: number
  type: keyof typeof productTypes
}

export type ProductInOrder = {
  code: string
  brand?: {
    id: number
    name: string
  }
  translation: {
    [kay: string]: {
      name: string
      description: string
    }
  }
  media: ProductMedia[]
}

export type ProductMedia = {
  id: number
  type: VisualTypes
  path: string
  main: boolean
  position: number
}
