import { useQuery } from '@tanstack/react-query'
import { fetchValues } from 'utils/api/Values/fetchValues'
import valuesQueryKeys from 'utils/api/Values/valuesQueryKeys'

export const useRecognitionValues = () => {
  return useQuery({
    queryKey: valuesQueryKeys.valuesList(),
    queryFn: () => fetchValues(),
    cacheTime: 5 * (60 * 1000), // 5 minute
    refetchOnWindowFocus: true,
    refetchIntervalInBackground: true,
    refetchInterval: 10 * (60 * 1000), // 10 minutes
  })
}
