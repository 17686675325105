import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { useDispatchShoppingCart } from 'shared/ShoppingCart/slice'
import { useDispatchWishlist } from 'shared/Wishlist/slice'
import { addCartItem } from 'utils/api/ShoppingCart/addCartItem'
import { useErrorSnackbar } from 'utils/hooks/useErrorSnackbar'
import { useIsLuckyBirdUser } from 'utils/hooks/useIsLuckyBirdUser'

type Props = {
  toggleDialog?: (value: boolean) => void
}

export const useAddItemToCart = ({ toggleDialog }: Props) => {
  const enqueueErrorSnackbar = useErrorSnackbar()
  const { t } = useTranslation()
  const { fetchWishlist } = useDispatchWishlist()
  const { refetchShoppingCart } = useDispatchShoppingCart()
  const { isLuckyBird } = useIsLuckyBirdUser()
  return useMutation(addCartItem, {
    onSuccess: () => {
      refetchShoppingCart()
      if (toggleDialog) {
        toggleDialog(true)
      }
      !isLuckyBird && fetchWishlist()
    },
    onError: (e) => {
      enqueueErrorSnackbar(e, { DEFAULT_ERROR: t('shopping_cart.add_item.fail') })
    },
  })
}
