import { useMatch } from 'react-router-dom'
import { PathPattern } from '@remix-run/router'
import isArray from 'lodash/isArray'

type Props = PathPattern | string | (PathPattern | string)[]
export const useIsRoute = (routes: Props) => {
  const routesMatch = (isArray(routes) ? routes : [routes]).map((route) => useMatch(route)).filter(Boolean)
  const isRoute = !!routesMatch.length

  return { isRoute, routesMatch, routeMatch: routesMatch?.[0] }
}
