import { useState } from 'react'
import Box from '@mui/material/Box'
import { MessageInactive } from 'components/Message/MessageInactive'
import { CommentAvatar } from 'pages/SocialWall/components/comment/CommentAvatar'
import { CommentEdit } from 'pages/SocialWall/components/comment/CommentEdit'
import { TimeStamp } from 'pages/SocialWall/components/TimeStamp'
import { PostComment } from 'utils/api/SocialWall/types'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'

interface Props {
  comment: PostComment
  postId: number
}
export const Comment = ({ postId, ...props }: Props) => {
  const { author, content, created_at, id } = props.comment
  const [showEdit, setShowEdit] = useState(false)
  const user = useCurrentUser()
  const canEdit = user?.id === author.id

  return (
    <Box
      display="grid"
      gridTemplateColumns={showEdit ? 'auto 1fr' : 'auto auto 1fr'}
      columnGap={1}
      data-cy={`comment-box-${id}`}
    >
      <CommentAvatar avatar={author.profile.avatar} fullName={author.profile.full_name} />
      {showEdit ? (
        <CommentEdit comment={props.comment} postId={postId} onExit={() => setShowEdit(false)} />
      ) : (
        <>
          <MessageInactive
            showEditButton={canEdit}
            onEditClick={() => setShowEdit(true)}
            value={content}
            author={author}
            size="small"
          />
          <TimeStamp created_at={created_at} />
        </>
      )}
    </Box>
  )
}
