import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import { DisplayDateOptions } from 'utils/convertDate'
import { useDisplayDateInTimeZone } from 'utils/useDisplayDateInTimeZone'

type DisplayDateProps = {
  dateString: string | undefined | null
  options?: DisplayDateOptions
}
export const DisplayDate = ({ dateString, options }: DisplayDateProps) => {
  const title = Intl.DateTimeFormat().resolvedOptions().timeZone
  const { displayDateInTimeZone } = useDisplayDateInTimeZone()

  return (
    <Tooltip title={title}>
      <Box sx={{ whiteSpace: 'nowrap' }}>{displayDateInTimeZone(dateString, options)}</Box>
    </Tooltip>
  )
}
