import { useState } from 'react'

const initState = {
  enoughPointsCheckbox: false,
  newProductsCheckbox: false,
  viewedCheckbox: false,
}
export const useCheckboxes = () => {
  const [checkboxes, setCheckboxes] = useState(initState)

  function setCheckbox(key: keyof typeof initState, val: boolean) {
    setCheckboxes((s) => ({ ...s, [key]: val }))
  }
  function toggleCheckbox(param: keyof typeof initState) {
    setCheckboxes((s) => ({ ...s, [param]: !s[param] }))
  }
  return [checkboxes, { setCheckboxes, setCheckbox, toggleCheckbox }] as const
}

export default useCheckboxes
