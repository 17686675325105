import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Loader from 'components/Loader/Loader'
import { PostBox } from 'pages/SocialWall/components/PostBox'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { useGetPosts } from 'utils/hooks/api/SocialWall/useGetPosts'

export const SocialWall = () => {
  const { ref, inView } = useInView({ delay: 100 })
  const { data, isInitialLoading, isFetchingNextPage, fetchNextPage, hasNextPage } = useGetPosts()
  const { t } = useTranslation()

  useEffect(() => {
    if (inView && hasNextPage) fetchNextPage()
  }, [inView, hasNextPage])

  if (isInitialLoading) return <Loader isLoading />

  const posts = data?.pages?.map(({ data }) => data.data.map((el) => ({ ...el, page: data.page })))?.flat() || []

  return (
    <Grid container spacing={4} data-cy="social-wall-container">
      {posts.map((post, i, arr) => (
        <Grid
          data-cy={`social-wall-post-box-${post.id}`}
          item
          xs={12}
          key={post.id}
          ref={valueOrUndefined(arr.length - i === 2, ref)}
        >
          <PostBox post={post} />
        </Grid>
      ))}
      {isFetchingNextPage && (
        <Grid item xs={12}>
          <Loader isLoading />
        </Grid>
      )}
      {!posts?.length && !isFetchingNextPage && (
        <Grid item xs={12}>
          <Typography textAlign="center" variant="body2">
            {t('recognition_post.no_posts')}
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}
