import { BrowserCacheLocation, LogLevel } from '@azure/msal-browser'
import { Configuration } from '@azure/msal-browser/dist/config/Configuration'
import { RedirectRequest } from '@azure/msal-browser/dist/request/RedirectRequest'
import { captureMessage } from '@sentry/browser'
import { ENVIRONMENT } from 'config/env'
import { SSOProvider } from 'pages/Login/api/useGetSSOProviders'

export const msalConfig = (provider?: SSOProvider): Configuration => ({
  auth: {
    clientId: provider?.secret ?? process.env.REACT_APP_MSAL_CLIENT_ID,
    authority: provider?.issuer ?? process.env.REACT_APP_MSAL_AUTHORITY,
    redirectUri: provider?.redirect_uri ?? process.env.REACT_APP_MSAL_REDIRECT_URI,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // This configures where your cache will be stored
    temporaryCacheLocation: BrowserCacheLocation.LocalStorage, // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    cacheMigrationEnabled: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: any) => {
        if (containsPii) {
          return
        }
        if (ENVIRONMENT === 'prod' && level === LogLevel.Error) {
          captureMessage(`[azure integration] ` + message)
        }
        if (['local', 'dev', 'staging'].includes(ENVIRONMENT)) {
          switch (level) {
            case LogLevel.Error:
              console.error(message)
              return
            case LogLevel.Info:
              console.info(message)
              return
            case LogLevel.Verbose:
              console.debug(message)
              return
            case LogLevel.Warning:
              console.warn(message)
              return
          }
        }
      },
    },
  },
})
export const loginRequest: RedirectRequest = {
  scopes: ['User.Read', 'email'],
}
