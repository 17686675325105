import { PaperProps } from '@mui/material'
import Paper from '@mui/material/Paper'
import { getResponsiveSize } from 'theme/styles.utils'
import { FCWithChildren } from 'utils/types/FCWithChildren'

export const ResponsivePaper: FCWithChildren<PaperProps> = ({ children, sx, ...props }) => {
  return (
    <Paper
      elevation={1}
      {...props}
      // @ts-ignore
      sx={(theme) => ({
        position: 'relative',
        fontFamily: 'ProximaNovaCond',
        minHeight: getResponsiveSize(10),
        padding: theme.spacing(3),
        borderRadius: theme.spacing(1.5),
        margin: 0.25,
        '& h1:first-of-type, h2:first-of-type, h3:first-of-type, h4:first-of-type, h5:first-of-type, h6:first-of-type, p:first-of-type':
          {
            marginBlockStart: 0,
          },
        [theme.breakpoints.down('md')]: {
          padding: theme.spacing(3),
          borderRadius: 0,
          margin: 0,
        },
        ...sx,
      })}
    >
      {children}
    </Paper>
  )
}
