export const XXL = 1921
export const MOBILE_TOP_BAR_HEIGHT = 96
const computeResponsive = () => {
  const width = window.visualViewport?.width || 0
  const multiplier = width / XXL
  const isXXL = width >= XXL
  return {
    multiplier,
    isXXL,
  }
}

export function getResponsiveSize(size: number, suffix?: undefined): number
export function getResponsiveSize(size: number, suffix: 'px' | 'rem'): string
export function getResponsiveSize(size: number, suffix: unknown = undefined): unknown {
  const { isXXL, multiplier } = computeResponsive()
  const computedSize = isXXL ? Number((size * multiplier).toFixed(suffix !== 'px' ? 2 : 0)) : size
  return suffix ? `${computedSize}${suffix}` : computedSize
}

export const fontFamilies = {
  default: 'ProximaNova',
  cond: 'ProximaNovaCond',
  logo: 'ProximaNova',
  black: 'ProximaNova',
} as const

export const fontSizes = {
  h1: getResponsiveSize(2, 'rem'),
  h2: getResponsiveSize(1.5, 'rem'),
  h3: getResponsiveSize(1.25, 'rem'),
  h4: getResponsiveSize(1, 'rem'),
  h5: getResponsiveSize(0.75, 'rem'),
  h6: getResponsiveSize(0.5, 'rem'),
  body1: getResponsiveSize(0.85, 'rem'),
  body2: getResponsiveSize(1, 'rem'),
}
export const borderRadiusButton = {
  small: getResponsiveSize(computeResponsive().isXXL ? 26 : 21, 'px'),
  medium: getResponsiveSize(computeResponsive().isXXL ? 32 : 25, 'px'),
  large: getResponsiveSize(computeResponsive().isXXL ? 48 : 29, 'px'),
}
export const borderRadiusInput = {
  extraSmall: getResponsiveSize(computeResponsive().isXXL ? 24 : 17, 'px'),
  small: getResponsiveSize(computeResponsive().isXXL ? 28 : 27, 'px'),
  medium: getResponsiveSize(computeResponsive().isXXL ? 48 : 33, 'px'),
}
export const otherColors = {
  separator: '#e3e2d9',
  scrollbar: '#f1f1f1',
} as const

export const inputPadding = {
  outer: {
    small: {
      x: getResponsiveSize(16, 'px'),
      y: getResponsiveSize(2, 'px'),
    },
    medium: {
      x: getResponsiveSize(16, 'px'),
      y: getResponsiveSize(8, 'px'),
    },
  },
  inner: {
    x: getResponsiveSize(16, 'px'),
    y: getResponsiveSize(14, 'px'),
  },
}
export const inputFontSize = {
  standard: fontSizes.h3,
  xxl: fontSizes.h4,
}
export const inputLabelPos = {
  transformAlignLeft: `translate(0%, 0) scale(1)`,
  transformAlignCenter: `translate(-50%, 0) scale(1)`,
  transformShrink: `translate(0%, ${getResponsiveSize(4, 'px')}) scale(0.75)`,
  leftAlignLeft: getResponsiveSize(32, 'px'),
  leftAlignCenter: '50%',
  leftShrink: getResponsiveSize(32, 'px'),
  topSmall: `calc(${inputPadding.outer.small.y} + ${inputPadding.inner.y})`,
  topMedium: `calc(${inputPadding.outer.medium.y} + ${inputPadding.inner.y})`,
  topShrink: 0,
}
