import { patchRequest } from 'utils/api/apiRequest'
import { PostComment } from 'utils/api/SocialWall/types'

export type PatchCommentPayload = {
  content: string
  id: number
}

export type PatchCommentResponse = PostComment
export const patchComment = ({ id, ...data }: PatchCommentPayload) =>
  patchRequest<PatchCommentResponse>(`/social-wall/posts/comments/${id}`, data)
