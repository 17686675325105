import { RefObject } from 'react'
import { Column, MaterialTableProps, Options, Query, QueryResult } from '@material-table/core'
import { useTheme } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { QueryClient } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import isString from 'lodash/isString'
import { WithPagination } from 'utils/api/pagination'
export const RANGE_FILTER = '<value<'
export type OnSuccessApi<T = undefined> = {
  onSuccess?: T extends undefined ? () => void : (arg: T) => void
}
export type QueryBuilderActionParams<T extends {}> = OnSuccessApi & {
  resolve?: (value: WithPagination<T>) => void
  reject?: (reason?: any) => void
  params?: string
}
export type FilteringQueryParam = {
  useQueryParam: boolean
  prevQuery?: string
}
type CustomOptions = {
  exportEnabled?: boolean
  exportFunc?: () => void
  filteringQuery?: FilteringQueryParam
}

const initialFilteringQuery: FilteringQueryParam = { useQueryParam: false }
const initialCustomOptions: CustomOptions = { exportEnabled: false, filteringQuery: initialFilteringQuery }
const debounceInterval = 1000

export const getOptionsWithoutPagination = <T extends {}>({
  exportEnabled,
  exportFunc,
} = initialCustomOptions): Options<T> => {
  const theme = useTheme()
  return {
    filtering: true,
    search: false,
    maxColumnSort: 1,
    columnsButton: true,
    toolbar: false,
    actionsColumnIndex: -1,
    debounceInterval,
    pageSize: 10,
    emptyRowsWhenPaging: false,
    pageSizeOptions: [5, 10, 20, 50, 100],
    headerStyle: {
      whiteSpace: 'nowrap',
      fontSize: theme.typography.h5.fontSize,
      color: alpha(theme.palette.common.black, 0.5),
      background: 'transparent',
    },
    rowStyle: {
      fontSize: theme.typography.h5.fontSize,
    },
    exportMenu:
      exportEnabled && exportFunc
        ? [
            {
              label: 'Export to CSV',
              exportFunc,
            },
          ]
        : undefined,
  }
}

export const queryBuilder = <RowData extends {}, AdditionalParamsType = string>(
  queryKey: (args: string[]) => string[],
  queryFn: (params?: string) => Promise<AxiosResponse<WithPagination<RowData>>>,
  columns: Column<RowData>[],
  queryClient: QueryClient,
  additionalParams?: string | { [key: string]: PrimitiveTypes | PrimitiveTypes[] | undefined | null }
) => {
  return (query: Query<RowData>): Promise<QueryResult<RowData>> =>
    new Promise((resolve, reject) => {
      const params: string[] = []

      // sort
      if (!isEmpty(query.orderByCollection)) {
        query.orderByCollection
          .filter((item) => item.orderDirection !== '')
          .forEach(({ orderBy, orderDirection }) =>
            params.push(`sort[${columns?.[orderBy]?.field?.toString()}]=${orderDirection}`)
          )
      }
      // filter
      if (!isEmpty(query.filters)) {
        query.filters.forEach(({ column, value }) => {
          if (isEmpty(value)) return

          if (column.lookup) {
            params.push(`filters[${column.field?.toString()}]=${value?.join(',')}`)
          } else if (column.type === 'boolean') {
            params.push(`filters[${column.field?.toString()}]=${value === 'checked' ? '1' : '0'}`)
          } else {
            if (value.includes(RANGE_FILTER)) {
              const [min, max] = value.split(RANGE_FILTER)
              if (min) params.push(`filters[${column.field?.toString()}|gte]=${min}`)
              if (max) params.push(`filters[${column.field?.toString()}|lte]=${max}`)
            } else {
              params.push(`filters[${column.field?.toString()}]=${value}`)
            }
          }
        })
      }
      // pagination
      if (query.pageSize) {
        params.push(`limit=${query?.pageSize || 10}`)
      }
      params.push(`page=${(query?.page || 0) + 1}`)
      // action
      if (isString(additionalParams)) {
        params.push(additionalParams)
      }

      params.join('&')
      queryClient
        .fetchQuery({
          queryKey: isFunction(queryKey) ? queryKey([params.join('&')]) : [queryKey, params.join('&')],
          queryFn: () => queryFn(params.join('&')),
        })
        .then((result) => {
          resolve({
            data: result.data?.data,
            page: result?.data.page - 1,
            totalCount: result?.data.items,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
}
type PrimitiveTypes = string | number | boolean | object

export type MaterialTableRef<T = { id: number }> = RefObject<MaterialTableProps<T & { id: number }>>['current']
