import { DeliveryData } from 'shared/ShoppingCart/api'
import { patchRequest } from 'utils/api/apiRequest'

export const changeOrderDelivery = (data: DeliveryData) =>
  patchRequest('/redemption-site/checkout/delivery-data', {
    ...data,
    delivery_address_type:
      // !!data?.delivery_address_type && data?.delivery_address_type !== DeliveryVariant.digital // TODO check if this is needed
      data?.delivery_address_type ? Number(data.delivery_address_type) : undefined,
  })
