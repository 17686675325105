import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import { LoginCallback, useOktaAuth } from '@okta/okta-react'
import { TextField } from 'components/TextField/TextField'
import { useAuthContext } from 'store/useAuthContext'

export const OktaErrors = () => {
  const { t } = useTranslation()
  const { authState, oktaAuth } = useOktaAuth()
  const [user, setUser] = useState(null)
  const { logoutUser } = useAuthContext()

  useEffect(() => {
    oktaAuth?.getUser().then((data) => setUser({ ...data, headers: undefined }))
  }, [])

  return (
    <Box display="grid" justifyContent="center" alignItems="center">
      <Grid container spacing={2} alignItems="center" flexDirection="column">
        {authState?.error?.message && (
          <Grid item xs={12}>
            {authState?.error?.message}
          </Grid>
        )}
        {user && (
          <Grid item sx={{ width: '100%' }}>
            <Collapse in>
              <TextField multiline minRows={5} value={JSON.stringify(user, undefined, 2)} />
            </Collapse>
          </Grid>
        )}
        <Grid item>{t('sso.okta.problem_with_fetch_user')}</Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              oktaAuth
                .signOut({ postLogoutRedirectUri: oktaAuth.options.redirectUri })
                .finally(() => oktaAuth.clearStorage())
              logoutUser()
            }}
          >
            {t('app.logout.from_okta')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <LoginCallback onAuthResume={() => console.info('[OKTA]: logged')} />
        </Grid>
      </Grid>
    </Box>
  )
}
