import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useSubdomainInfo } from 'shared/Site/api'
import { SiteType } from 'shared/Site/siteType'
import { getWishlist } from 'shared/Wishlist/api'

export const wishlistQueryKeys = {
  all: ['all', 'wishlist'] as const,
  fetchWishlist: () => [...wishlistQueryKeys.all, 'fetchWishlist'] as const,
}

export const useSelectWishlist = () => {
  const { data: subdomainInfo } = useSubdomainInfo()

  return useQuery({
    queryKey: wishlistQueryKeys.fetchWishlist(),
    queryFn: getWishlist,
    enabled: subdomainInfo?.data?.type === SiteType.project,
  })
}

export const useDispatchWishlist = () => {
  const queryClient = useQueryClient()

  return {
    fetchWishlist: () => queryClient.invalidateQueries(wishlistQueryKeys.fetchWishlist()),
  }
}
