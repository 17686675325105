import DeleteIcon from '@mui/icons-material/Delete'
import { RenderDeleteAction } from 'utils/actionsCreator/types'

import { CustomIconButton } from './CustomIconButton'
import { funcOrString } from './utils'

export const renderDeleteAction =
  <T,>({ isRemoving, popupMessage, hidden, ...args }: RenderDeleteAction<T>) =>
  (rowData: T) =>
    hidden ? null : (
      <CustomIconButton
        dataCy="delete-icon-button"
        isLoading={isRemoving}
        color="secondary"
        tooltipMessage="app.remove_item"
        // @ts-ignore
        rowData={rowData}
        popupMessage={
          funcOrString(rowData, popupMessage)
          // || 'app.dialog.remove'
        }
        {...args}
      >
        <DeleteIcon />
      </CustomIconButton>
    )
