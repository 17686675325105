import { useTranslation } from 'react-i18next'
import { dialogClasses } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import { useFormikContext } from 'formik'
import { useGetMyConsent } from 'pages/Login/api/useGetMyConsent'
import { Iframe } from 'shared/Iframe/Iframe'
import { makeStyles } from 'utils/styles'

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    width: '100%',
    height: '100%',
    rowGap: theme.spacing(2),
  },
}))

type Props = {
  open: boolean
  onClose: () => void
}

export const ConsentDialog = ({ open, onClose }: Props) => {
  const { gridContainer } = useStyles()
  const { t, i18n } = useTranslation()
  const { setFieldValue } = useFormikContext()
  const { data } = useGetMyConsent()
  const consent = data?.data?.translation?.[i18n.language]
  const onAccept = () => {
    setFieldValue('consent', true)
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{
        [`& .${dialogClasses.paper}`]: {
          height: '100%',
        },
      }}
    >
      <DialogContent>
        <Box className={gridContainer}>
          <Box>
            <Typography variant="h1" textAlign="center">
              {consent?.name}
            </Typography>
          </Box>
          <Box>
            <Iframe unsafeHTML={consent?.text} />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', pb: 4 }}>
        <Button onClick={onClose} variant="outlined" color="primary">
          {t('app.cancel')}
        </Button>
        <Button onClick={onAccept} variant="contained" color="primary">
          {t('auth.activate_account.consent_accept')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
