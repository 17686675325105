import React from 'react'
import { MessageReadOnly } from 'components/Message/components/MessageReadOnly'
import { MessageWrapper } from 'components/Message/components/MessageWrapper'
import { MessageBaseProps } from 'components/Message/types'

export type MessageInactiveProps = { children?: React.ReactNode | React.ReactNode[] } & Pick<
  MessageBaseProps,
  'size' | 'author' | 'value' | 'onEditClick' | 'fullWidth' | 'authorSide' | 'showEditButton' | 'variant'
>

export const MessageInactive = ({
  size,
  author,
  value,
  onEditClick,
  fullWidth,
  authorSide,
  showEditButton,
  children,
  variant = 'default',
}: MessageInactiveProps) => {
  return (
    <MessageWrapper
      showEditButton={showEditButton}
      size={size}
      onEditClick={onEditClick}
      fullWidth={fullWidth}
      authorSide={authorSide}
      variant={variant}
    >
      <MessageReadOnly value={value} author={author} size={size} />
      {children}
    </MessageWrapper>
  )
}
