import { useLocation } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import qs from 'query-string'
import brandsQueryKeys from 'utils/api/Brands/brandsQueryKeys'
import { fetchBrands } from 'utils/api/Brands/fetchBrands'

export const useBrands = ({ params }: { params?: string } = { params: '' }) => {
  const location = useLocation()
  const paramsUrl = qs.parse(location?.search.replace('?', ''))
  const searchBrands = paramsUrl?.searchBrands as string

  return useQuery({
    queryKey: brandsQueryKeys.brandList(searchBrands, params),
    queryFn: () => fetchBrands(searchBrands, params),
  })
}
