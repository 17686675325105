import { displayDateInTimeZone, DisplayDateOptions } from 'utils/convertDate'
import { useDateLocale } from 'utils/hooks/useDateLocale'

export const useDisplayDateInTimeZone = () => {
  const { locale } = useDateLocale()

  return {
    displayDateInTimeZone: (dateString: string, options?: DisplayDateOptions) =>
      // @ts-ignore
      displayDateInTimeZone(dateString, options, locale),
  }
}
