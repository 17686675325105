import { createContext, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { toRelativeUrl } from '@okta/okta-auth-js'
import { Security } from '@okta/okta-react'
import { useQuery } from '@tanstack/react-query'
import { LoaderFullScreen } from 'components/LoaderFullScreen/LoaderFullScreen'
import { useSnackbar } from 'notistack'
import { providerTypes, SSOProvider, useGetSSOProviders } from 'pages/Login/api/useGetSSOProviders'
import { redirectOnLogin } from 'pages/Login/redirectOnLogin'
import { oktaAuth } from 'pages/Login/SSO/okta'
import qs from 'query-string'
import { MsalWrapper } from 'store/MsalWrapper'
import { useAuthContext } from 'store/useAuthContext'
import { LoginResponseType } from 'utils/api/apiClient'
import { getRequest } from 'utils/api/apiRequest'
import { setAuthTokens } from 'utils/axios-jwt-cookies'
import { getProjectWithSocialWallFromUserProjects } from 'utils/hooks/useSocialWall'
import { FCWithChildren } from 'utils/types/FCWithChildren'

type CustomAuthToken = {
  externalToken?: string
  setExternalToken: (token?: string) => void
  providers: SSOProvider[]
}

const CustomAuthContext = createContext<CustomAuthToken | null>(null)
const { Provider, Consumer } = CustomAuthContext

const CustomAuthProvider: FCWithChildren = ({ children }) => {
  const location = useLocation()
  const [externalToken, setExternalToken] = useState<string | undefined>(
    qs.parse(location.search)?.externalToken?.toString()
  )
  const { refetch } = useAuthContext()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const { t } = useTranslation()

  useQuery({
    enabled: !!externalToken,
    queryKey: ['externalToken'],
    queryFn: () => getRequest<LoginResponseType['data']>(`/token/exchange/${externalToken}`),
    onSuccess: async ({ data }) => {
      setAuthTokens({
        accessToken: data?.access_token,
        refreshToken: data?.refresh_token,
      })
      setExternalToken(undefined)
      const userData = await refetch()
      if (!userData?.data) {
        enqueueSnackbar(t('auth.login.custom_jwt.error'), { variant: 'error' })
        return new Error('No user data')
      }
      const isSocialWallActive = !!getProjectWithSocialWallFromUserProjects(userData?.data?.data?.projects || {})
      redirectOnLogin(navigate, isSocialWallActive, location?.search)
      enqueueSnackbar(t('auth.login.success'), { variant: 'success' })
    },
    onError: async () => {
      enqueueSnackbar(t('auth.login.custom_jwt.error'), { variant: 'error' })
    },
  })

  const { data, isFetching } = useGetSSOProviders()
  const providers = data?.data?.data?.filter((provider) => provider.type !== providerTypes.van_moer) ?? []

  if (isFetching) {
    return <LoaderFullScreen />
  }

  return (
    <Provider value={{ externalToken, setExternalToken, providers }}>
      <MsalWrapper providers={providers}>
        <Security
          oktaAuth={oktaAuth(providers)}
          onAuthRequired={(oktaAuth) => oktaAuth.signInWithRedirect()}
          restoreOriginalUri={async (oktaAuth, originalUri) => {
            toRelativeUrl(originalUri || '/', window.location.origin)
          }}
        >
          {children}
        </Security>
      </MsalWrapper>
    </Provider>
  )
}

const useCustomAuthContext = () => {
  const context = useContext(CustomAuthContext)
  if (!context) throw Error('Missing CustomAuthContext')
  return context
}

export { CustomAuthProvider, Consumer as AuthConsumer, useCustomAuthContext }
export default CustomAuthContext
