import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { ROOT_CATEGORY_ID } from 'pages/HomeRedemptionSite/utils'
import categoriesQueryKeys from 'utils/api/Categories/categoriesQueryKeys'
import { fetchSubCategories } from 'utils/api/Categories/fetchSubCategories'

export const useSubCategories = (id = ROOT_CATEGORY_ID) => {
  const { i18n } = useTranslation()
  return useQuery({
    queryKey: categoriesQueryKeys.subCategoriesList(id, i18n.language),
    queryFn: () => fetchSubCategories(id, i18n.language),
  })
}
