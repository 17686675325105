import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { MyAccountExpiresPointsHistory } from 'pages/MyAccount/api/getPointsHistory'
import { usePointsName } from 'utils/hooks/usePointsName'

type Props = {
  row?: MyAccountExpiresPointsHistory
}

export const ExpiresPointsHistoryRow = ({ row }: Props) => {
  const total = Number(row.total)
  const pointName = usePointsName(total || 0)

  return (
    <>
      <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', py: 3 }}>{row.date}</Box>
      <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        {row ? (
          <Typography variant="h4" align="right">
            {total < 0 ? `${total * -1} ${pointName}` : `${total} ${pointName}`}
          </Typography>
        ) : (
          <Skeleton width="100%" />
        )}
      </Box>
    </>
  )
}
