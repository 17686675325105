import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { AuthLoggedUser } from 'components/AuthLoggedUser/AuthLoggedUser'
import Loader from 'components/Loader/Loader'
import { Form, Formik } from 'formik'
import isEmpty from 'lodash/isEmpty'
import { useSnackbar } from 'notistack'
import { ActivateForm } from 'pages/Login/ActivateForm'
import { patchActivateAccount } from 'pages/Login/api/patchActivateAccount'
import { useCheckToken } from 'pages/Login/api/useCheckToken'
import { WrapperLoginForm } from 'pages/Login/WrapperLoginForm'
import qs from 'query-string'
import { isLoggedIn } from 'utils/axios-jwt-cookies'
import { getTokenFromSearch } from 'utils/getTokenFromSearch'
import * as Yup from 'yup'

type FormValues = {
  first: string
  second: string
}

export const Activate = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const token = getTokenFromSearch(location.search.replace('?', ''))
  const params = qs.parse(location.search.replace('?', ''))

  const { isFetching, isError, error } = useCheckToken(token)

  const { mutate } = useMutation(patchActivateAccount, {
    onSuccess: async () => {
      navigate(
        params?.redirect
          ? `/auth/login?${qs.stringify({ ...params, token: undefined }, { encode: false })}`
          : '/auth/login'
      )
      enqueueSnackbar(t('auth.activate.success'), { variant: 'success' })
    },
    onError: async () => {
      enqueueSnackbar(t('auth.activate.fail'), { variant: 'error' })
    },
  })

  const onSubmit = (values: FormValues) => mutate({ token, password: values })

  const validationSchema = Yup.object().shape({
    first: Yup.string()
      .label(t('auth.activate.first'))
      .required()
      .matches(/^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/, t('app.validations.password'))
      .matches(
        // eslint-disable-next-line no-useless-escape
        /[\!\@\#\$\%\^\&\*\(\)\_\+\-\=\[\]\{\};:\",\.<>\/?]/,
        t('app.validations.hasSpecialCharacter').replace('$path', t('auth.activate.first'))
      ),
    second: Yup.string()
      .label(t('auth.activate.second'))
      .required()
      .oneOf([Yup.ref('first')], 'Passwords must be the same'),
  })

  const initialValues: FormValues = {
    first: '',
    second: '',
  }

  if (isLoggedIn()) return <AuthLoggedUser />

  return (
    <Loader isLoading={isFetching} fullHeight>
      <Formik initialValues={initialValues} enableReinitialize validationSchema={validationSchema} onSubmit={onSubmit}>
        <Form style={{ height: '100%' }}>
          <WrapperLoginForm>
            <ActivateForm isLoading={isFetching} error={error} isError={isError || isEmpty(token)} />
          </WrapperLoginForm>
        </Form>
      </Formik>
    </Loader>
  )
}
