import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

export const Hide = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 200 200">
    <g>
      <g>
        <path
          d="M45,137.2l15.8-15.8c-3.5-6.4-5.5-13.7-5.5-21.5c0-24.7,20.1-44.6,44.6-44.6c7.8,0,15.1,2,21.4,5.5l11.7-11.7
			c-9.7-4.2-20.7-6.9-33.1-6.9c-57.4,0-85.1,57.7-85.1,57.7S24.8,120.6,45,137.2z"
        />
        <path
          d="M154.9,62.7l-15.8,15.8c3.5,6.4,5.5,13.7,5.5,21.4c0,24.7-19.9,44.6-44.6,44.6c-7.8,0-15.1-2-21.5-5.5l-11.7,11.7
			c9.7,4.2,20.7,6.9,33.1,6.9c57.5,0,85.1-57.7,85.1-57.7S175.2,79.2,154.9,62.7z"
        />
      </g>
      <g>
        <path d="M109.7,72.7c-3-1-6.3-1.7-9.6-1.7c-16,0-29,12.9-29,28.9c0,3.4,0.6,6.8,1.7,9.8L109.7,72.7z" />
        <path d="M90.5,127.2c3,1,6.2,1.6,9.6,1.6c16,0,28.9-12.9,28.9-29c0-3.2-0.5-6.5-1.6-9.4L90.5,127.2z" />
      </g>
      <path
        d="M177.3,33.2l-144,144c-1.5,1.5-3.4,2.2-5.3,2.2s-3.8-0.7-5.3-2.2c-2.9-2.9-2.9-7.7,0-10.6l144-144c1.5-1.5,3.4-2.2,5.3-2.2
		c1.9,0,3.8,0.7,5.3,2.2C180.2,25.5,180.2,30.2,177.3,33.2z"
      />
    </g>
  </SvgIcon>
)
