import { useState } from 'react'
import Box from '@mui/material/Box'
import brokenImg from 'components/Image/broken-img.svg'
import { getThumbnailPath } from 'utils/getThumbnail'
import { useResize } from 'utils/hooks/useResize'

export interface ItemListImageProps {
  src: string
  alt?: string
  square?: boolean
  round?: boolean
}

export const Image = ({ src, square, alt = '', round = false }: ItemListImageProps) => {
  const [imgError, setImgError] = useState(false)
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const [boxRef, setBoxRef] = useState<HTMLDivElement>()
  const { width } = useResize(boxRef)
  const mediaUrl = getThumbnailPath(src)

  if (imgError)
    return (
      <Box
        ref={setBoxRef}
        component="img"
        src={brokenImg}
        loading="lazy"
        sx={(t) => ({
          maxWidth: '100%',
          width: '100%',
          height: square ? undefined : width || 'auto',
          background: t.palette.grey[200],
          aspectRatio: square ? '1/1' : undefined,
          padding: '25%',
          borderRadius: round ? '50%' : undefined,
        })}
      />
    )

  return (
    <Box
      ref={setBoxRef}
      sx={(t) => ({
        '@keyframes pulse': {
          '0%': {
            background: t.palette.grey[200],
          },
          '50%': {
            background: t.palette.grey[400],
          },
          '100%': {
            background: t.palette.grey[200],
          },
        },
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: square ? undefined : width || 'auto',
        aspectRatio: square ? '1/1' : undefined,
        animation: !isImageLoaded ? `pulse 2s infinite ease` : undefined,
        borderRadius: round ? '50%' : undefined,
      })}
    >
      <img
        onLoad={() => setIsImageLoaded(true)}
        onError={() => setImgError(true)}
        alt={alt}
        src={`${mediaUrl}?w=248&fit=crop&auto=format`}
        srcSet={`${mediaUrl}?w=248&fit=crop&auto=format&dpr=2 2x`}
        loading="lazy"
        style={{
          maxWidth: '100%',
          height: '100%',
          width: '100%',
          maxHeight: '100%',
          objectFit: 'contain',
          visibility: !isImageLoaded ? 'hidden' : undefined,
          borderRadius: round ? '50%' : undefined,
        }}
      />
    </Box>
  )
}
