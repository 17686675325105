import { MAIN_DOMAIN } from 'config/env'

export const getCatalogUrl = (subdomain: string) => {
  if (!subdomain) {
    return undefined
  }
  const hostnameSegments = window.location.hostname.split('.')
  const domain = MAIN_DOMAIN || hostnameSegments[hostnameSegments.length - 1]
  return `${window.location.protocol}//${subdomain}.${domain}${window.location.port ? ':' : ''}${
    window.location.port
  }/catalog`
}
