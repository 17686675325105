import { JSX } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Gift } from 'components/ArteelIcons'
import { ButtonWithIcon } from 'components/ButtonWithIcon/ButtonWithIcon'
import Loader from 'components/Loader/Loader'
import { ResponsivePaper } from 'components/ResponsivePaper'
import { ImageContent } from 'pages/CMS/components/ContentBox/ImageContent'
import { MyPointsText } from 'pages/CMS/components/MyPointsText'
import { SectionsGrid } from 'pages/CMS/components/Sections'
import { useSubdomainInfo } from 'shared/Site/api'
import { fontFamilies } from 'theme/styles.utils'
import { useLBThemes, useThemes } from 'utils/api/Theme/useThemes'
import { dataCy } from 'utils/cypressUtils'
import { getObjectTranslation } from 'utils/getObjectTranslation'
import { useIsMobile, useIsTablet } from 'utils/hooks/useBreakpoints'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'
import { useIsLuckyBirdUser } from 'utils/hooks/useIsLuckyBirdUser'
import { useLogout } from 'utils/hooks/useLogout'
import { useLBCodeStatus } from 'utils/hooks/useLuckyBirdCodeStatus'
import { safeHtml } from 'utils/safeHtml'

interface Props {
  middleColumnContent?: JSX.Element
}

// eslint-disable-next-line complexity
export const LuckyBirdWelcomePage = ({ middleColumnContent }: Props) => {
  const { isFetching: isFetchingLBTheme, data: luckyBirdTheme } = useLBThemes()
  const { isFetching: isFetchingThemes, data: themeData } = useThemes()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: subdomainInfo } = useSubdomainInfo()
  const subscriberLogo = subdomainInfo?.data?.subscriber?.logo_path
  const isMobile = useIsMobile()
  const orderInfo = getObjectTranslation(luckyBirdTheme?.system_text?.translation)?.value ?? null
  const welcomeText = getObjectTranslation(luckyBirdTheme?.theme.translation)?.welcome_text
  const rightColumnImages = getObjectTranslation(luckyBirdTheme?.theme.translation)?.images || []
  const statusLB = useLBCodeStatus()
  const { available_points, projects } = useCurrentUser()
  const { isPointsLogic } = useIsLuckyBirdUser()
  const isTablet = useIsTablet()
  const { handleConfirm } = useLogout()
  const project = Object.values(projects || {})?.[0]

  return (
    <Loader isLoading={isFetchingLBTheme || isFetchingThemes}>
      <SectionsGrid
        onlyMiddleComponent={true}
        LeftColumnComponent={
          <Grid container spacing={isMobile ? 2 : 4}>
            {subscriberLogo && (
              <Grid item xs={12}>
                <ResponsivePaper>
                  <ImageContent content={{ image_path: subscriberLogo }} dataCy={dataCy('image-logo-subscriber')} />
                </ResponsivePaper>
              </Grid>
            )}
            <Grid item xs={12}>
              <ResponsivePaper>
                <Button size="small" variant="outlined" fullWidth onClick={() => handleConfirm()}>
                  {t('lucky_bird.welcome_page.use_another_code').toUpperCase()}
                </Button>
              </ResponsivePaper>
            </Grid>
            {orderInfo && (
              <Grid item xs={12}>
                <ResponsivePaper>
                  <Typography
                    variant="h2"
                    fontFamily={fontFamilies.default}
                    color="primary"
                    textAlign="center"
                    fontWeight="bold"
                  >
                    {t('lucky_bird.welcome_page.order_info.title')}
                  </Typography>
                  <Box sx={{ fontFamily: fontFamilies.default }} dangerouslySetInnerHTML={safeHtml(orderInfo)} />
                </ResponsivePaper>
              </Grid>
            )}
          </Grid>
        }
        MiddleColumnComponent={
          middleColumnContent || (
            <Grid
              container
              spacing={isMobile ? 2 : 4}
              sx={{
                '& ul': {
                  paddingLeft: (theme) => theme.spacing(4),
                  listStyleType: 'disc',
                },
                '& sup': {
                  verticalAlign: 'super',
                  fontSize: 'smaller',
                },
                '& sub': {
                  verticalAlign: 'sub',
                  fontSize: 'smaller',
                },
                '& ol': {
                  paddingLeft: (theme) => theme.spacing(4),
                  listStyleType: 'decimal',
                },
                '& *': {
                  maxWidth: '100%',
                },
                '& img': {
                  display: 'block',
                  height: 'auto',
                },
                '& p': {
                  lineHeight: 1.3,
                },
                '& h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child, p:first-child':
                  {
                    marginBlockStart: 0,
                  },
                '& > *': {
                  overflowWrap: 'anywhere',
                },
              }}
            >
              <Grid item xs={12}>
                <ResponsivePaper>
                  {statusLB('fullyRedeemed') && (
                    <Alert
                      sx={(_) => ({
                        mb: 3,
                      })}
                      severity="info"
                    >
                      {t('lucky_bird.welcome_page.choose_gift.full_redeemed_info')}
                    </Alert>
                  )}
                  <Box
                    sx={{
                      fontFamily: fontFamilies.default, // RP-5201
                    }}
                  >
                    <Box dangerouslySetInnerHTML={safeHtml(welcomeText)} />
                  </Box>
                </ResponsivePaper>
              </Grid>
              {isTablet && (
                <Grid item xs={12}>
                  <ResponsivePaper>
                    <Grid container spacing={1} justifyContent="center">
                      {!statusLB('fullyRedeemed') && (
                        <>
                          {isPointsLogic && (
                            <Grid item xs={12}>
                              <MyPointsText project={project} availablePoints={available_points} />
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <ButtonWithIcon
                              dataCy={dataCy('button-lb-redeem')}
                              fullWidth
                              size="small"
                              variant="contained"
                              Icon={<Gift fontSize="small" />}
                              onClick={() => navigate('/catalog')}
                              translation="lucky_bird.welcome_page.choose_gift.button"
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12}>
                        <Button size="small" variant="outlined" fullWidth onClick={() => navigate('/service-request')}>
                          {t('app.help').toUpperCase()}
                        </Button>
                      </Grid>
                    </Grid>
                  </ResponsivePaper>
                </Grid>
              )}
              {orderInfo && isTablet && (
                <Grid item xs={12}>
                  <ResponsivePaper>
                    <Typography
                      variant="h2"
                      fontFamily={fontFamilies.black} // RP-5201
                      fontWeight={900}
                      color="primary"
                      textAlign="center"
                    >
                      {t('lucky_bird.welcome_page.order_info.title')}
                    </Typography>
                    <Box
                      sx={{
                        fontFamily: fontFamilies.default, // RP-5201
                      }}
                    >
                      <Box dangerouslySetInnerHTML={safeHtml(orderInfo)} />
                    </Box>
                  </ResponsivePaper>
                </Grid>
              )}
              {isTablet && (
                <Grid item xs={12}>
                  <ResponsivePaper>
                    <Button size="small" variant="outlined" fullWidth onClick={() => handleConfirm()}>
                      {t('lucky_bird.welcome_page.use_another_code').toUpperCase()}
                    </Button>
                  </ResponsivePaper>
                </Grid>
              )}
            </Grid>
          )
        }
        RightColumnComponent={
          <Grid container spacing={isMobile ? 2 : 4}>
            <Grid item xs={12}>
              <ResponsivePaper>
                <Grid container spacing={1} justifyContent="center">
                  {!statusLB('fullyRedeemed') && (
                    <>
                      {isPointsLogic && (
                        <Grid item xs={12}>
                          <MyPointsText project={project} availablePoints={available_points} />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <ButtonWithIcon
                          dataCy={dataCy('button-lb-redeem')}
                          fullWidth
                          size="small"
                          variant="contained"
                          Icon={<Gift fontSize="small" />}
                          onClick={() => navigate('/catalog')}
                          translation="lucky_bird.welcome_page.choose_gift.button"
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <Button size="small" variant="outlined" fullWidth onClick={() => navigate('/faq')}>
                      {t('app.faq').toUpperCase()}
                    </Button>
                  </Grid>
                </Grid>
              </ResponsivePaper>
            </Grid>
            {rightColumnImages.map((image_path, i) => (
              <Grid item xs={12} key={i}>
                <ResponsivePaper>
                  <ImageContent content={{ image_path }} dataCy={dataCy('image-lb-column')} />
                </ResponsivePaper>
              </Grid>
            ))}
          </Grid>
        }
      />
    </Loader>
  )
}
