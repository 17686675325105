import { ChangeEvent, Fragment, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Divider from '@mui/material/Divider'
import FormHelperText from '@mui/material/FormHelperText'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import RadioGroup from '@mui/material/RadioGroup'
import Typography from '@mui/material/Typography'
import { FormikTextField } from 'components/Formik/FormikTextField'
import Loader from 'components/Loader/Loader'
import { useFormikContext } from 'formik'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import { BpostPoint } from 'pages/CreateOrder/components/Bpost/BpostPoint'
import { getBpostPointFromXml } from 'pages/CreateOrder/components/Bpost/getBpostPointFromXml'
import { useStyles } from 'pages/CreateOrder/components/Bpost/useStyles'
import { DeliveryVariant } from 'pages/CreateOrder/deliveryOptions'
import { getNestedName } from 'pages/CreateOrder/helper/getNestedName'
import { CreateOrderType2 } from 'pages/CreateOrder/types'
import { BpostPointType } from 'utils/hooks/api/Bpost/bpostAxios'
import { useGetCollectionPoints } from 'utils/hooks/api/Bpost/useGetCollectionPoints'

type Props = {
  selectedCountryCode?: string
}

export const BpostCollectionPoints = ({ selectedCountryCode }: Props) => {
  const { paper, labelSelectCollectionPoint, divider } = useStyles()
  const { t } = useTranslation()
  const { setValues, getFieldMeta, setFieldValue } = useFormikContext<CreateOrderType2>()
  const { mutate, data, isLoading, isSuccess, isError } = useGetCollectionPoints(selectedCountryCode)
  const { touched, error, value } = getFieldMeta(`${DeliveryVariant.bpostCollectionPoint}.bpost_delivery_point_id`)
  const errorTextField = !isEmpty(error) && touched
  const helperText = touched && error && error
  const zipCode: string = getFieldMeta(getNestedName(DeliveryVariant.bpostCollectionPoint, 'zip'))?.value as string

  const points: BpostPointType[] = data && isSuccess ? getBpostPointFromXml(data?.data) : []
  const onChangeZipCode = useMemo(() => debounce((zip: string) => mutate(zip), 700), [])

  useEffect(() => {
    if (!isEmpty(zipCode)) {
      onChangeZipCode(zipCode)
    }
  }, [])

  const handleChangeBpostPoint = (_: ChangeEvent<HTMLInputElement>, value: string) => {
    const point = points.find((point) => point.Record.Id._text === value)
    setValues((prev) => ({
      ...prev,
      [DeliveryVariant.bpostCollectionPoint]: {
        ...prev?.[DeliveryVariant.bpostParcelLocker],
        bpost_delivery_point_id: value,
        bpost_delivery_point_name: point?.Record?.Name?._text,
        street: point?.Record?.Street?._text,
        number: point?.Record?.Number?._text,
        zip: point?.Record?.Zip?._text,
        city: point?.Record?.City?._text,
        is_company_address: false,
        company_name: '',
        box: '',
      },
    }))
  }

  return (
    <Grid container justifyContent="center" spacing={4}>
      <Grid item xs={12}>
        <Paper elevation={1} className={paper}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <Typography variant="h2" align="center">
                <span className="bold">{t('create_order.bpost_postcode')}</span>
              </Typography>
            </Grid>
            <Grid item>
              <FormikTextField
                variant="outlined"
                data-cy="collection-point-zip-input"
                name={getNestedName(DeliveryVariant.bpostCollectionPoint, 'zip')}
                fullWidth={false}
                label={t('create_order.postcode')}
                onChange={(event) => {
                  setFieldValue(getNestedName(DeliveryVariant.bpostCollectionPoint, 'zip'), event.target.value)
                  onChangeZipCode(event.target.value)
                }}
              />
              {isError && (
                <FormHelperText error sx={{ textAlign: 'center' }}>
                  {t('app.no_elements')}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <Loader isLoading={isLoading}>
                <RadioGroup onChange={handleChangeBpostPoint}>
                  <Grid container spacing={4}>
                    {isSuccess && (
                      <Grid item xs={12}>
                        <Typography variant="h2" align="center" className={labelSelectCollectionPoint}>
                          {t('create_order.select_collection_point')}
                        </Typography>
                      </Grid>
                    )}
                    {points.map((point) => (
                      <Fragment key={point?.Record?.Id?._text}>
                        <Grid item xs={12}>
                          <Divider className={divider} />
                        </Grid>
                        <BpostPoint point={point} checked={value.toString() === point.Record?.Id?._text?.toString()} />
                      </Fragment>
                    ))}
                    {errorTextField && (
                      <Fragment>
                        <Grid item xs={12}>
                          <Divider className={divider} />
                        </Grid>
                        <Grid item xs={12}>
                          <FormHelperText error sx={{ textAlign: 'center' }}>
                            {helperText}
                          </FormHelperText>
                        </Grid>
                      </Fragment>
                    )}
                  </Grid>
                </RadioGroup>
              </Loader>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
