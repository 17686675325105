import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Iframe } from 'shared/Iframe/Iframe'
import { useSelectUserConsent } from 'shared/UserConsent/slice'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    width: '100%',
    height: '100%',
    rowGap: theme.spacing(2),
  },
}))
export const UserConsentContent = () => {
  const { data: userConsent } = useSelectUserConsent()
  const { gridContainer } = useStyles()

  if (!userConsent.data?.consent) {
    console.error('Missing user consent')
    return null
  }
  const { name, text } = userConsent.data.consent

  return (
    <Box className={gridContainer}>
      <Box>
        <Typography variant="h1" textAlign="center">
          {name}
        </Typography>
      </Box>
      <Box>
        <Iframe unsafeHTML={text} />
      </Box>
    </Box>
  )
}
