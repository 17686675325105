import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { personalMessageKeys } from 'pages/PersonalMessages/api/keys'
import { getPersonalTemplates } from 'pages/PersonalMessages/api/useGetPersonalTemplates'
import { deleteRequest } from 'utils/api/apiRequest'
import { OnSuccessApi } from 'utils/getOptionsTable'
import { useErrorSnackbar } from 'utils/hooks/useErrorSnackbar'

type Data = OnSuccessApi & {
  id: number
}

const deletePersonalTemplate = ({ id }: Data) => deleteRequest(`/users/message-templates/${id}`)

type ReturnQueryType = Awaited<ReturnType<typeof getPersonalTemplates>>
export const useDeletePersonalTemplate = () => {
  const enqueueErrorSnackbar = useErrorSnackbar()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: personalMessageKeys.deletePersonalTemplate(),
    mutationFn: deletePersonalTemplate,
    onSuccess: (data, variables) => {
      variables?.onSuccess?.()
      const prevQuery = queryClient.getQueryData<ReturnQueryType>(personalMessageKeys.getPersonalTemplates([]))
      const newQuery: ReturnQueryType = {
        ...prevQuery,
        data: {
          ...prevQuery.data,
          data: prevQuery.data.data.map((occasion) => ({
            ...occasion,
            message_templates_user: occasion.message_templates_user.filter((template) => template.id !== variables.id),
          })),
        },
      }
      queryClient.setQueryData(personalMessageKeys.getPersonalTemplates([]), newQuery)
      queryClient.invalidateQueries({
        queryKey: personalMessageKeys.allTemplates(),
      })
    },
    onError: (err) => {
      enqueueErrorSnackbar(err, { DEFAULT_ERROR: t('personal_messages.templates.delete_template.fail') })
    },
  })
}
