import isEmpty from 'lodash/isEmpty'
import qs from 'query-string'
import { getRequest } from 'utils/api/apiRequest'
import { WithPagination } from 'utils/api/pagination'
import { CatalogProduct } from 'utils/api/Products/CatalogProduct'

export const fetchProductsInCategory = (id: number, search: string, nextId = 1) => {
  const paramsUrl = qs.parse(search.replace('?', ''))
  let params = ''
  if (nextId) {
    params += `&limit=15&page=${nextId}`
  }
  if (paramsUrl?.enough_points === 'true' || paramsUrl?.enough_points === '1') {
    params += `&orderNow=1`
  }
  if (!isEmpty(paramsUrl?.searchWidget)) {
    params += `&search=${paramsUrl?.searchWidget}`
  }
  if (!isEmpty(paramsUrl?.lang)) {
    params += `&lang=${paramsUrl?.lang}`
  }
  const sortParam = Object.keys(paramsUrl).find((p) => p.startsWith('sort'))
  if (sortParam) {
    params += `&${sortParam}=${paramsUrl?.[sortParam]}`
  }
  const filterParams = Object.keys(paramsUrl).filter((p) => p.startsWith('filter'))
  if (!isEmpty(filterParams)) {
    filterParams.forEach((param) => {
      // |ep special filter type - filtering products that the user can afford
      params += `&${param.replace('|ep', '|lt')}=${paramsUrl?.[param]}`
    })
  }
  return getRequest<WithPagination<CatalogProduct>>(`/redemption-site/products/category/${id}?${params}`)
}
