import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { Theme } from 'theme/styles'
import { getResponsiveSize } from 'theme/styles.utils'
import { TranslationsKeys } from 'utils/createTranslationKey'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  authMenuItem: {
    padding: theme.spacing(2.25, 0),
    width: '100%',
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    transition: '0.3s all',
    cursor: 'pointer',
    '&:last-child': {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  authMenuItemText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: theme.typography.h2.fontSize,
    textTransform: 'uppercase',
    marginTop: theme.spacing(0.125),
  },
}))

type Props = {
  icon: ReactNode
  text: TranslationsKeys
  link?: string
}

export const AuthMenuItem = ({ icon, text, link = '#' }: Props) => {
  const { authMenuItem, authMenuItemText } = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const currentRoute = location.pathname === link
  const color = (t: Theme) => (currentRoute ? t.palette.primary.main : t.palette.text.primary)

  return (
    <Grid
      item
      className={authMenuItem}
      data-cy={t(text)}
      onClick={() => {
        if (currentRoute) return
        if (link.includes('http')) {
          window.open(link)
        } else {
          navigate(link)
          window.scrollTo(0, 0)
        }
      }}
    >
      <Grid container alignItems="center" spacing={1}>
        {icon && (
          <Grid
            item
            sx={(t) => ({
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: getResponsiveSize(72),
              height: getResponsiveSize(32),
              marginTop: -t.spacing(0.25),
              color,
            })}
          >
            {icon}
          </Grid>
        )}
        <Grid item xs className={authMenuItemText} sx={{ color, fontWeight: currentRoute ? 900 : 700 }}>
          {t(text)}
        </Grid>
      </Grid>
    </Grid>
  )
}
