import { paperClasses, useMediaQuery, useTheme } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { ProductCardMedia } from 'pages/ProductCard/components/Media/ProductCardMedia'
import { ProductName } from 'pages/ProductCard/components/ProductName'
import { fontFamilies } from 'theme/styles.utils'
import { CatalogProduct } from 'utils/api/Products/CatalogProduct'
import { getObjectTranslation } from 'utils/getObjectTranslation'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { ButtonCloseDialog } from 'utils/hooks/useLogout'
import { safeHtml } from 'utils/safeHtml'
import { inside } from 'utils/styles'

interface Props {
  open: boolean
  onClose: () => void
  catalogProduct?: CatalogProduct
  initSlide?: number
  selectSlide?: (i: number) => void
}

export const ProductPresentation = ({ open, onClose, selectSlide, initSlide, catalogProduct }: Props) => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Dialog
      open={open}
      fullScreen
      maxWidth="lg"
      onClose={onClose}
      keepMounted={false}
      TransitionProps={{
        unmountOnExit: true,
      }}
      sx={(_) => ({
        [inside(paperClasses.root)]: {
          padding: mobile ? 2 : 4,
          boxSizing: 'content-box',
          ...valueOrUndefined(mobile, {
            display: 'flex',
            alignItems: 'center',
          }),
        },
      })}
    >
      <ButtonCloseDialog onClose={onClose} styles={{ position: 'fixed', transform: undefined, right: 10, top: 10 }} />
      <Grid container spacing={2} py={mobile ? 2 : 4}>
        <Grid item xs={12}>
          <ProductCardMedia
            catalogProduct={catalogProduct}
            selectSlide={selectSlide}
            initSlide={initSlide}
            showThumbs={false}
          />
        </Grid>
        <Grid item xs={12}>
          <ProductName product={catalogProduct?.product} />
        </Grid>
        <Grid item xs={12}>
          <Typography
            fontFamily={fontFamilies.default}
            variant="body2"
            fontSize={(t) => t.typography.h2.fontSize}
            dangerouslySetInnerHTML={safeHtml(
              getObjectTranslation(catalogProduct?.product?.translation)?.description ||
                catalogProduct?.product?.navision_description
            )}
          />
          <Typography
            fontFamily={fontFamilies.default}
            variant="body2"
            fontSize={(t) => t.typography.h2.fontSize}
            dangerouslySetInnerHTML={safeHtml(getObjectTranslation(catalogProduct?.product?.translation)?.extra)}
          />
        </Grid>
      </Grid>
    </Dialog>
  )
}
