import { useMediaQuery, useTheme } from '@mui/material'

export const useIsSmallMobile = () => {
  return useMediaQuery(useTheme().breakpoints.down('sm'))
}

export const useIsMobile = () => {
  return useMediaQuery(useTheme().breakpoints.down('md'))
}

export const useIsTablet = () => {
  return useMediaQuery(useTheme().breakpoints.down('lg'))
}
