import { DeliveryVariant, OneOfDeliveryVariant, Option } from 'pages/CreateOrder/deliveryOptions'
import { DeliveryTypes } from 'shared/ShoppingCart/api'
import { DeliveryTypeByCountry } from 'utils/api/DeliveryType/getDeliveryTypes'
import { User } from 'utils/hooks/userType'

export const filteringEnabledOptions = (
  allOptions: Option[],
  user: User,
  deliveryType: DeliveryTypes,
  countryDeliveryVariant: DeliveryTypeByCountry[],
  arePresetAddresses: boolean,
  selectedCountryId: number,
  enabledBpost: boolean
) => {
  const availableOptionInCountry: OneOfDeliveryVariant[] = countryDeliveryVariant.map(
    (option: DeliveryTypeByCountry) => option.id
  )
  const homeAddress = user.profile.addresses.find(
    (address) => address.type === 1 && address.country?.id === selectedCountryId
  )
  const workAddress = user.profile.addresses.find(
    (address) => address.type === 2 && address.country?.id === selectedCountryId
  )

  if (deliveryType === DeliveryTypes.digital) {
    return allOptions.filter((option) => option.value === DeliveryVariant.digital)
  }
  return allOptions
    .filter((option) => availableOptionInCountry.includes(option.value))
    .filter(
      (option) =>
        option.value !== DeliveryVariant.homeAddress || (option.value === DeliveryVariant.homeAddress && !!homeAddress)
    )
    .filter(
      (option) =>
        option.value !== DeliveryVariant.companyAddress ||
        (option.value === DeliveryVariant.companyAddress && !!workAddress)
    )
    .filter(
      (option) =>
        option.value !== DeliveryVariant.preset || (option.value === DeliveryVariant.preset && arePresetAddresses)
    )
    .filter(
      (option) =>
        (option.value !== DeliveryVariant.bpostParcelLocker && option.value !== DeliveryVariant.bpostCollectionPoint) ||
        ((option.value === DeliveryVariant.bpostParcelLocker ||
          option.value === DeliveryVariant.bpostCollectionPoint) &&
          enabledBpost)
    )
}
