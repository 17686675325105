import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { client } from 'utils/api/apiClient'

export const getRequest = <T>(route: string, config: AxiosRequestConfig<any> = {}) => client.get<T>(route, config)

export const postRequest = <T = any, R = AxiosResponse<T>, D = any>(route: string, params: any) =>
  client.post<T, R, D>(route, params)

export const putRequest = (route: string, params: any) => client.put(route, params)

export const patchRequest = <T, K = any>(route: string, params: K) => client.patch<T>(route, params)

export const deleteRequest = <T>(route: string, params?: any) => client.delete<T>(route, params)
