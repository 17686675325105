import { getRequest } from 'utils/api/apiRequest'
import { WithPagination } from 'utils/api/pagination'
import { Product } from 'utils/api/Products/CatalogProduct'

export type VoucherOrder = {
  order: {
    created_at: string
    id: number
  }
  product: Product
  voucher: {
    download_token: string
  }
}

export const getVoucherOrders = (limit = '3') =>
  getRequest<WithPagination<VoucherOrder>>(`/voucher-orders?limit=${limit}`)
