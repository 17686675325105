import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import isEmpty from 'lodash/isEmpty'
import { useSelectShoppingCart } from 'shared/ShoppingCart/slice'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  error: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.error.main,
  },
  divider: {
    marginTop: theme.spacing(2),
  },
}))

export const ShoppingCartWarnings = () => {
  const { divider, error } = useStyles()
  const { t } = useTranslation()
  const { data: shoppingCartItems } = useSelectShoppingCart()

  return (
    <Collapse in={!isEmpty(shoppingCartItems?.data?.warnings)} unmountOnExit mountOnEnter>
      {shoppingCartItems?.data?.warnings?.map((warn) => (
        <Fragment key={warn}>
          <Typography variant="h2" align="center" className={error}>
            {t(warn, { minimalOrderPoints: shoppingCartItems?.data?.minimal_order_value_in_points })}
          </Typography>
          <Divider className={divider} />
        </Fragment>
      ))}
    </Collapse>
  )
}
