import { FunctionComponent, JSX, ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { badgeClasses, SvgIconProps } from '@mui/material'
import Badge from '@mui/material/Badge'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Zoom from '@mui/material/Zoom'
import clsx from 'clsx'
import { Delivery, Gift, Heart, Home, Menu, MyProfile, ShoppingBasket } from 'components/ArteelIcons'
import { IconActive } from 'components/ArteelIcons/IconActive'
import { WriteMessage } from 'components/ArteelIcons/WriteMessage'
import { MAIN_DOMAIN, PROTOCOL } from 'config/env'
import isEmpty from 'lodash/isEmpty'
import { useGetPersonalMessage } from 'pages/PersonalMessages/api/useGetPersonalMessages'
import { useSelectShoppingCart } from 'shared/ShoppingCart/slice'
import { useSubdomainInfo } from 'shared/Site/api'
import { SiteType } from 'shared/Site/siteType'
import { useSelectWishlist } from 'shared/Wishlist/slice'
import { SelectLanguage } from 'theme/SelectLanguage'
import { getResponsiveSize } from 'theme/styles.utils'
import { useLanguages } from 'utils/api/Languages/useLanguages'
import { Role } from 'utils/api/User'
import { TranslationsKeys } from 'utils/createTranslationKey'
import { dataCy } from 'utils/cypressUtils'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { useIsTablet } from 'utils/hooks/useBreakpoints'
import { useCurrentUser, useIsStandardUser } from 'utils/hooks/useCurrentUser'
import { useIsCypress } from 'utils/hooks/useIsCypress'
import { useIsLuckyBirdUser } from 'utils/hooks/useIsLuckyBirdUser'
import { useLogout } from 'utils/hooks/useLogout'
import { useLBCodeStatus } from 'utils/hooks/useLuckyBirdCodeStatus'
import { useSocialWall } from 'utils/hooks/useSocialWall'
import { inside } from 'utils/styles'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  shoppingCartInactive: {
    '& path': {
      stroke: theme.palette.primary.main,
    },
  },
  menuIcon: {
    '&.MuiSvgIcon-fontSizeLarge': {
      fontSize: getResponsiveSize(1.7, 'rem'),
      color: theme.palette.primary.main,
    },
  },
}))

type MenuType = {
  Icon?: FunctionComponent<SvgIconProps & IconActive>
  onClick?: () => void
  siteType: SiteType[]
  className?: string
  badge?: number
  tooltip?: TranslationsKeys
  component?: JSX.Element
  hidden?: boolean
  dataCy?: string
}

export const MenuWeb = () => {
  const navigate = useNavigate()
  const user = useCurrentUser()
  const { data: shoppingCartItems } = useSelectShoppingCart()
  const { data: wishlistItems } = useSelectWishlist()
  const { DialogLogout } = useLogout()
  const { data: subdomainInfo } = useSubdomainInfo()
  const isTablet = useIsTablet()
  const { data: languages, isFetched } = useLanguages()
  const { isSocialWallActive } = useSocialWall()
  const { isLuckyBird } = useIsLuckyBirdUser()
  const isStandardUser = useIsStandardUser()
  const { data: personalMessagesData } = useGetPersonalMessage()
  const statusLB = useLBCodeStatus()
  const cmsUrl = subdomainInfo?.data?.subscriber?.sites?.find((site) => site.type === 2)?.subdomain
  const quantityArray = shoppingCartItems?.data?.items?.map((value) => value.quantity)
  const { isCypress } = useIsCypress()

  const isAuthor = user?.roles?.includes(Role.ROLE_AUTHOR)
  const isSingleLanguage = languages?.data?.length < 2

  const menu: MenuType[] = [
    {
      Icon: Home,
      onClick: () =>
        window.location.assign(`${PROTOCOL}://${cmsUrl}.${MAIN_DOMAIN}${isSocialWallActive ? '/social-wall' : ''}`),
      siteType: [SiteType.project],
      tooltip: 'app.tooltip.home' as const,
      dataCy: dataCy('menu-topbar-home'),
    },
    {
      Icon: Home,
      onClick: () => navigate(isSocialWallActive ? '/social-wall' : '/'),
      siteType: [SiteType.cms],
      tooltip: 'app.tooltip.home' as const,
      dataCy: dataCy('menu-topbar-home'),
    },
    {
      Icon: Home,
      onClick: () => navigate('/luckybird'),
      siteType: [SiteType.luckyBird],
      tooltip: 'app.tooltip.home' as const,
      dataCy: dataCy('menu-topbar-home'),
    },
    {
      Icon: Heart,
      onClick: () => navigate('/wishlist'),
      siteType: [SiteType.project],
      badge: wishlistItems?.data?.items ?? 0,
      tooltip: 'app.tooltip.wishlist' as const,
      dataCy: dataCy('menu-topbar-wishlist'),
    },
    {
      Icon: Gift,
      onClick: () => navigate('/catalog'),
      siteType: [SiteType.project, SiteType.luckyBird],
      tooltip: 'app.tooltip.catalog' as const,
      hidden: statusLB('fullyRedeemed'),
      dataCy: dataCy('menu-topbar-catalog'),
    },
    {
      Icon: ShoppingBasket,
      onClick: () => navigate('/shopping-cart'),
      siteType: [SiteType.project, SiteType.luckyBird],
      badge: isEmpty(quantityArray) ? 0 : quantityArray.reduce((prev, curr) => prev + curr) || 0,
      tooltip: 'app.tooltip.shopping_cart' as const,
      hidden: statusLB('fullyRedeemed'),
      dataCy: dataCy('menu-topbar-shopping-cart'),
    },
    {
      Icon: MyProfile,
      onClick: () => navigate('/my-account'),
      siteType: [SiteType.cms],
      tooltip: 'app.tooltip.my_account' as const,
      dataCy: dataCy('menu-topbar-my-account'),
    },
    {
      Icon: WriteMessage,
      onClick: () => navigate('/personal-messages'),
      siteType: [SiteType.cms],
      tooltip: 'app.tooltip.personal_messages' as const,
      badge: personalMessagesData?.data?.items,
      hidden: !isAuthor,
      dataCy: dataCy('menu-topbar-personal-messages'),
    },
    {
      siteType: [SiteType.cms, SiteType.luckyBird, SiteType.project],
      hidden: isSingleLanguage,
      component: (
        <Grid item>
          <SelectLanguage />
        </Grid>
      ),
    },
    {
      Icon: Menu,
      onClick: () => navigate('/menu'),
      siteType: [SiteType.cms, SiteType.luckyBird],
      tooltip: 'app.tooltip.menu' as const,
      dataCy: dataCy('menu-topbar-open'),
    },
    {
      Icon: Delivery,
      onClick: () => navigate('/create-order'),
      siteType: isCypress ? [SiteType.project, SiteType.luckyBird] : [],
      tooltip: 'app.tooltip.menu' as const,
      dataCy: dataCy('menu-topbar-create-order'),
      hidden: !isCypress,
    },
  ].filter((item) => !item.hidden)

  return (
    <Grid
      container
      alignItems="center"
      justifyContent={isTablet ? 'space-evenly' : 'end'}
      spacing={valueOrUndefined(!isTablet, 2)}
    >
      {menu
        .filter(({ siteType }) => {
          if (siteType.includes(SiteType.luckyBird) && isLuckyBird) {
            return true
          }
          return siteType.includes(subdomainInfo?.data?.type) && isStandardUser
        })
        .map((menuItem, index) => (
          <MenuItem menuItem={menuItem} key={`menu-${index}`} />
        ))}
      <DialogLogout />
    </Grid>
  )
}

const BadgeWrapper = ({ badge, children }: { badge?: number; children: ReactElement }) => {
  return useMemo(
    () =>
      !!badge || badge === 0 ? (
        <Zoom in>
          <Badge
            badgeContent={badge}
            color="primary"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            max={10}
            sx={{
              [inside(badgeClasses.standard)]: {
                fontSize: (theme) => theme.typography.h5.fontSize,
              },
            }}
          >
            {children}
          </Badge>
        </Zoom>
      ) : (
        children
      ),
    [badge]
  )
}

const TooltipWrapper = ({ tooltip, children }: { tooltip?: TranslationsKeys; children: ReactElement }) => {
  const { t } = useTranslation()
  return tooltip ? <Tooltip title={t(tooltip)}>{children}</Tooltip> : children
}

const MenuItem = ({
  menuItem: { Icon, onClick, className, badge, tooltip, component, dataCy },
}: {
  menuItem: MenuType
}) => {
  const { menuIcon } = useStyles()

  if (component) {
    return component
  }

  return (
    <Grid item>
      <TooltipWrapper tooltip={tooltip}>
        <IconButton onClick={onClick} size="large" data-cy={dataCy}>
          <BadgeWrapper badge={badge}>
            <Icon color="primary" fontSize="large" active className={clsx([menuIcon, className])} />
          </BadgeWrapper>
        </IconButton>
      </TooltipWrapper>
    </Grid>
  )
}
