import { JSX } from 'react'
import { useTranslation } from 'react-i18next'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Tooltip from '@mui/material/Tooltip'
import { Language } from 'components/ArteelIcons/Language'
import { CustomPopover } from 'components/CustomPopover/CustomPopover'
import { LanguageName } from 'components/LanguageName'
import { useLanguages } from 'utils/api/Languages/useLanguages'
import { Z_INDEX } from 'utils/styles/zIndex'

interface Props {
  CustomButton?: (props: { open: boolean }) => JSX.Element
}
export const SelectLanguage = ({ CustomButton }: Props) => {
  const { data: languages } = useLanguages()
  const { t, i18n } = useTranslation()

  return (
    <CustomPopover
      zIndex={Z_INDEX.DRAWER + 1}
      button={
        CustomButton
          ? CustomButton
          : ({ open }) => (
              <Tooltip title={t('profile.change_language.select_language')}>
                <IconButton
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  size="large"
                >
                  <Language color="primary" fontSize="large" />
                </IconButton>
              </Tooltip>
            )
      }
      content={({ closeHandler }) => (
        <MenuList>
          {languages?.data?.map((l) => (
            <MenuItem
              key={l.language.code}
              onClick={() => {
                closeHandler()
                i18n.changeLanguage(l.language.code)
              }}
              selected={i18n.language === l.language.code}
            >
              <LanguageName code={l.language.code} />
            </MenuItem>
          ))}
        </MenuList>
      )}
    />
  )
}
