import { getRequest } from 'utils/api/apiRequest'
import { Profile } from 'utils/api/User'

export type RecognitionProfile = Pick<Profile, 'full_name' | 'job_title' | 'department_path' | 'avatar'> & {
  avatar_relative_path?: string
  user: {
    id: number
  }
}

export type GetRecognitionProfilesResponse = { data: RecognitionProfile[] }

export const getRecognitionProfiles = () =>
  getRequest<GetRecognitionProfilesResponse>(`/social-wall/profiles?sort[full_name]=asc`)
