import { JSX } from 'react'
import NoMatch from 'components/NoMatch'
import { useIsLuckyBirdUser } from 'utils/hooks/useIsLuckyBirdUser'

type Props = {
  children: JSX.Element
}

export const ProtectedLuckyBird = ({ children }: Props) => {
  const { isLuckyBird } = useIsLuckyBirdUser()

  if (isLuckyBird) return children

  return <NoMatch />
}
