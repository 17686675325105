import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import { FormikTextField } from 'components/Formik/FormikTextField'
import { TextMaskInput } from 'components/TextMaskInput/TextMaskInput'
import { FormikValues, useFormikContext } from 'formik'
import { parseError } from 'utils/api/parseError'

type Props = {
  disabled: boolean
  isLoading: boolean
  isError: boolean
  variables?: { code: string }
  error: unknown
}

export const EnterCodeForm = ({ disabled, variables, error, isError, isLoading }: Props) => {
  const { t } = useTranslation()
  const { handleSubmit, values, setFieldValue } = useFormikContext<FormikValues>()
  const parsedError = parseError(error)

  const isVisibleError = (values: FormikValues) =>
    parsedError?.message && variables?.code === values?.code && !isLoading && isError

  useEffect(() => {
    if (!disabled) {
      setFieldValue('code', '')
    }
  }, [disabled])

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} lg={8}>
        <FormikTextField
          center
          name="code"
          inputLabel="auth.activate_account.field.code"
          placeholder="XXXX-XXXX-XX"
          disabled={disabled}
          InputProps={{
            inputComponent: TextMaskInput as any,
            inputProps: {
              onComplete: handleSubmit,
              visibleValue: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={12} lg={8} sx={{ pt: isVisibleError(values) ? 2 : 0 }}>
        <Collapse in={isVisibleError(values)}>
          <Alert severity="error" sx={{ borderRadius: 24 }}>
            {t('auth.activate_account.incorrect_code')}
          </Alert>
        </Collapse>
      </Grid>
    </Grid>
  )
}
