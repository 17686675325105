import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ShoppingCartItems } from 'shared/ShoppingCart/api'
import { shoppingCartQueryKeys } from 'shared/ShoppingCart/slice'
import { patchPersonalizationText } from 'utils/api/ShoppingCart/patchPersonalizationText'

export const usePersonalizationText = () => {
  const queryClient = useQueryClient()

  return useMutation(patchPersonalizationText, {
    onSuccess: (data) => {
      queryClient.setQueryData(shoppingCartQueryKeys.fetchShoppingCart(), (oldData: ShoppingCartItems) => ({
        ...oldData,
        items: oldData.items.map((item) => ({
          ...item,
          personalization_texts: data?.data.items.find((i) => i.id === item.id)?.personalization_texts,
        })),
      }))
    },
  })
}
