import SvgIcon from '@mui/material/SvgIcon'

export const LuckyBirdGlobalLogo = () => {
  const styleGrey = { fill: '#3C3C3B' }
  const styleRed = { fill: '#E52149' }
  return (
    <SvgIcon
      viewBox="0 0 681 148.5"
      style={{
        height: 'auto',
        maxWidth: '100%',
        width: '400px',
      }}
    >
      <g xmlns="http://www.w3.org/2000/svg">
        <g>
          <g>
            <path style={styleGrey} d="M125.9,118.2V28.7h18v73.7h30.2v15.8H125.9z" />
            <path
              style={styleGrey}
              d="M221.3,118.2v-8.2c-3.5,5-10.6,9.8-19.1,9.8c-12.1,0-17.5-6.6-17.5-17.6V53.4h16.1v42.8     c0,6.8,3.2,9.4,8.6,9.4c5.4,0,9.7-3.9,11.8-7.8V53.4h16.2v64.9H221.3z"
            />
            <path
              style={styleGrey}
              d="M248.7,85.7c0-19.7,12-34,29.8-34c10.2,0,17.1,4.2,20.7,8.7l-7.8,11.1c-3-3.8-6.4-5.6-11-5.6     c-9.3,0-15,8.3-15,19.7s5.8,19.9,15,19.9c4.6,0,8.1-2,11-5.8l7.8,11.3c-3.6,4.7-10.5,8.7-20.7,8.7     C260.7,119.9,248.7,105.6,248.7,85.7z"
            />
            <path
              style={styleGrey}
              d="M345.7,118.2l-13.6-25.5l-7,9.7v15.8H309V28.7h16.1v54l20-29.3H364l-20.7,29.1l21.4,35.7H345.7z"
            />
            <path
              style={styleGrey}
              d="M372.2,136.1l6.3-12.4c4.3,5,10.7,7.1,16.5,7.1c8.9,0,13.8-5.5,13.8-14.8v-8.6c-3.5,5.1-10.6,9.8-19.1,9.8     c-12.1,0-17.5-6.6-17.5-17.6V53.4h16.1v40.3c0,6.8,3.2,9.4,8.6,9.4c5.4,0,9.7-3.9,11.8-7.8V53.4h16.2v62.7     c0,17.9-11,28.5-29.3,28.5C386,144.6,378.1,141.7,372.2,136.1z"
            />
            <path
              style={styleGrey}
              d="M440.2,118.2V28.7h37.2c16.1,0,23.8,10.6,23.8,22.8c0,11.1-6.3,18.5-14.5,20.5c9.1,1.5,16,10.9,16,21.9     c0,13.7-7.7,24.3-24,24.3H440.2z M482.8,54.6c0-5.9-3.2-10.1-9.7-10.1h-14.9v20.3h14.9C479.6,64.8,482.8,60.8,482.8,54.6z      M484.3,91.5c0-6.2-3.6-11-10.5-11h-15.6v21.9h15.6C480.5,102.4,484.3,98.5,484.3,91.5z"
            />
            <path
              style={styleGrey}
              d="M513,36.5c0-5.4,4.4-9.8,9.7-9.8c5.4,0,9.8,4.4,9.8,9.8s-4.4,9.7-9.8,9.7C517.5,46.1,513,41.8,513,36.5z      M514.6,118.2V53.4h16.1v64.9H514.6z"
            />
            <path
              style={styleGrey}
              d="M545.3,118.2V53.4h16.1v8.9c3.6-5.8,10.9-10.5,17.7-10.5v15.8c-1.1-0.3-2.4-0.5-3.8-0.5     c-5.1,0-11.3,3.5-14,7.9v43.2H545.3z"
            />
            <path
              style={styleGrey}
              d="M625.6,118.2v-8.2c-4,6.4-10.2,9.8-16.5,9.8c-14.6,0-24.2-12.8-24.2-34.1c0-20.7,9.5-34,24.2-34     c6.3,0,12.6,3.1,16.5,9.9v-33h16.1v89.6H625.6z M625.6,98V73.5c-2.1-4.2-6.8-7.5-11.3-7.5c-8.5,0-12.8,8.5-12.8,19.7     c0,11.4,4.3,19.9,12.8,19.9C618.7,105.6,623.4,102.3,625.6,98z"
            />
            <path
              style={styleRed}
              d="M655.7,109.2c0-5.5,4.7-10.3,10.3-10.3s10.5,4.8,10.5,10.3c0,5.6-4.8,10.5-10.5,10.5     S655.7,114.9,655.7,109.2z"
            />
          </g>
        </g>
        <path
          style={styleRed}
          d="M71.5,29.2H59.9l15.6-15.6c2.3-2.3,2.3-6.1,0-8.5c-2.3-2.3-6.1-2.3-8.5,0L47.1,25L35.7,13.6   c-2.3-2.3-6.1-2.3-8.5,0c-2.3,2.3-2.3,6.1,0,8.5l7.2,7.2H22.5c-11,0-20,9-20,20v49c0,11,9,20,20,20h49c11,0,20-9,20-20v-49   C91.5,38.2,82.6,29.2,71.5,29.2z M79.5,49.2v18.5H53V41.2h18.5C75.9,41.2,79.5,44.8,79.5,49.2z M22.5,41.2H41v26.5H14.5V49.2   C14.5,44.8,18.1,41.2,22.5,41.2z M14.5,98.2V79.7H41v26.5H22.5C18.1,106.2,14.5,102.7,14.5,98.2z M71.5,106.2H53V79.7h26.5v18.5   C79.5,102.7,75.9,106.2,71.5,106.2z"
        />
      </g>
    </SvgIcon>
  )
}
