import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Image } from 'components/Image/Image'
import { Buttons } from 'pages/ShoppingCart/Buttons'
import { PersonalizationTexts } from 'pages/ShoppingCart/PersonalizationTexts'
import { ShoppingCartItem } from 'shared/ShoppingCart/api'
import { fontFamilies, getResponsiveSize } from 'theme/styles.utils'
import { dataCy } from 'utils/cypressUtils'
import { displayPoints } from 'utils/displayPoints'
import { getProductName } from 'utils/getProductName'
import { useIsMobile } from 'utils/hooks/useBreakpoints'
import { useIsLuckyBirdUser } from 'utils/hooks/useIsLuckyBirdUser'
import { usePointsName } from 'utils/hooks/usePointsName'
import { makeStyles } from 'utils/styles/makeStyles'

import { AmountItem } from './AmountItem'

const useStyles = makeStyles((theme) => ({
  imageWrapper: {
    width: getResponsiveSize(220),
    height: getResponsiveSize(220),
    display: 'flex',
  },
  brand: {
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.2,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.h3.fontSize,
    },
  },
  points: {
    position: 'relative',
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.2,
    [theme.breakpoints.down('sm')]: {
      fontSize: getResponsiveSize(0.8, 'rem'),
      fontFamily: fontFamilies.default,
      lineHeight: 1.8,
      whiteSpace: 'nowrap',
    },
  },
  name: {
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[600],
    lineHeight: 1.2,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.h3.fontSize,
    },
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      order: 2,
    },
  },
}))

type Props = {
  item: ShoppingCartItem
  disabledChange?: boolean
}

export const CartItem = ({ item, disabledChange }: Props) => {
  const { imageWrapper, brand, name, imageContainer, points } = useStyles()
  const isMobile = useIsMobile()
  const pointsName = usePointsName(item.points)
  const { t } = useTranslation()
  const { isSingleLogic } = useIsLuckyBirdUser()

  const mainMedia = item.catalog_product.product.media.find((m) => m.main)
  const firstMedia = item.catalog_product.product?.media?.length > 0 ? item.catalog_product.product.media[0] : undefined
  const thumbMedia = mainMedia || firstMedia

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} md="auto" className={imageContainer}>
        <div className={imageWrapper}>
          <Image src={thumbMedia?.path} alt={getProductName(item.catalog_product.product)} />
        </div>
      </Grid>
      <Grid item xs={12} md>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <Grid container sx={(t) => (!isMobile ? { paddingLeft: t.spacing(3) } : undefined)}>
              <Grid item xs={12} className={brand}>
                {item.catalog_product.product?.brand?.name || ''}
              </Grid>
              <Grid item xs={12} className={name}>
                {getProductName(item.catalog_product.product)}
              </Grid>
              {item.product.out_of_stock && (
                <Grid item xs={12} className={name}>
                  <Typography color="error" fontWeight="bold" variant="h4">
                    {t('product.is_out_of_stock')}
                  </Typography>
                </Grid>
              )}
              {!isMobile && <Buttons item={item} disabledChange={disabledChange} />}
            </Grid>
          </Grid>
          {!isMobile && <AmountItem disabledChange={disabledChange} item={item} />}
          {!isSingleLogic && (
            <Grid item xs="auto" data-cy={dataCy('wrapper-points-amount')}>
              <Typography align="right" variant="h2" className={points}>
                {displayPoints(item.points)} {pointsName}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      {isMobile && <AmountItem disabledChange={disabledChange} item={item} />}
      {isMobile && <Buttons item={item} disabledChange={disabledChange} />}
      <PersonalizationTexts item={item} disabledChange={disabledChange} />
    </Grid>
  )
}
