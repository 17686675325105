import { FunctionComponent } from 'react'
import { ResponsivePaper } from 'components/ResponsivePaper'
import { CmsContent } from 'pages/CMS/CMS.types'
import { LinkBoxLink } from 'pages/CMS/components/LinkBox'
import { CmsNotification } from 'utils/api/CMSNotifications/fetchNotifications'
import { WithPagination } from 'utils/api/pagination'

import { contentBoxTypes } from '../../constants'
import { HtmlContent } from './HtmlContent'
import { ImageContent } from './ImageContent'
import { LogicContent } from './LogicContent'
import { NotificationsBox } from './NotificationsBox'

type Props = {
  content: CmsContent
  notifications?: WithPagination<CmsNotification>
  sectionName: string
}
export const ContentBox: FunctionComponent<Props> = ({ content }) => {
  if (!content) return null
  switch (content?.type) {
    case contentBoxTypes.logic_box:
      return <LogicContent content={content} />
    case contentBoxTypes.link_box:
      return <LinkBoxLink content={content} />
    case contentBoxTypes.notification_box:
      return <NotificationsBox />
    case contentBoxTypes.html_box:
      return (
        <ResponsivePaper>
          <HtmlContent content={content} />
        </ResponsivePaper>
      )
    case contentBoxTypes.announcement_box:
      return (
        <ResponsivePaper>
          <HtmlContent content={content} showTitle />
        </ResponsivePaper>
      )
    case contentBoxTypes.image_box:
      return (
        <ResponsivePaper>
          <ImageContent content={content} />
        </ResponsivePaper>
      )
    default:
      throw Error('Unsupported CMS content')
  }
}
