import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'

import { IconActive } from './IconActive'

export const Gift = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 30 30">
    <g xmlns="http://www.w3.org/2000/svg">
      <path d="M14,29.7c-0.2,0-0.5,0-0.6,0c-2.4,0-4.8,0-7.2,0c-0.3,0-0.6,0-1,0c-0.2,0-0.5-0.2-0.6-0.2   c-0.6-0.2-1-0.6-1.1-1.4c-0.2-0.3-0.2-0.6-0.2-1c0-3.5,0-7.2,0-10.8c0-0.2,0-0.2,0-0.2c3.7,0,7.2,0,10.8,0   C14,16.4,14.2,29.5,14,29.7z" />
      <path d="M15.9,29.5c0-4.5,0-8.9,0-13.2c3.7,0,7.2,0,10.8,0v0.2v0.2c0,3.3,0,6.9,0,10.4c0,0.3,0,0.6,0,1   c0,0.2-0.2,0.5-0.2,0.6c-0.2,0.5-0.6,0.8-1.1,1c-0.3,0.2-0.6,0.2-1,0.2c-2.4,0-4.8,0-7.2,0C16.7,29.7,16.4,29.7,15.9,29.5   C15.9,29.7,15.9,29.5,15.9,29.5z" />
      <path d="M15,4.8c0-0.2,0.2-0.2,0.2-0.2c0.5-1.1,1-2.1,1.9-2.7c0.8-0.8,1.8-1.1,2.9-1.4c1-0.3,2.1-0.3,3,0.2   c1.4,0.6,2.2,1.6,2.7,3c0.2,0.8,0.2,1.8-0.3,2.6c-0.6,1.1-1.6,1.8-2.9,2.1c-0.3,0.2-0.5,0.2-0.8,0.2c-1.1,0-2.2,0.2-3.3,0.2   c-1.6,0-3.3,0-4.9,0c-0.5,0-0.8,0-1.3,0c-1,0-2.1-0.2-3-0.2c-0.3,0-0.6,0-0.8-0.2C7.8,8.2,7.5,8.2,7.2,8C6,7.5,5.4,7,4.9,6.1   C4.6,5.4,4.4,4.8,4.6,4.2c0.2-0.5,0.2-1,0.5-1.4C5.7,1.6,6.7,0.8,8,0.5c1-0.3,2.1-0.2,3,0.2c1.8,0.6,3,1.8,3.8,3.3   C14.8,4.3,15,4.5,15,4.8L15,4.8z M13.7,7c0-0.2-0.2-0.3-0.2-0.5c-0.2-0.6-0.3-1.4-0.6-1.9c-0.6-1-1.4-1.8-2.4-2.2   C9.9,2.1,9.1,2.1,8.4,2.3C7.5,2.4,6.8,3,6.5,4C6,4.5,6,5.1,6.5,5.6c0.2,0.5,0.6,0.6,1,1c0.3,0.2,0.6,0.2,1,0.3   c0.8,0,1.8,0.2,2.4,0.2c0.8,0,1.4,0,2.2,0C13.2,7,13.5,7,13.7,7z M16.4,6.9c0.2,0,0.2,0,0.2,0c0.5,0,0.8,0,1.1,0c0.6,0,1.1,0,1.9,0   c0.6,0,1.3-0.2,1.9-0.2c0.2,0,0.3,0,0.5-0.2c0.6-0.2,1.1-0.6,1.6-1.4c0.2-0.5,0.3-0.8,0.2-1.3c-0.3-1-0.8-1.6-1.9-1.9   c-0.5,0-1.1,0-1.8,0.3c-1,0.3-1.8,0.8-2.4,1.8c-0.5,0.6-0.8,1.1-1,2.1C16.4,6.2,16.4,6.6,16.4,6.9z" />
      <path d="M15.9,9.4C15.9,9.4,16.1,9.4,15.9,9.4c3.7,0,7.2,0,10.7,0c0.5,0,0.8,0.2,1.1,0.5c0.2,0.2,0.3,0.6,0.3,1   c0,0.8,0,1.8,0,2.4c0,0.8-0.6,1.4-1.3,1.4c-0.2,0-0.2,0-0.2,0c-3.5,0-7,0-10.5,0c-0.2,0-0.2,0-0.2,0C15.9,12.9,15.9,11.2,15.9,9.4z   " />
      <path d="M14.2,9.4c0,1.8,0,3.5,0,5.1c-0.2,0-0.2,0-0.2,0c-2.7,0-5.3,0-7.8,0c-1,0-1.8,0-2.7,0c-0.3,0-0.6-0.2-0.8-0.2   C2.2,14.1,2,13.7,2,13.3c0-0.8,0-1.6,0-2.4c0-0.6,0.6-1.4,1.4-1.4c0.2,0,0.2,0,0.2,0C7.2,9.4,10.5,9.4,14.2,9.4   C14,9.4,14,9.4,14.2,9.4z" />
    </g>
  </SvgIcon>
)
