import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { GetSocialWallPostsResponse } from 'utils/api/SocialWall/getSocialWallPosts'
import socialWallQueryKeys from 'utils/api/SocialWall/socialWallQueryKeys'
import { SocialWallPost } from 'utils/api/SocialWall/types'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'
import { InfinityQueryData } from 'utils/types/InfinityQueryData'

export type PostsQueryData = InfinityQueryData<GetSocialWallPostsResponse>

export const updatePostQueryData =
  (postId: number, callback: (post: SocialWallPost) => Partial<SocialWallPost>) => (old: PostsQueryData) => {
    const pageToUpdate = old.pages.find((page) => page.data.data?.find((post) => post.id === postId))
    if (!pageToUpdate) return old

    const pagePosts = pageToUpdate?.data.data
    const postToUpdate = pagePosts.find((post) => post.id === postId)
    const updatedPost = { ...postToUpdate, ...callback(postToUpdate) }
    const updatedPosts = pagePosts.map((post) => (post.id === postId ? updatedPost : post))
    const updatedPage = { ...pageToUpdate, data: { ...pageToUpdate.data, data: updatedPosts } }

    return {
      ...old,
      pages: old.pages.map((page) => (page.data.page === updatedPage.data.page ? updatedPage : page)),
    }
  }

export const useSWQueryHelpers = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const { search } = useLocation()
  const { id: userId, profile } = useCurrentUser()
  const queryClient = useQueryClient()

  const postsFilteredKey = socialWallQueryKeys.postsFiltered(search)

  const invalidateAllPosts = () => queryClient.invalidateQueries(socialWallQueryKeys.postsAll())
  const invalidateSWUser = () => queryClient.invalidateQueries(socialWallQueryKeys.user(userId))

  return { enqueueSnackbar, t, search, userId, profile, postsFilteredKey, invalidateAllPosts, invalidateSWUser }
}
