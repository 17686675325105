import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { captureMessage } from '@sentry/browser'
import { format } from 'date-fns'
import { ArrowLink } from 'pages/MyAccount/components/Points/ArrowLink'

type Props = {
  points: number
  pointName: string
  date: string
}

export const ShowPointsExpiryDate = ({ date, pointName, points }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const getDate = (dateString: string) => {
    try {
      const dateObject = new Date(dateString?.split('+')[0])
      return format(dateObject, 'MM/yyyy')
    } catch (e) {
      captureMessage(`ShowPointsExpiryDate - invalid format date: ${date}`)
      return '-'
    }
  }
  if (points <= 0 || !date) return null

  return (
    <Typography
      variant="h4"
      align="center"
      sx={{ fontWeight: 'bold', color: (theme) => theme.palette.primary.main, pt: 1 }}
      onClick={() => navigate('/my-account/history-points')}
    >
      <Box
        component="span"
        sx={{
          '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
          },
        }}
      >
        {t('cms.points_expiry_date', {
          date: getDate(date),
          points,
          pointName,
        })}
      </Box>
      <ArrowLink />
    </Typography>
  )
}
