import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { useOktaAuth } from '@okta/okta-react'
import { captureMessage } from '@sentry/browser'
import { Okta } from 'components/ArteelIcons/Okta'

export const LoginOkta = () => {
  const { t } = useTranslation()
  const { oktaAuth } = useOktaAuth()

  const handleOktaLogin = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    try {
      oktaAuth.signInWithRedirect({ originalUri: '/' })
    } catch (e) {
      captureMessage('[Okta login error] ' + JSON.stringify(e))
    }
  }

  return (
    <ListItem disablePadding>
      <ListItemButton onClick={(event) => handleOktaLogin(event)}>
        <ListItemAvatar sx={{ display: 'flex' }}>
          <Okta sx={{ fontSize: 24 }} />
        </ListItemAvatar>
        <ListItemText primary={t('auth.login_with_okta')} />
      </ListItemButton>
    </ListItem>
  )
}
