import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  welcomeText: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.h1.fontSize,
    color: theme.palette.grey[800],
  },
}))
interface WelcomeTextProps {
  onClick?(): void
}
export const WelcomeText = ({ onClick }: WelcomeTextProps) => {
  const { welcomeText } = useStyles()
  const { t } = useTranslation()

  return (
    <Typography variant="h1" align="center" className={welcomeText} onClick={onClick}>
      {t('template.aloha')}, {useCurrentUser()?.profile?.first_name}!
    </Typography>
  )
}
