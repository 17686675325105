import { useQuery } from '@tanstack/react-query'
import { fetchWidgetValues } from 'utils/api/Values/fetchWidgetValues'
import valuesQueryKeys from 'utils/api/Values/valuesQueryKeys'

export const useWidgetValues = ({ dateFrom }: { dateFrom: string }) => {
  return useQuery({
    queryKey: valuesQueryKeys.widgetValuesList(dateFrom),
    queryFn: () => fetchWidgetValues(dateFrom),
  })
}
