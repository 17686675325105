import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Check } from 'components/ArteelIcons'
import { getResponsiveSize } from 'theme/styles.utils'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  headerStep: {
    fontSize: theme.typography.h1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[800],
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    '& svg': {
      fontSize: getResponsiveSize(6, 'rem'),
      color: theme.palette.primary.main,
    },
    marginRight: theme.spacing(2),
  },
  title: {
    fontSize: getResponsiveSize(3, 'rem'),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[800],
  },
  wrapper: {
    marginTop: theme.spacing(2),
  },
  info: {
    fontFamily: 'ProximaNova',
    lineHeight: 1.25,
  },
  csEmail: {
    color: theme.palette.primary.main,
  },
  button: {
    marginBottom: theme.spacing(3),
  },
}))

export const Confirmation = () => {
  const { headerStep, icon, title, wrapper, info, csEmail, button } = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { email } = useParams<{ email: string }>()

  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      })}
    >
      <Box>
        <Grid container justifyContent="flex-end">
          <Grid item xs={12} className={headerStep}>
            <div className={icon}>
              <Check />
            </div>
            {t('create_order.confirmation')}
          </Grid>
        </Grid>
        {csEmail ? (
          <Grid container spacing={4} justifyContent="center" className={wrapper}>
            <Grid item xs={12}>
              <Typography variant="h2" align="center" className={info} sx={{ pb: 2 }}>
                {t('auth.activate_account.confirmation.info1')}
              </Typography>
              <Typography variant="h3" align="center" className={info}>
                {t('auth.activate_account.confirmation.info2')}
              </Typography>
              <Typography variant="h3" align="center" className={info}>
                {t('auth.activate_account.confirmation.info3')}
                <span className={csEmail}>{email}</span>
                {t('auth.activate_account.confirmation.info4')}
              </Typography>
              <Typography variant="h3" align="center" className={info}>
                {t('auth.activate_account.confirmation.info5')}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Typography variant="h1" align="center" className={title}>
            {t('create_order.confirmation.fail')}
          </Typography>
        )}
      </Box>
    </Box>
  )
}
