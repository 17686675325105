import React, { useEffect } from 'react'
import { DepartmentTreeProps } from 'components/DepartmentTree/types'
import debounce from 'lodash/debounce'
import { Department } from 'utils/api/Departments/fetchDepartmentTree'
import { useDepartmentTree } from 'utils/hooks/api/Departments/useDepartmentTree'
import { findInTree, NodeWithAncestors } from 'utils/treeUtils'

const getAllTreeIds = (tree: NodeWithAncestors<Department>[]): string[] => {
  return tree.flatMap((node) => [node.id.toString(), ...getAllTreeIds(node.__children)])
}

export const useDepartments = ({ selected, setSelected }: Pick<DepartmentTreeProps, 'setSelected' | 'selected'>) => {
  const [search, setSearch] = React.useState('')
  const { data = [], isLoading, rawData } = useDepartmentTree({ nameFilter: search })

  const [expanded, setExpanded] = React.useState([])
  const [selectedAncestors, setSelectedAncestors] = React.useState([])
  const expandAll = () => {
    setExpanded(getAllTreeIds(data || []))
  }

  const selectedNode = findInTree({ tree: rawData, findFunction: (value) => value.id.toString() === selected })

  useEffect(() => {
    if (search) {
      if (!selectedNode) {
        setSelected(undefined)
        setSelectedAncestors([])
      }
      expandAll()
    }
  }, [search])

  useEffect(() => {
    if (selected && data) {
      setSelectedAncestors(selectedNode?.ancestors.map((id) => id.toString()) || [])
    }

    if (!selected) {
      setSelectedAncestors([])
    }
  }, [selected, data])

  const handleSetSearch = debounce((newSearch) => setSearch(newSearch), 500)

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    if ((event.target as Element).nodeName === 'INPUT') {
      return
    }

    setExpanded(nodeIds)
  }

  return { handleSetSearch, handleToggle, selectedAncestors, expanded, isLoading, data, selectedNode }
}
export type UseDepartments = ReturnType<typeof useDepartments>
