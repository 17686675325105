import { ReactNode, SyntheticEvent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Autocomplete from '@mui/material/Autocomplete'
import { AutocompleteProps, AutocompleteRenderInputParams } from '@mui/material/Autocomplete/Autocomplete'
import Box from '@mui/material/Box'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import * as Sentry from '@sentry/browser'
import { TextField } from 'components/TextField/TextField'
import { useFormikContext } from 'formik'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import { TranslationsKeys } from 'utils/createTranslationKey'
import { getObjectTranslation } from 'utils/getObjectTranslation'

type ID = {
  id: number | string
}

type ObjectWithName = ID & {
  name: string
}

type ObjectWithNameAndCode = ID & {
  name: string
  code: string
}

type ObjectWithNavisionNameAndCode = ID & {
  navision_name_and_code: string
}

type ObjectWithCode = ID & {
  code: string
}

type ObjectWithFullName = ID & {
  customer_internal_id: string
  full_name?: string
}

type ObjectWithTranslation = ID & {
  translation: {
    [key: string]: {
      [key: string]: string
    }
  }
}

type ObjectWithType = ID & {
  type: {
    name: string
    id: number
  }
}

type ObjectWithNavisionName = ID & {
  navision_name: string
}

type ExtendedObject =
  | ObjectWithName
  | ObjectWithTranslation
  | ObjectWithFullName
  | ObjectWithType
  | ObjectWithNavisionName
  | ObjectWithNavisionNameAndCode
  | ObjectWithNameAndCode
  | ObjectWithCode

export const isObjectWithName = (object: any): object is ObjectWithName =>
  (typeof (object as ObjectWithName)?.id === 'number' || typeof (object as ObjectWithName)?.id === 'string') &&
  typeof (object as ObjectWithName)?.name === 'string' &&
  !object?.country

export const isObjectWithCode = (object: any): object is ObjectWithCode =>
  (typeof (object as ObjectWithCode)?.id === 'number' || typeof (object as ObjectWithCode)?.id === 'string') &&
  typeof (object as ObjectWithCode)?.code === 'string' &&
  !object?.country

export const isObjectWithNameAndCode = (object: any): object is ObjectWithNameAndCode =>
  (typeof (object as ObjectWithNameAndCode)?.id === 'number' ||
    typeof (object as ObjectWithNameAndCode)?.id === 'string') &&
  typeof (object as ObjectWithNameAndCode)?.name === 'string' &&
  typeof (object as ObjectWithNameAndCode)?.code === 'string' &&
  !object?.country

export const isObjectWithFullName = (object: any): object is ObjectWithFullName =>
  (typeof (object as ObjectWithFullName)?.id === 'number' || typeof (object as ObjectWithFullName)?.id === 'string') &&
  (typeof (object as ObjectWithFullName)?.full_name === 'string' ||
    typeof (object as ObjectWithFullName)?.full_name === 'object') &&
  typeof (object as ObjectWithFullName)?.customer_internal_id === 'string' &&
  !object?.country

export const isObjectWithTranslation = (object: any): object is ObjectWithTranslation => {
  const objectWithTranslation = object as ObjectWithTranslation
  if (
    typeof objectWithTranslation !== 'object' ||
    (typeof objectWithTranslation?.translation !== 'object' &&
      !(typeof objectWithTranslation?.id === 'number' || typeof objectWithTranslation?.id === 'string'))
  ) {
    return false
  }
  const translation = getObjectTranslation(objectWithTranslation?.translation)
  return (
    !object?.country &&
    (typeof translation?.name === 'string' ||
      typeof translation?.title === 'string' ||
      typeof translation?.header === 'string' ||
      typeof translation?.heading === 'string')
  )
}

export const isObjectWithType = (object: any): object is ObjectWithType =>
  typeof object?.type?.name === 'string' && !object?.country

export const isObjectWithNavisionName = (object: any): object is ObjectWithNavisionName =>
  typeof object?.navision_name === 'string'

export const isObjectWithNavisionNameAndCode = (object: any): object is ObjectWithNavisionNameAndCode =>
  typeof object?.navision_name_and_code === 'string'

type Props<
  T extends ExtendedObject,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> & {
  name: string
  translationNamespace?: string
  inputLabel?: TranslationsKeys
  textFieldProps?: TextFieldProps
  additionalInputElement?: ReactNode
  renderInput?: (params: AutocompleteRenderInputParams) => ReactNode
  autoSelectSingleValue?: boolean
  disabled?: boolean
}

export const FormikAutocomplete = <
  T extends ExtendedObject,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  translationNamespace,
  inputLabel,
  textFieldProps,
  additionalInputElement,
  options,
  autoSelectSingleValue,
  onChange,
  disabled,
  ...props
}: Props<T, Multiple, DisableClearable, FreeSolo>) => {
  const { t } = useTranslation()
  const { getFieldProps, setFieldValue, getFieldMeta } = useFormikContext()
  const { touched, error } = getFieldMeta(name)
  const { value, onBlur } = getFieldProps(name)
  const label: string = t(inputLabel || (`${translationNamespace}${name}` as TranslationsKeys))
  const errorTextField = !isEmpty(error) && touched
  const helperText = touched && error && error

  const handleChange: Props<T, Multiple, DisableClearable, FreeSolo>['onChange'] = (...args) => {
    if (onChange) {
      return onChange(...args)
    }
    if (!isString(args[1])) {
      setFieldValue(name, args[1])
    }
  }

  useEffect(() => {
    if (autoSelectSingleValue && !value && options?.length === 1) {
      handleChange({} as SyntheticEvent<any, any>, options[0] as any, 'selectOption', undefined)
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [value, options?.length, name, error, autoSelectSingleValue])

  return (
    <Autocomplete
      disabled={disabled}
      autoHighlight
      isOptionEqualToValue={(option, valueToEqual) => option.id === valueToEqual?.id}
      getOptionLabel={getOptionLabel}
      onChange={handleChange}
      onBlur={onBlur}
      value={value}
      options={options}
      renderInput={(params) => (
        <Box sx={{ position: 'relative' }}>
          <TextField
            variant="outlined"
            {...params}
            name={name}
            error={errorTextField}
            helperText={helperText}
            label={label}
            disabled={disabled}
            {...textFieldProps}
            inputProps={{
              autoComplete: Math.random().toString(36).slice(2),
              ...params.inputProps,
              ...textFieldProps?.inputProps,
            }}
          />
          {additionalInputElement}
        </Box>
      )}
      {...props}
    />
  )
}

const getOptionLabel = (option: unknown): string => {
  if (isString(option)) {
    return option || ''
  }
  if (isObjectWithFullName(option)) {
    return !isEmpty(option?.full_name?.replaceAll(' ', ''))
      ? option?.full_name || ''
      : `(${option?.customer_internal_id})`
  }
  if (isObjectWithTranslation(option)) {
    const translation = getObjectTranslation(option?.translation)
    return getTranslationTitle(translation)
  }
  if (isObjectWithNavisionNameAndCode(option)) {
    return option.navision_name_and_code
  }
  if (isObjectWithNameAndCode(option)) {
    return `(${option.code || ''}) ${option.name || ''}`
  }
  if (isObjectWithName(option)) {
    return option.name
  }
  if (isObjectWithType(option)) {
    return option.type.name
  }
  if (isObjectWithNavisionName(option)) {
    return option.navision_name
  }
  Sentry.captureMessage(`Unsupported object in FormikAutocomplete: ${JSON.stringify(option)}`)
  return 'n/a'
}

const getTranslationTitle = (
  translation: ObjectWithTranslation['translation'][keyof ObjectWithTranslation['translation']]
) => translation?.name || translation?.title || translation?.header || translation?.heading
