import { OneOfDeliveryVariant } from 'pages/CreateOrder/deliveryOptions'
import { getRequest } from 'utils/api/apiRequest'
import { WithPagination } from 'utils/api/pagination'

export type DeliveryTypeByCountry = {
  id: OneOfDeliveryVariant
  uuid: string
}

export const getDeliveryTypes = () =>
  getRequest<WithPagination<DeliveryTypeByCountry>>(`/redemption-site/delivery-address/delivery-types`)
