import { useTranslation } from 'react-i18next'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { Close } from 'components/ArteelIcons'
import { Image } from 'components/Image/Image'
import { Tooltip } from 'components/Tooltip'
import { ShoppingCartItem } from 'shared/ShoppingCart/api'
import { getResponsiveSize } from 'theme/styles.utils'
import { getProductName } from 'utils/getProductName'
import { useRemoveCartItem } from 'utils/hooks/api/ShoppingCart/useRemoveCartItem'
import { useChangeItemAmount } from 'utils/hooks/useChangeItemAmount'
import { useIsLuckyBirdUser } from 'utils/hooks/useIsLuckyBirdUser'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  wrapperAmount: {
    position: 'relative',
    paddingTop: `0 !important`,
    paddingLeft: `0 !important`,
    marginTop: 16,
    marginLeft: 16,
  },
  brand: {
    fontSize: theme.typography.h1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.2,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.h3.fontSize,
    },
  },
  name: {
    fontSize: theme.typography.h1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[600],
    lineHeight: 1.2,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.h3.fontSize,
    },
  },
  imageWrapper: {
    width: getResponsiveSize(70),
    height: getResponsiveSize(70),
    margin: theme.spacing(0, 4),
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      width: getResponsiveSize(70),
      margin: 'initial',
    },
  },
  changeQuantity: {
    position: 'absolute',
    '& svg': {
      fontSize: theme.typography.h1.fontSize,
    },
    '&.up': {
      top: 0,
      left: '50%',
      transform: 'translate(-50%,-75%)',
    },
    '&.down': {
      bottom: 0,
      left: '50%',
      transform: 'translate(-50%,75%)',
    },
  },
}))

type Props = {
  item: ShoppingCartItem
  onClose?: () => void
}

export const CartItem = ({ item, onClose }: Props) => {
  const { brand, name, imageWrapper, changeQuantity, wrapperAmount } = useStyles()
  const mainMedia = item.catalog_product.product.media.find((m) => m.main)
  const firstMedia = item.catalog_product.product?.media?.length > 0 ? item.catalog_product.product.media[0] : undefined
  const thumbMedia = mainMedia || firstMedia
  const { onChange, quantity, disabledArrow, isLoadingShoppingCart, isLoading } = useChangeItemAmount(item)
  const { isSingleLogic } = useIsLuckyBirdUser()
  const { t } = useTranslation()

  const { mutate: onRemoveCartItem } = useRemoveCartItem({
    onSuccess: () => {
      onClose()
    },
  })

  return (
    <Grid container alignItems="center" spacing={2} wrap="nowrap">
      <Grid item>
        <div className={imageWrapper}>
          <Image src={thumbMedia?.path} alt={getProductName(item.catalog_product.product)} />
        </div>
      </Grid>
      <Grid item xs>
        <Grid container>
          <Grid item xs={12} className={brand}>
            {item.catalog_product.product?.brand?.name || ''}
          </Grid>
          <Grid item xs={12} className={name}>
            {getProductName(item.catalog_product.product)}
          </Grid>
        </Grid>
      </Grid>
      {isSingleLogic ? (
        <>
          <Grid item className={wrapperAmount}>
            <Typography className={brand}>x{quantity}</Typography>
          </Grid>
          <Grid item>
            <Tooltip title={'app.remove'}>
              <IconButton size="small" color="error" onClick={() => onRemoveCartItem(item.id)}>
                <Close sx={{ fontSize: 16 }} />
              </IconButton>
            </Tooltip>
          </Grid>
        </>
      ) : (
        <Grid item className={wrapperAmount}>
          <IconButton
            className={`${changeQuantity} up`}
            size="small"
            color="primary"
            onClick={() => onChange('up')}
            disabled={disabledArrow.up}
          >
            <ExpandLess />
          </IconButton>
          <IconButton
            className={`${changeQuantity} down`}
            size="small"
            color="primary"
            onClick={() => onChange('down')}
            disabled={disabledArrow.down}
          >
            <ExpandMore />
          </IconButton>
          {isLoading || isLoadingShoppingCart ? (
            <CircularProgress size={20} />
          ) : (
            <Typography className={brand}>x{quantity}</Typography>
          )}
        </Grid>
      )}
    </Grid>
  )
}
