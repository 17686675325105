import { useEffect, useRef, useState } from 'react'
import debounce from 'lodash/debounce'
import { ShoppingCartItem } from 'shared/ShoppingCart/api'
import { useSelectShoppingCart } from 'shared/ShoppingCart/slice'
import { useChangeQuantityItem } from 'utils/hooks/api/ShoppingCart/useChangeQuantityItem'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'

export const useChangeItemAmount = (item: ShoppingCartItem) => {
  const [quantity, setQuantity] = useState<number>(item.quantity)
  const actualUserPoints = useCurrentUser()?.available_points || 0
  const { data: shoppingCartItems, isLoading: isLoadingShoppingCart } = useSelectShoppingCart()
  const { mutate: handleChangeQuantityItem, isLoading } = useChangeQuantityItem({
    onFail: () => setQuantity(item.quantity),
  })

  const currentValue = shoppingCartItems?.data?.total_order_value || 0
  const remainingPoints = actualUserPoints - currentValue

  const debounced = useRef(
    debounce((value) => {
      handleChangeQuantityItem({ cart_item_id: item.id, quantity: value })
    }, 700)
  )
  useEffect(() => {
    if (item.quantity !== quantity) {
      debounced.current(quantity)
    }
  }, [quantity])

  useEffect(() => {
    setQuantity(item.quantity)
  }, [item.quantity])

  const onChange = (value: 'up' | 'down') => {
    setQuantity((prevState) => (value === 'up' ? prevState + 1 : prevState - 1))
  }

  const disabledArrow = {
    up: remainingPoints < item.single_product_point || isLoading || isLoadingShoppingCart,
    down: quantity <= 1 || isLoading || isLoadingShoppingCart,
  }

  return {
    onChange,
    disabledArrow,
    quantity,
    isLoading,
    shoppingCartItems,
    isLoadingShoppingCart,
  }
}
