import { useQuery } from '@tanstack/react-query'
import { getRequest } from 'utils/api/apiRequest'
import { WithPagination } from 'utils/api/pagination'
import { CatalogProduct } from 'utils/api/Products/CatalogProduct'
import productsQueryKeys from 'utils/api/Products/productsQueryKeys'

export const useTopProducts = () => {
  return useQuery({
    queryKey: productsQueryKeys.topProducts(),
    queryFn: () => getRequest<WithPagination<CatalogProduct>>('/redemption-site/top-products'),
    select: (data) => data.data,
  })
}
