import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useDispatchWishlist } from 'shared/Wishlist/slice'
import { removeWishlistItem } from 'utils/api/Wishlist/removeWishlistItem'

export const useRemoveWishlistItem = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const { fetchWishlist } = useDispatchWishlist()

  return useMutation(removeWishlistItem, {
    onSuccess: () => {
      enqueueSnackbar(t('wishlist.remove_item.success'), { variant: 'success' })
      fetchWishlist()
    },
    onError: () => {
      enqueueSnackbar(t('wishlist.remove_item.fail'), { variant: 'error' })
    },
  })
}
