import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { getRecognitionProfiles } from 'utils/api/SocialWall/getRecognitionProfiles'
import socialWallQueryKeys from 'utils/api/SocialWall/socialWallQueryKeys'

export const useGetRecognitionProfiles = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  return useQuery({
    queryKey: socialWallQueryKeys.recognitionProfiles(),
    queryFn: getRecognitionProfiles,
    onError: () => {
      enqueueSnackbar(t('recognize_someone.fetch_profiles.fail'), { variant: 'error' })
    },
  })
}
