import { useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Zoom from '@mui/material/Zoom'
import { ScrollUpArrow } from 'components/ArteelIcons'
import { getResponsiveSize } from 'theme/styles.utils'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  button: {
    position: 'fixed',
    zIndex: 9999,
    right: 0,
    bottom: 0,
    [theme.breakpoints.down('lg')]: {
      bottom: theme.spacing(4),
    },
    '& .MuiSvgIcon-root': {
      fontSize: getResponsiveSize(4, 'rem'),
      [theme.breakpoints.down('lg')]: {
        fontSize: getResponsiveSize(5, 'rem'),
      },
    },
  },
}))
export const ScrollUp = () => {
  const [show, setShow] = useState(false)
  const { button } = useStyles()

  const handleScroll = () => {
    if (window.pageYOffset > 250) {
      if (!show) setShow(true)
    } else if (show) setShow(false)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  })

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <Zoom in={show}>
      <IconButton color="primary" className={button} onClick={handleClick} size="large">
        <ScrollUpArrow fontSize="large" />
      </IconButton>
    </Zoom>
  )
}
