import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import { Image } from 'components/Image/Image'
import { DEFAULT_LANGUAGE } from 'config/constants'
import { fontFamilies, getResponsiveSize } from 'theme/styles.utils'
import { OrderHistoryRow as OrderHistoryPosition } from 'utils/api/OrderHistory/getOrderHistory'
import { dateFormat } from 'utils/dateFormats'
import { makeStyles } from 'utils/styles/makeStyles'
import { useDisplayDateInTimeZone } from 'utils/useDisplayDateInTimeZone'

const useStyles = makeStyles((theme) => ({
  brand: {
    fontFamily: fontFamilies.cond,
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.2,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.h3.fontSize,
    },
  },
  reference: {
    fontFamily: fontFamilies.cond,
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.2,
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.h3.fontSize,
    },
    '& .MuiButton-label': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  image: {
    width: getResponsiveSize(81),
    height: getResponsiveSize(81),
    '& > img': {
      width: '100%',
      height: '100%',
    },
  },
}))

type Props = {
  order: OrderHistoryPosition
  onOpen: (order: OrderHistoryPosition) => void
}

export const OrderHistoryRow = ({ order, onOpen }: Props) => {
  const { brand, reference, image } = useStyles()
  const { t, i18n } = useTranslation()
  const { displayDateInTimeZone } = useDisplayDateInTimeZone()

  return (
    <Grid container wrap="nowrap" spacing={4}>
      <Grid item>{displayDateInTimeZone(order?.created_at, { formatTemplate: dateFormat })}</Grid>
      <Grid item xs>
        <Grid container direction="column" spacing={2}>
          {order?.order_items?.map((item) => (
            <Grid item key={item.id}>
              <Grid container spacing={4}>
                <Grid item xs>
                  <Grid container direction="column">
                    <Grid item className={brand}>
                      {item?.product?.brand?.name?.toUpperCase() || ''}
                    </Grid>
                    <Grid item>
                      {item?.product?.translation[i18n.language]?.name ||
                        item?.product?.translation[DEFAULT_LANGUAGE]?.name}
                    </Grid>
                    {item?.tracking_number && (
                      <Grid item>
                        <TrackingCode
                          trackingNumber={item?.tracking_number}
                          trackingNumberUrl={item?.tracking_number_url}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item>{item?.is_cancelled && t('order_history.is_cancelled')}</Grid>
                <Grid item className={image}>
                  <Image
                    src={item?.product?.media?.find((mediaItem) => mediaItem.main)?.path}
                    alt={
                      item?.product?.translation[i18n.language]?.name ||
                      item?.product?.translation[DEFAULT_LANGUAGE]?.name
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          ))}
          <Grid item className={reference}>
            {t('order_history.reference')}:{' '}
            <Button type="button" color="primary" onClick={() => onOpen(order)}>
              {order.ext_web_order_reference.id}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export const TrackingCode = ({
  trackingNumber,
  trackingNumberUrl,
}: {
  trackingNumber: string | null
  trackingNumberUrl: string | null
}) => (
  <Box position="relative" zIndex={1}>
    {trackingNumberUrl ? (
      <Link sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'none' } }} href={trackingNumberUrl} target="_blank">
        {trackingNumber}
      </Link>
    ) : (
      trackingNumber
    )}
  </Box>
)
