import { useEffect, useState } from 'react'
import debounce from 'lodash/debounce'

export const useResize = (
  htmlElement: HTMLDivElement | null,
  { debounceTime, manualTrigger }: { debounceTime?: number; manualTrigger?: boolean } = {}
) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0, topOffset: 0 })

  const handleResize = debounce(() => {
    if (htmlElement?.offsetWidth || htmlElement?.offsetHeight) {
      setDimensions({
        width: htmlElement.offsetWidth,
        height: htmlElement.offsetHeight,
        topOffset: htmlElement.offsetTop,
      })
    }
  }, debounceTime || 200)

  useEffect(() => {
    if (!htmlElement) return
    handleResize()
    window.addEventListener('load', handleResize)
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('load', handleResize)
      window.removeEventListener('resize', handleResize)
    }
  }, [htmlElement])

  useEffect(() => {
    if (manualTrigger === undefined || !htmlElement) return
    handleResize()
  }, [manualTrigger, htmlElement])

  return dimensions
}
