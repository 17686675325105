import { CmsContent, CmsSection } from 'pages/CMS/CMS.types'
import { contentBoxTypes, logicBoxTypes } from 'pages/CMS/constants'
import { useLanguages } from 'utils/api/Languages/useLanguages'
import { useThemes } from 'utils/api/Theme/useThemes'

type ContentBoxType = keyof typeof contentBoxTypes

const getVisibleContents = (arg: CmsContent[]) =>
  arg.filter((content) => content.visible).sort((a, b) => a.position - b.position)

const getContentsByType = (contents: CmsContent[], type: ContentBoxType) => {
  const value = contents
    .filter((content) => content.type === contentBoxTypes[type])
    .sort((a, b) => a.position - b.position)
  return {
    value,
    getVisible: () => getVisibleContents(value),
  }
}
const getContentsByOmitType = (contents: CmsContent[], type: ContentBoxType) => {
  const value = contents
    .filter((content) => content.type !== contentBoxTypes[type])
    .sort((a, b) => a.position - b.position)
  return {
    value,
    getVisible: () => getVisibleContents(value),
  }
}

const getContents = (s: CmsSection[]) => {
  if (!s) throw Error('No section provided')
  const value: CmsContent[] = []
  s.forEach((el) => {
    el.contents.forEach((ele) => {
      value.push(ele)
    })
  })
  return {
    value,
    getByType: (type: ContentBoxType) => getContentsByType(value, type),
    getByOmitType: (type: ContentBoxType) => getContentsByOmitType(value, type),
  }
}

export const useSections = () => {
  const { isFetching: isFetchingThemes, data: themeData } = useThemes()
  const { data: languages, isFetched } = useLanguages()
  const pageData = themeData?.data?.[0]?.pages?.find((page) => page.name === 'home_page')
  const sections = pageData?.sections || []
  const excludeLanguages = languages?.data?.length <= 1

  const sectionsWithExcludedLang = sections.map((s) => ({
    ...s,
    contents: s.contents.filter((c) =>
      excludeLanguages ? c.logic_box_type !== logicBoxTypes.logic_box_languages : true
    ),
  }))

  const getLogicBoxContents = (s: CmsSection[] = sectionsWithExcludedLang) =>
    getContents(s).getByType('logic_box').getVisible()

  const getNonLogicBoxContents = (s: CmsSection[] = sectionsWithExcludedLang) =>
    getContents(s).getByOmitType('logic_box').getVisible()

  return {
    sections: sectionsWithExcludedLang,
    pageData,
    getLogicBoxContents,
    getNonLogicBoxContents,
    isLoading: isFetchingThemes,
    theme: themeData,
  }
}
