import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import { useMutation } from '@tanstack/react-query'
import { Hide, Show } from 'components/ArteelIcons'
import { AuthLoggedUser } from 'components/AuthLoggedUser/AuthLoggedUser'
import { TextField } from 'components/TextField/TextField'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { patchResetPassword } from 'pages/Login/api/patchResetPassword'
import { useStylesLoginForm, WrapperLoginForm } from 'pages/Login/WrapperLoginForm'
import { isLoggedIn } from 'utils/axios-jwt-cookies'
import { getTextFieldParams } from 'utils/formik/getTextFieldProps'
import { getTokenFromSearch } from 'utils/getTokenFromSearch'
import { useErrorSnackbar } from 'utils/hooks/useErrorSnackbar'
import * as Yup from 'yup'

type FormValues = {
  first: string
  second: string
}

export const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false)
  const { loader } = useStylesLoginForm()
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const enqueueErrorSnackbar = useErrorSnackbar()
  const token = getTokenFromSearch(location.search.replace('?', ''))

  const { mutate, isLoading } = useMutation(patchResetPassword, {
    onSuccess: async () => {
      resetForm()
      navigate('/auth/login')
      enqueueSnackbar(t('auth.reset_password.success'), { variant: 'success' })
    },
    onError: async (error) => {
      enqueueErrorSnackbar(error, { DEFAULT_ERROR: t('auth.reset_password.error') })
    },
  })

  const onSubmit = (values: FormValues) => mutate({ token, password: values })

  const validationSchema = Yup.object().shape({
    first: Yup.string()
      .label(t('auth.reset_password.first'))
      .required()
      .matches(/^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/, t('app.validations.password'))
      .matches(
        // eslint-disable-next-line no-useless-escape
        /[\!\@\#\$\%\^\&\*\(\)\_\+\-\=\[\]\{\};:\",\.<>\/?]/,
        t('app.validations.hasSpecialCharacter').replace('$path', t('auth.activate.first'))
      ),
    second: Yup.string()
      .label(t('auth.reset_password.second'))
      .required()
      .oneOf([Yup.ref('first')], 'Passwords must be the same'),
  })

  const { getFieldMeta, getFieldProps, resetForm, handleSubmit, isSubmitting } = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      first: '',
      second: '',
    },
    validationSchema,
    onSubmit,
  })

  const getTextFieldProps = (name: string) =>
    getTextFieldParams(name, 'auth.reset_password.', getFieldMeta, getFieldProps)

  const toggleShowPassword = () => setShowPassword((prev) => !prev)

  if (isLoggedIn()) return <AuthLoggedUser />

  return (
    <form onSubmit={handleSubmit} style={{ height: '100%' }}>
      <WrapperLoginForm>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            {...getTextFieldProps('first')}
            type={showPassword ? 'text' : 'password'}
            size="small"
            data-cy="reset-password-input"
            center
            InputProps={{
              endAdornment: (
                <IconButton size="large" color="primary" onClick={toggleShowPassword}>
                  {showPassword ? <Hide fontSize="large" /> : <Show fontSize="large" />}
                </IconButton>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            {...getTextFieldProps('second')}
            type={showPassword ? 'text' : 'password'}
            size="small"
            center
            InputProps={{
              inputProps: { placehorder: 'password' },
              endAdornment: (
                <IconButton size="large" color="primary" onClick={toggleShowPassword}>
                  {showPassword ? <Hide fontSize="large" /> : <Show fontSize="large" />}
                </IconButton>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="column" alignItems="center" spacing={3}>
            <Grid item>
              <LoadingButton loading={isLoading} type="submit" color="primary" variant="contained" size="medium">
                {t('auth.reset_password')}
              </LoadingButton>
            </Grid>
            <Grid item>
              <Button
                type="button"
                variant="contained"
                size="medium"
                disabled={isLoading}
                onClick={() => navigate(`/auth/login`)}
              >
                {t('app.back')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </WrapperLoginForm>
    </form>
  )
}
