import { UserScoreLevel } from 'utils/api/User'

import { getRequest } from '../apiRequest'

export interface WidgetMyScore {
  received: number
  given: number
  last_recognition: {
    date: string
  }
  recognition_hint: {
    [key: string]: string
  }
  recognition_settings?: {
    image?: string
    translation?: {
      [key: string]: {
        name_plural: string
        name_singular: string
      }
    }
  }
  user_score_level: UserScoreLevel
}

export const fetchWidgetMyScore = (dateFrom: string) => {
  return getRequest<WidgetMyScore>(`/social-wall/recognitions/summary?date_from=${dateFrom}`)
}
