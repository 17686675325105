import Grid from '@mui/material/Grid'
import Loader from 'components/Loader/Loader'
import { useFormikContext } from 'formik'
import { BpostCollectionPoints } from 'pages/CreateOrder/components/Bpost/BpostCollectionPoints'
import { BpostParcelLockers } from 'pages/CreateOrder/components/Bpost/BpostParcelLockers'
import { CompanyName } from 'pages/CreateOrder/components/CompanyName'
import { PersonalData } from 'pages/CreateOrder/components/PersonalData'
import { PersonalDeliveries } from 'pages/CreateOrder/components/PersonalDeliveries'
import { SelectPresetAddress } from 'pages/CreateOrder/components/SelectPresetAddress'
import { DeliveryVariant, personalDeliveries } from 'pages/CreateOrder/deliveryOptions'
import { CreateOrderType2 } from 'pages/CreateOrder/types'
import { DeliveryPresetAddress } from 'utils/api/DeliveryPresetAddress/getDeliveryPresetAddresses'

type Props = {
  isLoadingPresetAddresses: boolean
  presetAddresses: DeliveryPresetAddress[]
  selectedCountryCode?: string
}

export const DeliveryVariants = ({ presetAddresses, isLoadingPresetAddresses, selectedCountryCode }: Props) => {
  const { values } = useFormikContext<CreateOrderType2>()
  const delivery_variant = values?.delivery_address_type

  return (
    <Loader isLoading={isLoadingPresetAddresses}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PersonalData />
        </Grid>
        {DeliveryVariant.onetime === delivery_variant && (
          <Grid item xs={12}>
            <CompanyName />
          </Grid>
        )}
        {personalDeliveries.includes(delivery_variant) && (
          <Grid item xs={12}>
            <PersonalDeliveries />
          </Grid>
        )}
        {DeliveryVariant.bpostCollectionPoint === delivery_variant && (
          <Grid item xs={12}>
            <BpostCollectionPoints selectedCountryCode={selectedCountryCode} />
          </Grid>
        )}
        {DeliveryVariant.bpostParcelLocker === delivery_variant && (
          <Grid item xs={12}>
            <BpostParcelLockers selectedCountryCode={selectedCountryCode} />
          </Grid>
        )}
        {DeliveryVariant.preset === delivery_variant && (
          <Grid item xs={12}>
            <SelectPresetAddress presetAddresses={presetAddresses} />
          </Grid>
        )}
      </Grid>
    </Loader>
  )
}
