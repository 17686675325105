import { useTranslation } from 'react-i18next'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import { Arrow } from 'components/ArteelIcons'
import { SortProduct } from 'components/SortProduct/SortProduct'
import { CategoriesAndFilters } from 'pages/HomeRedemptionSite/CategoriesAndFilters/CategoriesAndFilters'
import { useHomeRSUtils, useShowFiltersAndSorting } from 'pages/HomeRedemptionSite/utils'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { useIsLuckyBirdUser } from 'utils/hooks/useIsLuckyBirdUser'
import { makeStyles } from 'utils/styles'

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    fontSize: theme.typography.h2.fontSize,
    fontWeight: 900,
    color: theme.palette.primary.main,
    width: '100%',
    textTransform: 'uppercase',
  },
}))

export const ProductFilterSortMobile = () => {
  const { dialogTitle } = useStyles()
  const { t, i18n } = useTranslation()
  const { isSingleLogic } = useIsLuckyBirdUser()
  const { showFilters } = useShowFiltersAndSorting()
  const { currentCategoriesPath } = useHomeRSUtils()

  return (
    <>
      {!isSingleLogic && (
        <Accordion>
          <AccordionSummary expandIcon={<Arrow direction="down" color="primary" fontSize="small" />}>
            <Typography className={dialogTitle} align="center">
              {t('app.filter')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CategoriesAndFilters
              showFilters={isSingleLogic ? false : showFilters}
              selectedCategoryName={
                currentCategoriesPath?.[currentCategoriesPath?.length - 1]?.translation?.[i18n.language]?.name ||
                currentCategoriesPath?.[currentCategoriesPath?.length - 1]?.name
              }
            />
          </AccordionDetails>
        </Accordion>
      )}
      <Accordion expanded={valueOrUndefined(isSingleLogic, true)}>
        <AccordionSummary
          expandIcon={valueOrUndefined(!isSingleLogic, <Arrow direction="down" color="primary" fontSize="small" />)}
        >
          <Typography className={dialogTitle} align="center">
            {t('app.sort')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SortProduct />
        </AccordionDetails>
      </Accordion>
    </>
  )
}
