import { useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { TextField } from 'components/TextField/TextField'
import { ShoppingCartItem } from 'shared/ShoppingCart/api'
import { PersonalizationLineProps } from 'utils/api/ShoppingCart/patchPersonalizationText'
import { useIsMobile } from 'utils/hooks/useBreakpoints'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  csPosition: {
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[600],
    lineHeight: 1.2,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.h3.fontSize,
    },
    minWidth: theme.spacing(8),
  },
}))

type Props = {
  position: number
  item: ShoppingCartItem
  onChange: (data: PersonalizationLineProps) => void
  disabledChange?: boolean
}

export const PersonalizationLine = ({ position, item, onChange, disabledChange }: Props) => {
  const { csPosition } = useStyles()
  const [state, setState] = useState(item.personalization_texts?.[position] || '')
  const isMobile = useIsMobile()

  return (
    <Grid container alignItems="center" spacing={2}>
      {!isMobile && <Grid item className={csPosition}>{`${position + 1}. `}</Grid>}
      <Grid item xs>
        <TextField
          value={state}
          onChange={(e) => setState(e.target.value)}
          variant="outlined"
          size="small"
          onBlur={(e) => onChange({ text: e.target.value, cart_item_id: item.id, position })}
          InputProps={{
            inputProps: { maxLength: item.product.personalization_max_length },
          }}
          disabled={disabledChange}
        />
      </Grid>
      <Grid item>
        <Typography align="right" className={csPosition}>
          {state.length || 0} / {item.product.personalization_max_length}
        </Typography>
      </Grid>
    </Grid>
  )
}
