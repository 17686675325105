import { useLocation } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import qs from 'query-string'
import { getVoucherOrders } from 'utils/api/OrderHistory/getVoucherOrders'
import orderHistoryQueryKeys from 'utils/api/OrderHistory/orderHistoryQueryKeys'

export const useVoucherOrders = () => {
  const { search } = useLocation()

  const params = qs.parse(search.replace('?', ''))
  const limit = (params?.limit || '3').toString()

  return useQuery({
    queryKey: orderHistoryQueryKeys.voucherOrdersList(limit),
    queryFn: () => getVoucherOrders(limit),
  })
}
