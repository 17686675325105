import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Typography from '@mui/material/Typography'

type Props = {
  editable?: boolean
}

export const ChangePasswordButton = ({ editable }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  if (!editable) {
    return (
      <Typography
        component="span"
        color="primary"
        sx={(theme) => ({
          fontSize: theme.typography.h4.fontSize,
          lineBreak: 'anywhere',
          fontWeight: 'bold',
        })}
      >
        {t('auth.change_password')}
      </Typography>
    )
  }

  return (
    <Typography
      component="span"
      color="primary"
      sx={(theme) => ({
        cursor: 'pointer',
        fontSize: theme.typography.h4.fontSize,
        lineBreak: 'anywhere',
        textDecoration: 'underline',
        fontWeight: 'bold',
      })}
      onClick={() => navigate('/my-account/edit')}
    >
      {t('auth.change_password')}
    </Typography>
  )
}
