import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

export const CommentBubble = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 480 480">
    {active ? (
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M129.3,449v-73.8c-54.4-0.6-98.5-45-98.5-99.6v-145c0-54.9,44.7-99.6,99.6-99.6h219.3  c54.9,0,99.6,44.7,99.6,99.6v145.1c0,54.9-44.7,99.6-99.6,99.6h-92.8L129.3,449z"
      />
    ) : (
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M129.3,449v-73.8c-54.4-0.6-98.5-45-98.5-99.6V130.6c0-54.9,44.7-99.6,99.6-99.6h219.3  c54.9,0,99.6,44.7,99.6,99.6v145.1c0,54.9-44.7,99.6-99.6,99.6h-92.8L129.3,449z M130.4,58c-40,0-72.6,32.6-72.6,72.6v145.1  c0,40,32.6,72.6,72.6,72.6h25.9V399l92.4-50.8h100.9c40,0,72.6-32.6,72.6-72.6V130.6c0-40-32.6-72.6-72.6-72.6H130.4z"
      />
    )}
  </SvgIcon>
)
