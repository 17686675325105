import { InfiniteData } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { initialPagination, WithPagination } from 'utils/api/pagination'

export const infinityQueryMap = <T extends WithPagination<{}>>(data: InfiniteData<AxiosResponse<T>>) => {
  return (
    data?.pages
      ?.map((response) => response.data)
      ?.reduce(
        (acc, curr) =>
          ({
            isLoading: false,
            data: [...acc.data, ...curr.data],
            items: curr.items,
            itemsPerPage: acc.itemsPerPage + curr.itemsPerPage,
            page: curr.page,
            pages: curr.pages,
          }) as T,
        initialPagination() as T
      ) || (initialPagination() as T)
  )
}
