import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { inputLabelClasses, paperClasses } from '@mui/material'
import Box from '@mui/material/Box'
import FormHelperText from '@mui/material/FormHelperText'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { menuPaperSx } from 'components/CustomPopover/CustomPopover'
import { FormikButtons } from 'components/Formik/FormikButtons'
import { FormikTextField } from 'components/Formik/FormikTextField'
import { FormikAutocomplete } from 'components/FormikAutocomplete/FormikAutocomplete'
import { FormikValues, useFormikContext } from 'formik'
import debounce from 'lodash/debounce'
import { useGetTransferPointsProfiles } from 'pages/TransferPoints/api/useGetTransferPointsProfiles'
import { Project } from 'shared/Site/api'
import { fontFamilies } from 'theme/styles.utils'
import { TranslationsKeys } from 'utils/createTranslationKey'
import { useIsMobile } from 'utils/hooks/useBreakpoints'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'
import { usePointsName } from 'utils/hooks/usePointsName'
import { inside, makeSx } from 'utils/styles'

type Props = {
  onBack: () => void
  projectWithTransferPoints: Project[]
  isLoadingForm: boolean
}

export const TransferPointsForm = ({ onBack, projectWithTransferPoints, isLoadingForm }: Props) => {
  const [searchParam, setSearchParam] = useState('')
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const user = useCurrentUser()

  const { values, errors, touched } = useFormikContext<FormikValues>()
  const { data, isLoading } = useGetTransferPointsProfiles(values?.project, searchParam)

  const availableProjectPoints = Object.values(user?.projects || {}).find(
    (project) => project.id === Number(values?.project)
  )?.available_points
  const remainingPoints = availableProjectPoints - values?.points > 0 ? availableProjectPoints - values?.points : 0

  const updateSearchParam = useCallback(
    debounce((value: string) => setSearchParam(value), 200),
    [setSearchParam]
  )

  const sx = makeSx((theme) => ({
    [theme.breakpoints.down('md')]: {
      [inside(inputLabelClasses.root)]: {
        fontSize: '0.75rem',
      },
    },
  }))

  return (
    <Box display="grid" gridTemplateColumns={isMobile ? '1fr' : 'auto 1fr'} columnGap={6} rowGap={isMobile ? 1 : 4}>
      {projectWithTransferPoints?.length > 1 && (
        <>
          <Label label="transfer_points.project" />
          <Box>
            <FormikTextField
              select
              name="project"
              size="small"
              slotProps={{
                select: {
                  MenuProps: {
                    sx: (t) => ({ [inside(paperClasses.root)]: menuPaperSx(t) }),
                  },
                },
              }}
              sx={sx}
            >
              {projectWithTransferPoints.map((project) => (
                <MenuItem key={project.id} value={project.id?.toString()}>
                  {project.name}
                </MenuItem>
              ))}
            </FormikTextField>
          </Box>
        </>
      )}
      <Label label="transfer_points.who" />
      <Box>
        <FormikAutocomplete
          loading={isLoading}
          disabled={!values.project}
          options={data?.data?.data || []}
          name="who"
          inputLabel="transfer_points.who.placeholder"
          size="small"
          sx={sx}
          onInputChange={(event, value) => updateSearchParam(value || '')}
        />
      </Box>
      <Label label="transfer_points.reason" />
      <Box sx={{ position: 'relative' }}>
        <FormikTextField
          name="reason"
          inputLabel="transfer_points.reason.placeholder"
          multiline
          minRows={4}
          size="small"
          sx={sx}
        />
        <Box
          sx={(theme) => ({
            position: 'absolute',
            bottom: theme.spacing(errors?.reason && touched?.reason ? 4 : 1),
            left: theme.spacing(4),
            color: theme.palette.grey[500],
          })}
        >
          <Typography variant={isMobile ? 'h5' : 'h4'} fontFamily={fontFamilies.default}>
            {t('transfer_points.reason.placeholder2')}
          </Typography>
        </Box>
      </Box>
      <Label label="transfer_points.points" />
      <Box>
        <FormikTextField
          name="points"
          size="small"
          inputLabel="transfer_points.points.placeholder"
          InputProps={{
            type: 'number',
          }}
          sx={sx}
        />
        {!isNaN(availableProjectPoints) && (
          <FormHelperText sx={{ textAlign: 'right', pr: (theme) => theme.spacing(3) }}>
            {`${t('transfer_points.validation.available')}: ${availableProjectPoints} ${usePointsName(
              availableProjectPoints
            )} ${t('transfer_points.validation.remaining')}: ${remainingPoints} ${usePointsName(remainingPoints)}`}
          </FormHelperText>
        )}
      </Box>
      <Box />
      <Box>
        <FormikButtons
          onBack={onBack}
          textButtonCancel="app.cancel"
          textButtonSubmit="transfer_points.submit"
          fullWidth
          size="small"
          isLoading={isLoadingForm}
        />
      </Box>
    </Box>
  )
}

const Label = ({ label }: { label: TranslationsKeys }) => {
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        pt: (theme) => theme.spacing(2),
      }}
    >
      <Typography variant="h4" fontWeight="bold">
        {t(label)}
      </Typography>
    </Box>
  )
}
