import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { shoppingCartQueryKeys } from 'shared/ShoppingCart/slice'
import { patchRequest } from 'utils/api/apiRequest'
import brandsQueryKeys from 'utils/api/Brands/brandsQueryKeys'
import categoriesQueryKeys from 'utils/api/Categories/categoriesQueryKeys'
import deliveryPresetAddressesQueryKeys from 'utils/api/DeliveryPresetAddress/deliveryPresetAddressesQueryKeys'
import deliveryTypesQueryKeys from 'utils/api/DeliveryType/deliveryTypesQueryKeys'
import productsQueryKeys from 'utils/api/Products/productsQueryKeys'
import userQueryKeys from 'utils/api/User/userQueryKeys'
import { OnSuccessApi } from 'utils/getOptionsTable'

type Data = OnSuccessApi & {
  countryId: number
}

export const usePatchUserCountry = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  return useMutation({
    mutationFn: ({ countryId }: Data) => patchRequest('/users/update-data', { country: countryId }),
    onSuccess: (data, variables) => {
      variables?.onSuccess?.()
      queryClient.invalidateQueries({ queryKey: userQueryKeys.all })
      queryClient.resetQueries({ queryKey: shoppingCartQueryKeys.all })
      queryClient.resetQueries({ queryKey: deliveryPresetAddressesQueryKeys.all })
      queryClient.resetQueries({ queryKey: deliveryTypesQueryKeys.all })
      queryClient.resetQueries({ queryKey: categoriesQueryKeys.all })
      queryClient.resetQueries({ queryKey: brandsQueryKeys.all })
      queryClient.resetQueries({ queryKey: productsQueryKeys.all })
    },
    onError: (error) => {
      enqueueSnackbar(t('country_context.update.error'), { variant: 'error' })
    },
  })
}
