import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getShoppingCart } from 'shared/ShoppingCart/api'
import { useSubdomainInfo } from 'shared/Site/api'
import { SiteType } from 'shared/Site/siteType'
import { useIsLuckyBirdUser } from 'utils/hooks/useIsLuckyBirdUser'
export const shoppingCartQueryKeys = {
  all: ['all', 'shoppingCart'] as const,
  fetchShoppingCart: () => [...shoppingCartQueryKeys.all, 'fetchShoppingCart'] as const,
}
export const useSelectShoppingCart = () => {
  const { data: subdomainInfo } = useSubdomainInfo()
  const { isLuckyBird } = useIsLuckyBirdUser()

  return useQuery({
    queryKey: shoppingCartQueryKeys.fetchShoppingCart(),
    queryFn: getShoppingCart,
    enabled: subdomainInfo?.data?.type === SiteType.project || isLuckyBird,
  })
}
export const useDispatchShoppingCart = () => {
  const queryClient = useQueryClient()

  return {
    refetchShoppingCart: () => queryClient.invalidateQueries(shoppingCartQueryKeys.all),
  }
}

export const useAmountOfItemsInShoppingCart = () => {
  const { data } = useSelectShoppingCart()
  return data?.data?.items?.reduce((previousValue, currentValue) => previousValue + currentValue.quantity, 0) ?? 0
}
