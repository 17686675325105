import { AccordionCheckboxes } from 'components/AcordionCheckboxes/AccordionCheckboxes'
import { AccordionCheckboxBaseProps, AccordionCheckboxesProps } from 'components/AcordionCheckboxes/types'
import { useFormikContext } from 'formik'

export interface FormikAccordionCheckboxesProps<T extends AccordionCheckboxBaseProps>
  extends Pick<AccordionCheckboxesProps<T>, 'options' | 'initialValues' | 'isLoading'> {
  name: string
}

export const FormikAccordionCheckboxes = <T extends AccordionCheckboxBaseProps>({
  name,
  ...props
}: FormikAccordionCheckboxesProps<T>) => {
  const { setFieldValue, getFieldMeta, initialValues } = useFormikContext<{ [key: string]: number[] }>()
  const { touched, error } = getFieldMeta(name)
  const errorText = touched && error
  const initValues = initialValues[name] as typeof props.initialValues

  return (
    <AccordionCheckboxes
      error={errorText}
      initialValues={initValues}
      onChange={(v) => setFieldValue(name, v)}
      {...props}
    />
  )
}
