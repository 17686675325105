import { ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Languages } from 'components/Languages/Languages'
import Loader from 'components/Loader/Loader'
import { LuckyBirdGlobalLogo } from 'components/LuckyBirdGlobalLogo'
import { MAIN_DOMAIN, PROTOCOL } from 'config/env'
import { useIsLuckyBirdAuth } from 'pages/Login/LuckyBird/useIsLuckyBirdAuth'
import { useIsLuckyBirdGlobalAuth } from 'pages/Login/LuckyBird/useIsLuckyBirdGlobalAuth'
import qs from 'query-string'
import { useSubdomainInfo } from 'shared/Site/api'
import { SiteType } from 'shared/Site/siteType'
import { fontFamilies, getResponsiveSize } from 'theme/styles.utils'
import { getThumbnailPath } from 'utils/getThumbnail'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { useIsMobile } from 'utils/hooks/useBreakpoints'
import { makeStyles } from 'utils/styles/makeStyles'

export const useStylesLoginForm = makeStyles((theme) => ({
  loader: {
    marginLeft: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
  },

  welcomeText: {
    fontFamily: fontFamilies.default,
  },
}))
type Props = {
  children: ReactNode
}

export const WrapperLoginForm = ({ children }: Props) => {
  const { welcomeText } = useStylesLoginForm()
  const { data: subdomainInfo, isInitialLoading: isLoading, isFetching } = useSubdomainInfo()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = qs.parse(location.search.replace('?', ''))
  const subscriber = subdomainInfo?.data?.subscriber
  const project = subdomainInfo?.data?.project
  const domain = subdomainInfo?.data?.subdomain
  const isMobile = useIsMobile()
  const isGlobalLB = useIsLuckyBirdGlobalAuth()
  const isLuckyBird = useIsLuckyBirdAuth()

  useEffect(() => {
    if (!isFetching && !subdomainInfo?.data?.type) {
      navigate('/404')
    }
  }, [isFetching, subscriber, navigate])

  useEffect(() => {
    if (!isFetching && subscriber && subdomainInfo?.data?.type === SiteType.project && !params?.token) {
      const subdomainCMS = subscriber.sites.find((site) => site.type === SiteType.cms)?.subdomain
      window.location.assign(`${PROTOCOL}://${subdomainCMS}.${MAIN_DOMAIN}/auth/login?redirectProject=${domain}`)
    }
  }, [subdomainInfo, navigate, params, domain, subscriber])

  const Logo = () => (
    <Typography color="primary" variant="h1" align="center">
      {subscriber?.logo_path ? (
        <img
          src={getThumbnailPath(subscriber?.logo_path)}
          alt={subscriber?.name}
          style={{ maxHeight: getResponsiveSize(400), width: getResponsiveSize(400), maxWidth: '100%' }}
        />
      ) : (
        subscriber?.name
      )}
    </Typography>
  )

  return (
    <Loader isLoading={isLoading || subdomainInfo?.data?.type === SiteType.project} fullHeight>
      <Box
        display="grid"
        gridTemplateRows="1fr auto"
        gridTemplateColumns="1fr"
        justifyItems="center"
        alignItems="center"
        height={valueOrUndefined(!isMobile, '100%')}
      >
        <Box width="100%">
          <Grid container justifyContent="center">
            <Grid item md={8} lg={6} xl={5}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>{isGlobalLB ? <LuckyBirdGlobalLogo /> : <Logo />}</Grid>
                <Grid item xs={12}>
                  <Box py={3}>
                    <Typography variant="h3" align="center" className={welcomeText}>
                      {isGlobalLB || isLuckyBird
                        ? t('auth.welcome.lucky_bird')
                        : t('auth.welcome', {
                            subscriberName: subscriber?.name || '',
                            projectName: project?.name || '',
                          })}
                    </Typography>
                    <Typography variant="h3" align="center" className={welcomeText}>
                      {isGlobalLB || isLuckyBird
                        ? t('auth.welcome.lucky_bird2')
                        : t('auth.welcome2', {
                            pointsName:
                              subscriber?.points_name_plural?.translation?.[i18n.language]?.name || 'app.points',
                          })}
                    </Typography>
                  </Box>
                </Grid>
                {children}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Languages />
        </Box>
      </Box>
    </Loader>
  )
}
