import { NotificationTranslation, Project } from 'shared/Site/api'
import { getRequest } from 'utils/api/apiRequest'
import { WithPagination } from 'utils/api/pagination'

export type CmsNotification = {
  id: number
  type: number
  is_read: boolean
  translation: NotificationTranslation
  project: Project
}
export const fetchNotifications = (pageParam = 1) =>
  getRequest<WithPagination<CmsNotification>>(`/cms/notifications?limit=20&page=${pageParam}`)
