import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { LanguageName } from 'components/LanguageName'
import { useLanguages } from 'utils/api/Languages/useLanguages'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { useIsMobile } from 'utils/hooks/useBreakpoints'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  current: {
    color: theme.palette.primary.main,
  },

  pointer: {
    cursor: 'pointer',
    color: 'inherit',
    transition: '0.3s all',
    padding: theme.spacing(2, 0),
    display: 'block',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}))

type Props = {
  withoutPadding?: boolean
}

export const Languages = ({ withoutPadding }: Props) => {
  const { current, pointer } = useStyles()
  const { i18n } = useTranslation()
  const { data: languages } = useLanguages()
  const isMobile = useIsMobile()

  if (languages?.data?.length <= 1) return null

  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      alignItems="content"
      sx={(t) => ({
        fontSize: t.typography.h2.fontSize,
        fontWeight: t.typography.fontWeightBold,
        pt: valueOrUndefined(isMobile && !withoutPadding, 3),
      })}
    >
      {languages?.data?.map((l, index) => (
        <Fragment key={`${l.language.code}-${index}`}>
          <Grid
            item
            className={i18n.language === l.language.code ? current : undefined}
            onClick={() => i18n.changeLanguage(l.language.code)}
          >
            <span className={pointer}>
              <LanguageName code={l.language.code} />
            </span>
          </Grid>
          {index !== languages?.items - 1 && (
            <Grid item>
              <Box py={2}>/</Box>
            </Grid>
          )}
        </Fragment>
      ))}
    </Grid>
  )
}
