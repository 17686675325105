import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Zoom from '@mui/material/Zoom'
import { HeaderPage } from 'components/HeaderPage/HeaderPage'
import Loader from 'components/Loader/Loader'
import { ResponsivePaper } from 'components/ResponsivePaper'
import isEmpty from 'lodash/isEmpty'
import { OrderDetails } from 'pages/OrderHistory/components/OrderDetails'
import qs from 'query-string'
import { OrderHistoryRow as OrderHistoryPosition } from 'utils/api/OrderHistory/getOrderHistory'
import { useOrderHistory } from 'utils/hooks/api/useOrderHistory'
import { makeStyles } from 'utils/styles/makeStyles'

import { OrderHistoryRow } from './components/OrderHistoryRow'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3, 6),
  },
}))

export const OrderHistory = () => {
  const [openOrder, setOpenOrder] = useState<OrderHistoryPosition | undefined>()
  const { container } = useStyles()
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const location = useLocation()
  const limit = qs.parse(location.search.replace('?', ''))?.limit || '3'

  const { data, isLoading } = useOrderHistory()
  const orders: OrderHistoryPosition[] = data?.data?.data || []
  const areAllItems = data?.data?.items >= data?.data?.itemsPerPage

  useEffect(() => {
    if (!isEmpty(orders) && id) {
      const orderByParams = orders.find((order) => order.ext_web_order_reference.id === parseInt(id, 10))
      setOpenOrder(orderByParams)
    }
  }, [orders, setOpenOrder, id])

  return (
    <ResponsivePaper>
      <div className={container}>
        <Loader isLoading={isLoading} objects={orders}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <HeaderPage size="medium" text={t('profile.order_history')} />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {orders?.map((order) => (
              <Fragment key={`order-row-${order.id}`}>
                <Grid item xs={12}>
                  <OrderHistoryRow order={order} onOpen={setOpenOrder} />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Fragment>
            ))}
            <Grid item xs={12}>
              <Zoom in={areAllItems} unmountOnExit mountOnEnter>
                <Grid container justifyContent="center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate(`/my-account/order-history?limit=${parseInt(limit as string, 10) + 3}`)}
                  >
                    {t('app.show_more')}
                  </Button>
                </Grid>
              </Zoom>
            </Grid>
          </Grid>
          <OrderDetails order={openOrder} onClose={() => setOpenOrder(undefined)} />
        </Loader>
      </div>
    </ResponsivePaper>
  )
}
