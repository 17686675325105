import { useState } from 'react'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import { AuthLoggedUser } from 'components/AuthLoggedUser/AuthLoggedUser'
import { Step1 } from 'pages/Login/LuckyBird/Step1'
import { Step2 } from 'pages/Login/LuckyBird/Step2'
import { InitialValues, UpdateValues } from 'pages/Login/LuckyBird/types'
import { WrapperLoginForm } from 'pages/Login/WrapperLoginForm'
import { isLoggedIn } from 'utils/axios-jwt-cookies'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'

export const LuckyBirdCodeLogin = () => {
  const [step, setStep] = useState(1)
  const [initialValues, setInitialValues] = useState<InitialValues>({
    first_name: '',
    last_name: '',
    email: '',
    consent: false,
    code: '',
    subdomain: '',
  })
  const updateValues: UpdateValues = (values) => setInitialValues((prev) => ({ ...prev, ...values }))

  const user = useCurrentUser()

  if (isLoggedIn() && !!user) return <AuthLoggedUser />

  return (
    <WrapperLoginForm>
      <Grid item xs={12}>
        <Collapse in={step === 1}>
          <Step1 onNextStep={() => setStep(2)} updateValues={updateValues} />
        </Collapse>
        <Collapse in={step === 2} mountOnEnter={false} unmountOnExit={true}>
          <Step2 onBack={() => setStep(1)} initialValues={initialValues} />
        </Collapse>
      </Grid>
    </WrapperLoginForm>
  )
}
