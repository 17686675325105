import React, { forwardRef, JSX } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonProps } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { getResponsiveSize } from 'theme/styles.utils'
import { TranslationsKeys } from 'utils/createTranslationKey'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { useIsMobile } from 'utils/hooks/useBreakpoints'
import { makeStyles } from 'utils/styles'

interface Props extends Pick<ButtonProps, 'onClick' | 'color' | 'variant' | 'sx' | 'size' | 'fullWidth'> {
  translation: TranslationsKeys
  Icon: JSX.Element
  uppercase?: boolean
  isLoading?: boolean
  dataCy?: string
}

const useStyles = makeStyles((_) => ({
  mobileButton: {
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
  },
}))

export const ButtonWithIcon = forwardRef<any, Props>(
  (
    {
      Icon,
      translation,
      color = 'primary',
      variant = 'contained',
      uppercase = true,
      isLoading,
      dataCy,
      ...props
    }: Props,
    ref
  ) => {
    const { t } = useTranslation()
    const { mobileButton } = useStyles()
    const isMobile = useIsMobile()
    const IconComponent = React.cloneElement(Icon, { sx: { mt: '-3px', ml: `0!important` } })

    return (
      <Button
        ref={ref}
        color={color}
        variant={variant}
        className={valueOrUndefined(isMobile, mobileButton)}
        data-cy={dataCy}
        {...props}
      >
        <Box
          display="grid"
          gridTemplateColumns={`${getResponsiveSize(25, 'px')} 1fr ${getResponsiveSize(25, 'px')}`}
          width="100%"
          alignItems="center"
        >
          {isLoading ? (
            <CircularProgress
              size={getResponsiveSize(20, 'px')}
              sx={(t) => ({ color: variant === 'contained' ? t.palette.primary.contrastText : 'primary' })}
            />
          ) : (
            IconComponent
          )}
          <Box className="text" sx={{ px: 2, textTransform: uppercase ? 'uppercase' : undefined }}>
            {t(translation)}
          </Box>
        </Box>
      </Button>
    )
  }
)
