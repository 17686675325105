import { FunctionComponent, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import { CmsContent } from 'pages/CMS/CMS.types'
import { fontFamilies } from 'theme/styles.utils'
import { getObjectTranslation } from 'utils/getObjectTranslation'
import { safeHtml } from 'utils/safeHtml'
import { makeStyles } from 'utils/styles/makeStyles'

import { fitVideos } from './fitVideos'

const useStyles = makeStyles((theme) => ({
  htmlContainer: {
    '& ul': {
      paddingLeft: theme.spacing(4),
      listStyleType: 'disc',
    },
    '& sup': {
      verticalAlign: 'super',
      fontSize: 'smaller',
    },
    '& sub': {
      verticalAlign: 'sub',
      fontSize: 'smaller',
    },
    '& ol': {
      paddingLeft: theme.spacing(4),
      listStyleType: 'decimal',
    },
    '& *': {
      maxWidth: '100%',
    },
    '& img': {
      display: 'block',
      height: 'auto',
    },
    '& p': {
      lineHeight: 1.3,
    },
    '& h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child, p:first-child': {
      marginBlockStart: 0,
    },
    '& > *': {
      overflowWrap: 'anywhere',
    },
  },
  videosWrapper: {
    position: 'relative',
    height: 0,
    '& iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  },
}))

type Props = {
  content: CmsContent
  showTitle?: boolean
}

export const HtmlContent: FunctionComponent<Props> = ({ content, showTitle }) => {
  const { htmlContainer, videosWrapper } = useStyles()
  const { i18n, t } = useTranslation()
  const htmlContentElementId = `html-content-${content.id}`

  useLayoutEffect(() => {
    fitVideos(videosWrapper, htmlContentElementId)
  }, [i18n.language, htmlContentElementId, videosWrapper])

  return (
    <>
      {showTitle && (
        <Typography
          variant="h2"
          fontFamily={fontFamilies.default}
          dangerouslySetInnerHTML={safeHtml(getObjectTranslation(content?.translation)?.title || '')}
        />
      )}
      <div
        id={htmlContentElementId}
        className={htmlContainer}
        dangerouslySetInnerHTML={safeHtml(
          getObjectTranslation(content?.translation)?.data || t('app.missingTranslation')
        )}
      />
    </>
  )
}
