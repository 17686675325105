import { useTranslation } from 'react-i18next'
import { SxProps, Theme } from '@mui/material'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import DisplayDate from 'components/DisplayDate'
import { MyAccountPointsHistory } from 'pages/MyAccount/api/getPointsHistory'
import { PointsCell } from 'pages/MyAccount/components/PointsHistory/PointsCell'
import { dateDayMonthYearFormat } from 'utils/dateFormats'
import { getObjectTranslation } from 'utils/getObjectTranslation'
import { safeHtml } from 'utils/safeHtml'

type Props = {
  row?: MyAccountPointsHistory
}

export const PointsHistoryRow = ({ row }: Props) => {
  const { t } = useTranslation()

  const boxSx: SxProps<Theme> = {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: 'auto',
    alignItems: 'center',
    pl: 4,
    py: 3,
    fontSize: '0.85rem',
    lineHeight: 1.25,
  }

  const getInfo = (info: string) => {
    // remove '- csv upload'
    if (info.includes('–')) {
      return info.split('–')[0]
    }
    return info
  }

  return (
    <>
      <Box
        sx={(theme) => ({
          ...boxSx,
          pl: 0,
          justifyContent: 'flex-start',
          [theme.breakpoints.down('md')]: {
            pl: 0,
            pt: 2,
            pb: 1,
          },
        })}
      >
        {row ? (
          <DisplayDate dateString={row.created_at} options={{ formatTemplate: dateDayMonthYearFormat }} />
        ) : (
          <Skeleton width="100%" />
        )}
      </Box>
      <Box
        sx={(theme) => ({
          ...boxSx,
          justifyContent: 'flex-end',
          [theme.breakpoints.down('md')]: {
            pl: 0,
            pt: 2,
            pb: 1,
          },
          [theme.breakpoints.up('md')]: { display: 'none' },
        })}
      >
        <PointsCell row={row} />
      </Box>
      <Box
        sx={(theme) => ({
          ...boxSx,
          [theme.breakpoints.down('md')]: {
            pl: 0,
            py: 1,
          },
        })}
      >
        <Typography color="grey" lineHeight={1.25}>
          {row ? getInfo(row.info) : <Skeleton />}
        </Typography>
        {row?.occasion && (
          <Typography fontWeight="bold" lineHeight={1.25}>
            {getObjectTranslation(row.occasion?.translation)?.name}
          </Typography>
        )}
      </Box>
      <Box
        sx={(theme) => ({
          ...boxSx,
          [theme.breakpoints.down('md')]: {
            pl: 0,
            py: 1,
          },
        })}
      >
        <Typography
          color="grey"
          sx={(theme) => ({
            [theme.breakpoints.down('md')]: {
              textAlign: 'right',
            },
          })}
        >
          {row ? (
            <>
              {t('cms.points.history.performed_by')}
              {':'}
              <br />
              {row.performed_by}
            </>
          ) : (
            <Skeleton />
          )}
        </Typography>
      </Box>
      <Box
        sx={(theme) => ({
          ...boxSx,
          [theme.breakpoints.down('md')]: {
            borderBottom: '1px solid #E0E0E0',
            gridColumn: 'span 2',
            pl: 0,
            pt: 1,
            pb: 2,
          },
        })}
      >
        {row ? (
          <Typography
            color="grey"
            component="div"
            sx={(theme) => ({
              lineHeight: 1,

              '& > *': {
                lineHeight: 1,
                margin: 0,
                padding: 0,
              },
            })}
            dangerouslySetInnerHTML={safeHtml(
              row?.message ? `${t('personal_messages.add.message')}:</br>${row?.message}` : ''
            )}
          />
        ) : (
          <Typography component="div">
            <Skeleton />
          </Typography>
        )}
      </Box>
      <Box
        sx={(theme) => ({
          ...boxSx,
          justifyContent: 'flex-end',
          [theme.breakpoints.down('md')]: { display: 'none' },
        })}
      >
        <PointsCell row={row} />
      </Box>
    </>
  )
}
