import Grid from '@mui/material/Grid'
import { BirthdayPerson } from 'utils/api/Birthdays/BirthdayPerson'

import { BirthdayCard } from './BirthdayCard'

interface Props {
  persons: BirthdayPerson[]
}

export const BirthdayView = ({ persons }: Props) => {
  return (
    <Grid item xs sx={{ paddingTop: 3, paddingBottom: 3 }}>
      <Grid container justifyContent="space-evenly" alignItems="center" gap={3}>
        {persons.map((profile) => (
          <Grid key={profile.full_name} item xs={12} md={3}>
            <Grid item container justifyContent="center">
              <BirthdayCard person={profile} />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}
