import { useTranslation } from 'react-i18next'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Loader from 'components/Loader/Loader'
import { PointsHistoryRow } from 'pages/MyAccount/components/PointsHistory/PointsHistoryRow'
import { useGetPointsHistory } from 'pages/MyAccount/hooks/useGetPointsHistory'
import { infinityQueryMap } from 'utils/hooks/infinityQueryMap'

export const PointsHistoryList = () => {
  const { t } = useTranslation()
  const { data, isInitialLoading, hasNextPage, fetchNextPage, isFetching } = useGetPointsHistory()
  const pointsHistoryRows = infinityQueryMap(data)?.data || []

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Loader isLoading={isInitialLoading} emptyLabel={t('app.no_elements')} objects={pointsHistoryRows}>
          <Box
            display="grid"
            alignItems="center"
            sx={(theme) => ({
              [theme.breakpoints.down('md')]: {
                px: 0,
                gridTemplateColumns: '1fr 1fr',
              },
              [theme.breakpoints.up('md')]: {
                px: 4,
                gridTemplateColumns: 'auto auto auto 1fr auto',
                [`& > :not(:nth-last-of-type(-n+6))`]: {
                  borderBottom: '1px solid #E0E0E0',
                },
              },
            })}
          >
            {pointsHistoryRows.map((row) => (
              <PointsHistoryRow key={row.transaction_id} row={row} />
            ))}
            {isFetching && Array.from(Array(5).keys()).map((value) => <PointsHistoryRow key={value} />)}
          </Box>
        </Loader>
      </Grid>
      {hasNextPage && (
        <Grid item xs={12} display="flex" justifyContent="center">
          <LoadingButton
            loading={isFetching}
            color="primary"
            variant="outlined"
            size="small"
            onClick={() => fetchNextPage()}
          >
            {t('app.show_more')}
          </LoadingButton>
        </Grid>
      )}
    </Grid>
  )
}
