import { useQuery } from '@tanstack/react-query'

import { fetchUserConsent } from './api'

export const userConsentQueryKeys = {
  all: ['all', 'userConsent'] as const,
  acceptConsent: () => [...userConsentQueryKeys.all, 'acceptConsent'] as const,
  fetchUserConsent: () => [...userConsentQueryKeys.all, 'fetchUserConsent'] as const,
}

export const useSelectUserConsent = () => {
  return useQuery({
    queryKey: userConsentQueryKeys.fetchUserConsent(),
    queryFn: fetchUserConsent,
  })
}
