import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'

export const UserConsent = ({ ...rest }: SvgIconProps) => {
  return (
    <SvgIcon {...rest} x="0px" y="0px" viewBox="0 0 100 100">
      <g>
        <g>
          <path d="M21.7,25.5c0,10.7,8.7,19.4,19.4,19.4s19.4-8.7,19.4-19.4S51.8,6.1,41.1,6.1S21.7,14.7,21.7,25.5z" />
        </g>
        <path
          d="M50.1,73.1c0-10,7.1-18.4,16.5-20.4c-1-1-2.2-2-3.4-2.8c-2.4-1.7-5.2-3.1-8.3-4.1c-4,2.7-8.7,4.3-13.9,4.3
		s-9.9-1.6-13.9-4.3c-3.1,1-5.8,2.4-8.3,4.1c-3.1,2.2-5.6,4.8-7.4,7.9C9.6,61,8.5,64.5,8.3,68.3c0,0.6,0,8.2,0,8.3
		c0,2.9,2.6,5.3,5.9,5.3h37.9C50.8,79.2,50.1,76.3,50.1,73.1z"
        />
        <path
          d="M70.9,57.4c-8.7,0-15.8,7.1-15.8,15.8S62.2,89,70.9,89s15.8-7.1,15.8-15.8S79.6,57.4,70.9,57.4z M80.8,70.9
		l-8,10.4c-0.5,0.6-1.2,1-2,1c0,0,0,0,0,0c-0.8,0-1.5-0.4-2-1L61,70.9c-0.8-1.1-0.6-2.7,0.5-3.5c1.1-0.8,2.7-0.6,3.5,0.5l5.9,7.8
		l6-7.8c0.8-1.1,2.4-1.3,3.5-0.5C81.5,68.2,81.7,69.8,80.8,70.9z"
        />
      </g>
    </SvgIcon>
  )
}
