import { postRequest } from 'utils/api/apiRequest'

export type ServiceRequest = {
  first_name: string
  last_name: string
  email: string
  phone_number: string
  subject: string
  project_name: string
  message: string
  order_reference_id: string
  /**
   * @remarks
   * This field works only for not logged-in users
   */
  language: string
}
export type ServiceRequestFormValues = ServiceRequest & {
  file_id_1?: Image
  file_id_1_name?: string
  file_id_2?: Image
  file_id_2_name?: string
  product: string
}
export type ServiceRequestData = ServiceRequest & {
  file_id_1?: number
  file_id_2?: number
  product: string
}

export type Image = { id: number; path: string }

export const postServiceRequest = (data: ServiceRequestData) => postRequest('/cms/service-request', data)
