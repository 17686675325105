import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Radio from '@mui/material/Radio'
import Typography from '@mui/material/Typography'
import { useStyles } from 'pages/CreateOrder/components/Bpost/useStyles'
import { BpostPointType } from 'utils/hooks/api/Bpost/bpostAxios'

type Props = {
  point: BpostPointType
  checked: boolean
}

export const BpostPoint = ({ point, checked }: Props) => {
  const { wrapperCollectionPoint, radio } = useStyles()
  const { t } = useTranslation()

  return (
    <Grid item xs={12}>
      <Grid container spacing={3} alignItems="center" className={wrapperCollectionPoint}>
        <Grid item>
          <FormControlLabel
            checked={checked}
            value={point?.Record?.Id?._text}
            control={<Radio color="primary" size="small" className={radio} />}
            label=""
            data-cy="parcel-locker-point-radio"
          />
        </Grid>
        <Grid item xs>
          <Typography variant="h3" fontWeight="bold">
            {point?.Record?.Name?._text}
          </Typography>
          <Typography variant="h3">
            {point?.Record?.Street?._text} {point?.Record?.Number?._text}
          </Typography>
          <Typography variant="h3">
            {point?.Record?.Zip?._text} {point?.Record?.City?._text}
          </Typography>
        </Grid>
        <Grid item sx={{ display: 'none' }}>
          <Button color="primary" size="small">
            {t('create_order.more_information')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
