import { useEffect } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { WithPagination } from 'utils/api/pagination'
import { CatalogProduct } from 'utils/api/Products/CatalogProduct'
import { fetchRecentlyViewedProducts } from 'utils/api/Products/fetchRecentlyViewedProducts'
import { postRecentlyViewedProduct } from 'utils/api/Products/postRecentlyViewedProduct'
import productsQueryKeys from 'utils/api/Products/productsQueryKeys'

type State = {
  recentlyViewedProducts: WithPagination<CatalogProduct>
}

type Props = {
  productId: number
}

export const useRecentlyViewedProducts = ({ productId }: Props) => {
  const queryClient = useQueryClient()

  const { mutate } = useMutation(postRecentlyViewedProduct, {
    onSuccess: () => {
      queryClient.invalidateQueries(productsQueryKeys.recentlyViewedLists())
    },
  })

  useEffect(() => {
    if (productId) {
      mutate(productId)
    }
  }, [productId, mutate])

  return useQuery({
    queryKey: productsQueryKeys.recentlyViewedList(),
    queryFn: fetchRecentlyViewedProducts,
  })
}
