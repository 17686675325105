import { getRequest } from '../apiRequest'
import { BirthdayPerson } from './BirthdayPerson'

interface BirthdayResponse {
  [month: string]: BirthdayPerson[]
}

export const fetchBirthdays = (organisation: string) => {
  return getRequest<BirthdayResponse>(
    `/social-wall/profiles/birthdays/?${organisation ? `organisation=${organisation}` : ''}`
  )
}
