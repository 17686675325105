import { useQuery } from '@tanstack/react-query'
import isEmpty from 'lodash/isEmpty'
import { getRequest } from 'utils/api/apiRequest'
import { isLoggedIn } from 'utils/axios-jwt-cookies'

export const checkTokenQueryKeys = {
  all: ['all', 'checkToken'] as const,
  getCheckToken: (token: string) => [...checkTokenQueryKeys.all, token] as const,
}

const getCheckToken = (token: string) => getRequest(`/validate-activation-token/${token}`)

export const useCheckToken = (token: string) => {
  return useQuery({
    queryKey: checkTokenQueryKeys.getCheckToken(token),
    queryFn: () => getCheckToken(token),
    enabled: !isLoggedIn() && !isEmpty(token),
  })
}
