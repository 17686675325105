import { Column, EditCellColumnDef } from '@material-table/core'
import DateRange from '@mui/icons-material/DateRange'
import { inputAdornmentClasses } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import DisplayDate from 'components/DisplayDate'
import { TextField } from 'components/TextField/TextField'
import { differenceInCalendarDays, format, isValid } from 'date-fns'
import { dateFormat } from 'utils/dateFormats'
import { makeSx } from 'utils/styles/makeSx'

const RANGE_FILTER = '<value<'
const INVALID_DATE = 'invalidDate'

const formatDate = (d: Date) => format(d, dateFormat)
const isInvalidDate = (d: string) => d === INVALID_DATE
const dateOrNull = (d: string) => (d ? new Date(d) : null)
const isInvalidDateStringLength = (d = '') => d.length && d.length !== 10

const OpenPickerIcon = () => <DateRange fontSize="small" />

type Props<T extends {}> = {
  columnDef: Column<T> & Partial<Pick<EditCellColumnDef, 'tableData'>>
  onFilterChanged: (rowId: number, value: any) => void
}

export const DateRangeFilter = <T extends {}>({ columnDef, onFilterChanged }: Props<T>) => {
  const {
    tableData: { id, filterValue },
  } = columnDef
  const [minString, maxString] = filterValue?.[0]?.split(RANGE_FILTER) ?? ['', '']

  const onChangeMin = (dateValue: Date) => {
    if (!dateValue) {
      if (isInvalidDate(maxString)) return
      onFilterChanged(id, maxString ? [`${RANGE_FILTER}${maxString}`] : [])
      return
    }
    if (!!dateValue && isValid(dateValue)) {
      const formattedMinString = formatDate(dateValue)
      if (isInvalidDate(maxString)) return
      if (maxString && differenceInCalendarDays(new Date(maxString), dateValue) < 0) {
        return
      }
      onFilterChanged(id, [`${formattedMinString}${RANGE_FILTER}${maxString}`])
    }
  }

  const onChangeMax = (dateValue: Date) => {
    if (!dateValue) {
      if (isInvalidDate(minString)) return
      onFilterChanged(id, minString ? [`${minString}${RANGE_FILTER}`] : [])
      return
    }
    if (!!dateValue && isValid(dateValue)) {
      const formattedMaxString = formatDate(dateValue)
      if (isInvalidDate(minString)) return
      if (minString && differenceInCalendarDays(new Date(minString), dateValue) > 0) {
        return
      }
      onFilterChanged(id, [`${minString}${RANGE_FILTER}${formattedMaxString}`])
    }
  }

  const BUTTON_SIZE = 36

  const sxBox = makeSx((theme) => ({
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 24,
    minWidth: BUTTON_SIZE,
    height: BUTTON_SIZE,
    display: 'flex',
    alignItems: 'center',
    [`& .${inputAdornmentClasses.root}`]: {
      height: BUTTON_SIZE,
      marginLeft: 0,
    },
    '& button': {
      width: BUTTON_SIZE,
      height: BUTTON_SIZE,
      margin: 0,
    },
    '& .value': {
      minWidth: 72,
      marginLeft: theme.spacing(1),
      fontWeight: 'bold',
      lineHeight: 1,
    },
  }))

  return (
    <Grid container wrap="nowrap" alignItems="center">
      <Grid item>
        <DesktopDatePicker
          slotProps={{
            actionBar: {
              actions: ['clear'],
            },
          }}
          format={dateFormat}
          value={dateOrNull(minString)}
          maxDate={dateOrNull(maxString)}
          onChange={onChangeMin}
          slots={{
            openPickerIcon: OpenPickerIcon,
            textField: ({ InputProps, ...props }) => (
              <Box ref={InputProps?.ref} sx={sxBox}>
                {minString && (
                  <Box className="value">
                    <DisplayDate dateString={minString} options={{ formatTemplate: dateFormat }} />
                  </Box>
                )}
                {InputProps?.endAdornment}
                <TextField {...props} sx={{ display: 'none' }} />
              </Box>
            ),
          }}
        />
      </Grid>
      <Grid item>-</Grid>
      <Grid item>
        <DesktopDatePicker
          slotProps={{
            actionBar: {
              actions: ['clear'],
            },
          }}
          format={dateFormat}
          value={dateOrNull(maxString)}
          minDate={dateOrNull(minString)}
          onChange={onChangeMax}
          slots={{
            openPickerIcon: OpenPickerIcon,
            textField: ({ InputProps, ...props }) => (
              <Box ref={InputProps?.ref} sx={sxBox}>
                {maxString && (
                  <Box className="value">
                    <DisplayDate dateString={maxString} options={{ formatTemplate: dateFormat }} />
                  </Box>
                )}
                {InputProps?.endAdornment}
                <TextField {...props} sx={{ display: 'none' }} />
              </Box>
            ),
          }}
        />
      </Grid>
    </Grid>
  )
}
