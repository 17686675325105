import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import { Codes } from 'components/ArteelIcons/Codes'
import { PersonalDetails } from 'components/ArteelIcons/PersonalDetails'
import { Profiles } from 'components/ArteelIcons/Profiles'
import { HeaderPage } from 'components/HeaderPage/HeaderPage'
import { Stepper } from 'components/Stepper/Stepper'
import { EnterCode } from 'pages/Login/ActivateAccount/EnterCode'
import { EnterCustomerInternalId } from 'pages/Login/ActivateAccount/EnterCustomerInternalId'
import { EnterPersonalDetails } from 'pages/Login/ActivateAccount/EnterPersonalDetails'
import { EnterCustomerInternalIdResponse } from 'pages/Login/api/useEnterCustomerInternalId'
import { getResponsiveSize } from 'theme/styles.utils'
import { getObjectTranslation } from 'utils/getObjectTranslation'
import { User } from 'utils/hooks/userType'
import { TranslationObj } from 'utils/types/TranslationObj'

export const ActivateAccount = () => {
  const [step, setStep] = useState(0)
  const [loadedSteps, setLoadedSteps] = useState<number[]>([])
  const [user, setUser] = useState<User | null>(null)
  const [notLinkedUser, setNotLinkedUser] = useState<EnterCustomerInternalIdResponse | null>(null)
  const [code, setCode] = useState<string | null>(null)
  const [hint, setHint] = useState<TranslationObj<'content'>>()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const steps = useMemo(
    () => [
      {
        id: 0,
        label: t('auth.activate_account.enter_code'),
        icon: <Codes />,
        content: (
          <EnterCode
            disabled={step !== 0}
            onSuccess={(data) => {
              if (data?.user) {
                setStep(2)
                setLoadedSteps([2])
              } else {
                setStep(1)
                setLoadedSteps([1, 2])
              }
              setUser(data?.user)
              setCode(data?.code)
              setHint(data?.personal_id_hint)
            }}
          />
        ),
      },
      {
        id: 1,
        label: t('auth.activate_account.enter_personal_id'),
        optionalLabel: getObjectTranslation(hint?.translation)?.content,
        icon: <PersonalDetails />,
        lazyLoaded: true,
        content: (
          <EnterCustomerInternalId
            onSuccess={(data) => {
              setStep(2)
              setNotLinkedUser(data)
            }}
            onBack={() => {
              setLoadedSteps([])
              setStep(0)
            }}
            disabled={step !== 1}
          />
        ),
      },
      {
        id: 2,
        label: t('auth.activate_account.enter_personal_information'),
        icon: <Profiles />,
        lazyLoaded: true,
        content: (
          <EnterPersonalDetails
            code={code}
            user={user}
            notLinkedUser={notLinkedUser}
            onBack={() => {
              setLoadedSteps([])
              setStep(0)
            }}
            onSuccess={({ email }) => navigate(`/auth/activate-account/confirmation/${email}`)}
          />
        ),
      },
    ],
    [step]
  )

  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      })}
    >
      <Box>
        <HeaderPage text={t('auth.activate_account')} uppercase={false} color="medium" />
        <Box
          sx={(theme) => ({
            margin: 2,
            [theme.breakpoints.up('lg')]: { width: getResponsiveSize(800) },
          })}
        >
          <Stepper activeStep={step} steps={steps} loadedSteps={loadedSteps} />
        </Box>
      </Box>
    </Box>
  )
}
