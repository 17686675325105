import { LanguageSubscriber } from 'utils/api/Languages/types'
import { WithPagination } from 'utils/api/pagination'

const CODES = ['en', 'nl', 'fr']

export const orderLanguages = <T>(languages: WithPagination<T extends LanguageSubscriber ? T : never>) => {
  try {
    const { data } = languages

    const en = data.find((el) => el.language.code === CODES[0])
    const nl = data.find((el) => el.language.code === CODES[1])
    const fr = data.find((el) => el.language.code === CODES[2])

    const filteredData = data
      .filter((el) => !CODES.includes(el.language.code))
      .sort((lang1, lang2) => lang1.language.name?.localeCompare(lang2.language.name))

    const orderedData = [en, nl, fr, ...filteredData].filter(Boolean)

    return { ...languages, data: orderedData }
  } catch (e) {
    return languages
  }
}
