import { useTranslation } from 'react-i18next'
import { Locale } from 'date-fns'
import en from 'date-fns/locale/en-GB'
import fr from 'date-fns/locale/fr'
import nl from 'date-fns/locale/nl'

export const useDateLocale = () => {
  const { i18n } = useTranslation()
  // const [locale, setLocale] = useState(undefined)

  // useEffect(() => {
  //   ;(async function () {
  //     try {
  //       const localeModule = await import(`date-fns/locale/${i18n.language === 'en' ? 'en-US' : i18n.language}`)
  //       setLocale(localeModule.default)
  //     } catch (e) {
  //       console.log('e', e)
  //       setLocale(en)
  //     }
  //   })()
  // }, [i18n.language])
  const all: Record<typeof i18n.language, Pick<Locale, any>> = {
    en: en as any,
    fr: fr as unknown as Locale,
    nl: nl as unknown as Locale,
  }

  return { locale: all?.[i18n.language] ?? en }
}
