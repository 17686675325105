import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Image } from 'components/Image/Image'
import { useSelectShoppingCart } from 'shared/ShoppingCart/slice'
import { WishlistItem } from 'shared/Wishlist/api'
import { fontFamilies, getResponsiveSize } from 'theme/styles.utils'
import { displayPoints } from 'utils/displayPoints'
import { getObjectTranslation } from 'utils/getObjectTranslation'
import { getProductName } from 'utils/getProductName'
import { useAddItemToCart } from 'utils/hooks/api/ShoppingCart/useAddItemToCart'
import { useRemoveWishlistItem } from 'utils/hooks/api/Wishlist/useRemoveWishlistItem'
import { useIsMobile } from 'utils/hooks/useBreakpoints'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'
import { usePointsName } from 'utils/hooks/usePointsName'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  imageWrapper: {
    width: getResponsiveSize(220),
    height: getResponsiveSize(220),
    display: 'flex',
    justifyContent: 'center',
  },
  brand: {
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.2,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.h3.fontSize,
    },
  },
  points: {
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.2,
    [theme.breakpoints.down('sm')]: {
      fontSize: getResponsiveSize(0.8, 'rem'),
      fontFamily: fontFamilies.default,
      lineHeight: 1.8,
      whiteSpace: 'nowrap',
    },
  },
  name: {
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[600],
    lineHeight: 1.2,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.h3.fontSize,
    },
  },
  buttonText: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.h3.fontSize,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.h4.fontSize,
    },
  },
  buttonsWrapper: {
    marginLeft: theme.spacing(-0.625),
    [theme.breakpoints.down('md')]: {
      order: 3,
    },
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      order: 2,
    },
  },
}))

type Props = {
  item: WishlistItem
}

export const WishlistItemView = ({ item }: Props) => {
  const { imageWrapper, brand, name, buttonText, buttonsWrapper, imageContainer, points } = useStyles()
  const { t } = useTranslation()
  const { mutate: handleAddItemToShoppingCart } = useAddItemToCart({})
  const { mutate: onRemoveWishlistItem } = useRemoveWishlistItem()
  const { data: shoppingCartItems } = useSelectShoppingCart()
  const user = useCurrentUser()
  const currentValue = shoppingCartItems?.data?.total_order_value || 0
  const actualUserPoints = user?.available_points || 0
  const remainingPoints = actualUserPoints - currentValue > 0 ? actualUserPoints - currentValue : 0
  const isMobile = useIsMobile()
  const pointsName = usePointsName(item.points)

  const mainMedia = item.product.media.find((m) => m.main)
  const firstMedia = item.product?.media?.length > 0 ? item.product.media[0] : undefined
  const thumbMedia = mainMedia || firstMedia

  const Buttons = () => (
    <Grid item xs={12} className={buttonsWrapper}>
      <Grid container spacing={1} alignItems="center" wrap="nowrap" justifyContent={isMobile ? 'center' : 'flex-start'}>
        <Grid item>
          <Button size="small" color="primary" className={buttonText} onClick={() => onRemoveWishlistItem(item.id)}>
            {t('app.remove')}
          </Button>
        </Grid>
        <Grid item>|</Grid>
        <Grid item>
          <Button
            size="small"
            color="primary"
            className={buttonText}
            onClick={() => handleAddItemToShoppingCart(item.product.id)}
            disabled={remainingPoints < item.points}
          >
            {t('product.move_to_shopping_cart')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} md="auto" className={imageContainer}>
        <div className={imageWrapper}>
          <Image src={thumbMedia?.path} alt={getProductName(item.product)} />
        </div>
      </Grid>
      <Grid item xs={12} md>
        <Grid container spacing={2}>
          <Grid item xs>
            <Grid container>
              <Grid item xs={12} className={brand}>
                {item.product?.brand?.name || ''}
              </Grid>
              <Grid item xs={12} className={name}>
                {getObjectTranslation(item.product?.translation)?.name || item.product?.navision_name || ''}
              </Grid>
              {!isMobile && <Buttons />}
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Typography align="right" variant="h2" className={points}>
              {displayPoints(item.points)} {pointsName}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {isMobile && <Buttons />}
    </Grid>
  )
}
