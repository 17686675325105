import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import { formatDistanceToNowStrict } from 'date-fns/formatDistanceToNowStrict'
import { useDateLocale } from 'utils/hooks/useDateLocale'

export const MyScoreText = ({ lastRecognitionDate }: { lastRecognitionDate: string }) => {
  const { t } = useTranslation()
  const { locale } = useDateLocale()

  if (!lastRecognitionDate) return null

  try {
    return (
      <>
        <Typography
          component={'span'}
          sx={(theme) => ({
            fontWeight: theme.typography.fontWeightBold,
            fontSize: theme.typography.h2.fontSize,
          })}
        >
          {formatDistanceToNowStrict(new Date(lastRecognitionDate), {
            // @ts-ignore
            locale,
            unit: 'day',
          })}
        </Typography>{' '}
        <Typography component={'span'} sx={(theme) => ({ fontSize: theme.typography.h2.fontSize })}>
          {t('my_score.since_last')}
        </Typography>
      </>
    )
  } catch (e) {
    return null
  }
}
