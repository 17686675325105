import { useTranslation } from 'react-i18next'
import { Panel } from 'components/Panel/Panel'
import { PanelProductGrid } from 'components/PanelProductGrid/PanelProductGrid'
import { CatalogProduct } from 'utils/api/Products/CatalogProduct'
import { useRecentlyViewedProducts } from 'utils/hooks/api/useRecentlyViewedProducts'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    maxWidth: '100%',
    margin: theme.spacing(2),
  },
}))
type Props = {
  catalogProduct: CatalogProduct
}

export const Panels = ({ catalogProduct }: Props) => {
  const { wrapper } = useStyles()
  const { t } = useTranslation()
  const { data: recentlyViewedProductsData, isFetching: isLoading } = useRecentlyViewedProducts({
    productId: catalogProduct.product.id,
  })
  const recentlyViewedProducts = recentlyViewedProductsData?.data

  /*  NOTE: will be used in future */
  // const { data: relatedProductsData, isLoading: isLoadingRelatedProducts } = useRelatedProducts({
  //   productId: catalogProduct.product.id,
  // })
  // const relatedProducts = relatedProductsData?.data

  return (
    <div className={wrapper}>
      {/*  NOTE: will be used in future*/}
      {/* <Panel*/}
      {/*  initialValue*/}
      {/*  title={t('product.related_products')}*/}
      {/*  content={*/}
      {/*    <PanelProductGrid catalogProducts={relatedProducts?.data || []} isLoading={isLoadingRelatedProducts} />*/}
      {/*  }*/}
      {/*/ >*/}
      <Panel
        initialValue
        title={t('product.recently_products')}
        content={<PanelProductGrid catalogProducts={recentlyViewedProducts?.data || []} isLoading={isLoading} />}
      />
    </div>
  )
}
