import { FunctionComponent } from 'react'
import { CmsContent } from 'pages/CMS/CMS.types'
import { ValueCodesWidget } from 'pages/CMS/components/ContentBox/ValueCodesWidget'

import { logicBoxTypes } from '../../constants'
import { LanguagesContent } from './LanguagesContent'
import { UserProfileContent } from './UserProfileContent'

type Props = {
  content: CmsContent
}

export const LogicContent: FunctionComponent<Props> = ({ content }) => {
  const renderLogicBoxContent = () => {
    switch (content.logic_box_type) {
      case logicBoxTypes.logic_box_profile:
        return <UserProfileContent />
      case logicBoxTypes.logic_box_languages:
        return <LanguagesContent />
      case logicBoxTypes.logic_box_value_codes:
        return <ValueCodesWidget />
      default:
        return null
    }
  }

  return renderLogicBoxContent()
}
