import { FunctionComponent, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Apps from '@mui/icons-material/Apps'
import { darken, useTheme } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { Box } from '@mui/system'
import { Arrow } from 'components/ArteelIcons'
import { drawerContext } from 'components/DrawerSelect/drawerContext'
import { Image } from 'components/Image/Image'
import Loader from 'components/Loader/Loader'
import { useShowCategoriesTree } from 'components/ShopAll/useShowCategoriesTree'
import { ROOT_CATEGORY_ID } from 'pages/HomeRedemptionSite/utils'
import { getResponsiveSize, otherColors } from 'theme/styles.utils'
import { Category } from 'utils/api/Categories/fetchCategories'
import { getObjectTranslation } from 'utils/getObjectTranslation'
import { useCategories } from 'utils/hooks/api/useCategories'

const AVATAR_SIZE = 52

export const ShopAll: FunctionComponent = () => {
  const { t } = useTranslation()
  const showCategoriesTree = useShowCategoriesTree()
  const { data, isFetching } = useCategories(!showCategoriesTree)
  const [activeCategory, setActiveCategory] = useState<{ id: number; name: string }>()
  const [activeSecondCategory, setActiveSecondCategory] = useState<{ id: number; name: string }>()
  const navigate = useNavigate()
  const { closeDrawer } = useContext(drawerContext)
  const categories = data?.data?.categories_tree?.[0]?.__children || []

  const showCategory = (categoryId: number) => {
    closeDrawer()
    navigate(`/catalog/categories/${categoryId}`)
    setActiveCategory(undefined)
    setActiveSecondCategory(undefined)
  }

  const selectedCategory = categories?.find((category) => category.id === activeCategory?.id)
  const selectedSecondCategory = selectedCategory?.__children?.find(
    (category) => category.id === activeSecondCategory?.id
  )
  const theme = useTheme()

  return (
    <Loader isLoading={isFetching}>
      <Container maxWidth="lg" sx={{ [theme.breakpoints.up(1921)]: { maxWidth: 2400 } }}>
        <Box
          sx={(theme) => ({
            padding: theme.spacing(2, 12),
            color: theme.palette.text.primary,
            fontSize: `${getResponsiveSize(1)}rem`,
          })}
        >
          {showCategoriesTree && (
            <>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  lg={4}
                  sx={(theme) => ({
                    fontWeight: '900',
                    fontSize: theme.typography.h1.fontSize,
                    padding: theme.spacing(1, 0),
                    textAlign: 'center',
                    color: theme.palette.text.primaryLight,
                  })}
                  data-cy="shop_all.categories"
                >
                  {t('shop_all.categories')}
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={4}
                  sx={(theme) => ({
                    fontWeight: '900',
                    fontSize: theme.typography.h1.fontSize,
                    padding: theme.spacing(1, 0),
                    textAlign: 'center',
                    color: theme.palette.text.primaryLight,
                  })}
                >
                  {activeCategory?.name}
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={4}
                  sx={(theme) => ({
                    fontWeight: '900',
                    fontSize: theme.typography.h1.fontSize,
                    padding: theme.spacing(1, 0),
                    textAlign: 'center',
                    color: theme.palette.text.primaryLight,
                  })}
                >
                  {activeSecondCategory?.name}
                </Grid>
              </Grid>

              <Box display="grid" gridTemplateColumns="1fr 1fr 1fr">
                <Box
                  sx={(theme) => ({
                    borderRight: `2px solid ${otherColors.separator}`,
                    [theme.breakpoints.down('lg')]: {
                      borderTop: '2px solid #e3e2d9',
                      borderRight: 'none',
                    },
                  })}
                >
                  <List dense sx={{ paddingInline: 0, margin: 0, padding: 0 }}>
                    {categories?.map((category, index) => (
                      <Item
                        isFirstElement={index === 0}
                        category={category}
                        key={category.id}
                        isActive={category?.id === activeCategory?.id}
                        haveChildren={!!category.__children?.length}
                        isNavCategory
                        onClick={(data) => {
                          if (!category.__children?.length) {
                            showCategory(category.id)
                          } else {
                            setActiveCategory(data)
                          }
                          setActiveSecondCategory(undefined)
                        }}
                      />
                    ))}
                    {!!categories?.length && (
                      <Item isNavCategory showAll onClick={() => showCategory(ROOT_CATEGORY_ID)} />
                    )}
                  </List>
                </Box>
                {selectedCategory && (
                  <Box
                    sx={(theme) => ({
                      borderRight: `2px solid ${otherColors.separator}`,
                      [theme.breakpoints.down('lg')]: {
                        borderTop: '2px solid #e3e2d9',
                        borderRight: 'none',
                      },
                    })}
                  >
                    <List dense sx={{ paddingInline: 0, margin: 0, padding: 0 }}>
                      {selectedCategory?.__children?.map((category, index) => (
                        <Item
                          isFirstElement={index === 0}
                          category={category}
                          key={category.id}
                          isActive={category?.id === activeSecondCategory?.id}
                          haveChildren={!!category.__children?.length}
                          onClick={({ id, name }) => {
                            if (!category.__children?.length) {
                              showCategory(category.id)
                            } else {
                              setActiveSecondCategory({ id, name })
                            }
                          }}
                        />
                      ))}
                      {!!selectedCategory?.__children?.length && (
                        <Item showAll onClick={() => showCategory(selectedCategory?.id)} />
                      )}
                    </List>
                  </Box>
                )}
                {selectedSecondCategory && (
                  <Box
                    sx={(theme) => ({
                      borderRight: `2px solid ${otherColors.separator}`,
                      [theme.breakpoints.down('lg')]: {
                        borderTop: '2px solid #e3e2d9',
                        borderRight: 'none',
                      },
                    })}
                  >
                    <List dense sx={{ paddingInline: 0, margin: 0, padding: 0 }}>
                      {selectedSecondCategory?.__children?.map((category, index) => (
                        <Item
                          isFirstElement={index === 0}
                          category={category}
                          key={category.id}
                          haveChildren={!!category.__children?.length}
                          onClick={() => showCategory(category.id)}
                        />
                      ))}
                      {!!selectedSecondCategory?.__children?.length && (
                        <Item showAll onClick={() => showCategory(selectedSecondCategory?.id)} />
                      )}
                    </List>
                  </Box>
                )}
              </Box>
            </>
          )}
        </Box>
      </Container>
    </Loader>
  )
}

const Item = ({
  category,
  isActive,
  onClick,
  showAll,
  haveChildren,
  isNavCategory,
  isFirstElement,
}: {
  category?: Category
  isActive?: boolean
  onClick?: ({ id, name }?: { id: number; name: string }) => void
  showAll?: boolean
  haveChildren?: boolean
  isNavCategory?: boolean
  isFirstElement?: boolean
}) => {
  const { t } = useTranslation()

  return (
    <ListItem
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 0,
        marginTop: isFirstElement ? 0 : 1,
        marginBottom: showAll ? 0 : 1,
        color: isActive || showAll ? theme.palette.primary.main : undefined,

        ...(isActive && !showAll
          ? {
              position: 'relative',
              listStyleType: 'none',

              fontWeight: 'bold',
              background: darken(theme.palette.background.default, 0.05),
              '& > *': {
                color: theme.palette.primary.main,
              },
            }
          : {}),
      })}
    >
      <ListItemButton
        sx={{ padding: 0, paddingRight: 2, paddingLeft: isNavCategory ? 0 : 2 }}
        dense
        role={undefined}
        onClick={() =>
          onClick?.({
            id: category?.id,
            name: `${getObjectTranslation(category?.translation)?.name || category?.name || '-'}`,
          })
        }
      >
        <ListItemAvatar sx={{ mr: 3, ml: !isNavCategory && -4 }}>
          <Avatar
            variant="square"
            sx={{
              width: AVATAR_SIZE,
              height: AVATAR_SIZE,
              background: showAll && isNavCategory ? undefined : 'transparent',
            }}
          >
            {showAll && isNavCategory ? (
              <Apps />
            ) : category?.image && !showAll && isNavCategory ? (
              <Image src={category?.image} alt={category?.name} square />
            ) : (
              <Box sx={{ width: AVATAR_SIZE, height: AVATAR_SIZE }} />
            )}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primaryTypographyProps={{ sx: { fontSize: getResponsiveSize(1.25, 'rem') } }}>
          {showAll
            ? t('shop_all.show_all')
            : getObjectTranslation(category?.translation)?.name || category?.name || '-'}
        </ListItemText>
        {haveChildren && <Arrow fontSize="small" />}
      </ListItemButton>
    </ListItem>
  )
}
