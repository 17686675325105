import { ReactNode } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { fontFamilies } from 'theme/styles.utils'

type Props = {
  children: ReactNode
  onClick?: () => void
  checked?: boolean
  primary?: boolean
  endAdornment?: ReactNode
}

export const Row = ({ children, onClick, checked, endAdornment, primary }: Props) => {
  return (
    <Typography
      sx={{
        fontSize: (theme) => theme.typography.h2.fontSize,
        fontFamily: fontFamilies.cond,
        fontWeight: checked ? 'bold' : 'normal',
        lineHeight: 2,
        cursor: onClick ? 'pointer' : 'default',
        position: 'relative',
        color: primary ? 'primary.main' : 'text.primary',
        ':hover': {
          color: 'primary.main',
        },
      }}
      onClick={onClick}
    >
      {children}
      <Box sx={{ position: 'absolute', top: 0, right: 0 }}>{endAdornment}</Box>
    </Typography>
  )
}
