import EditIcon from '@mui/icons-material/Edit'
import { RenderEditAction } from 'utils/actionsCreator/types'

import { CustomIconButton } from './CustomIconButton'

export const renderEditAction =
  <T,>({ hidden, ...args }: RenderEditAction<T>) =>
  (rowData: T) =>
    hidden ? null : (
      <CustomIconButton
        tooltipMessage="app.edit_item"
        color="primary"
        dataCy="edit-icon-button"
        // @ts-ignore
        rowData={rowData}
        {...args}
      >
        <EditIcon />
      </CustomIconButton>
    )
