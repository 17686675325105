import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'

import { IconActive } from './IconActive'

export const Search = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 200 200">
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M118,136.1c-1.7,1-3.3,2-4.8,2.8c-7.1,3.9-14.7,6.3-22.7,7.1c-11.9,1.3-23.3-0.6-34-5.9  c-11.3-5.5-20.2-13.8-26.7-24.7c-4.3-7.2-7.1-14.9-8.2-23.1C18.7,72.7,23.8,55.1,37,40.1c9.7-11,21.9-17.7,36.3-20.1  c14.8-2.5,28.9,0.1,41.8,7.8c14.8,8.8,24.5,21.7,29.1,38.3c1.3,4.8,2,9.7,2.2,14.7c0.4,13.6-3.2,26-10.6,37.3  c-0.1,0.2-0.2,0.4-0.4,0.7c0.2,0.2,0.5,0.5,0.7,0.7c11.6,11.7,23.1,23.4,34.7,35.2c1.7,1.7,3.3,3.3,5,5c2.3,2.4,3.5,5.3,3.6,8.7  c0.1,5-2.9,9.5-7.5,11.5c-4.5,1.9-9.7,1-13.3-2.6c-6.2-6.2-12.3-12.4-18.4-18.6c-7.2-7.3-14.3-14.5-21.5-21.8  C118.3,136.6,118.2,136.4,118,136.1z M83.6,43.9c-2.8,0-5.6,0.3-8.4,0.9c-7.4,1.7-13.8,5.2-19.1,10.8C47,65.3,43.6,76.8,45.8,89.8  c1.3,7.6,4.6,14.3,10,19.8c9.5,9.8,21.1,13.7,34.7,11.4c6.9-1.1,12.9-4.2,18.1-8.8c8.5-7.5,12.9-17,13.4-28.4  c0.1-3.9-0.4-7.7-1.4-11.4C116.1,55.7,100.9,44,83.6,43.9z"
    />
  </SvgIcon>
)
