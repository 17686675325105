import { useInfiniteQuery } from '@tanstack/react-query'
import qs from 'query-string'
import { getSocialWallPosts } from 'utils/api/SocialWall/getSocialWallPosts'
import { useSWQueryHelpers } from 'utils/hooks/api/SocialWall/utils'

export const useGetPosts = () => {
  const { search, t, enqueueSnackbar, postsFilteredKey } = useSWQueryHelpers()
  return useInfiniteQuery(
    postsFilteredKey,
    ({ pageParam }) => getSocialWallPosts({ pageParam, params: qs.parse(search, { decode: false }) }),
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.data.page + 1
        return nextPage > lastPage.data.pages ? false : nextPage
      },
      onError: () => {
        enqueueSnackbar(t('recognition_post.posts.fetch.fail'), { variant: 'error' })
      },
      cacheTime: 1 * (60 * 1000), // 1 minute
      refetchOnWindowFocus: true,
      refetchIntervalInBackground: true,
      refetchInterval: 2 * (60 * 1000), // 2 minutes
    }
  )
}
