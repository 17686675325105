import React, { ReactElement } from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import { Tooltip } from 'components/Tooltip'
import { CustomIconButtonProps } from 'utils/actionsCreator/types'
import { funcOrBool, funcOrString, sizeParams } from 'utils/actionsCreator/utils'
import { TranslationsKeys } from 'utils/createTranslationKey'

export const CustomIconButton = <T extends {} = { id: number }>({
  tooltipMessage,
  children,
  onClick,
  color = 'primary',
  rowData,
  isDisabled,
  isLoading,
  size = 'medium',
  tooltipMessageDisabled,
  dataCy = 'custom-icon-button',
  popupMessage,
  ...props
}: CustomIconButtonProps<T>) => {
  const disabled = funcOrBool(rowData, isDisabled)
  const loading = funcOrBool(rowData, isLoading)
  const { loaderSize, buttonSize, fontSize } = sizeParams[size]
  const onClickHandler = () =>
    // popupMessage
    //   ? showRemoveDialog({
    //       textMessage: funcOrString(rowData, popupMessage),
    //       handleSubmit: () => onClick(rowData),
    //     })
    //   :
    onClick(rowData)

  if (!children) {
    return null
  }

  if (loading) {
    return (
      <IconButton size={buttonSize}>
        <CircularProgress size={loaderSize} />
      </IconButton>
    )
  }

  if (typeof children === 'string') {
    return <Box sx={{ pr: '8px' }}>{children}</Box>
  }

  return (
    <TooltipWrapper
      tooltipMessageDisabled={tooltipMessageDisabled}
      tooltipMessage={tooltipMessage}
      disabled={disabled}
      rowData={rowData}
    >
      <IconButton
        onClick={onClickHandler}
        size={buttonSize}
        color={color}
        disabled={disabled}
        data-cy={dataCy}
        {...props}
      >
        {React.cloneElement(children, { fontSize, ...children.props })}
      </IconButton>
    </TooltipWrapper>
  )
}

type TooltipWrapperProps<T> = {
  children: ReactElement
  disabled?: boolean
  tooltipMessageDisabled?: ((v: any) => TranslationsKeys) | TranslationsKeys
  tooltipMessage?: TranslationsKeys | { title: TranslationsKeys; interpolation?: { [key: string]: string } }
  rowData: T
}

const TooltipWrapper = <T extends {} = { id: number }>({
  children,
  tooltipMessageDisabled,
  disabled,
  rowData,
  tooltipMessage,
}: TooltipWrapperProps<T>) => {
  const tooltipTitle = funcOrString(rowData, tooltipMessageDisabled) as TranslationsKeys

  if (disabled && tooltipMessageDisabled) {
    return (
      <Tooltip title={tooltipTitle} disabled={disabled}>
        {children}
      </Tooltip>
    )
  }

  if (disabled || !tooltipMessage) {
    return children
  }

  return <Tooltip title={tooltipMessage}>{children}</Tooltip>
}
