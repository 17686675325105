import { useMutation } from '@tanstack/react-query'
import { getRequest } from 'utils/api/apiRequest'
import { Profile } from 'utils/api/User'
import { isAxiosResponse } from 'utils/isAxiosResponse'

type EnterCustomerInternalId = {
  customer_internal_id: string
}

export type EnterCustomerInternalIdResponse = {
  email: string | null
  id: number
  profile: Pick<
    Profile,
    'avatar' | 'avatar_relative_path' | 'customer_internal_id' | 'email' | 'first_name' | 'id' | 'last_name'
  >
}

const patchEnterCode = (data: EnterCustomerInternalId) =>
  getRequest<EnterCustomerInternalIdResponse>(
    `/activation-codes/validate-user?customer_internal_id=${data?.customer_internal_id}`
  )

export const useEnterCustomerInternalId = (onSuccess: (data: EnterCustomerInternalIdResponse) => void) => {
  return useMutation(patchEnterCode, {
    onSuccess: (data, variables) => {
      if (isAxiosResponse(data)) {
        onSuccess({
          ...data?.data,
          profile: { ...data?.data?.profile, customer_internal_id: variables?.customer_internal_id },
        })
      }
    },
  })
}
