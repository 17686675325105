import MuiAvatar from '@mui/material/Avatar'
import { AutocompleteUserAvatarProps } from 'components/AutocompleteUsers/types'
import { getNameInitials } from 'components/Avatar/Avatar'
import { fontSizes, getResponsiveSize } from 'theme/styles.utils'
import { important } from 'utils/styles/stylesUtils'

export const AutocompleteUsersAvatar = ({ avatar, sx, full_name, ...props }: AutocompleteUserAvatarProps) => (
  <MuiAvatar
    src={avatar}
    sx={{
      width: important(getResponsiveSize(24, 'px')),
      height: important(getResponsiveSize(24, 'px')),
      fontSize: important(fontSizes.h5),
      fontWeight: 400,
      ...sx,
    }}
    {...props}
  >
    {!avatar ? getNameInitials(full_name).join('').toUpperCase() : null}
  </MuiAvatar>
)
