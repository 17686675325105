import { useQuery } from '@tanstack/react-query'
import { getVoucherStatus } from 'utils/api/ReceiveVoucher/getVoucherStatus'
import voucherQueryKeys from 'utils/api/ReceiveVoucher/voucherQueryKeys'

export const useGetVoucherStatus = (token: string) => {
  return useQuery({
    queryKey: voucherQueryKeys.voucherStatus(token),
    queryFn: () => getVoucherStatus(token),
  })
}
