import { useQuery } from '@tanstack/react-query'
import { personalMessageKeys } from 'pages/PersonalMessages/api/keys'
import { getRequest } from 'utils/api/apiRequest'
import { WithPagination } from 'utils/api/pagination'

export type SubscriberTemplate = {
  id: number
  translation: Record<string, { name: string }>
  message_templates: {
    id: number
    default: boolean
    translation: Record<string, { name: string; content: string }>
  }[]
}

const getSubscriberTemplatesByOccasionId = (occasionId: number | string) =>
  getRequest<WithPagination<SubscriberTemplate>>(
    `/subscriber/message-templates/grouped?limit=1000&filters[id]=${occasionId}`
  )

export const useGetSubscriberTemplatesByOccasionId = (occasionId: number) => {
  return useQuery({
    enabled: !!occasionId,
    queryKey: personalMessageKeys.getSubscriberTemplatesByOccasionId([occasionId?.toString()]),
    queryFn: () => getSubscriberTemplatesByOccasionId(occasionId),
    refetchOnMount: true,
  })
}
