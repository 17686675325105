import { ReactNode } from 'react'
import { CountryContextDialog } from 'components/CountryContextDialog/CountryContextDialog'
import { useCountryContext } from 'store/useCountryContext'

type Props = {
  children: ReactNode
}

export const CountryContextDialogWrapper = ({ children }: Props) => {
  const { country } = useCountryContext()
  return (
    <>
      {children}
      {country ? null : <CountryContextDialog />}
    </>
  )
}
