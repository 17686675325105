import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import { BackendSvgIcon } from 'components/BackendSvgIcon/BackendSvgIcon'
import Loader from 'components/Loader/Loader'
import { getObjectTranslation } from 'utils/getObjectTranslation'
import { useWidgetMyScore } from 'utils/hooks/api/MyScore/useWidgetMyScore'

import { Filter } from '../components/Filter'
import { filterOptions } from '../components/FilterOptions'
import { ContentCard } from '../ContentCard'
import { getFormattedDateFromFilterName } from '../helpers/getDateFromFilterName'
import { HelpTooltip } from './components/HelpTooltip'
import { HighlightedNumbers } from './components/HighlightedNumbers'
import { MyScoreLevel } from './components/MyScoreLevel'
import { MyScoreText } from './components/MyScoreText'

export const MyScoreWidget = () => {
  const [filter, setFilter] = useState(filterOptions[1])
  const { t } = useTranslation()

  const { data, isFetching } = useWidgetMyScore({
    dateFrom: getFormattedDateFromFilterName(filter.name),
  })

  return (
    <ContentCard
      title={getObjectTranslation(data?.recognition_settings?.translation)?.name_plural}
      icon={<BackendSvgIcon svgUrl={data?.recognition_settings?.image} />}
      filters={<Filter title={t(filter.label)} options={filterOptions} onSelect={setFilter} currentOption={filter} />}
      dataCy="my-score-widget"
    >
      <Grid container justifyContent="center" alignItems="center">
        <Loader isLoading={isFetching}>
          {data && (
            <Grid container gap={2} sx={{ padding: 2, paddingTop: 0, textAlign: 'center' }}>
              <Grid container>
                <HighlightedNumbers received={data.received} given={data.given} />
              </Grid>
              <Grid item xs={12}>
                <MyScoreText lastRecognitionDate={data.last_recognition.date} />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sx={{ position: 'relative', paddingTop: 1 }}>
                <Box sx={{ position: 'absolute', top: 0, right: 0, color: 'primary.main' }}>
                  <HelpTooltip hint={getObjectTranslation(data.recognition_hint)} />
                </Box>
                <MyScoreLevel level={data?.user_score_level} />
              </Grid>
            </Grid>
          )}
        </Loader>
      </Grid>
    </ContentCard>
  )
}
