import { RecognitionValue } from 'utils/api/Values/fetchValues'

export interface RecognizingProfile {
  first_name: string
  last_name: string
  full_name: string
  avatar?: string
  id: number
  job_title?: string
  department?: {
    id: number
    name: string
  }
}
export interface RecognizingUser {
  id: number
  profile: RecognizingProfile
}
export type PostComment = {
  id: number
  content: string
  created_at: string
  author: RecognizingUser
}

export enum SocialWallPostType {
  Recognition = 1,
  SpecialMoment = 2,
}

export type Like = {
  id: number
  user: {
    id: number
    profile: Omit<RecognizingProfile, 'avatar' | 'department' | 'job_title'>
  }
}
export type UserRecognition = {
  user_awarded: RecognizingUser
}
type Recognition = {
  id: number
  user_recognitions: UserRecognition[]
  user_recognizing: RecognizingUser
  recognition_values: { value: RecognitionValue }[]
  description: string
}
type SpecialMoment = {
  id: number
  user_awarded: RecognizingUser
  icon: string
  title: string
  content?: string
}
export interface SocialWallPost {
  id: number
  type: SocialWallPostType
  recognition?: Recognition
  special_moment?: SpecialMoment
  comments_count: number
  comments: PostComment[]
  likes: Like[]
  created_at: string
}
