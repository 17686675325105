import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { ThumbUp } from 'components/ArteelIcons/ThumbUp'
import { UserNameWithAvatar } from 'components/Message/components/MessageUserWithTooltip'
import { TypographyWithIcon } from 'pages/SocialWall/components/TypographyWithIcon'
import { useLikedByLoggedUser } from 'pages/SocialWall/components/utils'
import { SocialWallPost } from 'utils/api/SocialWall/types'
import { valueOrUndefined } from 'utils/getValueOrUndefined'

interface Props {
  likes: SocialWallPost['likes']
}
const LikesTooltipTitle = ({ likes = [] }: Props) => {
  const { t } = useTranslation()
  const MAX_LIKES_ROWS = 35
  const renderedLikes = likes.slice(0, MAX_LIKES_ROWS)

  return (
    <>
      {renderedLikes.map((el) => (
        <Typography key={el.id} lineHeight={1}>
          {el.user.profile.full_name}
        </Typography>
      ))}
      {likes.length > MAX_LIKES_ROWS && (
        <Typography>{t('recognition_post.likes.and_more', { amount: likes.length - MAX_LIKES_ROWS })}</Typography>
      )}
    </>
  )
}

const LikesInfoText = ({ likes = [] }: Props) => {
  const { t } = useTranslation()
  const { likedByLoggedUser, userId, userLike } = useLikedByLoggedUser(likes)

  const renderLikeUsers = () => {
    const rest = likes.filter((el) => el.user.id !== userId)
    const first = rest.find((el) => el.user.id !== userId)
    const second = rest.filter((el) => el.user.id !== first?.user.id)?.find((el) => el.user.id !== userId)
    const othersLength = likes.length - [userLike, first, second].filter(Boolean).length
    const YOU = t('recognition_post.you')
    const AND = t('recognition_post.and')
    const OTHERS = t('recognition_post.others')

    let text = ''
    if (likedByLoggedUser) {
      text = YOU
      if (first && !second) {
        text = `${text} ${AND} ${first.user.profile.full_name}`
      }
      if (first && second) {
        text = `${text}, ${first.user.profile.full_name} ${AND} ${rest.length - 1} ${OTHERS}`
      }
    }
    if (!likedByLoggedUser && first) {
      text = first.user.profile.full_name
      if (second && !othersLength) {
        text = `${text} ${AND} ${second.user.profile.full_name}`
      }
      if (second && othersLength) {
        text = `${text}, ${second.user.profile.full_name} ${AND} ${likes.length - 2} ${OTHERS}`
      }
    }
    return text || 0
  }

  return (
    <TypographyWithIcon>
      <ThumbUp color="primary" active={likedByLoggedUser} sx={{ mr: 0.5, mb: 0.5, fontSize: 'larger' }} />
      {renderLikeUsers()}
    </TypographyWithIcon>
  )
}

export const PostActionsInfoLikes = ({ likes = [] }: Props) => {
  const { t } = useTranslation()
  const [showLikesDialog, setShowLikesDialog] = useState(false)

  return (
    <>
      <Tooltip open={valueOrUndefined(likes.length < 3, false)} title={<LikesTooltipTitle likes={likes} />}>
        <Box
          onClick={valueOrUndefined(!!likes.length, () => setShowLikesDialog(true))}
          sx={{ cursor: valueOrUndefined(!!likes.length, 'pointer') }}
          data-cy="likes-number-text"
        >
          <LikesInfoText likes={likes} />
        </Box>
      </Tooltip>
      <Dialog
        open={showLikesDialog}
        scroll="paper"
        PaperProps={{ sx: { maxHeight: 400, width: '100%', maxWidth: 300 } }}
        onClose={() => setShowLikesDialog(false)}
      >
        <DialogTitle variant="h3" textAlign="center" fontWeight={600}>
          {t('recognition_post.likes', { amount: likes.length })}
        </DialogTitle>
        <DialogContent dividers>
          {likes.map((el) => (
            <UserNameWithAvatar user={el.user} key={el.id} pb={1} />
          ))}
        </DialogContent>
      </Dialog>
    </>
  )
}
