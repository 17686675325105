import { ReactNode, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { LoaderFullScreen } from 'components/LoaderFullScreen/LoaderFullScreen'
import { providerTypes, SSOProvider } from 'pages/Login/api/useGetSSOProviders'
import { msalConfig } from 'pages/Login/SSO/msalConfig'
import { CustomNavigationClient } from 'pages/Login/SSO/utils'

type Props = {
  children: ReactNode
  providers?: SSOProvider[]
}

export const MsalWrapper = ({ children, providers }: Props) => {
  const navigate = useNavigate()
  const [msalInstance, setMsalInstance] = useState<IPublicClientApplication | null>(null)
  const msalProvider = providers?.find((provider) => provider.type === providerTypes.azure)

  useEffect(() => {
    if (msalProvider) {
      const pca = new PublicClientApplication(msalConfig(msalProvider))
      const navigationClient = new CustomNavigationClient(navigate)
      pca.setNavigationClient(navigationClient)
      setMsalInstance(pca)
    }
  }, [msalProvider])

  if (!!msalProvider && !msalInstance) {
    return <LoaderFullScreen />
  }

  if (!msalProvider) {
    return children
  }

  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>
}
