import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { MaintenanceIcon } from 'components/ArteelIcons/MaintenanceIcon'
import DisplayDate from 'components/DisplayDate'
import { MaintenanceWork } from 'components/MaintenancePage/types'

type Props = {
  currentMaintenance: MaintenanceWork
}

export const MaintenancePage = ({ currentMaintenance }: Props) => {
  return (
    <Box
      sx={(theme) => ({
        width: 'calc(100vw - 16px)',
        height: '100vh',
        background: '#EEEEF4',
        margin: 0,
        padding: 0,
        textAlign: 'center',
        fontFamily: '"Roboto", sans-serif',
        fontWeight: 100,
        cursor: 'default',
        '& .construction-message': {
          background: '#EEEEF4',
          position: 'absolute',
          top: '25%',
          [theme.breakpoints.down('md')]: {
            top: '0%',
          },
          width: '100%',
        },
        h1: {
          color: '#E52149',
          fontWeight: 100,
          fontSize: '3rem',
        },
        h2: {
          color: '#333333',
          fontWeight: 300,
          fontSize: '1.5rem',
        },
        a: {
          fontWeight: 300,
          color: '#E52149',
          textDecoration: 'none',
          fontSize: '1.5rem',
        },
        span: {
          color: '#E52149',
        },
      })}
    >
      <div className="construction-message">
        <h1>Site Under Maintenance</h1>
        <MaintenanceIcon sx={{ fontSize: '6rem' }} />
        <h2>We are currently working on the site to make it even better for you.</h2>
        <h2>
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12} md="auto" display="inline-flex" justifyContent="center">
              Platform will not be available
            </Grid>
            <Grid item xs={12} md="auto" display="inline-flex" justifyContent="center">
              from&nbsp;
              <span>
                <DisplayDate dateString={currentMaintenance.start} />
              </span>
            </Grid>
            <Grid item xs={12} md="auto" display="inline-flex" justifyContent="center">
              to&nbsp;
              <span>
                <DisplayDate dateString={currentMaintenance.end} />
              </span>
            </Grid>
          </Grid>
        </h2>
        <br />
        <h2>To contact us in the meantime please email</h2>
        <a href="mailto:support@arteel.com">support@arteel.com</a>&nbsp;or call&nbsp;
        <a href="tel:+3216442777">+32 16 442 777 (9:00 - 17:00)</a>
        <div style={{ marginTop: '100px' }}></div>
      </div>
    </Box>
  )
}
