import { luckyBirdUserTypes } from 'utils/hooks/types'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'
import { userTypes } from 'utils/hooks/userTypes'

export const useIsLuckyBirdUser = () => {
  const user = useCurrentUser()

  const isLuckyBird = user?.type === userTypes.lucky_bird
  const isSingleLogic = isLuckyBird && user?.lucky_bird_type === luckyBirdUserTypes.singleLogic
  const isPointsLogic = isLuckyBird && user?.lucky_bird_type === luckyBirdUserTypes.pointsLogic
  const isDemoUser = user?.lucky_bird_code_is_demo === true
  return {
    isLuckyBird,
    isSingleLogic,
    isPointsLogic,
    isDemoUser,
  }
}
