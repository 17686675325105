import { captureMessage } from '@sentry/browser'
import isArray from 'lodash/isArray'
import { renderCustomAction } from 'utils/actionsCreator/renderCustomAction'
import { renderDeleteAction } from 'utils/actionsCreator/renderDeleteAction'
import { renderEditAction } from 'utils/actionsCreator/renderEditAction'
import { ActionCreator, ArgsToRenderAction } from 'utils/actionsCreator/types'

export const argsToRenderAction = <T>(
  arg: ActionCreator<T>,
  rowData: T,
  type: 'custom' | 'delete' | 'edit' = 'custom'
) => {
  const renderer = (arg: ArgsToRenderAction<T>, index?: number) => {
    if (type === 'custom') {
      return renderCustomAction({ keyProp: index, ...(arg as any) })(rowData)
    }
    if (type === 'delete') {
      return renderDeleteAction(arg)(rowData)
    }
    if (type === 'edit') {
      return renderEditAction(arg)(rowData)
    }
    captureMessage('Unknown type in argsToRenderAction')
  }

  if (!arg || (isArray(arg) && !arg.length)) {
    return null
  }

  // is array of objects
  if (isArray(arg) && arg.length) {
    return arg.map(renderer)
  }

  // is array of functions
  if (arg instanceof Function && isArray(arg(rowData))) {
    return (arg as (v: T) => ArgsToRenderAction<T>[])(rowData).map(renderer)
  }

  // is function
  if (arg instanceof Function) {
    const params = (arg as (v: T) => ArgsToRenderAction<T>)(rowData)
    return renderer(params)
  }

  // is object
  return renderer(arg as ArgsToRenderAction<T>)
}
