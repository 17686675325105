import { useLocation } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { SiteType } from 'shared/Site/siteType'
import { useMaintenanceContext } from 'store/useMaintenanceContext'
import { getRequest } from 'utils/api/apiRequest'
import { Subscriber } from 'utils/api/User'

export enum NotificationType {
  assignToProject = 1,
  pointsAwards = 2,
  valueCodes = 3,
}

export type NotificationTranslation = {
  [key: string]: {
    heading: string
    message: string
  }
}

export type Site = {
  id: number
  subdomain: string
  type: SiteType
  project?: Project
}

export type Project = {
  id: number
  available_points: number
  charity_round_up: boolean
  name: string
  site: Site
  slug: string
  subdomain: string
  subscriber: Subscriber
  point_saving_attribute: boolean
  is_social_wall_active: boolean
  is_transfer_points_active: boolean
  is_value_codes_active: boolean
  is_points_project: boolean
  social_wall?: {
    id: number
    birthday_widget?: boolean
    values_widget?: boolean
    recognition_settings?: {
      image?: string
      translation?: {
        [key: string]: {
          name_plural: string
          name_singular: string
        }
      }
    }
  }
  project_settings?: {
    show_expiration_date: boolean
  }
}

type SubdomainInfo = {
  type: SiteType
  project: Pick<Project, 'id' | 'name' | 'charity_round_up'> | null
  subscriber: Pick<
    Subscriber,
    'id' | 'name' | 'points_name_plural' | 'points_name_singular' | 'logo_path' | 'theme'
  > & {
    sites: Pick<Site, 'id' | 'subdomain' | 'type'>[]
    has_activation_codes: boolean
    has_enabled_sso: boolean
  }
  subdomain: string
}

const fetchSubdomainInfo = () => getRequest<SubdomainInfo>(`/sites/subdomain`)
export const useSubdomainInfo = () => {
  const location = useLocation()
  const { currentMaintenance } = useMaintenanceContext()

  return useQuery({
    enabled: location.pathname !== '/404' && !currentMaintenance,
    queryKey: ['fetchSubdomainInfo'],
    queryFn: fetchSubdomainInfo,
  })
}
