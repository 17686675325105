import { useState } from 'react'
import { ProductCardMedia } from 'pages/ProductCard/components/Media/ProductCardMedia'
import { ProductPresentation } from 'pages/ProductCard/components/Media/ProductPresentation'
import { CatalogProduct } from 'utils/api/Products/CatalogProduct'

type Props = {
  catalogProduct?: CatalogProduct
  initSlide?: number
  selectSlide?: (i: number) => void
  onClickSlide?: () => void
  showThumbs?: boolean
}
export const ProductMediaContainer = ({ catalogProduct }: Props) => {
  const [selectedSlide, setSelectedSlide] = useState(0)
  const [open, setOpen] = useState(false)
  return (
    <>
      <ProductCardMedia
        catalogProduct={catalogProduct}
        onClickSlide={() => setOpen(true)}
        selectSlide={setSelectedSlide}
      />
      <ProductPresentation
        open={open}
        catalogProduct={catalogProduct}
        initSlide={selectedSlide}
        onClose={() => setOpen(false)}
      />
    </>
  )
}
