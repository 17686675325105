import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Delivery } from 'components/ArteelIcons'
import { useGetAvailableCountries } from 'components/CountryContextDialog/hooks/useGetAvailableCountries'
import { useFormikContext } from 'formik'
import { DeliveryVariant, OneOfDeliveryVariant } from 'pages/CreateOrder/deliveryOptions'
import { CreateOrderType2 } from 'pages/CreateOrder/types'
import { DeliveryMethods } from 'shared/ShoppingCart/api'
import { useSelectShoppingCart } from 'shared/ShoppingCart/slice'
import { getResponsiveSize } from 'theme/styles.utils'
import { Country } from 'utils/api/country'
import { TranslationsKeys } from 'utils/createTranslationKey'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  deliveryIcon: {
    fontSize: getResponsiveSize(6, 'rem'),
    color: theme.palette.primary.main,
  },
  csDeliveryVariant: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[800],
  },
  csAddress: {
    '& > .MuiTypography-root': {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.grey[500],
      lineHeight: 1.25,
    },
  },
  buttonText: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.h4.fontSize,
    marginBottom: theme.spacing(-1),
  },
}))
type Props = {
  selectedOptionLabel?: TranslationsKeys
  onBack: () => void
  isDigital: boolean
}

export const SelectedAddress = ({ selectedOptionLabel, onBack, isDigital }: Props) => {
  const { csDeliveryVariant, deliveryIcon, csAddress, buttonText } = useStyles()
  const { t, i18n } = useTranslation()
  const { data: shoppingCartItems, isFetching } = useSelectShoppingCart()
  const { getFieldProps, values } = useFormikContext<CreateOrderType2>()
  const isGroupAndDate = shoppingCartItems?.data?.delivery_method_name === DeliveryMethods.groupedAndDate

  const getValue = (name: keyof Omit<CreateOrderType2, OneOfDeliveryVariant>) => getFieldProps(name)?.value || ''
  const getNestedValue = (name: CreateOrderType2[OneOfDeliveryVariant]) =>
    getFieldProps(`${values.delivery_address_type}.${name}`)?.value || ''
  const { data } = useGetAvailableCountries()
  const deliveryCountries: Country[] = data?.data || []

  const deliveryCountry: Country | undefined = deliveryCountries.find(
    (country) => country.id === getFieldProps('country')?.value
  )

  const countryName = deliveryCountry?.translation?.[i18n.language]?.name
  const showRecipientName =
    values.delivery_address_type !== DeliveryVariant.preset &&
    values.delivery_address_type !== DeliveryVariant.bpostCollectionPoint &&
    values.delivery_address_type !== DeliveryVariant.bpostParcelLocker

  const showBpostName =
    values.delivery_address_type === DeliveryVariant.bpostCollectionPoint ||
    values.delivery_address_type === DeliveryVariant.bpostParcelLocker

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={8} justifyContent="flex-end" alignItems="center">
          <Grid item xs={12} lg>
            <Grid container direction="column" alignItems="center" spacing={2}>
              <Grid item>
                <Delivery className={deliveryIcon} />
              </Grid>
              <Grid item>
                <Typography variant="h1" align="center" className={csDeliveryVariant}>
                  {t(selectedOptionLabel).toUpperCase()}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs className={csAddress}>
                <Typography variant="h2">{getNestedValue('company_name')}</Typography>
                {showRecipientName && (
                  <Typography variant="h2">
                    {getValue('first_name')} {getValue('last_name')}
                  </Typography>
                )}
                {showBpostName && <Typography variant="h2">{getNestedValue('bpost_delivery_point_name')}</Typography>}
                {isDigital ? (
                  <Grid item xs className={csAddress}>
                    <Typography variant="h2">{getValue('email')}</Typography>
                  </Grid>
                ) : (
                  <>
                    <Typography variant="h2">
                      {getNestedValue('street')} {getNestedValue('number')} {getNestedValue('box')}
                    </Typography>
                    <Typography variant="h2">
                      {getNestedValue('zip')} {getNestedValue('city')}
                    </Typography>
                    <Typography variant="h2">{countryName}</Typography>
                  </>
                )}
              </Grid>
              {!isGroupAndDate && (
                <Grid item>
                  <Button color="primary" onClick={onBack} size="small" className={buttonText}>
                    {t('app.change')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  )
}
