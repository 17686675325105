import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Loader from 'components/Loader/Loader'
import { ResponsivePaper } from 'components/ResponsivePaper'
import { useSnackbar } from 'notistack'
import { useAcceptConsent } from 'pages/UserConsent/useAcceptConsent'
import { UserConsentContent, UserConsentError, useSelectUserConsent } from 'shared/UserConsent'
import { useAuthContext } from 'store/useAuthContext'
import { Footer } from 'theme/Footer'
import { getResponsiveSize } from 'theme/styles.utils'
import { makeStyles } from 'utils/styles/makeStyles'

const height = 'calc(100vh - 170px)'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    maxWidth: getResponsiveSize(600),
    margin: 'auto',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  gridContainer: {
    display: 'grid',
    height,
    width: '100%',
    rowGap: theme.spacing(2),
  },
}))

export const UserConsent = () => {
  const { t } = useTranslation()
  const { wrapper, gridContainer } = useStyles()
  const { data: userConsent, isFetching, isSuccess, isError } = useSelectUserConsent()
  const { enqueueSnackbar } = useSnackbar()
  const [warning, setWarning] = useState(false)
  const { mutate, isError: isAcceptError, isLoading } = useAcceptConsent()

  const toggleWarning = () => setWarning((s) => !s)

  const acceptConsent = () => mutate()

  useEffect(() => {
    if (isAcceptError) {
      enqueueSnackbar(t('user_consent.accept.fetch.fail'), { variant: 'error' })
    }
  }, [isAcceptError])

  return (
    <Box
      sx={(_) => ({
        background: _.palette.background.default,
        height: '100%',
        maxWidth: '100%',
        fontFamily: 'ProximaNovaCond',
      })}
    >
      <div className={wrapper}>
        <Grid container>
          <ResponsivePaper sx={{ width: '100%' }}>
            <Loader isLoading={isFetching || isLoading}>
              {isError && <UserConsentError />}
              {isSuccess &&
                (!warning ? (
                  <Consent toggleWarning={toggleWarning} acceptConsent={acceptConsent} />
                ) : (
                  <ConsentWarning toggleWarning={toggleWarning} />
                ))}
            </Loader>
          </ResponsivePaper>
        </Grid>
      </div>
      <Footer />
    </Box>
  )
}

type PropsWarinng = {
  toggleWarning: () => void
}

const ConsentWarning = ({ toggleWarning }: PropsWarinng) => {
  const { t } = useTranslation()
  const { gridContainer } = useStyles()
  const { logoutUser } = useAuthContext()

  return (
    <Box
      className={gridContainer}
      sx={{
        gridTemplateRows: 'auto 1fr auto ',
      }}
    >
      <Box>
        <Typography variant="h1" textAlign="center">
          {t('user_consent.title')}
        </Typography>
      </Box>
      <Box>
        <Typography paragraph variant="h4" textAlign="center">
          {t('user_consent.no_acceptance.body')}
        </Typography>
      </Box>
      <Box sx={{ textAlign: 'center' }}>
        <Button onClick={logoutUser}> {t('app.leave')}</Button>
        <Button onClick={toggleWarning} variant="contained" sx={{ ml: 2 }}>
          {t('user_consent.back_to_user_consent')}
        </Button>
      </Box>
    </Box>
  )
}

type PropsConsent = {
  toggleWarning: () => void
  acceptConsent: () => void
}
const Consent = ({ toggleWarning, acceptConsent }: PropsConsent) => {
  const { t } = useTranslation()
  const { gridContainer } = useStyles()
  const [acceptance, setAcceptance] = useState(false)

  return (
    <Box
      className={gridContainer}
      data-cy="consent-box"
      sx={{
        gridTemplateRows: '1fr auto auto',
      }}
    >
      <UserConsentContent />
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox checked={acceptance} onClick={() => setAcceptance((s) => !s)} data-cy="accept-consent-checkbox" />
          }
          label={<span>{t('user_consent.acceptance.label')}</span>}
        />
      </FormGroup>
      <Box sx={{ textAlign: 'center' }}>
        <Button onClick={toggleWarning}>{t('app.cancel')}</Button>
        <Button
          onClick={acceptConsent}
          disabled={!acceptance}
          variant="contained"
          sx={{ ml: 2 }}
          data-cy="save-consent-button"
        >
          {t('app.save')}
        </Button>
      </Box>
    </Box>
  )
}
