import isString from 'lodash/isString'
import qs from 'query-string'

export const getTokenFromSearch = (search: string): string => {
  try {
    const params = qs.parse(search)
    return isString(params.token) ? params.token : params.token[0]
  } catch (e) {
    return ''
  }
}
