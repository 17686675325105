import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

export const ThumbUp = ({ active, ...rest }: SvgIconProps & IconActive) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 480 480">
      {active ? (
        <g xmlns="http://www.w3.org/2000/svg">
          <path d="M36.6,208.2c-12,0-21.8,9.8-21.8,21.9v207.2c0,12.1,9.8,21.8,21.8,21.8h68.5c12,0,21.8-9.8,21.8-21.8V230   c0-12.1-9.8-21.9-21.8-21.9H36.6z" />
          <path d="M229.6,31.3c-2.3,2.5-3.4,5.8-3.2,9.2l2.3,61c0,44.6-69.7,104.2-69.7,149.1v147.4c0,23.7,34,39.8,79.8,39.8   h128.5c23.7,0,39.3-8,47.9-23.7c7.8-14.4,6.5-28.2,3.5-38.2c12.9-4.1,25.1-13.8,28.7-31.8c2.3-11.7-0.4-21.3-4.2-28.5   c11.3-6.3,22-15.4,22-37.8c0-22.4-7.5-28.8-15.7-35.2c4.5-7.5,8.8-18.2,8.8-31.8c0-27.9-21.6-45.2-56.3-45.2h-89.9   c2.6-14.6,5.7-43.5,5.7-57.7c0-29.4-7-66-33.9-80.5c-8-4.3-14.6-6.5-24.8-6.5C243.5,20.9,233.3,27.3,229.6,31.3" />
        </g>
      ) : (
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M449.8,275.5c0-18.8-5-28.9-11.8-36.1c3.5-8.5,5.3-17.6,5.3-27c0-33.6-25.8-55.3-65.8-55.3h-69.9  c1.8-14.2,3.3-31.4,3.3-41.6c0-24.7-5-68.9-38.7-87c-10.1-5.4-18.6-7.6-29.3-7.6c-17.9,0-30.9,7.2-37.1,13.9l-0.1,0.1  c-4.6,5-6.8,11.5-6.3,17.7l2.1,56.9c0,17.1-15.6,40.4-30.6,62.9c-7.4,11.1-14.7,22.4-20.9,33.7c-5.9-5.3-13.5-8.7-22-8.7H63.5  c-18.4,0-33.3,14.9-33.3,33.3v195.2c0,18.4,14.9,33.3,33.3,33.3H128c18.4,0,33.3-14.9,33.3-33.3v-0.1c15.3,8.4,36.8,13.3,62.5,13.3  h121.1c26.9,0,45.8-9.7,56.3-29c5.8-10.7,8.1-22.5,6.6-34.6c13-7.4,21.9-19.6,25-35c1.6-8.2,1.3-16.5-0.9-24.4  C441.1,308.6,449.8,296.6,449.8,275.5z M135.8,425.8c0,4.3-3.5,7.9-7.8,7.9H63.5c-4.3,0-7.8-3.5-7.8-7.9V230.6  c0-4.3,3.5-7.9,7.8-7.9H128c4.3,0,7.8,3.5,7.8,7.9v19.2v138.9V425.8z M410.2,299.9l-10.9,6l5.7,11c3.1,5.9,4,12.1,2.8,18.5  c-2.6,12.8-11.4,18.1-18.4,20.3l-12,3.8l3.7,12.1c2.9,9.4,2.1,18.2-2.3,26.2c-3.7,6.7-11.2,15.7-33.9,15.7H223.7  c-38.6,0-62.5-12.9-62.5-24.8V249.9c0-17.3,15.7-40.7,30.8-63.4c17.2-25.7,34.9-52.2,34.9-77.5l-2.1-57.3c1.5-1.2,7.3-5.4,18-5.4  c7.4,0,11.6,1.6,17.3,4.6c22.8,12.3,25.3,49.3,25.3,64.6c0,12.4-2.8,39-5.2,52.1l-2.7,14.9h99.9c12.1,0,40.3,2.9,40.3,29.9  c0,8.5-2.2,16.3-6.4,23.3l-5.8,9.6l8.8,7c6.2,4.8,9.9,7.8,9.9,23.1C424.3,290.9,417.9,295.7,410.2,299.9z"
        />
      )}
    </SvgIcon>
  )
}
