import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { lighten } from '@mui/system'
import { ConclusionPoints } from 'components/ConclusionPoints/ConclusionPoints'
import { FooterConclusionPoints } from 'components/FooterConclusionPoints/FooterConclusionPoints'
import Loader from 'components/Loader/Loader'
import { ShoppingCartWarnings } from 'components/ShoppingCartWarnings/ShoppingCartWarnings'
import { WhitePaper } from 'components/WhitePaper/WhitePaper'
import isEmpty from 'lodash/isEmpty'
import { CartItem } from 'pages/ShoppingCart/CartItem'
import { Header } from 'pages/ShoppingCart/Header'
import { useSelectShoppingCart } from 'shared/ShoppingCart/slice'
import { useIsCypress } from 'utils/hooks/useIsCypress'
import { useIsDisabledOrdering } from 'utils/hooks/useIsDisabledOrdering'
import { useIsLuckyBirdUser } from 'utils/hooks/useIsLuckyBirdUser'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  whitePaper: {
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(0),
    },
  },
  contact: {
    fontWeight: theme.typography.fontWeightBold,
    color: lighten(theme.palette.common.black, 0.3),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
}))

export const ShoppingCart = () => {
  const { whitePaper, contact, divider } = useStyles()
  const { data: shoppingCartItems, isFetching } = useSelectShoppingCart()
  const { t } = useTranslation()
  const { isSingleLogic } = useIsLuckyBirdUser()

  const isOrderDisabled = useIsDisabledOrdering()

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} xl={8} className={whitePaper}>
        <WhitePaper
          changeViewDisable
          header={<Header disabledOrder={useIsCypress() ? false : isOrderDisabled} />}
          showPointsDisable
        >
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <ShoppingCartWarnings />
            </Grid>
            {shoppingCartItems?.data?.items?.map((item) => (
              <Fragment key={item.id}>
                <Grid item xs={12}>
                  <Loader isLoading={isFetching} objects={shoppingCartItems?.data?.items}>
                    <CartItem item={item} />
                  </Loader>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Fragment>
            ))}
            <Grid item xs={12}>
              <Collapse in={isEmpty(shoppingCartItems?.data?.items)} unmountOnExit mountOnEnter>
                <>
                  <Typography variant="h2" align="center" className={contact}>
                    {t('shopping_cart.empty')}
                  </Typography>
                  <Divider className={divider} />
                </>
              </Collapse>
            </Grid>
            {!isSingleLogic && (
              <>
                <Grid item xs={12}>
                  <ConclusionPoints
                    currentValue={shoppingCartItems?.data?.total_order_value || 0}
                    currentValueLabel="shopping_cart.current_order_total"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <FooterConclusionPoints />
            </Grid>
          </Grid>
        </WhitePaper>
      </Grid>
    </Grid>
  )
}
