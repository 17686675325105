import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'

import { IconActive } from './IconActive'

export const Home = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 30 30">
    <g xmlns="http://www.w3.org/2000/svg">
      <path d="M29.2,13.3l-5.1-3.9V4.9c0-0.3-0.1-0.4-0.4-0.4h-2.7c-0.3,0-0.4,0.1-0.4,0.4v1.6l-4.6-3.6    c-0.4-0.4-1.2-0.4-1.7,0L0.7,13.3c-0.6,0.4-0.7,1.3-0.3,1.9c0.4,0.6,1.3,0.7,1.9,0.3l11.8-9.2c0,0,0.3-0.3,0.7-0.3    c0.3,0,0.7,0.3,0.7,0.3l11.8,9.2c0.3,0.1,0.6,0.3,0.9,0.3c0.4,0,0.9-0.1,1.2-0.6C29.9,14.6,29.9,13.7,29.2,13.3z" />
      <path d="M25.4,15.6L15.3,7.6c-0.1-0.1-0.6-0.1-0.7,0L4.2,15.7c-0.1,0.1-0.4,0.4-0.4,0.7V27c0,0.3,0.1,0.4,0.4,0.4h7.4    c0.3,0,0.4-0.1,0.4-0.4v-7.4c0-0.3,0.1-0.4,0.4-0.4H17c0.3,0,0.4,0.1,0.4,0.4V27c0,0.3,0.1,0.4,0.4,0.4h7.4c0.3,0,0.4-0.1,0.4-0.4    V16.4C25.7,16.2,25.8,15.7,25.4,15.6z" />
    </g>
  </SvgIcon>
)
