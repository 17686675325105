import 'react-responsive-carousel/lib/styles/carousel.min.css'

import { ReactElement, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { captureMessage } from '@sentry/browser'
import { Arrow, Play } from 'components/ArteelIcons'
import { getResponsiveSize } from 'theme/styles.utils'
import { CatalogProduct, VisualTypes } from 'utils/api/Products/CatalogProduct'
import { getProductName } from 'utils/getProductName'
import { getThumbnailPath, getVimeoId, getYTId } from 'utils/getThumbnail'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { important } from 'utils/styles'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  arrow: {
    position: 'absolute',
    top: '50%',
    '&.right': {
      transform: 'translate(30%, -50%)',
      right: 0,
    },
    '&.left': {
      transform: 'translate(-30%,-50%)',
      zIndex: 1,
    },
  },
  carousel: {
    '& .carousel-slider': {
      overflow: 'initial!important',
    },
    '& .carousel': {
      '& .slider': { maxHeight: '80vh' },
      '& .slide': { padding: '2px' },
      '& .control-arrow': {
        '&.control-prev': {
          left: -4,
          '&:before': {
            borderRightColor: `${theme.palette.grey[400]}!important`,
          },
        },
        '&.control-next': {
          right: -4,
          '&:before': {
            borderLeftColor: `${theme.palette.grey[400]}!important`,
          },
        },
      },
      '& .thumbs-wrapper': {
        textAlign: 'center',
      },
      '& .thumb': {
        maxWidth: 100,
        height: 100,
        padding: 0,
        display: 'inline-flex',
        borderWidth: 3,
        borderColor: theme.palette.grey[200],
        '&.selected, &:hover': {
          borderColor: theme.palette.grey[400],
        },
        '& img': {
          width: '100%',
          height: 'auto',
          objectFit: 'contain',
        },
      },
    },
  },
}))

type Props = {
  catalogProduct?: CatalogProduct
  initSlide?: number
  selectSlide?: (i: number) => void
  onClickSlide?: () => void
  showThumbs?: boolean
}

const getVideo = (path: string): ReactElement<{ 'data-thumb'?: string; 'data-mediatype'?: string }> => {
  const isVimeo = path.includes('vimeo')
  const id = isVimeo ? getVimeoId(path) : getYTId(path)
  const link = isVimeo ? `https://player.vimeo.com/video/${id}` : `https://www.youtube.com/embed/${id}`
  const thumb = isVimeo ? `https://vumbnail.com/${id}_small.jpg` : `https://img.youtube.com/vi/${id}/sddefault.jpg`

  return (
    <Box
      data-thumb={thumb}
      data-mediatype={VisualTypes.VIDEO}
      sx={{
        height: '100%',
        width: '100%',
        position: 'relative',
        '& embed': {
          width: '100vw',
          maxWidth: 'calc(100% - 32px)',
          height: '100%',
          margin: 'auto',
        },
      }}
    >
      <embed key={link} src={link} />
    </Box>
  )
}

export const ProductCardMedia = ({
  catalogProduct,
  selectSlide,
  initSlide = 0,
  onClickSlide,
  showThumbs = true,
}: Props) => {
  const { carousel, arrow } = useStyles()
  const [skippedImages, setSkippedImages] = useState<string[]>([])

  const validItems = catalogProduct?.product?.media?.filter((el) => !skippedImages.includes(el.path)) || []
  const isPresentationView = !onClickSlide

  return (
    <div>
      <Carousel
        showIndicators={false}
        showStatus={false}
        className={carousel}
        selectedItem={initSlide}
        onChange={(i) => selectSlide?.(i)}
        thumbWidth={100}
        renderArrowNext={(onClick, hasNext) =>
          hasNext && (
            <IconButton className={`${arrow} right`} color="primary" onClick={onClick} size="large">
              <Arrow fontSize="large" />
            </IconButton>
          )
        }
        renderArrowPrev={(onClick, hasPrev) =>
          hasPrev && (
            <IconButton className={`${arrow} left`} color="primary" onClick={onClick} size="large">
              <Arrow fontSize="large" direction="left" />
            </IconButton>
          )
        }
        showThumbs={showThumbs}
        renderItem={(el) => {
          const element = el as ReactElement
          return (
            <Box
              key={element.key}
              onClick={() => onClickSlide?.()}
              sx={{
                cursor: valueOrUndefined(element.props.component === 'img' && !isPresentationView, 'pointer'),
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                p: 1,
              }}
            >
              {element}
            </Box>
          )
        }}
        renderThumbs={(thumbs) =>
          (thumbs as ReactElement<{ 'data-thumb'?: string; 'data-mediatype'?: string }>[]).map((el, i) => {
            if (el?.props?.['data-mediatype'] === VisualTypes.IMAGE) {
              return el
            }
            if (el?.props?.['data-mediatype'] === VisualTypes.VIDEO) {
              return (
                <Box key={i} sx={{ display: 'flex', position: 'relative' }}>
                  <img src={el?.props?.['data-thumb']} alt="thumbnail" />
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%,-50%)',
                      color: (theme) => theme.palette.primary.main,
                      background: 'white',
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 0.25,
                    }}
                  >
                    <Play sx={{ fontSize: '3rem' }} />
                  </Box>
                </Box>
              )
            }
            captureMessage(`Unknown media type ${window.location.href}`)
            return null
          })
        }
        // props below allow to scroll page down on mobile devices
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={50}
      >
        {validItems.map((el) =>
          el?.type === VisualTypes.VIDEO ? (
            getVideo(el?.path)
          ) : (
            <Box
              component="img"
              data-mediatype={VisualTypes.IMAGE} // must be here to be able to filter out thumbs
              sx={(_) =>
                isPresentationView
                  ? {
                      height: 'auto',
                      width: important('fit-content'),
                      maxWidth: '100%',
                      maxHeight: '100%',
                      objectFit: 'contain',
                    }
                  : {
                      height: 'auto',
                      width: important('auto'),
                      maxHeight: getResponsiveSize(512),
                      [_.breakpoints.down('md')]: {
                        maxHeight: getResponsiveSize(360),
                        maxWidth: '100%',
                      },
                    }
              }
              onError={() => setSkippedImages((s) => [...s, el?.path])}
              src={getThumbnailPath(el?.path)}
              alt={getProductName(catalogProduct.product)}
              key={el.path}
            />
          )
        )}
      </Carousel>
    </div>
  )
}
