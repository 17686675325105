import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

export const ScrollUpArrow = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 70 70">
    <g>
      <g>
        <circle cx="35.5" cy="35.5" r="24.4" />
      </g>
      <path
        style={{ fill: '#FFFFFF' }}
        d="M44.1,34l-7.9-7.9c0,0,0,0,0,0c-0.4-0.4-1.1-0.4-1.5,0L26.8,34c-0.4,0.4-0.4,1.1,0,1.5
		c0.2,0.2,0.5,0.3,0.8,0.3s0.5-0.1,0.8-0.3l6.1-6.1v14.7c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1V29.4l6.1,6.1
		c0.2,0.2,0.5,0.3,0.8,0.3s0.5-0.1,0.8-0.3C44.5,35.1,44.5,34.4,44.1,34z"
      />
    </g>
  </SvgIcon>
)
