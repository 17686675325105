import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import Typography from '@mui/material/Typography'
import { fontFamilies } from 'theme/styles.utils'
import { getObjectTranslation } from 'utils/getObjectTranslation'
import { safeHtml } from 'utils/safeHtml'
import { important } from 'utils/styles/stylesUtils'

import { AccordionCheckboxBaseProps, AccordionCheckboxRowProps } from './types'

export const AccordionCheckboxRow = <T extends AccordionCheckboxBaseProps>({
  value,
  first,
  onChange,
  isChecked,
}: AccordionCheckboxRowProps<T>) => {
  const description = getObjectTranslation(value.translation)?.description
  const name = getObjectTranslation(value.translation)?.name
  return (
    <Accordion
      data-cy={`accordion-checkbox-row-${value.id}`}
      sx={(t) => ({
        borderTop: first ? `1px solid ${t.palette.background.paper}` : 'none',
        boxShadow: 'none',
        borderBottom: `1px solid ${t.palette.background.paper}`,
        background: t.palette.background.default,
        margin: important(0),
        '&::before': { display: 'none' },
      })}
    >
      <AccordionSummary
        sx={{
          pointerEvents: 'none',
          margin: 0,
          border: 'none',
          minHeight: important(0),
          '& .MuiAccordionSummary-content': {
            margin: important(0),
          },
        }}
        expandIcon={
          description ? (
            <ExpandMoreIcon
              color="primary"
              fontSize="large"
              sx={{
                pointerEvents: 'auto',
              }}
            />
          ) : null
        }
      >
        <FormControlLabel
          onClick={(e) => {
            e.stopPropagation()
          }}
          control={
            <Radio
              onClick={(e) => {
                e.stopPropagation()
                onChange(value, !isChecked)
              }}
              checked={isChecked}
            />
          }
          label={
            <Typography
              variant="body2"
              fontFamily={fontFamilies.default}
              fontWeight={400}
              sx={{ color: (t) => (isChecked ? t.palette.primary.main : t.palette.text.secondary) }}
            >
              {name}
            </Typography>
          }
          sx={{
            pointerEvents: 'auto',
            width: '100%',
          }}
        />
      </AccordionSummary>
      {!!description && (
        <AccordionDetails>
          <Typography
            variant="body2"
            fontFamily={fontFamilies.default}
            sx={{
              '& img': {
                maxWidth: '100%',
                height: 'auto',
              },
            }}
            dangerouslySetInnerHTML={safeHtml(description)}
          />
        </AccordionDetails>
      )}
    </Accordion>
  )
}
