import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { personalMessageKeys } from 'pages/PersonalMessages/api/keys'
import { postRequest } from 'utils/api/apiRequest'
import { OnSuccessApi } from 'utils/getOptionsTable'
import { useErrorSnackbar } from 'utils/hooks/useErrorSnackbar'

type Data = OnSuccessApi & {
  occasion: number
  name: string
  content: string
}

const postPersonalTemplate = (data: Data) => postRequest(`/users/message-templates`, { ...data, onSuccess: undefined })

export const usePostPersonalTemplate = () => {
  const enqueueErrorSnackbar = useErrorSnackbar()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: personalMessageKeys.postPersonalTemplate(),
    mutationFn: postPersonalTemplate,
    onSuccess: (data, variables) => {
      variables?.onSuccess?.()
      queryClient.invalidateQueries({
        queryKey: personalMessageKeys.allTemplates(),
      })
    },
    onError: (err) => {
      enqueueErrorSnackbar(err, { DEFAULT_ERROR: t('personal_messages.templates.add_template.fail') })
    },
  })
}
