import { useQuery } from '@tanstack/react-query'
import Axios from 'axios'

export const useIconFromApi = ({ icon }: { icon: string }) => {
  return useQuery({
    queryKey: [icon?.toString()],
    queryFn: () => Axios.get(icon),
    enabled: !!icon,
  })
}
