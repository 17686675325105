import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

export const Nominate = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 70 70">
    <g>
      <path
        d="M52.3,38.3C49,31.8,41,31.5,41,31.5s-0.6-0.1-1.7-0.1c3.7-2.2,6.1-6.3,5.8-10.9c-0.3-6-5.3-10.9-11.3-11.2
		C27.1,9,21.5,14.5,21.5,21.2c0,4.3,2.4,8.1,5.9,10.2c-0.6,0-1.1,0.1-1.7,0.1c-7.7,0.8-10.8,5.6-11.6,8.6c-0.7,2.1-0.9,7-0.9,10.5
		c0,2.1,1.7,3.8,3.8,3.8h33.3c2.1,0,3.8-1.7,3.8-3.8C54,46.8,53.6,41,52.3,38.3z"
      />
      <g>
        <circle cx="45.3" cy="49.1" r="10.7" />
        <path
          style={{ fill: '#FFFFFF' }}
          d="M45.3,38.4c5.9,0,10.7,4.8,10.7,10.7c0,5.9-4.8,10.7-10.7,10.7c-5.9,0-10.7-4.8-10.7-10.7
			C34.6,43.2,39.4,38.4,45.3,38.4 M45.3,36.6c-6.9,0-12.5,5.6-12.5,12.5s5.6,12.5,12.5,12.5S57.7,56,57.7,49.1S52.1,36.6,45.3,36.6
			L45.3,36.6z"
        />
      </g>
      <path
        style={{ fill: '#FFFFFF' }}
        d="M50.4,45.3c-0.4-0.3-0.9-0.2-1.2,0.2l-3.9,5.2l-1.9-2.4c-0.3-0.4-0.9-0.4-1.2-0.1c-0.4,0.3-0.4,0.9-0.1,1.2
		l2.6,3.2c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0.1c0,0,0,0,0.1,0C45,53,45.1,53,45.3,53c0.2,0,0.3-0.1,0.5-0.2c0,0,0,0,0.1,0
		c0,0,0,0,0-0.1c0,0,0.1-0.1,0.1-0.1l4.6-6.1C50.9,46.2,50.8,45.6,50.4,45.3z"
      />
    </g>
  </SvgIcon>
)
