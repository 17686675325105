import { Project, Site } from 'shared/Site/api'
import { getRequest } from 'utils/api/apiRequest'
import { Country } from 'utils/api/country'
import { Language } from 'utils/api/Languages/types'
import { Timestampable } from 'utils/api/timestampable'
import { User } from 'utils/hooks/userType'
import { NumericRange } from 'utils/types/NumericRange'

export enum Role {
  ROLE_WAREHOUSE = 'ROLE_WAREHOUSE',
  ROLE_USER = 'ROLE_USER',
  ROLE_COORDINATOR = 'ROLE_COORDINATOR',
  ROLE_MANAGER = 'ROLE_MANAGER',
  ROLE_AUTHOR = 'ROLE_AUTHOR',
  ROLE_ADMIN = 'ROLE_ADMIN',
}

export type SubscriberTheme = {
  button_background_color: string
  button_foreground_color: string
  color1: string
  color2: string
  color3: string
  id: number
  is_active: boolean
  logo: null | string
  name: string
  theme: string
}

export type Subscriber = {
  id: number
  logo_path: string | null
  name: string
  points_name_plural: PointsName
  points_name_singular: PointsName
  projects: Project[]
  sites: Site[]
  theme: SubscriberTheme
  has_social_wall_active_project: boolean
}

type PointsName = {
  id: number
  translation: {
    [key: string]: {
      name: string
    }
  }
}

type ScoreLevelsCodes = 'starter' | 'beginner' | 'intermediate' | 'advanced' | 'expert' | 'all_star'
type ScoreLevelsNames = 'Starter' | 'Beginner' | 'Intermediate' | 'Advanced' | 'Expert' | 'All star'

export const MaxScoreLevelNumber = 6

type ScoreLevelsNumbers = NumericRange<1, typeof MaxScoreLevelNumber>

export const isValidScoreLevel = (n: number): n is ScoreLevelsNumbers => {
  return n >= 1 && n <= MaxScoreLevelNumber
}

export interface UserScoreLevel {
  code: ScoreLevelsCodes
  name: ScoreLevelsNames
  level: ScoreLevelsNumbers
}

type ProjectPoints = {
  projectId: number
  projectName: string
  availablePoints: number
  awardedPoints: number
  redeemedPoints: number
  transferredPoints: number
  expiredPoints: number
  firstExpiredPoints: number
  firstExpirationDate: string
}

export type Profile = Timestampable & {
  id: number
  customer_internal_id: string
  first_name?: string
  last_name?: string
  full_name?: string
  birth_date: string | null
  hired_date: string | null
  email: string
  phone?: string
  active: boolean
  language: Language | null
  has_user: boolean
  full_name_with_email: string
  manager?: Profile

  addresses: Address[]
  position: string
  department: { id: number; name: string }
  department_path: string
  job_title: string
  avatar: string | null
  avatar_relative_path?: string | null
  user: { id: number }
  points_overall: Record<number, ProjectPoints>
}

export type Address = {
  id: number
  name?: string
  default: boolean
  type: AddressType
  full_address?: string
  street: string
  number: string
  box?: string
  zip: string
  city: string
  region: string
  country: Country
  additional_info?: string
  company_name?: string
  active: boolean
}

export type DeliveryAddress = {
  street: string
  number: string
  box?: string
  zip: string
  city: string
  country?: Country | string
}

export const addressType = {
  home: 1,
  work: 2,
} as const

export type AddressType = (typeof addressType)[keyof typeof addressType]

export const getCurrentUser = () => getRequest<User>('/me')
