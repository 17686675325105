import { FunctionComponent, JSX } from 'react'
import { useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import isArray from 'lodash/isArray'
import { CmsContent, CmsPage, CmsSection } from 'pages/CMS/CMS.types'
import { LinkBoxContentProvider } from 'pages/CMS/components/LinkBox'
import qs from 'query-string'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { useIsMobile } from 'utils/hooks/useBreakpoints'
import { makeSx } from 'utils/styles'

import { ContentBox } from './ContentBox'

interface ContentBoxesProps {
  section: CmsSection
  page: CmsPage
}

const getInfoContent = (contentId: string, page: CmsPage): CmsContent | undefined =>
  contentId
    ? (page?.sections?.map((section) => section.contents) || [])
        ?.reduce((flat, toFlatten) => flat.concat(toFlatten))
        .find((content) => content.id.toString() === contentId)
    : undefined

const ContentBoxes = ({ section, page }: ContentBoxesProps) => {
  const { search } = useLocation()
  const params = qs.parse(search.replace('?', ''))
  const infoContent = params?.info
    ? getInfoContent(isArray(params?.info) ? params?.info?.[0] : params?.info, page)
    : undefined

  if (infoContent) {
    return <ContentBox content={infoContent} sectionName={section?.name} />
  }

  return (
    <>
      {section.contents
        .filter((content) => content.visible)
        .sort((a, b) => a.position - b.position)
        .map((content) => (
          <Grid key={content.id} item xs={12}>
            <ContentBox content={content} sectionName={section?.name} />
          </Grid>
        ))}
    </>
  )
}

type Props = {
  section: CmsSection
  page: CmsPage
  middleColumnContent?: JSX.Element
}

export const CenterColumn: FunctionComponent<Props> = ({ section, page, middleColumnContent }) => {
  const isMobile = useIsMobile()

  const sx = makeSx((t) => ({
    maxHeight: '100vh',
    overflow: isMobile ? 'visible' : 'auto',
    height: '100%',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    [`&::-webkit-scrollbar`]: {
      display: 'none',
    },
    [t.breakpoints.up('md')]: {
      maxHeight: 'inherit',
    },
  }))

  return (
    <>
      <Box sx={valueOrUndefined(!isMobile, sx)} data-cy="center-column-wrapper">
        <LinkBoxContentProvider>
          {middleColumnContent || (
            <Grid container spacing={isMobile ? 2 : 4}>
              <ContentBoxes section={section} page={page} />
            </Grid>
          )}
        </LinkBoxContentProvider>
      </Box>
    </>
  )
}
export default CenterColumn
