import { useQuery } from '@tanstack/react-query'
import { personalMessageKeys } from 'pages/PersonalMessages/api/keys'
import { getRequest } from 'utils/api/apiRequest'
import { WithPagination } from 'utils/api/pagination'

export type SubscriberTemplate = {
  id: number
  translation: Record<string, { name: string }>
  message_templates: {
    id: number
    default: boolean
    translation: Record<string, { name: string; content: string }>
  }[]
}

const getSubscriberTemplatesByOccasionId = () =>
  getRequest<WithPagination<SubscriberTemplate>>(`/subscriber/message-templates/grouped?limit=1000`)

export const useGetSubscriberTemplates = () => {
  return useQuery({
    queryKey: personalMessageKeys.getSubscriberTemplates([]),
    queryFn: () => getSubscriberTemplatesByOccasionId(),
  })
}
