import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { useIsTablet } from 'utils/hooks/useBreakpoints'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.typography.h1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: theme.typography.h1.fontSize,
    },
  },
  header: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 2, 2, 8),
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(0),
      padding: theme.spacing(1, 0, 2, 1),
    },
  },
  buttonsWrapper: {
    '& button': {
      [theme.breakpoints.down('lg')]: {
        fontSize: theme.typography.h3.fontSize,
        padding: theme.spacing(1, 4),
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h4.fontSize,
        padding: theme.spacing(0.4, 2),
      },
    },
  },
}))

export const Header = ({ quantity = 0 }) => {
  const { title, header, buttonsWrapper } = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const isTablet = useIsTablet()

  return (
    <Grid container spacing={2} alignItems="center" className={header}>
      <Grid item xs={12} md className={title}>
        {t('wishlist')} ({quantity})
      </Grid>
      <Grid item xs={12} md="auto">
        <Grid container spacing={isTablet ? 2 : 4} className={buttonsWrapper} justifyContent="center">
          <Grid item>
            <Button variant="outlined" color="primary" onClick={() => navigate('/catalog')}>
              {t('wishlist.back_to_catalog')}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => navigate('/shopping-cart')}>
              {t('wishlist.go_to_shopping_cart')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
