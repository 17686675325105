const productsQueryKeys = {
  all: ['all', 'products'] as const,

  productsLists: () => [...productsQueryKeys.all, 'productsList'] as const,
  productsList: () => [...productsQueryKeys.productsLists()] as const,

  productDetails: () => [...productsQueryKeys.all, 'productDetail'] as const,
  productDetail: (id: number) => [...productsQueryKeys.productDetails(), id] as const,

  expensivestProduct: () => [...productsQueryKeys.all, 'expensivest'] as const,

  productsInBrandLists: () => [...productsQueryKeys.all, 'productsInBrandList'] as const,
  productsInBrandList: (id: string, search?: string) =>
    [...productsQueryKeys.productsInBrandLists(), id, { search }] as const,

  productsInCategoryLists: () => [...productsQueryKeys.all, 'productsInCategoryList'] as const,
  productsInCategoryList: (id: number, search?: string) =>
    [...productsQueryKeys.productsInCategoryLists(), id, { search }] as const,

  relatedProductsLists: () => [...productsQueryKeys.all, 'relatedProductsList'] as const,
  relatedProductsList: (id: number) => [...productsQueryKeys.relatedProductsLists(), id] as const,

  recentlyViewedLists: () => [...productsQueryKeys.all, 'recentlyViewedList'] as const,
  recentlyViewedList: () => [...productsQueryKeys.recentlyViewedLists()] as const,
  topProducts: () => [...productsQueryKeys.all, 'topProducts'] as const,
}

export default productsQueryKeys
