import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

export const Logout = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 70 70">
    <path
      d="M55.5,49.8c2.6-3.4,7.7,0.6,5.1,4C55,61,46.4,65.3,37.2,65.3c-16.5,0-29.8-13.3-29.8-29.8
		C7.4,19,20.7,5.6,37.2,5.6c9.2,0,17.8,4.2,23.5,11.5c2.6,3.4-2.5,7.4-5.1,4c-4.4-5.7-11.2-8.9-18.4-8.9
		c-12.9,0-23.3,10.4-23.3,23.3c0,12.9,10.4,23.3,23.3,23.3C44.4,58.7,51.1,55.4,55.5,49.8z M42,27.8H21.3v15.3H42v8.4l21.6-16.1
		L42,19.4V27.8L42,27.8z"
    />
  </SvgIcon>
)
