import { useTranslation } from 'react-i18next'
import LoadingButton from '@mui/lab/LoadingButton'
import Grid from '@mui/material/Grid'
import Zoom from '@mui/material/Zoom'
import { Box } from '@mui/system'
import { useFormikContext } from 'formik'
import { DeliveryVariant } from 'pages/CreateOrder/deliveryOptions'
import { CreateOrderType2 } from 'pages/CreateOrder/types'
import { dataCy } from 'utils/cypressUtils'

type Props = {
  isFirstStep: boolean
  isLoading: boolean
}

export const ButtonContinue = ({ isFirstStep, isLoading }: Props) => {
  const { t } = useTranslation()
  const { values } = useFormikContext<CreateOrderType2>()
  const isSelectedCountry = values.country !== ''
  const isDigitalVariantType = values.delivery_address_type === DeliveryVariant.digital

  return (
    <Zoom in={isFirstStep && (isSelectedCountry || isDigitalVariantType)} unmountOnExit mountOnEnter timeout={300}>
      <Grid container justifyContent="center" alignItems="center" spacing={4}>
        <Grid item>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            loading={isLoading}
            data-cy={dataCy('button-order-overview-show')}
          >
            {t('create_order.continue_to_overview')}
          </LoadingButton>
        </Grid>
        <Grid item xs={12}>
          <Box sx={(t) => ({ p: t.spacing(2) })} />
        </Grid>
      </Grid>
    </Zoom>
  )
}
