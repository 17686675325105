import Box from '@mui/material/Box'
import { ChangePasswordButton } from 'pages/MyAccount/components/ChangePasswordButton'
import { LanguageSelector } from 'pages/MyAccount/components/LanguageSelector'
import { ProfileDetail } from 'pages/MyAccount/components/ProfileDetail'
import { User } from 'utils/hooks/userType'

type Props = {
  user?: User
  editable?: boolean
}

export const ProfileDetails = ({ user, editable }: Props) => {
  return (
    <Box
      display="grid"
      gridTemplateColumns="auto 1fr"
      columnGap={6}
      rowGap={2}
      alignContent="center"
      sx={{ height: '100%' }}
    >
      <ProfileDetail label="auth.login.username" value={user?.email} />
      <ProfileDetail label="auth.login.password" value={<ChangePasswordButton editable={editable} />} />
      <ProfileDetail label="profile.language" value={<LanguageSelector editable={editable} />} />
    </Box>
  )
}
