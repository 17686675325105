import { getResponsiveSize } from 'theme/styles.utils'
import { makeStyles } from 'utils/styles/makeStyles'
import { important } from 'utils/styles/stylesUtils'

const useStyles = makeStyles({
  '@global': {
    '.material-icons': {
      fontFamily: 'Material Icons, sans-serif',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontSize: important(getResponsiveSize(20, 'px')),
      lineHeight: 1,
      letterSpacing: 'normal',
      textTransform: 'none',
      display: 'inline-block',
      whiteSpace: 'nowrap',
      wordWrap: 'normal',
      direction: 'ltr',
    },
  },
})

export default function MaterialIcons() {
  useStyles()

  return <div className="cssjss-advanced-global-root" />
}
