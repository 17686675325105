import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Grid from '@mui/material/Grid'
import { useFormikContext } from 'formik'
import { ConsentDialog } from 'pages/Login/ActivateAccount/ConsentDialog'
import { field } from 'pages/Login/LuckyBird/utils'
import { dataCy } from 'utils/cypressUtils'

export const ConsentField = () => {
  const [openConsent, setOpenConsent] = useState(false)
  const { t } = useTranslation()
  const { setFieldValue, touched, errors, values } = useFormikContext<{ consent: boolean }>()

  return (
    <>
      <FormControl name={'consent'} required error={!!errors.consent} component="fieldset" variant="standard">
        <FormControlLabel
          control={
            <Checkbox checked={values.consent} onChange={(_, checked) => setFieldValue(field('consent'), checked)} />
          }
          label={
            <Grid container alignItems="center">
              <Grid item data-cy={dataCy('label-consent')}>
                {t('auth.activate_account.consent')}
              </Grid>
              <Grid item>
                <Button variant="text" size="small" onClick={() => setOpenConsent(true)}>
                  {t('auth.activate_account.consent_link')}
                </Button>
              </Grid>
            </Grid>
          }
        />
        <FormHelperText>{touched.consent && errors.consent}</FormHelperText>
      </FormControl>
      <ConsentDialog open={openConsent} onClose={() => setOpenConsent(false)} />
    </>
  )
}
