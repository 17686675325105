import { useTranslation } from 'react-i18next'
import MenuItem from '@mui/material/MenuItem'
import { FormikTextField } from 'components/Formik/FormikTextField'
import { useFormikContext } from 'formik'
import { DeliveryVariant } from 'pages/CreateOrder/deliveryOptions'
import { getAddress } from 'pages/CreateOrder/helper/getAddress'
import { CreateOrderType2 } from 'pages/CreateOrder/types'
import { DeliveryPresetAddress } from 'utils/api/DeliveryPresetAddress/getDeliveryPresetAddresses'

type Props = {
  presetAddresses: DeliveryPresetAddress[]
}

export const SelectPresetAddress = ({ presetAddresses }: Props) => {
  const { t, i18n } = useTranslation()
  const { values, setValues } = useFormikContext<CreateOrderType2>()

  return (
    <FormikTextField
      select
      center
      name={`${values.delivery_address_type}.preset_address_id`}
      inputLabel="create_order.preset_address_id"
      data-cy={`${values.delivery_address_type}.preset_address_id`}
      onChange={(e) => {
        const presetAddress = presetAddresses?.find((el) => el.id === Number(e.target.value))
        setValues((prev) => ({
          ...prev,
          [DeliveryVariant.preset]: {
            preset_address_id: Number(e.target.value),
            is_company_address: Boolean(presetAddress?.company_name),
            company_name: presetAddress?.company_name,
            street: presetAddress?.street,
            number: presetAddress?.number,
            box: presetAddress?.box,
            zip: presetAddress?.zip,
            city: presetAddress?.city,
          },
        }))
      }}
    >
      <MenuItem value={0} disabled sx={{ display: 'none' }}>
        {t('create_order.select_preset_address')}
      </MenuItem>
      {presetAddresses?.map((presetAddress) => (
        <MenuItem key={presetAddress.id} value={presetAddress.id}>
          {getAddress(presetAddress, i18n.language)}
        </MenuItem>
      ))}
    </FormikTextField>
  )
}
