import { SocialWallPost } from 'utils/api/SocialWall/types'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'
import { makeSx } from 'utils/styles/makeSx'

export const useLikedByLoggedUser = (likes: SocialWallPost['likes'] = []) => {
  const user = useCurrentUser()
  const userLike = likes.find((like) => like.user.id === user.id)
  const likedByLoggedUser = !!userLike
  return { likedByLoggedUser, userId: user.id, userLike }
}

export const actionButtonSx = makeSx((t) => ({
  py: 1,
  lineHeight: 1,
  fontSize: t.typography.body2.fontSize,
  fontWeight: 600,
  borderRadius: 0,
}))
