import { useQuery } from '@tanstack/react-query'
import { personalMessageKeys } from 'pages/PersonalMessages/api/keys'
import { getPersonalMessages } from 'pages/PersonalMessages/getPersonalMessages'
import { Role } from 'utils/api/User'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'

export const useGetPersonalMessage = () => {
  return useQuery({
    enabled: !!useCurrentUser()?.roles?.includes(Role.ROLE_AUTHOR),
    queryKey: personalMessageKeys.getPersonalMessages(['limit=10&page=1']),
    queryFn: () => getPersonalMessages(),
  })
}
