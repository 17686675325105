import { useTranslation } from 'react-i18next'
import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import { FormikButtons } from 'components/Formik/FormikButtons'
import { FormikTextField } from 'components/Formik/FormikTextField'
import { Form, Formik } from 'formik'
import { EnterCustomerInternalIdResponse, useEnterCustomerInternalId } from 'pages/Login/api/useEnterCustomerInternalId'
import { parseError } from 'utils/api/parseError'
import * as Yup from 'yup'

type Props = {
  disabled?: boolean
  onSuccess: (notLinkedUser: EnterCustomerInternalIdResponse | null) => void
  onBack: () => void
}

export const EnterCustomerInternalId = ({ disabled, onSuccess, onBack }: Props) => {
  const { t } = useTranslation()
  const { mutate, error, isError, isLoading } = useEnterCustomerInternalId(onSuccess)
  const parsedError = parseError(error)

  const isVisibleError = parsedError?.message && !isLoading && isError

  const initialValues = {
    customer_internal_id: '',
  }

  const validationSchema = Yup.object().shape({
    customer_internal_id: Yup.string().label(t('auth.activate_account.field.customer_internal_id')).required(),
  })

  const onSubmit = (values: typeof initialValues) => {
    mutate(values)
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} enableReinitialize onSubmit={onSubmit}>
      <Form data-cy="ENTER-PERSONAL-ID-FORM">
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} lg={8}>
            <FormikTextField
              name="customer_internal_id"
              inputLabel="auth.activate_account.field.customer_internal_id"
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} lg={8} sx={{ pt: isVisibleError ? 2 : 0 }}>
            <Collapse in={isVisibleError}>
              <Alert severity="error" sx={{ borderRadius: 24 }}>
                {parsedError?.message}
              </Alert>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <FormikButtons onBack={onBack} />
          </Grid>
        </Grid>
      </Form>
    </Formik>
  )
}
