import { useMutation } from '@tanstack/react-query'
import { bpostAxios } from 'utils/hooks/api/Bpost/bpostAxios'

const getParcelLocker = (zip: string, selectedCountryCode: string) =>
  bpostAxios.get(
    `/Locator?Function=search&Partner=999999&Language=NL&Zone=${zip}&Country=${selectedCountryCode}&Type=4&Limit=10`
  )

export const useGetParcelLocker = (selectedCountryCode = 'BE') =>
  useMutation({ mutationFn: (zip: string) => getParcelLocker(zip, selectedCountryCode), cacheTime: 3000, retry: 0 })
