import * as React from 'react'
import { styled, TypographyProps } from '@mui/material'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

const MenuItemText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'clickable',
})<{ clickable: boolean }>(({ theme, clickable }) => ({
  ...(clickable && {
    transition: '0.3s all',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  }),
}))

interface Props {
  onClick?: () => void
  color?: TypographyProps['color']
}

export const MenuItem = ({ children, onClick, color = 'textPrimary' }: React.PropsWithChildren<Props>) => {
  return (
    <Grid item>
      <MenuItemText onClick={onClick} color={color} clickable={!!onClick}>
        {children}
      </MenuItemText>
    </Grid>
  )
}
