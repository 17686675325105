import * as React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import { autocompleteClasses, inputBaseClasses } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { AutocompleteUsersChip } from 'components/AutocompleteUsers/AutocompleteUsersChip'
import { autocompleteUsersRenderOption } from 'components/AutocompleteUsers/autocompleteUsersRenderOption'
import { AutocompleteUsersProps, OptionBaseParams, RenderOptionBaseParams } from 'components/AutocompleteUsers/types'
import { fontFamilies, fontSizes } from 'theme/styles.utils'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { makeSx } from 'utils/styles/makeSx'
import { important, inside } from 'utils/styles/stylesUtils'

export const AutocompleteUsers = <T extends OptionBaseParams>({
  maxOptions = 0,
  isLoading,
  options = [],
  getOptionLabel,
  getChipLabel,
  onChange,
  error,
}: AutocompleteUsersProps<T>) => {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)
  const [selected, setSelected] = React.useState<T[]>([])
  const isSelectLocked = !maxOptions ? true : selected.length >= maxOptions
  const isSelectLockedEmpty = isSelectLocked && !selected?.length
  const limitSelectedOptions = isSelectLocked ? false : open

  const optionLabel = (v: T) => getOptionLabel?.(v) ?? v.full_name
  const chipLabel = (v: T) =>
    getChipLabel?.(v) ?? (
      <Box component="span" sx={{ fontWeight: 600 }}>
        {v.full_name}
      </Box>
    )
  const enhanceOption = (v: T): T & RenderOptionBaseParams => ({
    ...v,
    optionLabel: optionLabel(v),
    chipLabel: chipLabel(v),
    isSelected: selected.map((el) => el.id).includes(v.id),
  })

  const paperSx = makeSx((t) => ({
    boxShadow: 'none',
    border: `1px solid ${t.palette.primary.light}`,
    pt: '24px',
    borderRadius: t.spacing(0, 0, 4, 4),
    background: t.palette.background.default,
    [inside(autocompleteClasses.option)]: {
      [`& p`]: {
        fontFamily: important(fontFamilies.default),
      },
    },
  }))

  const textFieldSx = makeSx((t) => ({
    zIndex: 2,
    pointerEvents: valueOrUndefined(isSelectLockedEmpty, 'none'),
    [inside(inputBaseClasses.root)]: {
      background: important(t.palette.background.paper),
      py: important(t.spacing(1)),
    },
    [inside(inputBaseClasses.input)]: {
      py: important(t.spacing(0.5)),
      display: valueOrUndefined(isSelectLocked && !!selected?.length, 'none'),
      fontSize: fontSizes.h4,
    },
  }))

  useEffect(() => {
    onChange(selected)
  }, [selected])

  return (
    <>
      <Autocomplete
        id="select-user"
        data-cy="select-user-autocomplete"
        size="small"
        multiple
        loading={isLoading}
        fullWidth
        autoHighlight
        disablePortal
        disableClearable
        value={selected}
        options={options}
        open={limitSelectedOptions}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={(e, v) => {
          const values = v.filter((el) => typeof el !== 'string') as T[]
          setSelected(values)
          setOpen(false)
        }}
        getOptionLabel={(o) => (typeof o === 'string' ? '' : optionLabel(o))}
        sx={{
          ['& fieldset']: {
            borderWidth: important('1px'),
          },
          [inside(autocompleteClasses.endAdornment)]: {
            top: 'auto',
          },
        }}
        slotProps={{
          paper: {
            sx: paperSx,
          },
          popupIndicator: {
            disabled: isSelectLocked,
            color: valueOrUndefined(!isSelectLocked, 'primary'),
            'data-cy': 'select-user-open-button',
            sx: { top: '14px' },
          },
          popper: { sx: { top: important('-24px'), zIndex: important(1) } },
        }}
        popupIcon={<AddCircleOutlineOutlinedIcon />}
        renderOption={(props, option, state) => autocompleteUsersRenderOption(props, enhanceOption(option), state)}
        renderTags={(values) =>
          values
            .map(enhanceOption)
            .map((option) => (
              <AutocompleteUsersChip
                key={option.id}
                option={enhanceOption(option)}
                onDelete={(id) => setSelected((prev) => prev.filter((el) => el.id !== id))}
              />
            ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            slotProps={{
              input: {
                readOnly: isSelectLocked,
                ...params.InputProps,
              },
            }}
            disabled={isSelectLockedEmpty}
            fullWidth
            placeholder={t('recognize_someone.name')}
            sx={textFieldSx}
            error={!!error && !open}
            helperText={valueOrUndefined(!!error, open ? ' ' : error)}
          />
        )}
      />
      <Typography ml={2} sx={{ color: (t) => t.palette.grey[600] }}>
        {t('recognize_someone.recognitions_left', { number: maxOptions - selected.length })}
      </Typography>
    </>
  )
}
