import CameraAlt from '@mui/icons-material/CameraAlt'
import MuiAvatar from '@mui/material/Avatar'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import { useIsFetching } from '@tanstack/react-query'
import Avatar from 'components/Avatar'
import { useChangeAvatar } from 'utils/api/User/useChangeAvatar'
import userQueryKeys from 'utils/api/User/userQueryKeys'
import { getThumbnailPath } from 'utils/getThumbnail'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  relative: {
    position: 'relative',
  },
}))

interface Props {
  editable?: boolean
}

export const MyAccountHeader = ({ editable = false }: Props) => {
  const { relative } = useStyles()
  const user = useCurrentUser()
  const { mutate, isLoading: isAvatarUploading } = useChangeAvatar()
  const isAvatarFetching = useIsFetching(userQueryKeys.me()) > 0

  return (
    <Grid container justifyContent="center" className={relative}>
      <Grid item>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          invisible={!editable}
          badgeContent={
            <MuiAvatar
              sx={(theme) => ({ backgroundColor: theme.palette.background.default, width: '32px', height: '32px' })}
            >
              <IconButton
                data-cy="upload-avatar-button"
                disabled={isAvatarUploading}
                size="small"
                color="primary"
                aria-label="upload avatar"
                component="label"
                sx={{ width: '32px', height: '32px', padding: 0 }}
              >
                <input
                  hidden
                  disabled={isAvatarUploading}
                  accept="image/*"
                  type="file"
                  onChange={(e) => {
                    mutate({ image: e.target.files[0] })
                  }}
                />
                <Box
                  sx={(theme) => ({
                    background: theme.palette.primary.main,
                    borderRadius: '50%',
                    width: '26px',
                    height: '26px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  })}
                >
                  <CameraAlt sx={(theme) => ({ color: theme.palette.common.white, fontSize: '1rem' })} />
                </Box>
              </IconButton>
            </MuiAvatar>
          }
        >
          <Avatar
            fullName={user?.profile?.full_name}
            image={user?.profile?.avatar ? getThumbnailPath(user?.profile?.avatar) : undefined}
            isLoading={isAvatarFetching || isAvatarUploading}
          />
        </Badge>
      </Grid>
    </Grid>
  )
}
