import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'

import { IconActive } from './IconActive'

export const ShoppingBasket = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 30 30">
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M28.2,9.4h-3.5l-0.2-0.9c-0.2-1.1-0.6-2-1.2-2.9C22.6,4.8,22,4,21.1,3.3c-0.9-0.6-1.8-1.1-2.9-1.5  c-0.9-0.3-2.1-0.5-3.2-0.5l0,0c-1.1,0-2.3,0.2-3.2,0.6c-1.1,0.3-2,0.9-2.9,1.5C8,4,7.4,4.8,6.8,5.7C6.2,6.6,5.7,7.5,5.6,8.6L5.4,9.5  H1.8c-0.9,0-1.5,0.8-1.5,1.5V12c0,0.9,0.8,1.5,1.5,1.5h26.5c0.9,0,1.5-0.8,1.5-1.5v-1.1C29.8,10.1,29.2,9.4,28.2,9.4z M8.6,9.2  c0.6-2.7,3.2-4.9,6.4-4.9s5.8,2,6.4,4.7v0.2L8.6,9.2L8.6,9.2z M3.7,27.5c0.2,0.8,0.8,1.2,1.5,1.2h19.3c0.8,0,1.5-0.6,1.5-1.2l2-13.1  H1.8L3.7,27.5z M21.1,18.2c0-0.6,0.5-1.1,1.1-1.1h0.2c0.6,0,1.1,0.5,1.1,1.1v6.7c0,0.6-0.5,1.1-1.1,1.1h-0.2c-0.6,0-1.1-0.5-1.1-1.1  V18.2z M16.4,18.2c0-0.6,0.5-1.1,1.1-1.1h0.2c0.6,0,1.1,0.5,1.1,1.1v6.7c0,0.6-0.5,1.1-1.1,1.1h-0.2c-0.6,0-1.1-0.5-1.1-1.1  C16.4,24.9,16.4,18.2,16.4,18.2z M11.7,18.2c0-0.6,0.5-1.1,1.1-1.1h0.2c0.6,0,1.1,0.5,1.1,1.1v6.7c0,0.6-0.5,1.1-1.1,1.1h-0.2  c-0.6,0-1.1-0.5-1.1-1.1V18.2z M6.9,18.2c0-0.6,0.5-1.1,1.1-1.1h0.2c0.6,0,1.1,0.5,1.1,1.1v6.7c0,0.6-0.5,1.1-1.1,1.1H8  c-0.6,0-1.1-0.5-1.1-1.1V18.2z"
    />
  </SvgIcon>
)
