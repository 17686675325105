import { JSX, ReactNode, useEffect, useState } from 'react'
import { backdropClasses, BoxProps, drawerClasses } from '@mui/material'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import { SelectButton } from 'components/SelectButton'
import { SelectButtonProps } from 'components/SelectButton/SelectButton'
import { useThemeContext } from 'store/ThemeContext'
import { getResponsiveSize } from 'theme/styles.utils'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { inside } from 'utils/styles'
import { Z_INDEX } from 'utils/styles/zIndex'
import { FCWithChildren } from 'utils/types/FCWithChildren'

import { drawerContext } from './drawerContext'
const { Provider } = drawerContext

type Props = {
  label?: string | ReactNode
  countFilters?: number
  disableCustomStyle?: boolean
  buttonProps?: SelectButtonProps
  keepMounted?: boolean
  buttonWrapperProps?: (open: boolean) => BoxProps
  gutterTop?: number
  CustomButton?: (props: { open: boolean; onClick: () => void }) => JSX.Element
  fullHeight?: boolean
}

export const DrawerSelect: FCWithChildren<Props> = ({
  children,
  label,
  disableCustomStyle,
  buttonProps,
  keepMounted = true,
  buttonWrapperProps,
  countFilters,
  gutterTop,
  CustomButton,
  fullHeight,
}) => {
  const [open, setOpen] = useState(false)
  const { appBarHeight } = useThemeContext()
  const [showBackground, setShowBackground] = useState(false)
  const toggleDrawer = () => setOpen((o) => !o)
  const closeDrawer = () => setOpen(false)

  useEffect(() => {
    setShowBackground(open)
  }, [open])

  return (
    <>
      <Box {...buttonWrapperProps?.(open)}>
        {CustomButton ? (
          <CustomButton open={open} onClick={toggleDrawer} />
        ) : (
          <SelectButton open={open} onClick={toggleDrawer} disableCustomStyle={disableCustomStyle} {...buttonProps}>
            <Badge
              slotProps={{
                badge: {
                  style: {
                    top: getResponsiveSize(-6, 'px'),
                    left: getResponsiveSize(-4, 'px'),
                  },
                },
              }}
              badgeContent={countFilters}
              color="primary"
              anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
            >
              {label}
            </Badge>
          </SelectButton>
        )}
      </Box>
      <Provider value={{ closeDrawer }}>
        <Drawer
          keepMounted={keepMounted}
          anchor="top"
          open={open}
          transitionDuration={300}
          onClose={closeDrawer}
          sx={{
            zIndex: Z_INDEX.DRAWER,
            overflow: 'hidden',
            top: gutterTop ?? appBarHeight,
            '&:before': {
              content: '""',
              display: 'block',
              width: '100%',
              height: '100%',
              background: 'rgba(0, 0, 0, 0.3)',
              opacity: showBackground ? 1 : 0,
              transition: 'all .3s',
            },
            [inside(drawerClasses.paper)]: {
              backgroundColor: (t) => t.palette.background.default,
              boxShadow: '0px 11px 28px -21px rgba(0, 0, 0, 0.6)',
              position: 'absolute',
              height: valueOrUndefined(fullHeight, '100%'),
            },
            [inside(backdropClasses.root)]: {
              top: appBarHeight,
              backgroundColor: 'transparent',
              zIndex: 0,
            },
          }}
        >
          {children}
        </Drawer>
      </Provider>
    </>
  )
}
