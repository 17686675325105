const categoriesQueryKeys = {
  all: ['all', 'categories'] as const,
  categoriesLists: () => [...categoriesQueryKeys.all, 'categoriesList'] as const,
  categoriesList: (lang: string) => [...categoriesQueryKeys.categoriesLists(), { lang }] as const,

  breadcrumbLists: () => [...categoriesQueryKeys.all, 'breadcrumbList'] as const,
  breadcrumbList: () => [...categoriesQueryKeys.breadcrumbLists()] as const,

  subCategoriesLists: () => [...categoriesQueryKeys.all, 'subCategoriesList'] as const,
  subCategoriesList: (id: number, lang: string) => [...categoriesQueryKeys.subCategoriesLists(), id, { lang }] as const,

  specialSubcategories: () => [...categoriesQueryKeys.all, 'specialSubcategories'] as const,
}

export default categoriesQueryKeys
