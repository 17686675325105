import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { LoginCallback, useOktaAuth } from '@okta/okta-react'
import { isAxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import { useAuthContext } from 'store/useAuthContext'
import { setAuthTokens } from 'utils/axios-jwt-cookies'

export const OktaLogged = () => {
  const { authState, oktaAuth } = useOktaAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const { refetch } = useAuthContext()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  useEffect(() => {
    if (authState?.isAuthenticated) {
      if (authState.accessToken?.accessToken) {
        setAuthTokens({
          accessToken: authState.accessToken?.accessToken ?? '',
          refreshToken: authState.refreshToken?.refreshToken ?? authState.accessToken?.accessToken ?? '',
        })
      }
      if (!authState.refreshToken?.refreshToken) {
        console.error('[OKTA]: refreshToken is missing')
      }
      refetch()
        .then((data) => {
          if (data?.isError) {
            console.info('[OKTA]: problem with refetching user data')
            enqueueSnackbar(
              isAxiosError(data?.error) && data?.error?.response?.data
                ? data?.error?.response?.data
                : t('sso.okta.problem_with_fetch_user'),
              { variant: 'error' }
            )
            navigate('/auth/login/okta-errors')
          } else {
            console.info('[OKTA]: refetched user data')
            navigate('/')
          }
        })
        .catch((error) => {
          console.info('[OKTA]: problem with refetching user data', error)
          navigate('/auth/logout')
        })
    }
  }, [authState])

  return <LoginCallback onAuthResume={() => console.info('[OKTA]: logged')} />
}
