import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useDispatchShoppingCart } from 'shared/ShoppingCart/slice'
import { useDispatchWishlist } from 'shared/Wishlist/slice'
import { addWishlistItem } from 'utils/api/Wishlist/addWishlistItem'
import { useErrorSnackbar } from 'utils/hooks/useErrorSnackbar'

export const useAddItemToWishlist = () => {
  const { enqueueSnackbar } = useSnackbar()
  const enqueueErrorSnackbar = useErrorSnackbar()
  const { t } = useTranslation()
  const { fetchWishlist } = useDispatchWishlist()
  const { refetchShoppingCart } = useDispatchShoppingCart()

  const { mutate, isLoading } = useMutation(addWishlistItem, {
    onSuccess: () => {
      enqueueSnackbar(t('wishlist.add_item.success'), { variant: 'success' })
      fetchWishlist()
      refetchShoppingCart()
    },
    onError: (e) => {
      enqueueErrorSnackbar(e, { DEFAULT_ERROR: t('wishlist.add_item.fail') })
    },
  })

  const handleAddItemToWishlist = (id: number) => {
    mutate(id)
  }

  return { handleAddItemToWishlist, isLoading }
}
