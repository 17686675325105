import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

export const CloseContained = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 30 30">
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M15,0.3C6.9,0.3,0.3,6.9,0.3,15S6.9,29.7,15,29.7S29.7,23.1,29.7,15S23.1,0.3,15,0.3z M20.9,19.2  c0.5,0.5,0.5,1.3,0,1.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.7-0.1-0.9-0.4l-4.2-4.2l-4.2,4.2c-0.2,0.2-0.6,0.4-0.9,0.4  c-0.3,0-0.7-0.1-0.9-0.4c-0.5-0.5-0.5-1.3,0-1.9l4.2-4.2l-4.2-4.2c-0.5-0.5-0.5-1.3,0-1.9c0.5-0.5,1.3-0.5,1.9,0l4.2,4.2l4.2-4.2  c0.5-0.5,1.3-0.5,1.9,0s0.5,1.3,0,1.9L16.8,15L20.9,19.2z"
    />
  </SvgIcon>
)
