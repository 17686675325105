import { MAIN_DOMAIN, PROTOCOL } from 'config/env'
import isString from 'lodash/isString'
import qs from 'query-string'

export const redirectOnLogin = (navigate: (location: string) => void, isSocialWallActive = false, search = '') => {
  const params = qs.parse(search.replace('?', ''))
  const redirect: string = isString(params?.redirect) ? params?.redirect : params?.redirect?.[0]

  if ('redirectProject' in params) {
    return window.location.assign(`${PROTOCOL}://${params.redirectProject}.${MAIN_DOMAIN}/`)
  }
  console.log('redirectOnLogin', redirect || '/')
  if (isSocialWallActive) {
    return navigate(redirect && redirect !== '/' ? redirect : '/social-wall')
  } else {
    return navigate(redirect || '/')
  }
}
