import { useMutation } from '@tanstack/react-query'
import { bpostAxios } from 'utils/hooks/api/Bpost/bpostAxios'

const getCollectionPoints = (zip: string, selectedCountryCode: string) =>
  bpostAxios.get(
    `/Locator?Function=search&Partner=999999&Language=NL&Zone=${zip}&Country=${selectedCountryCode}&Type=3&Limit=10`
  )

export const useGetCollectionPoints = (selectedCountryCode = 'BE') =>
  useMutation({ mutationFn: (zip: string) => getCollectionPoints(zip, selectedCountryCode), cacheTime: 3000, retry: 0 })
