import { useState } from 'react'
import { useQueryParams } from 'utils/hooks/useQueryParams'

interface Props {
  searchKey: string
}

export const useSearchCard = ({ searchKey }: Props) => {
  const { params, pushParam } = useQueryParams()
  const [searchPhrase, setSearchPhrase] = useState<string | undefined>(params?.[searchKey] as string)
  const handleChange = (value?: string) => {
    setSearchPhrase(value)
    pushParam({ [searchKey]: value })
  }

  return { searchPhrase, setSearchPhrase: handleChange }
}
