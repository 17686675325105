import { forwardRef } from 'react'
import {
  inputBaseClasses,
  inputLabelClasses,
  paperClasses,
  styled,
  textFieldClasses,
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material'
import MuiTextField from '@mui/material/TextField'
import { menuPaperSx } from 'components/CustomPopover/CustomPopover'
import { inputLabelPos } from 'theme/styles.utils'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { and, inside } from 'utils/styles'

export type TextFieldProps = {
  center?: boolean
} & MuiTextFieldProps

const StyledTextField = styled(MuiTextField, {
  shouldForwardProp: (propName) => propName !== 'center',
})((props: TextFieldProps) => ({
  ...(props.center && {
    [and(textFieldClasses.root)]: {
      [inside(inputBaseClasses.input)]: {
        textAlign: 'center',
        // for proper "select" positioning
        justifyContent: 'center',
      },
      [inside(inputLabelClasses.root)]: {
        transform: inputLabelPos.transformAlignCenter,
        left: inputLabelPos.leftAlignCenter,
      },
    },
  }),
}))
export const TextField = forwardRef<HTMLDivElement, TextFieldProps>((props, ref) => {
  const selectProps: TextFieldProps['SelectProps'] = {
    MenuProps: {
      sx: (t) => ({ [inside(paperClasses.root)]: menuPaperSx(t) }),
    },
  }
  return <StyledTextField ref={ref} SelectProps={valueOrUndefined(props.select, selectProps)} {...props} />
})
