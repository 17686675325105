import React from 'react'
import { RenderCustomAction } from 'utils/actionsCreator/types'

import { CustomIconButton } from './CustomIconButton'

export const renderCustomAction =
  <T,>({ icon, keyProp, hidden, ...args }: RenderCustomAction<T>) =>
  (rowData: T) =>
    !icon || hidden ? null : (
      // @ts-ignore
      <CustomIconButton {...args} rowData={rowData} key={keyProp}>
        {React.isValidElement(icon)
          ? icon
          : typeof icon === 'string'
            ? icon
            : // @ts-ignore
              icon(rowData)}
      </CustomIconButton>
    )
