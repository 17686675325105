import { Fragment } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { FormikDatePicker } from 'components/Formik/FormikDatePicker'
import { FormikTextField } from 'components/Formik/FormikTextField'
import { JSONAutocomplete } from 'components/JSONForm/JSONAutocomplete'
import {
  FieldsExtended,
  FormFieldDate,
  FormFieldExtended,
  FormFieldSelect,
  FormFieldText,
} from 'components/JSONForm/types'
import { TFunction } from 'i18next'
import { ProfileAvatar } from 'pages/Login/ActivateAccount/ProfileAvatar'
import { TranslationsKeys } from 'utils/createTranslationKey'
import { getObjectTranslation } from 'utils/getObjectTranslation'

const renderTextField = <T extends FormFieldExtended<FormFieldText>>(f: T) => {
  return (
    <Grid item xs={12} lg={f.size || 6}>
      <FormikTextField name={f.name} inputLabel={f.translationKey} disabled={f['read-only']} />
    </Grid>
  )
}
const renderSelect = <T extends FormFieldExtended<FormFieldSelect>>(f: T, t: TFunction) => {
  return (
    <Grid item xs={12} lg={f.size || 6}>
      <FormikTextField name={f.name} inputLabel={f.translationKey} disabled={f['read-only']} select>
        <MenuItem key="no_selected" value="">
          {t('app.no_selected')}
        </MenuItem>
        {f.options.map((o) => {
          let name = o.name
          if (o?.translation && Object.keys(o?.translation).length) {
            name = getObjectTranslation(o?.translation)?.name
          }
          if (o.translation_key) {
            name = t(`${f.translationKey}.${o.translation_key}` as TranslationsKeys)
          }
          return (
            <MenuItem key={o.value} value={o.value}>
              {name}
            </MenuItem>
          )
        })}
      </FormikTextField>
    </Grid>
  )
}
const renderAutocomplete = <T extends FormFieldExtended<FormFieldSelect>>(f: T) => {
  return (
    <Grid item xs={12} lg={f.size || 6}>
      <JSONAutocomplete
        name={f.name}
        options={f.options}
        translationLabel={f.translationKey}
        disabled={f['read-only']}
      />
    </Grid>
  )
}

const renderDatePicker = <T extends FormFieldExtended<FormFieldDate>>(f: T) => {
  return (
    <Grid item xs={12} lg={f.size || 6}>
      <FormikDatePicker name={f.name} label={f.translationKey} disabled={f['read-only']} />
    </Grid>
  )
}
const renderAvatar = <T extends FormFieldExtended<FormFieldText>>(f: T) => {
  return (
    <Grid item xs={12}>
      <ProfileAvatar name={f.name} />
    </Grid>
  )
}

export const renderFormFields = (f: FieldsExtended, t: TFunction) => {
  if (!f) return null

  const orderedArr = Object.entries(f)
    .map(([k, v]) => v)
    .sort((a, b) => a.position - b.position)

  const renderField = (el: (typeof orderedArr)[0]) => {
    if (el.type === 'text' || el.type === 'email') {
      return renderTextField(el)
    }
    if (el.type === 'select') {
      return renderSelect(el, t)
    }
    if (el.type === 'autocomplete') {
      return renderAutocomplete(el)
    }
    if (el.type === 'date') {
      return renderDatePicker(el)
    }
    if (el.type === 'image') {
      return renderAvatar(el)
    }
    if (el.type === 'object') {
      if (el.header)
        return (
          <Grid container item spacing={2}>
            <Grid item xs={12}>
              <Typography
                textTransform="uppercase"
                variant="body2"
                textAlign="center"
                fontWeight="bold"
                sx={{ color: (t) => t.palette.grey[600] }}
              >
                {t(el.header)}
              </Typography>
            </Grid>
            {renderFormFields(el.fields, t)}
            <Box sx={{ py: 1, width: '100%' }} />
          </Grid>
        )
      return renderFormFields(el.fields, t)
    }
    console.warn(`Unhandled form field type: ${el.type}`)
    return null
  }
  return orderedArr.map((el) => <Fragment key={el.name}>{renderField(el)}</Fragment>)
}
