import { useLocation } from 'react-router-dom'
import isArray from 'lodash/isArray'
import { CmsContent } from 'pages/CMS/CMS.types'
import qs from 'query-string'

export const useFilterVisibleContent = () => {
  const { search } = useLocation()
  const params = qs.parse(search.replace('?', ''))
  const infoContentId = isArray(params?.info) ? undefined : params?.info

  return (content: CmsContent) => content.visible && content.id.toString() !== infoContentId
}
