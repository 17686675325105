import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { fetchProduct } from 'utils/api/Products/fetchProduct'
import productsQueryKeys from 'utils/api/Products/productsQueryKeys'
import { useErrorSnackbar } from 'utils/hooks/useErrorSnackbar'

export const useProduct = (id = 1) => {
  const navigate = useNavigate()
  const enqueueErrorSnackbar = useErrorSnackbar()

  return useQuery({
    queryKey: productsQueryKeys.productDetail(id),
    queryFn: () => fetchProduct(id),
    onError(e) {
      const { isCode } = enqueueErrorSnackbar(e)
      if (isCode('NOT_FOUND')) {
        navigate('/no-match')
      }
    },
  })
}
