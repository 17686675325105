import { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { TextField, TextFieldProps } from 'components/TextField/TextField'
import { getIn, useFormikContext } from 'formik'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import { TranslationsKeys } from 'utils/createTranslationKey'

export type FormikTextFieldProps = Partial<TextFieldProps> & {
  name: string
  select?: true
  inputLabel?: TranslationsKeys
  children?: ReactNode
  required?: boolean
  disabled?: boolean
  additionalInputElement?: ReactNode
}

export const FormikTextField = ({
  name,
  children,
  select,
  inputLabel,
  required,
  additionalInputElement,
  ...props
}: FormikTextFieldProps) => {
  const { t } = useTranslation()
  const { getFieldProps, setFieldValue, touched: touchedArray, errors } = useFormikContext()
  const { touched, error } = { touched: getIn(touchedArray, name), error: getIn(errors, name) }
  const { value, onChange, onBlur } = getFieldProps(name)
  const label = t(inputLabel)
  const errorTextField = !isEmpty(error) && touched
  const helperText = touched && error && error
  const hasLabel = Boolean(inputLabel)

  return useMemo(
    () => (
      <Box sx={{ position: 'relative' }}>
        <TextField
          variant="outlined"
          select={select}
          name={name}
          error={errorTextField}
          helperText={helperText}
          label={hasLabel ? label : undefined}
          fullWidth
          value={value}
          onChange={onChange}
          onBlur={(e) => {
            onBlur(e)
            if (isString(e.target.value)) {
              setFieldValue(name, e.target.value?.trim())
            }
          }}
          children={children}
          onWheel={(e) => {
            if (props.type !== 'number') return
            e.target instanceof HTMLElement && e.target.blur()
          }}
          {...(props as TextFieldProps)}
        />
        {additionalInputElement}
      </Box>
    ),
    [
      children,
      errorTextField,
      helperText,
      label,
      name,
      onBlur,
      onChange,
      props,
      select,
      setFieldValue,
      value,
      hasLabel,
      additionalInputElement,
    ]
  )
}
