import { Project, useSubdomainInfo } from 'shared/Site/api'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'

export const getProjectWithSocialWallFromUserProjects = (projects: { [key: string]: Project }) => {
  return Object.values(projects)?.find((project) => project.is_social_wall_active)
}

export const useSocialWall = () => {
  const { isFetching } = useSubdomainInfo()

  const user = useCurrentUser()

  const socialWallProject = getProjectWithSocialWallFromUserProjects(user?.projects || {})
  return {
    isLoading: isFetching,
    isSocialWallActive: !!socialWallProject?.is_social_wall_active,
    isBirthdayWidgetActive: !!socialWallProject?.social_wall?.birthday_widget,
    isValuesWidgetActive: !!socialWallProject?.social_wall?.values_widget,
  }
}
