import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import { HeaderPage } from 'components/HeaderPage/HeaderPage'
import Loader from 'components/Loader/Loader'
import { ResponsivePaper } from 'components/ResponsivePaper'
import { ShowMoreButton } from 'components/ShowMoreButton/ShowMoreButton'
import { OrderHistoryRow } from 'pages/OrderHistory/components/OrderHistoryRow'
import { OrderHistoryRow as OrderHistoryPosition } from 'utils/api/OrderHistory/getOrderHistory'
import { useOrderHistory } from 'utils/hooks/api/useOrderHistory'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3, 6),
  },
}))

export const MyAccountHistory = () => {
  const { container } = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { data, isFetching } = useOrderHistory()
  const orders: OrderHistoryPosition[] = data?.data?.data || []

  if (!orders?.length) return null

  return (
    <Grid item xs={12}>
      <ResponsivePaper>
        <div className={container}>
          <Loader isLoading={isFetching} objects={orders}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <HeaderPage size="medium" text={t('profile.order_history')} />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {orders?.map((order) => (
                <Fragment key={order.id}>
                  <Grid item xs={12}>
                    <OrderHistoryRow
                      order={order}
                      onOpen={() => navigate(`/my-account/order-history/${order.ext_web_order_reference.id}`)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Fragment>
              ))}
              <ShowMoreButton
                items={data?.data?.page}
                allItems={data?.data?.pages}
                isLoading={isFetching}
                onClick={() => navigate(`/my-account/order-history?limit=${15}`)}
              />
            </Grid>
          </Loader>
        </div>
      </ResponsivePaper>
    </Grid>
  )
}
