import { getRequest } from 'utils/api/apiRequest'

import { WithPagination } from '../pagination'

export type RecognitionValue = {
  id: number
  color: string
  translation: {
    [key: string]: {
      name: string
      image?: string | null
      description: string
    }
  }
}

export type FetchValuesResponse = WithPagination<RecognitionValue>
export const fetchValues = () => getRequest<FetchValuesResponse>('/social-wall/values?sort[position]=asc')
