import { JSX } from 'react'
import { useTranslation } from 'react-i18next'
import { ActionsCreator } from 'utils/actionsCreator/types'
import { TranslationsKeys } from 'utils/createTranslationKey'

import { argsToRenderAction } from './argsToRenderAction'

interface RenderActionsBase<T> {
  render: (rowData: T | undefined) => JSX.Element
  size?: 'small' | 'medium' | 'large'
  title?: TranslationsKeys
}
const renderActionsBase = <T,>({ render, size = 'medium', title = 'app.actions' }: RenderActionsBase<T>) => {
  const { t } = useTranslation()
  const padding = {
    small: undefined,
    medium: '0 8px',
    large: undefined,
  }[size]

  return {
    title: t(title),
    filterCellStyle: { minWidth: 'max-content' } as const,
    headerStyle: { minWidth: 'max-content' } as const,
    sorting: false,
    filtering: false,
    align: 'right' as const,
    width: 'max-content' as const,
    cellStyle: {
      minWidth: 'max-content',
      width: 'fit-content',
      whiteSpace: 'nowrap',
      padding,
    } as const,
    render,
  }
}

export const actionsCreator = <T extends Record<any, any> | undefined = { id: number }>({
  remove,
  edit,
  size = 'medium',
  before,
  between,
  after,
  title,
}: ActionsCreator<T>) =>
  renderActionsBase<T>({
    render: (rowData) => (
      <>
        {argsToRenderAction(
          // @ts-ignore
          before,
          rowData
        )}
        {argsToRenderAction(
          // @ts-ignore
          edit,
          rowData,
          'edit'
        )}
        {argsToRenderAction(
          // @ts-ignore
          between,
          rowData
        )}
        {argsToRenderAction(
          // @ts-ignore
          remove,
          rowData,
          'delete'
        )}
        {argsToRenderAction(
          // @ts-ignore
          after,
          rowData
        )}
      </>
    ),
    size,
    title,
  })
