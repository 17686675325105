import { RoutingType } from 'routes/types'
import { SiteType } from 'shared/Site/siteType'

export const filterRoutes =
  ({ siteType, paths, show }: { paths: string[]; siteType: SiteType[]; show: boolean | undefined }) =>
  (route: RoutingType): boolean => {
    const isPath = paths.find((p) => (p.includes('*') ? route.path.startsWith(p.slice(0, -1)) : route.path === p))
    if (!(isPath && siteType.includes(route.siteType))) return true
    return show
  }
