import { useQuery } from '@tanstack/react-query'
import birthdayQueryKeys from 'utils/api/Birthdays/birthdayQueryKeys'
import { fetchUpcomingBirthdays } from 'utils/api/Birthdays/fetchUpcomingBirthdays'

export const useUpcomingBirthdays = () => {
  return useQuery({
    queryKey: birthdayQueryKeys.upcomingBirthdayList(),
    queryFn: () => fetchUpcomingBirthdays(),
  })
}
