import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Loader from 'components/Loader/Loader'

export const AuthRedirect = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate('/')
  })
  return <Loader isLoading>404</Loader>
}
