import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useOktaAuth } from '@okta/okta-react'
import { useIsLuckyBirdAuth } from 'pages/Login/LuckyBird/useIsLuckyBirdAuth'
import { useSubdomainInfo } from 'shared/Site/api'
import { SiteType } from 'shared/Site/siteType'
import { useAuthContext } from 'store/useAuthContext'
import { dataCy } from 'utils/cypressUtils'
import { useIsLuckyBirdUser } from 'utils/hooks/useIsLuckyBirdUser'

export const Logout = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: subdomainInfo } = useSubdomainInfo()
  const areCMS = subdomainInfo?.data?.type === SiteType.cms
  const { oktaAuth, authState } = useOktaAuth()
  const { instance } = useMsal()
  const { isLuckyBird } = useIsLuckyBirdUser()
  const isLuckyBirdAuth = useIsLuckyBirdAuth()
  const { logoutUser } = useAuthContext()

  const loginRoute = isLuckyBird || isLuckyBirdAuth ? '/auth/luckybird' : '/auth/login'
  useEffect(() => {
    if (areCMS) {
      if (authState?.isAuthenticated) {
        oktaAuth.clearStorage()
      }
      // TODO: Uncomment this when we have the correct instance
      // if (instance?.getActiveAccount()) {
      //   instance?.logout()
      // }
      logoutUser()
      navigate(loginRoute)
    }
  }, [navigate, areCMS, loginRoute])

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
      <Grid item>
        <Typography variant="h2">{t('auth.are_logout')}</Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate(loginRoute)}
              data-cy={dataCy('button-logout')}
            >
              {t('auth.login')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
