import Grid from '@mui/material/Grid'
import { FormikTextField } from 'components/Formik/FormikTextField'
import { useFormikContext } from 'formik'
import { DeliveryVariant } from 'pages/CreateOrder/deliveryOptions'
import { CreateOrderType2 } from 'pages/CreateOrder/types'

export const PersonalData = () => {
  const { getFieldProps, getFieldMeta, values } = useFormikContext<CreateOrderType2>()

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg>
            <FormikTextField name="first_name" inputLabel="create_order.first_name" />
          </Grid>
          <Grid item xs={12} lg>
            <FormikTextField name="last_name" inputLabel="create_order.last_name" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg>
            <FormikTextField name="email" inputLabel="create_order.email" />
          </Grid>
          {values.delivery_address_type !== DeliveryVariant.digital && (
            <Grid item xs={12} lg>
              <FormikTextField name="phone_number" inputLabel="create_order.phone_number" />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
