import { createContext, useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { replayIntegration } from '@sentry/browser'
import {
  browserApiErrorsIntegration,
  browserProfilingIntegration,
  browserTracingIntegration,
  init,
  isInitialized,
} from '@sentry/react'
import { CookiesContextState, CookiesContextType } from 'components/CookiesDialog/types'
import { APP_VERSION, ENVIRONMENT, SENTRY_DNS } from 'config/env'
import { format } from 'date-fns'
import { getCookie, setCookie } from 'utils/cookies'
import { dateWithTimeFormat } from 'utils/dateFormats'
import { FCWithChildren } from 'utils/types/FCWithChildren'

export const CookiesContext = createContext<CookiesContextType | null>(null)
const COOKIES_ACCEPT_KEY = 'cookies_accept'

export const CookiesProvider: FCWithChildren = ({ children }) => {
  const parsedCookies = JSON.parse(
    getCookie(COOKIES_ACCEPT_KEY) ||
      JSON.stringify({
        isNecessaryAccepted: false,
        isPerformanceAccepted: false,
        isFunctionalAccepted: false,
        acceptedAt: null,
      })
  ) as CookiesContextState
  const [state, setState] = useState<CookiesContextState>(parsedCookies)
  const [isCookieBannerVisible, setIsCookieBannerVisible] = useState(
    !parsedCookies?.acceptedAt || !parsedCookies?.isNecessaryAccepted
  )
  const [isCookieDetailsVisible, setIsCookieDetailsVisible] = useState(false)
  const initialized = isInitialized()

  useEffect(() => {
    if (state.isPerformanceAccepted) {
      console.info('reactGA4 initialize')
      ReactGA.initialize('G-4S6DKYXFQ3', { testMode: ENVIRONMENT !== 'prod' })
    }
    if (!state.isPerformanceAccepted && ReactGA._hasLoadedGA) {
      console.info('reactGA4 reset')
      ReactGA.reset()
    }
  }, [state.isPerformanceAccepted])

  useEffect(() => {
    if (['dev', 'staging', 'prod'].includes(ENVIRONMENT) && !document.location.hostname.includes('localhost')) {
      if (state.isFunctionalAccepted && !initialized) {
        try {
          console.info('sentry initialize')
          init({
            dsn: SENTRY_DNS,
            environment: ENVIRONMENT,
            normalizeDepth: 10,
            maxBreadcrumbs: 50,
            release: APP_VERSION,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            integrations: [
              browserTracingIntegration(),
              browserProfilingIntegration(),
              browserApiErrorsIntegration(),
              replayIntegration({
                maskAllInputs: true,
                maskAllText: ENVIRONMENT === 'prod',
                blockAllMedia: false,
                beforeAddRecordingEvent: (event) => (state.isFunctionalAccepted ? event : null),
                beforeErrorSampling: (event) => state.isFunctionalAccepted,
              }),
            ],
            beforeSend: (event, hint) => (state.isFunctionalAccepted ? event : null),
            beforeBreadcrumb: (breadcrumb) => (state.isFunctionalAccepted ? breadcrumb : null),
            beforeSendTransaction: (transaction) => (state.isFunctionalAccepted ? transaction : null),
            tracesSampler: (samplingContext) => (state.isFunctionalAccepted ? 1.0 : false),
          })
        } catch (e) {
          console.warn('Sentry error: ', e)
        }
      }
    }
  }, [state.isFunctionalAccepted, initialized])

  const savePreferences = (data: Omit<CookiesContextState, 'acceptedAt'>) => {
    setIsCookieBannerVisible(false)
    setIsCookieDetailsVisible(false)
    updateCookiesState(
      {
        isNecessaryAccepted: data.isPerformanceAccepted,
        isPerformanceAccepted: data.isPerformanceAccepted,
        isFunctionalAccepted: data.isFunctionalAccepted,
      },
      true
    )
  }

  const openDetails = () => setIsCookieDetailsVisible(true)

  const updateCookiesState = (data: Omit<CookiesContextState, 'acceptedAt'>, savePreferences: boolean) => {
    const acceptedAt = savePreferences ? format(new Date(), dateWithTimeFormat) : null
    const dataWithDate: CookiesContextState = {
      acceptedAt,
      ...data,
      isNecessaryAccepted: true,
    }

    setState(dataWithDate)

    setCookie(COOKIES_ACCEPT_KEY, JSON.stringify(dataWithDate), 365)
  }

  return (
    <CookiesContext.Provider
      value={{
        state,
        isCookieBannerVisible,
        isCookieDetailsVisible,
        setIsCookieDetailsVisible,
        savePreferences,
        openDetails,
      }}
    >
      {children}
    </CookiesContext.Provider>
  )
}
