import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

export const Values = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 70 70">
    <g>
      <path
        d="M63.21,21.69H7.66c-1.94,0-3.52-1.57-3.52-3.52s1.57-3.52,3.52-3.52h55.54c1.94,0,3.52,1.57,3.52,3.52
		S65.15,21.69,63.21,21.69z"
      />
      <path
        d="M35.07,38.9H7.66c-1.94,0-3.52-1.57-3.52-3.52c0-1.94,1.57-3.52,3.52-3.52h27.4c1.94,0,3.52,1.57,3.52,3.52
		C38.58,37.32,37.01,38.9,35.07,38.9z"
      />
      <path
        d="M56.17,56.21H7.66c-1.94,0-3.52-1.57-3.52-3.52c0-1.94,1.57-3.52,3.52-3.52h48.51c1.94,0,3.52,1.57,3.52,3.52
		C59.69,54.64,58.12,56.21,56.17,56.21z"
      />
    </g>
  </SvgIcon>
)
