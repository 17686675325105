import { patchRequest } from 'utils/api/apiRequest'

type ChangePasswordProps = {
  current_password: string
  password: {
    first: string
    second: string
  }
}

export const changePassword = (values: ChangePasswordProps) => patchRequest('/redemption-site/password/change', values)
