import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

export const Play = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 70 70">
    <path
      d="M35.5,69.4c18.7,0,34-15.3,34-34s-15.3-34-34-34s-34,15.3-34,34S16.7,69.4,35.5,69.4z M26.2,18.7l23,16.7
	l-23,16.7V18.7z"
    />
  </SvgIcon>
)
