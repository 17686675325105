import { FunctionComponent } from 'react'
import Grid from '@mui/material/Grid'
import { CmsSection } from 'pages/CMS/CMS.types'
import { useIsMobile } from 'utils/hooks/useBreakpoints'
import { useFilterVisibleContent } from 'utils/hooks/useFilterVisibleContent'

import { ContentBox } from './ContentBox'

type Props = {
  section: CmsSection
}

export const RightColumn: FunctionComponent<Props> = ({ section }) => {
  const isMobile = useIsMobile()
  const filterContent = useFilterVisibleContent()

  return (
    <Grid container spacing={isMobile ? 2 : 4}>
      {section.contents
        .filter(filterContent)
        .sort((a, b) => a.position - b.position)
        .map((content) => (
          <Grid key={content.id} item xs={12}>
            <ContentBox content={content} sectionName={section?.name} />
          </Grid>
        ))}
    </Grid>
  )
}
