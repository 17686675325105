import { useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import SearchIcon from '@mui/icons-material/Search'
import { inputBaseClasses } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Zoom from '@mui/material/Zoom'
import { TextField } from 'components/TextField/TextField'
import { WhitePaperProps } from 'components/WhitePaper/types'
import SearchContext from 'store/SearchContext'
import { Footer } from 'theme/Footer'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { useIsTablet } from 'utils/hooks/useBreakpoints'
import { important, inside } from 'utils/styles'
import { makeStyles } from 'utils/styles/makeStyles'
import { FCWithChildren } from 'utils/types/FCWithChildren'

import { Toolbar } from './Toolbar'

const useStyles = makeStyles((theme) => ({
  csSearch: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(0, 1),
    },
  },
  paperHeader: {
    padding: theme.spacing(0, 2),
    minHeight: theme.spacing(8),
    borderRadius: 0,
    position: 'relative',
    zIndex: 1,
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(2),
      borderTopLeftRadius: theme.spacing(1.5),
      borderTopRightRadius: theme.spacing(1.5),
    },
    '&.absolute': {
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: 2,
    },
  },
  paperBody: {
    padding: theme.spacing(0, 2, 8, 2),
    borderRadius: 0,
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 4, 8, 4),
      borderBottomLeftRadius: theme.spacing(1.5),
      borderBottomRightRadius: theme.spacing(1.5),
    },
    '&.absolute': {
      padding: theme.spacing(8, 4, 8, 4),
    },
  },
  toolbar: {
    fontSize: theme.typography.h2.fontSize,
    color: theme.palette.primary.main,
    padding: `${theme.spacing(0.5, 2, 0, 2)} !important`,
  },
  points: {
    fontSize: theme.typography.h2.fontSize,
    textDecoration: 'none',
  },
}))

export const WhitePaper: FCWithChildren<WhitePaperProps> = ({
  children,
  changeViewDisable,
  showPointsDisable = false,
  header,
  FilterComponent,
  SortComponent,
  headerAbsolute,
  defaultBgColor,
}) => {
  const { paperBody, paperHeader, csSearch } = useStyles()
  const isTablet = useIsTablet()
  const { t } = useTranslation()
  const { hasSearching, onCloseSearch, currentValueSearch, onChangeSearch, setSearchParam } = useContext(SearchContext)
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <Grid container justifyContent="center">
      <Zoom in={hasSearching} mountOnEnter unmountOnExit timeout={200}>
        <Grid item xs={12} lg={10} className={csSearch}>
          <TextField
            inputRef={inputRef}
            sx={(t) => ({
              [inside(inputBaseClasses.root)]: {
                background: important(t.palette.background.paper),
              },
              [`& .Mui-focused input::placeholder`]: {
                visibility: 'hidden',
              },
            })}
            size="small"
            variant="outlined"
            fullWidth
            autoFocus
            center
            placeholder={t('app.search_placeholder')}
            defaultValue={currentValueSearch}
            onChange={(event) => onChangeSearch(event.target.value)}
            slotProps={{
              input: {
                endAdornment: (
                  <IconButton color="primary" onClick={() => onChangeSearch(inputRef?.current?.value)}>
                    <SearchIcon color="primary" />
                  </IconButton>
                ),
              },
            }}
          />
        </Grid>
      </Zoom>

      {(FilterComponent || SortComponent || !changeViewDisable || !showPointsDisable) && !isTablet && (
        <Toolbar
          FilterComponent={FilterComponent}
          SortComponent={SortComponent}
          changeViewDisable={changeViewDisable}
          showPointsDisable={showPointsDisable}
        />
      )}
      <Grid item xs={12} lg={10}>
        <Paper
          elevation={defaultBgColor ? 0 : 1}
          sx={(t) => ({
            [t.breakpoints.up('lg')]: {
              borderRadius: t.spacing(1.5),
              position: 'relative',
            },
            [t.breakpoints.down('lg')]: {
              borderRadius: 0,
              marginTop: t.spacing(2),
            },
            backgroundColor: valueOrUndefined(defaultBgColor, t.palette.background.default),
          })}
        >
          {header && <Box className={`${paperHeader}${headerAbsolute ? ' absolute' : ''}`}>{header}</Box>}
          <Box className={`${paperBody}${headerAbsolute ? ' absolute' : ''}`}>{children}</Box>
        </Paper>
      </Grid>
      <Grid item xs={12} lg={10}>
        <Footer />
      </Grid>
    </Grid>
  )
}
