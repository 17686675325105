import { createContext, useContext, useState } from 'react'
import { FCWithChildren } from 'utils/types/FCWithChildren'

type ThemeContextType = {
  swSwitcherHeight: number
  appBarHeight: number
  setSwSwitcherHeight: (height: number) => void
  setAppBarHeight: (height: number) => void
}

const ThemeContext = createContext<ThemeContextType | null>(null)
const { Provider, Consumer } = ThemeContext

const CMSThemeProvider: FCWithChildren = ({ children }) => {
  const [values, setValues] = useState({ swSwitcherHeight: 57, appBarHeight: 640 })

  const setSwSwitcherHeight = (height: number) => {
    setValues((prev) => ({ ...prev, swSwitcherHeight: height }))
  }
  const setAppBarHeight = (height: number) => {
    setValues((prev) => ({ ...prev, appBarHeight: height }))
  }

  return <Provider value={{ ...values, setSwSwitcherHeight, setAppBarHeight }}>{children}</Provider>
}

const useThemeContext = () => {
  const context = useContext(ThemeContext)
  if (!context) throw Error('Missing AuthContext')
  return context
}

export { CMSThemeProvider, Consumer as ThemeConsumer, useThemeContext }
export default ThemeContext
