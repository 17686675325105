import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Loader from 'components/Loader/Loader'
import qs from 'query-string'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
}))

export const AuthLoggedUser = () => {
  const { root } = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = qs.parse(location.search.replace('?', ''))

  useEffect(() => {
    navigate(
      params?.redirect
        ? `${params?.redirect as string}?${qs.stringify({ ...params, redirect: '' })}`
        : `/?${qs.stringify({ ...params, redirect: '' })}`
    )
  }, [])

  return (
    <Grid container alignItems="center" justifyContent="center" className={root}>
      <Grid item>
        <Loader isLoading={!!((params?.redirectProject as string) || params?.redirect || (params?.token as string))}>
          <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid item>
              <Typography variant="h2">{t('auth.logged_user')}</Typography>
            </Grid>
          </Grid>
        </Loader>
      </Grid>
    </Grid>
  )
}
