import { useGetAvailableCountries } from 'components/CountryContextDialog/hooks/useGetAvailableCountries'
import { useDeliveryAddressPreset } from 'pages/CreateOrder/components/useDeliveryAddressPreset'
import { DeliveryVariant } from 'pages/CreateOrder/deliveryOptions'
import {
  CreateOrderBPostPointData,
  CreateOrderShippingAddress,
  CreateOrderShippingData,
  CreateOrderType2,
} from 'pages/CreateOrder/types'
import { DeliveryMethods, DeliveryTypes } from 'shared/ShoppingCart/api'
import { useSelectShoppingCart } from 'shared/ShoppingCart/slice'
import { useCountryContext } from 'store/useCountryContext'
import { DeliveryPresetAddress } from 'utils/api/DeliveryPresetAddress/getDeliveryPresetAddresses'
import { addressType } from 'utils/api/User'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'
import { User } from 'utils/hooks/userType'

const getEmptyInitialData = () => {
  const user: User = useCurrentUser()
  const homeAddress = user?.profile?.addresses?.find((address) => address.type === addressType.home)
  const workAddress = user?.profile?.addresses?.find((address) => address.type === addressType.work)

  const createOrderShippingData: CreateOrderShippingData = {
    first_name: user.profile?.first_name || '',
    last_name: user.profile?.last_name || '',
    email: user?.email || '',
    phone_number: user.profile?.phone || '',
  }

  const physicalDeliveryAddressData: CreateOrderShippingAddress = {
    company_name: '',
    is_company_address: false,
    street: '',
    number: '',
    box: '',
    zip: '',
    city: '',
  }

  const bPostDeliveryPointData: CreateOrderBPostPointData = {
    bpost_delivery_point_id: '',
    bpost_delivery_point_name: '',
  }

  return {
    createOrderShippingData,
    bPostDeliveryPointData,
    physicalDeliveryAddressData,
    homeAddress,
    workAddress,
  }
}

// eslint-disable-next-line complexity
export const getInitialValues2 = (): CreateOrderType2 => {
  const user = useCurrentUser()
  const { country } = useCountryContext()
  const { data: shoppingCartItems, isFetching } = useSelectShoppingCart()
  const deliveryData = shoppingCartItems?.data?.delivery_data
  const deliveryAddressType = deliveryData?.delivery_address_type
  const shippingAddress = deliveryData?.shipping_address
  const { data } = useGetAvailableCountries()
  const deliveryCountries = data?.data || []
  const isGroupAndDate = shoppingCartItems?.data?.delivery_method_name === DeliveryMethods.groupedAndDate
  const { data: presetAddressesData } = useDeliveryAddressPreset()
  const presetAddresses: DeliveryPresetAddress[] = presetAddressesData?.data?.data || []
  const presetAddress: DeliveryPresetAddress | undefined = presetAddressesData?.data?.data?.find((preset) => preset.id)

  const { createOrderShippingData, bPostDeliveryPointData, physicalDeliveryAddressData, homeAddress, workAddress } =
    getEmptyInitialData()

  const shippingCartDeliveryData: CreateOrderShippingAddress & CreateOrderBPostPointData = {
    company_name: shippingAddress?.company_name || physicalDeliveryAddressData.company_name,
    is_company_address: !!shippingAddress?.company_name,
    street: shippingAddress?.street || physicalDeliveryAddressData.street,
    number: shippingAddress?.number || physicalDeliveryAddressData.number,
    box: shippingAddress?.box || physicalDeliveryAddressData.box,
    zip: shippingAddress?.zip || physicalDeliveryAddressData.zip,
    city: shippingAddress?.city || physicalDeliveryAddressData.city,
    bpost_delivery_point_id: deliveryData?.bpost_delivery_point_id || bPostDeliveryPointData.bpost_delivery_point_id,
    bpost_delivery_point_name:
      deliveryData?.bpost_delivery_point_name || bPostDeliveryPointData.bpost_delivery_point_name,
  }

  const initial: CreateOrderType2 = {
    ...createOrderShippingData,
    delivery_address_type: isGroupAndDate
      ? DeliveryVariant.preset
      : deliveryAddressType
        ? deliveryAddressType
        : shoppingCartItems?.data?.delivery_type === DeliveryTypes.digital
          ? DeliveryVariant.digital
          : DeliveryVariant.null,
    country: isGroupAndDate
      ? presetAddress?.country?.id
      : (country?.id ?? (deliveryCountries.length === 1 ? (deliveryCountries?.[0].id ?? '') : '')),
    [DeliveryVariant.null]: {},
    [DeliveryVariant.digital]: {},
    [DeliveryVariant.preset]:
      isGroupAndDate || presetAddresses?.length === 1
        ? {
            company_name: presetAddress?.company_name || '',
            is_company_address: !!presetAddress?.company_name,
            street: presetAddress?.street || '',
            number: presetAddress?.number || '',
            box: presetAddress?.box || '',
            zip: presetAddress?.zip || '',
            city: presetAddress?.city || '',
            preset_address_id: presetAddress?.id || 0,
          }
        : {
            ...physicalDeliveryAddressData,
            preset_address_id: deliveryData?.preset_address_id || 0,
          },
    [DeliveryVariant.homeAddress]: {
      ...physicalDeliveryAddressData,
      ...(homeAddress ? { ...homeAddress, country: undefined } : {}),
    },
    [DeliveryVariant.companyAddress]: {
      ...physicalDeliveryAddressData,
      ...(workAddress ? { ...workAddress, country: undefined } : {}),
    },
    [DeliveryVariant.bpostCollectionPoint]: {
      ...physicalDeliveryAddressData,
      ...bPostDeliveryPointData,
    },
    [DeliveryVariant.bpostParcelLocker]: {
      ...physicalDeliveryAddressData,
      ...bPostDeliveryPointData,
    },
    [DeliveryVariant.onetime]: { ...physicalDeliveryAddressData },
  }
  if (isGroupAndDate) {
    return initial
  }

  return {
    ...initial,
    [deliveryAddressType]: {
      ...initial[deliveryAddressType],
      ...shippingCartDeliveryData,
    },
  }
}
