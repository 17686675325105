import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { useGetAvailableCountries } from 'components/CountryContextDialog/hooks/useGetAvailableCountries'
import { FormikTextField } from 'components/Formik/FormikTextField'
import { useFormikContext } from 'formik'
import { DeliveryVariant } from 'pages/CreateOrder/deliveryOptions'
import { CreateOrderType2 } from 'pages/CreateOrder/types'
import { fontFamilies } from 'theme/styles.utils'
import { Country } from 'utils/api/country'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[800],
    fontFamily: fontFamilies.default,
  },
}))

type Props = {
  isDigital: boolean
}

export const SelectCountry = ({ isDigital }: Props) => {
  const [open, setOpen] = useState(false)
  const { label } = useStyles()
  const { t, i18n } = useTranslation()
  const { setValues, values } = useFormikContext<CreateOrderType2>()

  const { data, isFetching } = useGetAvailableCountries()
  const deliveryCountries: Country[] = data?.data || []

  return (
    <>
      <Grid item xs={12}>
        <Typography align="center" className={label}>
          {t(
            isDigital
              ? 'create_order.select_address.selected_country.digital'
              : 'create_order.select_address.selected_country'
          )}
        </Typography>
      </Grid>
      <Grid item xs={12} md={10}>
        <FormikTextField
          select
          center
          variant="outlined"
          name="country"
          onChange={(event) =>
            setValues((prev) => ({
              ...prev,
              country: Number(event.target.value),
              delivery_address_type: DeliveryVariant.null,
            }))
          }
          disabled={!!values?.country}
          sx={{ '& svg': { display: 'none' } }}
          onMouseOver={() => setOpen(true)}
          onMouseOut={() => setOpen(false)}
        >
          {deliveryCountries?.map((country) => (
            <MenuItem key={country.id} value={country.id}>
              {country.translation?.[i18n.language]?.name}
            </MenuItem>
          ))}
        </FormikTextField>
      </Grid>
      <Grid item xs={12} md={10} sx={{ pt: `0px !important` }}>
        <Collapse in={open}>
          <Typography variant="body2" align="center" pt={2}>
            {t('create_order.select_address.selected_country.tooltip')}
          </Typography>
        </Collapse>
      </Grid>
    </>
  )
}
