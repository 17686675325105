import i18n from 'i18next'
import { getRequest, patchRequest } from 'utils/api/apiRequest'

export interface UserConsent {
  id: number
  accepted: boolean
  consent: {
    id: number
    created: string
    updated: string
    name: string
    text: string
  }
  accepted_at: string | null
}
export type AcceptUserConsent = Pick<UserConsent, 'id' | 'accepted' | 'accepted_at'>

export const fetchUserConsent = () => getRequest<UserConsent>(`consents/${i18n.language}/my-consent`)
export const fetchUserConsentPdf = () =>
  getRequest<Blob>(`consents/${i18n.language}/my-consent/pdf`, { responseType: 'blob' })
export const acceptUserConsent = () => patchRequest<AcceptUserConsent>('consents/accept', {}).then((data) => data.data)
