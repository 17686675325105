import { useMemo } from 'react'
import { svgIconClasses } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { fontFamilies, getResponsiveSize } from 'theme/styles.utils'
import { Brand } from 'utils/api/Brands/fetchBrands'

type Props = {
  item: Brand
  handleToggle: (value: number) => void
  isChecked: boolean
  style?: React.CSSProperties
}

export const BrandRow = ({ item, handleToggle, isChecked, style }: Props) => {
  return useMemo(
    () => (
      <ListItem sx={{ padding: 0 }} style={style}>
        <ListItemButton role={undefined} onClick={() => handleToggle(item.id)} dense>
          <ListItemIcon sx={{ minWidth: 'auto', alignItems: 'center', alignContent: 'center' }}>
            <Checkbox
              edge="start"
              checked={isChecked}
              tabIndex={-1}
              disableRipple
              inputProps={{ 'aria-labelledby': item.name }}
              color="primary"
              sx={{
                [`& .${svgIconClasses.root}`]: {
                  lineHeight: 1.25,
                  fontSize: getResponsiveSize(1.25, 'rem'),
                  color: (theme) => theme.palette.primary.main,
                },
              }}
            />
          </ListItemIcon>
          <ListItemText
            sx={{
              '& span': {
                fontFamily: fontFamilies.default,
                fontSize: '1.15rem',
                color: (theme) => theme.palette.text.primary,
                marginTop: '2px',
              },
            }}
            primary={item.name}
          />
        </ListItemButton>
      </ListItem>
    ),
    [isChecked, item, style]
  )
}
