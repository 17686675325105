import { useTranslation } from 'react-i18next'
import { ShopAllButton } from 'components/HeaderPage/ShopAllButton'
import { ProductGrid } from 'components/ProductGrid/ProductGrid'
import { getHeading, ROOT_CATEGORY_ID, useHomeRSUtils } from 'pages/HomeRedemptionSite/utils'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { useProductsInCategory } from 'utils/hooks/api/useProductsInCategory'

export const ProductsList = () => {
  const { currentCategoriesPath, productsCategoryId, isCategoriesPage } = useHomeRSUtils()
  const { t } = useTranslation()
  const isShopAll = productsCategoryId === ROOT_CATEGORY_ID
  const heading = isShopAll ? t('products') : getHeading(currentCategoriesPath, t('products'))

  const productQuery = useProductsInCategory(productsCategoryId)

  return (
    <ProductGrid
      heading={heading}
      {...productQuery}
      headerRightComponent={valueOrUndefined(isCategoriesPage, <ShopAllButton />)}
    />
  )
}
