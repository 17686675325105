import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { Brand } from 'utils/api/Brands/fetchBrands'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  brandsWrapper: {
    padding: theme.spacing(4, 6),
  },
  csBrand: {
    textAlign: 'center',
    fontSize: theme.typography.h1.fontSize,
    transition: '0.3s all',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& > p': {
      fontSize: theme.typography.h1.fontSize,
    },
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
}))

type Props = {
  brands: Brand[]
}
export const BrandViews = ({ brands }: Props) => {
  const { brandsWrapper, csBrand } = useStyles()
  const navigate = useNavigate()
  const handleClick = (id: number) => () => navigate(`/catalog/brands/${id}`)
  return (
    <Grid item xs className={brandsWrapper}>
      <Grid container spacing={4}>
        {brands.map((brand) => {
          const brandSplit = brand.name.split(' ')
          const countString = brandSplit.length
          return (
            <Grid key={brand.id} item lg={3} className={csBrand} onClick={handleClick(brand.id)}>
              {countString === 1 ? brand.name : brandSplit.map((s) => <p key={s}>{s}</p>)}
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  )
}
