import { JSX, useEffect, useRef, useState } from 'react'
import { autocompleteClasses, menuItemClasses } from '@mui/material'
import Box from '@mui/material/Box'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import { getResponsiveSize } from 'theme/styles.utils'
import { and, inside } from 'utils/styles'
import { makeSx } from 'utils/styles/makeSx'
import { Z_INDEX } from 'utils/styles/zIndex'

interface Params {
  open: boolean
  closeHandler: () => void
}
interface Props {
  button: (params: Pick<Params, 'open'>) => JSX.Element
  content: (params: Params) => JSX.Element
  zIndex?: number
}

export const menuPaperSx = makeSx((theme) => ({
  zIndex: 1,
  padding: theme.spacing(1),
  overflow: 'visible',
  borderRadius: '1rem',
  filter: 'drop-shadow(0px 2px 5px rgba(0,0,0,0.40))',
  marginTop: 2,
  [inside(menuItemClasses.root)]: {
    justifyContent: 'center',
    fontWeight: theme.typography.fontWeightBold,
    [and(menuItemClasses.selected)]: {
      color: theme.palette.primary.main,
    },
  },
  [inside(autocompleteClasses.option)]: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.body2.fontSize,
    [`&[aria-selected="true"]`]: {
      color: theme.palette.primary.main,
    },
  },
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    left: '50%',
    width: getResponsiveSize(15, 'px'),
    height: getResponsiveSize(15, 'px'),
    bgcolor: 'background.paper',
    transform: 'translate(-50%, -50%) rotate(45deg)',
    zIndex: 0,
  },
}))

export const CustomPopover = ({ content, button, zIndex = Z_INDEX.CUSTOM_POPOVER }: Props) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  const handleListKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleListKeyDown)
    return () => window.removeEventListener('keydown', handleListKeyDown)
  }, [])

  return (
    <>
      <Box ref={anchorRef} onClick={() => setOpen(true)}>
        {button({ open })}
      </Box>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition sx={{ zIndex }}>
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'top',
            }}
          >
            <Paper elevation={0} sx={menuPaperSx}>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                {content({ open, closeHandler: () => setOpen(false) })}
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}
