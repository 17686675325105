import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { TranslationsKeys } from 'utils/createTranslationKey'

type Props = {
  label: TranslationsKeys
  value?: number | string
  redirectTo?: string
}

export const Cell = ({ label, redirectTo, value = 0 }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Box display="grid" gridTemplateColumns="1fr 1fr" gap={1}>
      <Box>
        <Typography align="left" variant="body1" fontWeight="bold" color="primary">
          {t(label)}:
        </Typography>
      </Box>
      <Box>
        <Typography
          align="right"
          variant="body1"
          fontWeight="bold"
          color="primary"
          sx={{
            '&:hover': {
              textDecoration: 'underline',
              cursor: 'pointer',
            },
          }}
          onClick={() => navigate(redirectTo || '/my-account/history-points')}
        >{`${value?.toLocaleString()} >`}</Typography>
      </Box>
    </Box>
  )
}
