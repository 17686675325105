import { useMutation, useQueryClient } from '@tanstack/react-query'
import { userConsentQueryKeys } from 'shared/UserConsent'
import { acceptUserConsent } from 'shared/UserConsent/api'
import { useAuthContext } from 'store/useAuthContext'

export const useAcceptConsent = () => {
  const { updateUser } = useAuthContext()
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: userConsentQueryKeys.acceptConsent(),
    mutationFn: acceptUserConsent,
    onSuccess: (data) => {
      queryClient.invalidateQueries(userConsentQueryKeys.all)
      updateUser({ consent_accepted: !!data?.accepted_at })
    },
  })
}
