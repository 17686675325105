import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import { isValidScoreLevel, MaxScoreLevelNumber, UserScoreLevel } from 'utils/api/User'

import { LevelProgressBar } from '../../components/ProgressBar'

export const MyScoreLevel = ({ level }: { level: UserScoreLevel }) => {
  const { t } = useTranslation()
  const getLevelLabel = () => {
    if (isValidScoreLevel(level?.level)) {
      return level?.name
    }

    return level?.code
  }

  return (
    <>
      <Typography variant="h3" fontWeight="bold" pb={1}>
        {t('my_score.my_score')}
      </Typography>
      <LevelProgressBar level={level?.level} maxLevel={MaxScoreLevelNumber} animated />
      <Typography variant="h3" fontWeight="bold" pt={1}>
        {getLevelLabel()}
      </Typography>
    </>
  )
}
