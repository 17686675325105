import { Fields, FieldsExtended, FieldType, FormFieldSelect } from 'components/JSONForm/types'
import mapValues from 'lodash/mapValues'
import { TranslationsKeys } from 'utils/createTranslationKey'

interface ExtendFieldsConfig {
  orderedKeys?: string[]
  translationKey?: string
  overrideProperties?: {
    keys: string[]
    properties: {
      type?: FieldType
      header?: TranslationsKeys
      size?: number
      options?: FormFieldSelect['options']
      value?: (v?: string | number) => string | number | null
    }
  }[]
}

export const extendFields = <T extends Fields, U extends string = Extract<keyof T, string>>(
  fieldsObj: T,
  args: ExtendFieldsConfig = {},
  parent?: { name: string }
): FieldsExtended<U> =>
  mapValues(fieldsObj, (f, k) => {
    let overrides = {} as typeof f
    if (args.overrideProperties?.find((o) => o.keys.includes(k))) {
      args.overrideProperties.forEach((o) => {
        if (o.keys.includes(k)) {
          const { value: v, ...properties } = o.properties
          let value = f.value
          if (v) {
            value = v(f.value)
          }
          overrides = { ...overrides, value, ...properties }
        }
      })
    }

    const field = { ...f, ...overrides }
    const name = parent ? `${parent?.name}.${k}` : k
    const translationKey = `${args.translationKey}${name}`

    const position =
      field.position ??
      (() => {
        if (!args.orderedKeys?.length) return 0
        const i = args.orderedKeys?.findIndex((orderKey) => orderKey === k)
        return i > -1 ? i : 0
      })()

    let fields
    if ('fields' in field) {
      fields = extendFields(field.fields, args, { name })
    }
    return { ...field, position, name, fields, translationKey }
  }) as FieldsExtended<U>
