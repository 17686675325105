import { useQuery } from '@tanstack/react-query'
import { getRequest } from 'utils/api/apiRequest'

type SubscriberConsent = {
  translation: {
    [key: string]: {
      name: string
      text: string
    }
  }
}

const getConsent = () => getRequest<SubscriberConsent>(`/consent/subscriber`)

export const useGetMyConsent = () => {
  return useQuery({
    queryFn: getConsent,
    queryKey: ['consent'],
  })
}
