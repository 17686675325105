import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMsal } from '@azure/msal-react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx'
import { useOktaAuth } from '@okta/okta-react'
import { Close } from 'components/ArteelIcons'
import { useAuthContext } from 'store/useAuthContext'
import { Theme } from 'theme/styles'
import { dataCy } from 'utils/cypressUtils'
import { makeStyles } from 'utils/styles/makeStyles'
import { makeSx } from 'utils/styles/makeSx'

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(6),
    '&.MuiDialogContent-root:first-of-type': {
      paddingTop: theme.spacing(6),
    },
  },
  dialogPaper: {
    '& .MuiPaper-rounded': {
      borderRadius: 12,
      overflowY: 'visible',
    },
  },
}))

export const useLogout = () => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const { dialog, dialogPaper } = useStyles()
  const { logoutUser } = useAuthContext()
  const { oktaAuth, authState } = useOktaAuth()
  const { instance } = useMsal()

  const onClickLogout = () => {
    setOpen(true)
  }

  const handleConfirm = () => {
    setOpen(false)
    logoutUser()
    if (authState.isAuthenticated) {
      oktaAuth.signOut({ postLogoutRedirectUri: oktaAuth.options.redirectUri }).finally(() => oktaAuth.clearStorage())
    }
    // TODO: Uncomment this when we have the correct instance
    // if (instance?.getActiveAccount()) {
    //   instance?.logout()
    // }
  }

  const handleCancel = () => setOpen(false)
  const buttonSx = makeSx((_) => ({ textTransform: 'uppercase', minWidth: 200 }))

  const DialogLogout = () => (
    <Dialog open={open} className={dialogPaper}>
      <ButtonCloseDialog onClose={handleCancel} />
      <DialogContent className={dialog}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h2" align="center" fontWeight="bold">
              {t('app.logout.question')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} direction="column" alignItems="center">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleConfirm}
                  sx={buttonSx}
                  data-cy={dataCy('button-logout-confirm')}
                >
                  {t('app.logout')}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" size="large" onClick={handleCancel} sx={buttonSx}>
                  {t('app.cancel')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )

  return { onClickLogout, DialogLogout, handleConfirm }
}

export const ButtonCloseDialog = ({
  onClose,
  styles = {},
}: {
  onClose: () => void
  styles?: SystemStyleObject<Theme>
}) => {
  return (
    <IconButton
      color="primary"
      onClick={onClose}
      size="large"
      sx={(t) => ({
        position: 'absolute',
        right: 0,
        background: t.palette.primary.main,
        zIndex: 1,
        transform: 'translateX(50%)',
        '&:hover': {
          background: t.palette.primary.dark,
        },
        ...styles,
      })}
    >
      <Close fontSize="small" sx={(t) => ({ color: t.palette.background.paper })} />
    </IconButton>
  )
}
