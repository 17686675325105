import { useTranslation } from 'react-i18next'
import { CardSearch } from 'components/CardSearch/CardSearch'

const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('')
const scrollOptions = alphabet.map((letter) => ({
  text: letter.toLocaleUpperCase(),
  elementId: `letter-${letter}`,
  isVisible: true,
}))

export const SearchBrand = () => {
  const { t } = useTranslation()

  return <CardSearch scrollOptions={scrollOptions} searchText={t('brands.search')} searchKey="searchBrands" />
}
