import { useTranslation } from 'react-i18next'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import { Box } from '@mui/system'
import { ShoppingCartItem } from 'shared/ShoppingCart/api'
import { fontFamilies, getResponsiveSize } from 'theme/styles.utils'
import { useIsMobile } from 'utils/hooks/useBreakpoints'
import { useChangeItemAmount } from 'utils/hooks/useChangeItemAmount'
import { useIsLuckyBirdUser } from 'utils/hooks/useIsLuckyBirdUser'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  changeQuantity: {
    position: 'absolute',
    '& svg': {
      fontSize: theme.typography.h1.fontSize,
    },
    '&.up': {
      top: 0,
      left: '50%',
      transform: 'translate(-50%,-75%)',
    },
    '&.down': {
      bottom: 0,
      left: '50%',
      transform: 'translate(-50%,75%)',
    },
  },
  points: {
    position: 'relative',
    textAlign: 'center',
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.2,
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: `${getResponsiveSize(0.8)}rem`,
      fontFamily: fontFamilies.default,
      whiteSpace: 'nowrap',
    },
  },
}))

type Props = {
  disabledChange: boolean
  item: ShoppingCartItem
}

export const AmountItem = ({ item, disabledChange }: Props) => {
  const { changeQuantity, points } = useStyles()
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const { onChange, quantity, disabledArrow } = useChangeItemAmount(item)
  const { isSingleLogic } = useIsLuckyBirdUser()

  if (isSingleLogic || disabledChange) {
    return (
      <Grid item xs={12} md={3}>
        <Box className={points} sx={(t) => (isMobile ? { my: t.spacing(2) } : undefined)}>
          {t('shopping_cart.quantity')}: {quantity}
        </Box>
      </Grid>
    )
  }

  return (
    <Grid item xs={12} md={3}>
      <Box className={points} sx={(t) => (isMobile ? { my: t.spacing(2) } : undefined)}>
        <IconButton
          className={`${changeQuantity} up`}
          size="small"
          color="primary"
          onClick={() => onChange('up')}
          disabled={disabledArrow.up}
        >
          <ExpandLess />
        </IconButton>
        <IconButton
          className={`${changeQuantity} down`}
          size="small"
          color="primary"
          onClick={() => onChange('down')}
          disabled={disabledArrow.down}
        >
          <ExpandMore />
        </IconButton>
        {t('shopping_cart.quantity')}: {quantity}
      </Box>
    </Grid>
  )
}
