import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { putVoucherStatus } from 'utils/api/ReceiveVoucher/putVoucherStatus'
import voucherQueryKeys from 'utils/api/ReceiveVoucher/voucherQueryKeys'

type Props = {
  onSuccess: (data: AxiosResponse<{ url: string }>) => void
}

export const useReceiveVoucher = ({ onSuccess }: Props) => {
  const queryClient = useQueryClient()

  return useMutation(putVoucherStatus, {
    onSuccess: (data, token) => {
      queryClient.invalidateQueries(voucherQueryKeys.voucherStatus(token))
      onSuccess(data)
    },
  })
}
