import Axios from 'axios'
import { BPOST_API } from 'config/env'

export const bpostAxios = Axios.create({
  baseURL: BPOST_API,
})

type BpostText = {
  _text: string
}

export type BpostPointType = {
  Record: {
    Id: BpostText
    Type: BpostText
    Name: BpostText
    Street: BpostText
    Number: BpostText
    Zip: BpostText
    City: BpostText
    X: BpostText
    Y: BpostText
    Longitude: BpostText
    Latitude: BpostText
  }
  Distance: BpostText
  Info: {
    _attributes: BpostText
  }
}
