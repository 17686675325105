import { useTranslation } from 'react-i18next'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { FormikTextField } from 'components/Formik/FormikTextField'
import { useFormikContext } from 'formik'
import { DeliveryVariant } from 'pages/CreateOrder/deliveryOptions'
import { getNestedName } from 'pages/CreateOrder/helper/getNestedName'
import { CreateOrderType2 } from 'pages/CreateOrder/types'
import { fontFamilies } from 'theme/styles.utils'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: fontFamilies.cond,
    fontSize: theme.typography.h3.fontSize,
    color: theme.palette.grey['500'],
    marginLeft: theme.spacing(2),
  },
}))

export const CompanyName = () => {
  const { label } = useStyles()
  const { t } = useTranslation()
  const { setValues, getFieldMeta, values } = useFormikContext<CreateOrderType2>()
  const getName = (name: Parameters<typeof getNestedName>[1]) => getNestedName(values.delivery_address_type, name)

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} md="auto">
        <FormControlLabel
          className={label}
          value="end"
          name={getName('is_company_address')}
          checked={!!getFieldMeta(getName('is_company_address')).value}
          control={<Checkbox color="primary" />}
          onChange={(_, checked) =>
            setValues((prev) => ({
              ...prev,
              [values.delivery_address_type]: {
                ...prev[values.delivery_address_type],
                is_company_address: checked,
                company_name:
                  checked && 'company_name' in prev[DeliveryVariant.onetime]
                    ? prev?.[DeliveryVariant.onetime]?.company_name || ''
                    : '',
              },
            }))
          }
          label={<Typography className={label}>{t('create_order.is_company_name')}</Typography>}
          labelPlacement="end"
        />
      </Grid>
      <Grid item xs={12} md>
        <FormikTextField
          name={getName('company_name')}
          inputLabel="create_order.company_name"
          disabled={!getFieldMeta(getName('is_company_address')).value}
        />
      </Grid>
    </Grid>
  )
}
