import { amber, blue, green, red } from '@mui/material/colors'
import Snackbar from '@mui/material/Snackbar'
import SnackbarContent from '@mui/material/SnackbarContent'
import clsx from 'clsx'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(7),
  },
  content: {
    ...theme.typography.h3,
    fontVariant: 'all-petite-caps',
  },
  success: { backgroundColor: green[500] },
  error: { backgroundColor: red[500] },
  warning: { backgroundColor: amber[700] },
  info: { backgroundColor: blue[500] },
}))

type Props = {
  message: string
  variant: 'success' | 'error' | 'warning' | 'info'
}

export const Notification = ({ message, variant }: Props) => {
  const classes = useStyles()
  const vertical = 'top'
  const horizontal = 'right'
  return (
    <Snackbar open className={classes.root} anchorOrigin={{ vertical, horizontal }}>
      <SnackbarContent message={message} className={clsx(classes[variant], classes.content)} />
    </Snackbar>
  )
}
