import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { dialogClasses } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import { CloseContained } from 'components/ArteelIcons'
import { ResponsivePaper } from 'components/ResponsivePaper'
import { DEFAULT_LANGUAGE } from 'config/constants'
import { CmsContent } from 'pages/CMS/CMS.types'
import { HtmlContent } from 'pages/CMS/components/ContentBox/HtmlContent'
import { contentBoxTypes } from 'pages/CMS/constants'
import qs from 'query-string'
import { fontFamilies } from 'theme/styles.utils'
import { useIsMobile } from 'utils/hooks/useBreakpoints'
import { useSections } from 'utils/hooks/useSections'
import { important, inside } from 'utils/styles'

const useLinkBox = () => {
  const navigate = useNavigate()
  const { search, pathname } = useLocation()

  const params = qs.parse(search)
  const { sections } = useSections()
  const linkBoxes = sections
    .map((section) => section.contents.filter((content) => content.type === contentBoxTypes.link_box).flat())
    .flat()
  const openedLinkBox = linkBoxes.find((el) => el.id.toString() === params?.info)
  const isOpenLinkBox = (id: number) => id.toString() === params?.info
  const closeLinkBox = () => navigate(pathname)
  const openLinkBox = (id: number) => navigate(`?info=${id}`)

  return { openedLinkBox, closeLinkBox, openLinkBox, isOpenLinkBox }
}

const LinkBoxContent = () => {
  const { openedLinkBox, closeLinkBox } = useLinkBox()
  return (
    <Grid item xs={12}>
      <ResponsivePaper>
        <IconButton
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
          }}
          onClick={closeLinkBox}
          aria-label="close"
          size="small"
        >
          <CloseContained fontSize="medium" color="primary" />
        </IconButton>
        <HtmlContent content={openedLinkBox} />
      </ResponsivePaper>
    </Grid>
  )
}

export const LinkBoxContentProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
  const isMobile = useIsMobile()
  const { openedLinkBox } = useLinkBox()

  return <>{openedLinkBox && !isMobile ? <LinkBoxContent /> : children}</>
}

export const LinkBoxContentDialog = () => {
  const { openedLinkBox, closeLinkBox } = useLinkBox()
  if (!openedLinkBox) return null
  return (
    <Dialog
      open={true}
      fullScreen
      onClose={closeLinkBox}
      sx={{
        [inside(dialogClasses.container)]: {
          transitionDuration: important('0s'),
        },
      }}
    >
      <DialogTitle sx={{ textAlign: 'right' }}>
        <IconButton size="medium" onClick={closeLinkBox}>
          <CloseContained color="primary" fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={true}>
        <HtmlContent content={openedLinkBox} />
      </DialogContent>
    </Dialog>
  )
}

export const LinkBoxLink = ({ content }: { content: CmsContent }) => {
  const { i18n, t } = useTranslation()
  const { isOpenLinkBox, openLinkBox } = useLinkBox()

  if (!isOpenLinkBox(content.id))
    return (
      <ResponsivePaper>
        <div>
          <Link
            sx={(t) => ({
              fontSize: t.typography.h3.fontSize,
              wordBreak: 'break-word',
            })}
            component="button"
            variant="body2"
            onClick={() => openLinkBox(content.id)}
            fontFamily={fontFamilies.default}
          >
            {content?.translation?.[i18n.language]?.title ||
              content?.translation?.[DEFAULT_LANGUAGE]?.title ||
              t('app.missingTranslation')}
          </Link>
        </div>
      </ResponsivePaper>
    )
  return null
}
