import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { personalMessageKeys } from 'pages/PersonalMessages/api/keys'
import { patchRequest } from 'utils/api/apiRequest'
import { OnSuccessApi } from 'utils/getOptionsTable'
import { useErrorSnackbar } from 'utils/hooks/useErrorSnackbar'

type Data = OnSuccessApi & {
  id: number
  occasion: number
  name: string
  content: string
}

const patchPersonalTemplate = ({ id, onSuccess, ...rest }: Data) => patchRequest(`/users/message-templates/${id}`, rest)

export const usePatchPersonalTemplate = () => {
  const enqueueErrorSnackbar = useErrorSnackbar()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: personalMessageKeys.patchPersonalTemplate(),
    mutationFn: patchPersonalTemplate,
    onSuccess: (data, variables) => {
      variables?.onSuccess?.()
      queryClient.invalidateQueries({
        queryKey: personalMessageKeys.allTemplates(),
      })
    },
    onError: (err) => {
      enqueueErrorSnackbar(err, { DEFAULT_ERROR: t('personal_messages.templates.edit_template.fail') })
    },
  })
}
