import { useQuery } from '@tanstack/react-query'
import { fetchWidgetMyScore } from 'utils/api/MyScore/fetchWidgetMyScore'
import myScoreQueryKeys from 'utils/api/MyScore/myScoreQueryKeys'

export const useWidgetMyScore = ({ dateFrom }: { dateFrom: string }) => {
  return useQuery({
    queryKey: myScoreQueryKeys.widgetMyScoreList(dateFrom),
    queryFn: () => fetchWidgetMyScore(dateFrom),
    select: (response) => response.data,
    cacheTime: 5 * (60 * 1000), // 5 minute
    refetchOnWindowFocus: true,
    refetchIntervalInBackground: true,
    refetchInterval: 10 * (60 * 1000), // 10 minutes
  })
}
