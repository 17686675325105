import { useTranslation } from 'react-i18next'

const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] as const

export const useMonths = () => {
  const { t } = useTranslation()

  return {
    months: months.map((month) => ({
      month,
      longName: t(`month.${month}.long_name`),
      shortName: t(`month.${month}.short_name`),
      elementId: `month-${month}`,
    })),
  }
}
