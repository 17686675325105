import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { DrawerSelect } from 'components/DrawerSelect'
import { FilterProduct } from 'components/FilterProduct/FilterProduct'
import { SortProduct } from 'components/SortProduct/SortProduct'

interface Props {
  gutterTop?: number
}
export const DesktopFilterComponent = ({ gutterTop }: Props) => {
  const { t } = useTranslation()
  return (
    <DrawerSelect label={t('app.filters')} gutterTop={gutterTop}>
      <Box px={2}>
        <FilterProduct />
      </Box>
    </DrawerSelect>
  )
}

export const DesktopSortComponent = ({ gutterTop }: Props) => {
  const { t } = useTranslation()
  return (
    <DrawerSelect label={t('app.sort')} gutterTop={gutterTop}>
      <SortProduct />
    </DrawerSelect>
  )
}
