import { Navigate, useLocation } from 'react-router-dom'
import { useIsLuckyBirdGlobalAuth } from 'pages/Login/LuckyBird/useIsLuckyBirdGlobalAuth'
import qs from 'query-string'
import { useAuthContext } from 'store/useAuthContext'

type Props = {
  to: string
}
export const ProtectedRedirect = ({ to }: Props) => {
  const location = useLocation()
  const { user } = useAuthContext()
  const params = qs.parse(location.search.replace('?', ''))
  const isGlobalLB = useIsLuckyBirdGlobalAuth()

  const getParams = () => {
    if ('redirect' in params) {
      delete params?.redirect
    }
    return qs.stringify(params, { encode: false })
  }

  if (params?.activateToken === '1') {
    delete params?.activateToken
    return (
      <Navigate
        to={{
          pathname: '/auth/activate',
          search: `?redirect=${location.pathname}&${getParams()}`,
        }}
        state={{ from: location }}
      />
    )
  }

  if (isGlobalLB) {
    return (
      <Navigate
        to={{
          pathname: user ? to : '/auth/luckybird',
          search: user ? location.search || '' : `?redirect=${location.pathname}&${getParams()}`,
        }}
        state={{ from: location }}
      />
    )
  }

  return (
    <Navigate
      to={{
        pathname: user ? to : '/auth/login',
        search: user ? location.search || '' : `?redirect=${location.pathname}&${getParams()}`,
      }}
      state={{ from: location }}
    />
  )
}
