import { useCallback } from 'react'
import { useSnackbar } from 'notistack'
import { ErrorMessages, parseError } from 'utils/api/parseError'

export const useErrorSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar()

  const handler = useCallback((e: unknown, messages: Partial<ErrorMessages> = {}) => {
    const axiosError = parseError(e, messages)
    enqueueSnackbar(axiosError.message, { variant: 'error' })
    return axiosError
  }, [])

  return handler
}
