import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { GoBackButton } from 'components/GoBackButton/GoBackButton'
import { ResponsivePaper } from 'components/ResponsivePaper'
import { Form, Formik } from 'formik'
import isString from 'lodash/isString'
import { usePostTransferPoints } from 'pages/TransferPoints/api/usePostTransferPoints'
import { TransferPointsForm } from 'pages/TransferPoints/TransferPointsForm'
import { fontFamilies, getResponsiveSize } from 'theme/styles.utils'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'
import { usePointsName } from 'utils/hooks/usePointsName'
import { useSocialWall } from 'utils/hooks/useSocialWall'
import * as Yup from 'yup'

import { FormValues } from './types'

export const TransferPoints = () => {
  const { isSocialWallActive } = useSocialWall()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const user = useCurrentUser()
  const projectsArray = Object.values(user?.projects || {})
  const projectWithTransferPoints = projectsArray?.filter((project) => project.is_transfer_points_active)
  const pointsName = usePointsName(user?.available_points)

  const { mutate, isSuccess, reset, isLoading } = usePostTransferPoints()

  const initialValues: FormValues = {
    project: location?.state?.projectId || projectWithTransferPoints?.[0]?.id?.toString(),
    who: '',
    reason: '',
    points: '0',
  }

  const validationSchema = Yup.object().shape({
    who: Yup.object().label(t('transfer_points.who')).required().nullable(),
    reason: Yup.string().label(t('transfer_points.reason')).required().min(20),
    points: Yup.number()
      .label(t('transfer_points.points'))
      .required()
      .positive()
      .test(
        'max-points',
        t('transfer_points.max_points', { pointsName }),
        (value, a) =>
          Number(value) <=
          projectWithTransferPoints.find((project) => project.id === Number(a?.parent?.project)).available_points
      ),
  })

  const onSubmit = (values: FormValues) => {
    mutate({
      points: Number(values.points),
      project_id: Number(values.project),
      reason: values.reason,
      receiver: isString(values.who) ? null : Number(values.who?.user?.id),
    })
  }

  const onBack = () => {
    navigate(isSocialWallActive ? '/social-wall' : '/')
  }

  return (
    <ResponsivePaper data-cy="edit-personal-message-desktop">
      <Box position="absolute" right={getResponsiveSize(10, 'px')} top={0}>
        <GoBackButton translation="app.back" noPx onClick={onBack} />
      </Box>
      <Collapse in={isSuccess} mountOnEnter unmountOnExit>
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <CheckCircleOutline color="primary" sx={{ fontSize: getResponsiveSize(6, 'rem') }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h2" align="center">
              {t('transfer_points.success')}
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={() => reset()}>
              {t('cms.give_points_to_others')}
            </Button>
          </Grid>
        </Grid>
      </Collapse>
      <Collapse in={!isSuccess} mountOnEnter unmountOnExit>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h1" color="primary" fontWeight="bold">
                  {t('transfer_points.header', { firstName: useCurrentUser()?.profile?.first_name || '' })},
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h1">{t('transfer_points.subheader')}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3" fontFamily={fontFamilies.default}>
              {t('transfer_points.description')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              <Form>
                <TransferPointsForm
                  onBack={onBack}
                  projectWithTransferPoints={projectWithTransferPoints}
                  isLoadingForm={isLoading}
                />
              </Form>
            </Formik>
          </Grid>
        </Grid>
      </Collapse>
    </ResponsivePaper>
  )
}
