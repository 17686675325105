import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ArticleIcon from '@mui/icons-material/Article'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { ResponsivePaper } from 'components/ResponsivePaper'
import { useSnackbar } from 'notistack'
import { UserConsentError, UserConsentModal, useSelectUserConsent } from 'shared/UserConsent'
import { fetchUserConsentPdf } from 'shared/UserConsent/api'
import { useIsAdmin } from 'utils/hooks/useIsAdmin'
import { makeStyles } from 'utils/styles/makeStyles'
import { useDisplayDateInTimeZone } from 'utils/useDisplayDateInTimeZone'

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: theme.typography.h3.fontSize,
    color: theme.palette.grey[500],
  },
  value: {
    fontSize: theme.typography.h3.fontSize,
  },
}))
export const MyAccountUserConsent = () => {
  const { label, value } = useStyles()
  const { data: userConsent, isFetching, isError, isSuccess } = useSelectUserConsent()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const isAdmin = useIsAdmin()
  const [open, setOpen] = useState(false)
  const { displayDateInTimeZone } = useDisplayDateInTimeZone()

  const downloadPdf = async () => {
    try {
      setIsLoading(true)
      const res = await fetchUserConsentPdf()
      window.open(URL.createObjectURL(res.data))
    } catch (error) {
      enqueueSnackbar(t('user_consent.pdf.fetch.fail'), { variant: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  if (isAdmin) return null

  return (
    <Grid item xs={12}>
      <ResponsivePaper>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Typography fontWeight={600} variant="h2">
              {t('user_consent.title')}
            </Typography>
          </Grid>
          {isFetching && (
            <Grid item alignSelf="center">
              <CircularProgress />
            </Grid>
          )}
          {isError && (
            <Grid item>
              <UserConsentError />
            </Grid>
          )}
          {isSuccess && (
            <Grid container item spacing={2}>
              <Grid item>
                <Typography className={label}>{`${t('user_consent.acceptance_date')}: `}</Typography>
              </Grid>
              <Grid item>
                <Typography className={value}>{displayDateInTimeZone(userConsent.data.accepted_at)}</Typography>
              </Grid>
            </Grid>
          )}
          <Grid item>
            <Button
              disabled={!isSuccess}
              variant="contained"
              startIcon={<ArticleIcon />}
              sx={{ mr: 2 }}
              onClick={() => setOpen(true)}
            >
              {t('user_consent.show')}
            </Button>
            <LoadingButton
              loading={isLoading}
              onClick={downloadPdf}
              loadingPosition="start"
              startIcon={<PictureAsPdfIcon />}
            >
              {t('app.download')}
            </LoadingButton>
          </Grid>
        </Grid>
      </ResponsivePaper>
      <UserConsentModal open={open} close={() => setOpen(false)} />
    </Grid>
  )
}
