import { getRequest } from 'utils/api/apiRequest'
import { WithPagination } from 'utils/api/pagination'
import { Product } from 'utils/api/Products/CatalogProduct'

export type WishlistItem = {
  id: number
  product: Product
  points: number
}
export const getWishlist = () => getRequest<WithPagination<WishlistItem>>('/redemption-site/wishlist?limit=1000')
