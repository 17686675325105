import { createTranslationKey, TranslationsKeys } from 'utils/createTranslationKey'

export type Option = {
  id?: number
  label: TranslationsKeys
  value: OneOfDeliveryVariant
}

export const DeliveryVariant = {
  null: -1,
  digital: 0,
  homeAddress: 1,
  companyAddress: 2,
  preset: 3,
  onetime: 4,
  bpostParcelLocker: 5,
  bpostCollectionPoint: 6,
} as const

export type OneOfDeliveryVariant = (typeof DeliveryVariant)[keyof typeof DeliveryVariant]

const ns = <T extends string>(field: T) => createTranslationKey(field, 'create_order.select_address.delivery_variant.')

export const deliveryOptions: Option[] = [
  { id: 0, label: ns('digital'), value: DeliveryVariant.digital },
  { id: 1, label: ns('my_address'), value: DeliveryVariant.homeAddress },
  {
    id: 2,
    label: ns('company_address'),
    value: DeliveryVariant.companyAddress,
  },
  { id: 3, label: ns('preset'), value: DeliveryVariant.preset },
  { id: 4, label: ns('onetime'), value: DeliveryVariant.onetime },
  { id: 5, label: ns('bpost_pl'), value: DeliveryVariant.bpostParcelLocker },
  {
    id: 6,
    label: ns('bpost_cp'),
    value: DeliveryVariant.bpostCollectionPoint,
  },
]

export const digitalDeliveries: OneOfDeliveryVariant[] = [DeliveryVariant.digital]
export const personalDeliveries: OneOfDeliveryVariant[] = [
  DeliveryVariant.homeAddress,
  DeliveryVariant.companyAddress,
  DeliveryVariant.onetime,
]
