import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

export const Filter = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 80 80">
    <g>
      <path d="M45,14.1c-3.6,1.6-6.2,4.7-7.1,8.5H6c-1.7,0-3.1,1.4-3.1,3.1c0,1.7,1.4,3.1,3.1,3.1h31.9   c0.2,0.7,0.4,1.5,0.7,2.2c2.9,6.4,10.5,9.3,16.9,6.4c3.5-1.6,6.2-4.7,7.1-8.5H74c1.7,0,3.1-1.4,3.1-3.1c0-1.7-1.4-3.1-3.1-3.1H62.6   c-1-4.1-3.9-7.4-7.9-8.9C51.5,12.5,48,12.7,45,14.1z M52.5,19.5c3.4,1.3,5.1,5.1,3.8,8.5c-1.3,3.4-5.1,5.1-8.5,3.8   c-1.6-0.6-3-1.9-3.7-3.4c-0.4-0.8-0.6-1.8-0.6-2.7c0-2.6,1.5-4.9,3.9-6C49.1,19,50.9,18.9,52.5,19.5z" />
      <path d="M18.3,49.8c-0.2,0.5-0.3,0.9-0.4,1.4H6c-1.7,0-3.1,1.4-3.1,3.1c0,1.7,1.4,3.1,3.1,3.1h12   c1.7,6.8,8.7,11,15.5,9.3c0.7-0.2,1.4-0.4,2.1-0.7c3.1-1.4,5.5-3.9,6.7-7.1c0.2-0.5,0.3-0.9,0.4-1.4H74c1.7,0,3.1-1.4,3.1-3.1   c0-1.7-1.4-3.1-3.1-3.1H42.7c-0.2-0.7-0.4-1.5-0.7-2.2c-2.9-6.4-10.4-9.3-16.8-6.5C22,44,19.5,46.6,18.3,49.8z M32.7,48.1   c3.4,1.3,5.1,5.1,3.8,8.5c-0.6,1.6-1.9,3-3.4,3.7c-3.3,1.5-7.2,0-8.7-3.3c-0.4-0.8-0.6-1.8-0.6-2.7c0-0.8,0.1-1.6,0.4-2.3   c0.6-1.6,1.9-2.9,3.5-3.6l0,0C29.2,47.6,31,47.5,32.7,48.1z" />
    </g>
  </SvgIcon>
)
