import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import departmentKeys from 'utils/api/Departments/departmentKeys'
import { fetchDepartmentTree } from 'utils/api/Departments/fetchDepartmentTree'
import { addAncestors, filterTreeStructure } from 'utils/treeUtils'

export const useDepartmentTree = ({ nameFilter }: { nameFilter?: string } = {}) => {
  const query = useQuery({
    queryKey: departmentKeys.departmentTree(),
    queryFn: () => fetchDepartmentTree(),
    select: ({ data: { departments_tree } }) => {
      return departments_tree
    },
  })

  const dataWithAncestors = useMemo(() => addAncestors({ tree: query.data || [] }), [query.data])
  const filteredData = useMemo(
    () =>
      nameFilter
        ? filterTreeStructure({ tree: dataWithAncestors, filter: { fieldName: 'name', fieldValue: nameFilter } })
        : dataWithAncestors,
    [nameFilter, dataWithAncestors, nameFilter]
  )

  return { ...query, data: filteredData, rawData: dataWithAncestors || [] }
}
