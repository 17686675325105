import { Fragment } from 'react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import LoadingButton from '@mui/lab/LoadingButton'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { ConclusionPoints } from 'components/ConclusionPoints/ConclusionPoints'
import Loader from 'components/Loader/Loader'
import { TextField } from 'components/TextField/TextField'
import isEmpty from 'lodash/isEmpty'
import { CartItem } from 'pages/ShoppingCart/CartItem'
import { useDispatchShoppingCart, useSelectShoppingCart } from 'shared/ShoppingCart/slice'
import { useSubdomainInfo } from 'shared/Site/api'
import { useAuthContext } from 'store/useAuthContext'
import charityQueryKeys from 'utils/api/Order/charityQueryKeys'
import { Charity, getCharity } from 'utils/api/Order/getCharity'
import { patchShoppingCartCharity } from 'utils/api/Order/patchShoppingCartCharity'
import userQueryKeys from 'utils/api/User/userQueryKeys'
import { dataCy } from 'utils/cypressUtils'
import { useCreateOrder } from 'utils/hooks/api/Order/useCreateOrder'
import { useIsLuckyBirdUser } from 'utils/hooks/useIsLuckyBirdUser'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  contact: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[800],
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  charityLabel: {
    fontSize: theme.typography.h1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[900],
    '& .grey': {
      color: theme.palette.grey[500],
    },
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.h3.fontSize,
    },
    '& .description': {
      fontSize: theme.typography.h4.fontSize,
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
}))

export const Overview = () => {
  const { contact, divider, charityLabel } = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: subdomainInfo, isInitialLoading: isLoadingSubdomainInfo } = useSubdomainInfo()
  const { data: shoppingCartItems, isFetching } = useSelectShoppingCart()
  const { refetchShoppingCart } = useDispatchShoppingCart()
  const { updateUser, user } = useAuthContext()
  const queryClient = useQueryClient()

  const currentValue = shoppingCartItems?.data?.total_order_value || 0
  const actualUserPoints = user?.available_points || 0
  const remainingPoints = actualUserPoints - currentValue > 0 ? actualUserPoints - currentValue : 0

  const { mutate: mutateCreateOrder, isLoading: isCreatingOrder } = useCreateOrder({
    onSuccess: (id: number, email: string) => {
      navigate(`/create-order/success/${id}?email=${email}`)
      updateUser({ available_points: remainingPoints })
      refetchShoppingCart()
      queryClient.invalidateQueries(userQueryKeys.all)
    },
  })
  const handleCreateOrder = () => mutateCreateOrder()
  const { isSingleLogic } = useIsLuckyBirdUser()

  const { isLoading: isLoadingCharity, data } = useQuery({
    queryKey: charityQueryKeys.charityList(),
    queryFn: getCharity,
    enabled: !isSingleLogic,
  })
  const { mutate } = useMutation(patchShoppingCartCharity, {
    onSuccess: () => {
      refetchShoppingCart()
    },
  })
  const charities: Charity[] = data?.data?.data || []
  const isEnabledCharity =
    !!Object.entries(user?.projects ?? {})?.find(([id, project]) => project.charity_round_up) &&
    charities?.length > 0 &&
    remainingPoints > 0

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    mutate({ charity: parseInt(event.target.value, 10) || null })
  }

  return (
    <Grid container spacing={2} justifyContent="center" data-cy="overview">
      {shoppingCartItems?.data?.items.map((item) => (
        <Fragment key={item.id}>
          <Grid item xs={12}>
            <Loader isLoading={isFetching} objects={shoppingCartItems?.data?.items}>
              <CartItem item={item} disabledChange />
            </Loader>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Fragment>
      ))}
      <Grid item xs={12}>
        <Collapse in={isEmpty(shoppingCartItems?.data?.items)} unmountOnExit mountOnEnter>
          <>
            <Typography variant="h2" align="center" className={contact}>
              {t('shopping_cart.empty')}
            </Typography>
            <Divider className={divider} />
          </>
        </Collapse>
      </Grid>
      {!isSingleLogic && (
        <Grid item xs={12}>
          <ConclusionPoints currentValue={currentValue} currentValueLabel="shopping_cart.current_order_total" />
        </Grid>
      )}
      {isEnabledCharity && !isSingleLogic && (
        <>
          <Grid item xs={12} className={charityLabel} data-cy={dataCy('container-charity')}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md>
                <Grid container direction="column">
                  <Grid item>{t('create_order.charity')}:</Grid>
                  <Grid item className="description">
                    {t('create_order.charity_description')}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md className="grey">
                <TextField
                  variant="outlined"
                  select
                  center
                  value={shoppingCartItems?.data?.charity || 0}
                  onChange={onChange}
                >
                  <MenuItem value={0}>
                    {t(isLoadingCharity || isLoadingSubdomainInfo ? 'app.loading' : 'create_order.no_selected')}
                  </MenuItem>
                  {charities.map((charity) => (
                    <MenuItem key={`charity-${charity.id}`} value={charity.id.toString()}>
                      {charity.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Grid item>
            <LoadingButton
              sx={(t) => ({ mt: t.spacing(3) })}
              type="button"
              variant="contained"
              color="primary"
              size="large"
              onClick={handleCreateOrder}
              loading={isCreatingOrder}
              data-cy={dataCy('button-order-submit')}
            >
              {t('create_order.complete_order')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
