import { useQuery } from '@tanstack/react-query'
import { fetchLBTheme, fetchThemes } from 'shared/CMSTheme/api'
import { useSubdomainInfo } from 'shared/Site/api'
import { SiteType } from 'shared/Site/siteType'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'
import { userTypes } from 'utils/hooks/userTypes'

export const themeQueryKeys = {
  all: ['all', 'themes'] as const,
  getThemes: () => [...themeQueryKeys.all, 'fetchThemes'] as const,
  getLBTheme: () => [...themeQueryKeys.all, 'fetchLBTheme'] as const,
}

export const useThemes = () => {
  const user = useCurrentUser()
  const { data: subdomainInfo } = useSubdomainInfo()

  return useQuery({
    enabled: !!user && subdomainInfo?.data?.type === SiteType.cms,
    queryKey: themeQueryKeys.getThemes(),
    queryFn: fetchThemes,
  })
}

export const useLBThemes = () => {
  const user = useCurrentUser()

  return useQuery({
    enabled: user?.type === userTypes.lucky_bird,
    queryKey: themeQueryKeys.getLBTheme(),
    queryFn: fetchLBTheme,
  })
}
