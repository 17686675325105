import React from 'react'
import Box from '@mui/material/Box'
import { useIsSmallMobile } from 'utils/hooks/useBreakpoints'
import { FCWithChildren } from 'utils/types/FCWithChildren'

export interface TopBarGridMobileProps {
  LeftComponent?: React.ReactNode
  MidComponent?: React.ReactNode
  RightComponent?: React.ReactNode
}

export const TopBarGridMobile: FCWithChildren<TopBarGridMobileProps> = ({
  LeftComponent,
  MidComponent,
  RightComponent,
}) => {
  const isSmallMobile = useIsSmallMobile()
  const Space = () => <Box p={1.5} />

  return (
    <Box gridTemplateColumns="1fr auto 1fr" display="grid" alignItems="center" px={isSmallMobile ? 1 : 2}>
      <Box justifySelf="start"> {LeftComponent || <Space />}</Box>
      <Box> {MidComponent || <Space />}</Box>
      <Box justifySelf="end">{RightComponent || <Space />}</Box>
    </Box>
  )
}
