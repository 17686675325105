import { useState } from 'react'
import { MessageTags } from 'components/Message/components/MessageTags'
import { MessageTopUsers } from 'components/Message/components/MessageUsersTop'
import { MessageInactive } from 'components/Message/MessageInactive'
import { RecognitionEdit } from 'pages/SocialWall/components/RecognitionEdit'
import { SocialWallPost } from 'utils/api/SocialWall/types'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'

interface Props {
  post: SocialWallPost
}

export const Recognition = ({ post }: Props) => {
  const user = useCurrentUser()
  const [editPostMode, setEditPostMode] = useState(false)
  const { recognition_values, description, user_recognitions, user_recognizing } = post.recognition

  return (
    <>
      <MessageTopUsers author={user_recognizing} recognitionUsers={user_recognitions.map((el) => el.user_awarded)} />
      {editPostMode ? (
        <RecognitionEdit post={post} onExit={() => setEditPostMode(false)} />
      ) : (
        <MessageInactive
          fullWidth
          authorSide="right"
          showEditButton={user?.id === user_recognizing.id}
          onEditClick={() => setEditPostMode(true)}
          value={
            <>
              {description} <MessageTags tags={recognition_values} clickable />
            </>
          }
        />
      )}
    </>
  )
}
