import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import React, { useState } from 'react'
import Slider from 'react-slick'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import { SliderSolid } from 'components/ArteelIcons'
import { detect } from 'detect-browser/'
import { isSafari } from 'utils/isSafari'
import { important } from 'utils/styles'
import { makeStyles } from 'utils/styles/makeStyles'
import { FCWithChildren } from 'utils/types/FCWithChildren'

// top bar + bottom bar
const BROWSER_TAB_BAR = (() => {
  const { os, name } = detect()
  // iOS safari
  if (isSafari()) return 64
  // iOS chrome
  if (os === 'iOS' && name === 'crios') return 84
  return 64
})()

const useStyles = makeStyles((theme) => ({
  wrapper: {
    ['& .slick-list']: {
      minHeight: `calc(100vh - ${BROWSER_TAB_BAR}px)`,
      maxHeight: `calc(100vh - ${BROWSER_TAB_BAR}px)`,
    },
  },
}))

const ElementContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
}))

const DotsWrapper = styled('div')(({ theme }) => ({
  width: 'fit-content',
  margin: '0 auto',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '20px',
  boxShadow: important('none'),
  padding: 6,
  border: `1px solid ${theme.palette.grey[300]}`,
  ['& li']: {
    margin: important(0),
    width: important(14),
    height: important(14),
  },
}))

const settings: React.ComponentProps<typeof Slider> = {
  accessibility: false,
  adaptiveHeight: true,
  arrows: false,
  dots: true,
  infinite: false,
  initialSlide: 1,
  slidesToScroll: 1,
  slidesToShow: 1,
  speed: 200,
  touchThreshold: 3000,
}
const dotProps = {
  sx: {
    fontSize: 12,
  },
}

export const SectionCarouselMobile: FCWithChildren = (props) => {
  const [activeSlide, setActiveSlide] = useState(1)
  const { wrapper } = useStyles()

  const customPaging = (i: number) => (
    <ElementContainer>
      {i === activeSlide ? (
        <SliderSolid {...dotProps} color="primary" />
      ) : (
        <SliderSolid {...dotProps} color="disabled" />
      )}
    </ElementContainer>
  )

  const appendDots = (dots: React.ReactNode) => (
    <div style={{ bottom: 15, position: 'fixed' }}>
      <DotsWrapper sx={{ boxShadow: 1 }}>{dots}</DotsWrapper>
    </div>
  )

  if (!props.children) {
    throw Error('Missing sections for mobile carousel')
  }
  return (
    <div className={wrapper}>
      <Slider
        {...settings}
        customPaging={customPaging}
        appendDots={appendDots}
        beforeChange={(i, next) => setActiveSlide(next)}
      >
        {React.Children.map(props.children, (child, i) =>
          child ? (
            <Box
              sx={{
                maxHeight: `calc(100vh - ${1 || BROWSER_TAB_BAR}px)`,
                overflowY: 'auto',
              }}
            >
              {child}
            </Box>
          ) : null
        )}
      </Slider>
    </div>
  )
}
