import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

export const Check = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 70 70">
    <g>
      <g>
        <circle cx="36.2" cy="36.2" r="26.8" />
      </g>
      <path
        fill="#FFFFFF"
        d="M30.6,48.1c-0.2,0-0.4-0.1-0.5-0.2l-7.1-7.1c-0.3-0.3-0.3-0.7,0-1c0.3-0.3,0.7-0.3,1,0l6.6,6.6l17.9-17.9
		c0.3-0.3,0.7-0.3,1,0c0.3,0.3,0.3,0.7,0,1L31.1,47.8C30.9,48,30.7,48.1,30.6,48.1z"
      />
    </g>
  </SvgIcon>
)
