import { Occasion } from 'pages/PersonalMessages/api/useGetPersonalTemplatesByOccasionId'
import { Project } from 'shared/Site/api'
import { getRequest } from 'utils/api/apiRequest'
import { WithPagination } from 'utils/api/pagination'

export type MyAccountPointsHistory = {
  transaction_id?: string
  value: number
  order_id: number | null
  info: string
  other_info?: string
  message?: string
  occasion?: Occasion
  project?: Project
  // expired_at: string NO IN MVP
  created_at: string
  can_rollback: boolean
  performed_by: string
}

export type MyAccountExpiresPointsHistory = {
  date: string
  total: string
}

export const getPointsHistory = (params: string) =>
  getRequest<WithPagination<MyAccountPointsHistory>>(`/points-history?${params}`)

export const getExpiresPointsHistory = () => getRequest<MyAccountExpiresPointsHistory[]>('/points-history/expires')
