import React, { cloneElement, ForwardedRef, forwardRef } from 'react'
import { useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import { useIsMobile } from 'utils/hooks/useBreakpoints'
import { createStyles, makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) =>
  createStyles({
    gridListItem: {
      borderRight: `1px solid ${theme.palette.background.default}`,
      borderBottom: `1px solid ${theme.palette.background.default}`,
      '&:nth-child(3n+0)': {
        borderRight: 'none',
      },
      '&:nth-last-child(-n+3)': {
        borderBottom: 'none',
      },
      '&:last-child': {
        borderRight: 'none',
      },
    },
  })
)

export interface ItemListGridProps {
  gap?: number
  children: React.ReactElement[]

  lineGrid?: boolean
  itemsPerPage?: number
  gridTemplateColumns?: string
}

export const ItemListGrid = forwardRef(
  (
    { gap = 5, children, lineGrid, itemsPerPage = 15, gridTemplateColumns }: ItemListGridProps,
    ref: ForwardedRef<any>
  ) => {
    const { gridListItem } = useStyles()
    const isMobile = useIsMobile()
    const lessThan300px = useMediaQuery('@media (max-width:299.95px)')
    const returnRef = (i: number) => (i % itemsPerPage === 0 ? ref : undefined)

    return (
      <Box
        display="grid"
        gridTemplateColumns={
          gridTemplateColumns ||
          (isMobile ? (lessThan300px ? '1fr' : `calc(50% - ${gap * 4}px) calc(50% - ${gap * 4}px)`) : '1fr 1fr 1fr')
        }
        gridTemplateRows={'auto'}
        columnGap={gap}
        rowGap={1}
        sx={{ width: '100%' }}
      >
        {children.map((child, index) =>
          cloneElement(child, {
            key: index,
            ref: returnRef(index),
            className: lineGrid ? gridListItem : undefined,
          })
        )}
      </Box>
    )
  }
)
