import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

type ArteelColor = {
  primary?: string
  secondary?: string
}

export const ArteelLogo = ({
  active,
  primary = '#3c3c3b',
  secondary = '#e52149',
  ...rest
}: SvgIconProps & IconActive & ArteelColor) => (
  <SvgIcon viewBox="0 0 136.98 30.73" {...rest}>
    <title>Asset 1</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          style={{ fill: primary }}
          d="M47.37,30.24,46.1,25.42H37.65l-1.31,4.82H30.42L38.59,3h6.57l8.13,27.23ZM41.86,8.76,38.71,20.6H45Z"
        />
        <path
          style={{ fill: primary }}
          d="M55.45,30.24V10.52h4.9v2.69A6.89,6.89,0,0,1,65.74,10v4.82a5,5,0,0,0-1.14-.17,5.49,5.49,0,0,0-4.25,2.41V30.24Z"
        />
        <path
          style={{ fill: primary }}
          d="M69.33,25.5V14.81H66.8V10.52h2.53V5.13h4.94v5.39H77.5v4.29H74.27v9.55c0,1.27.45,2,1.43,2a2,2,0,0,0,1.47-.57l1,3.76a5.64,5.64,0,0,1-3.76,1.14c-3.47,0-5.06-1.76-5.06-5.23"
        />
        <path
          style={{ fill: primary }}
          d="M79.62,20.36C79.62,14.64,83,10,88.52,10S97,14.44,97,20.56V22H84.56c.24,2.66,1.84,4.74,4.82,4.74a6,6,0,0,0,4.33-2l2.28,3a9.69,9.69,0,0,1-7.1,2.94c-5.6,0-9.27-4.17-9.27-10.37M88.44,14c-2.78,0-3.8,2.49-3.88,4.53h7.76c0-2-1-4.53-3.88-4.53"
        />
        <path
          style={{ fill: primary }}
          d="M99.3,20.36c0-5.72,3.39-10.33,8.9-10.33s8.49,4.41,8.49,10.53V22H104.24c.24,2.66,1.84,4.74,4.82,4.74a6,6,0,0,0,4.32-2l2.29,3a9.71,9.71,0,0,1-7.1,2.94c-5.6,0-9.27-4.17-9.27-10.37M108.12,14c-2.78,0-3.8,2.49-3.88,4.53H112c0-2-1-4.53-3.88-4.53"
        />
        <path
          style={{ fill: primary }}
          d="M120,25.5V3h4.9V24.36c0,1.27.49,2,1.47,2a1.93,1.93,0,0,0,1.43-.57l1,3.76a5.7,5.7,0,0,1-3.8,1.14c-3.43,0-5-1.76-5-5.23"
        />
        <path style={{ fill: secondary }} d="M130.65,27.5a3.2,3.2,0,0,1,3.15-3.14,3.17,3.17,0,1,1-3.15,3.14" />
        <path
          style={{ fill: secondary }}
          d="M18.18,6.8H15.1l4.1-4.1a1.58,1.58,0,0,0,0-2.24A1.6,1.6,0,0,0,17,.46L11.74,5.68l-3-3A1.58,1.58,0,0,0,6.5,4.91L8.38,6.8H5.27A5.26,5.26,0,0,0,0,12.06V25a5.27,5.27,0,0,0,5.27,5.27H18.18A5.27,5.27,0,0,0,23.44,25V12.06A5.26,5.26,0,0,0,18.18,6.8m2.1,5.26v4.88h-7V10h4.88a2.1,2.1,0,0,1,2.1,2.1M5.27,10h4.87v7h-7V12.06A2.11,2.11,0,0,1,5.27,10M3.16,25V20.1h7v7H5.27A2.12,2.12,0,0,1,3.16,25m15,2.11H13.3v-7h7V25a2.11,2.11,0,0,1-2.1,2.11"
        />
      </g>
    </g>
  </SvgIcon>
)
