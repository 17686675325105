import { useQuery } from '@tanstack/react-query'
import { fetchExpensivestProducts } from 'utils/api/Products/fetchExpensivestProducts'
import productsQueryKeys from 'utils/api/Products/productsQueryKeys'

export const useExpensivestProducts = () => {
  return useQuery({
    queryKey: productsQueryKeys.expensivestProduct(),
    queryFn: fetchExpensivestProducts,
  })
}
