import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { svgIconClasses } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { ArrowDown, Gift } from 'components/ArteelIcons'
import { GivePoints } from 'components/ArteelIcons/GivePoints'
import { ButtonWithIcon } from 'components/ButtonWithIcon/ButtonWithIcon'
import { MAIN_DOMAIN, PROTOCOL } from 'config/env'
import { Project } from 'shared/Site/api'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { inside } from 'utils/styles'

type Props = {
  project: Project
}

export const RedeemButton = ({ project }: Props) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const handleClickRedeem = () => {
    window.location.assign(`${PROTOCOL}://${project?.subdomain}.${MAIN_DOMAIN}/catalog`)
  }

  const handleClickTransferPoints = () => {
    navigate('/transfer-points', { state: { projectId: project?.id } })
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  if (!project?.is_transfer_points_active) {
    return (
      <ButtonWithIcon
        fullWidth
        size="small"
        variant="outlined"
        Icon={<Gift fontSize="small" />}
        onClick={handleClickRedeem}
        translation="cms.redeem"
      />
    )
  }

  return (
    <>
      <ButtonGroup variant="outlined" fullWidth ref={anchorRef} aria-label="split button" sx={{ zIndex: 2 }}>
        <ButtonWithIcon
          fullWidth
          size="small"
          variant="outlined"
          Icon={<Gift fontSize="small" />}
          onClick={handleClickRedeem}
          translation="cms.redeem"
          sx={{
            borderBottomLeftRadius: valueOrUndefined(open, 0),
            transition: ' 0.2s all ease',
            pr: `0!important`,
            '&:hover': {
              borderRightColor: `transparent !important`,
            },
            [inside('text')]: {
              pl: '0.5rem',
            },
          }}
        />
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="menu"
          data-cy="transfer-points-menu-button"
          onClick={handleToggle}
          sx={{
            padding: '0rem 1.5rem 0rem 1rem',
            borderBottomRightRadius: valueOrUndefined(open, 0),
            transition: ' 0.2s all ease',
            maxWidth: '2rem',
            [inside(svgIconClasses.root)]: {
              fontSize: '0.75rem',
            },
          }}
        >
          <ArrowDown />
        </Button>
      </ButtonGroup>
      <Box
        sx={{
          width: '100%',
          borderRadius: 24,
          position: 'relative',
          transition: '0.2s all ease',
        }}
      >
        <Box
          sx={{
            transition:
              'opacity 194ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 129ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            opacity: open ? 1 : 0,
          }}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <ButtonWithIcon
              fullWidth
              size="small"
              variant="outlined"
              Icon={<GivePoints fontSize="small" />}
              onClick={handleClickTransferPoints}
              translation="cms.give_points_to_others"
              data-cy="transfer-points-button"
              uppercase={false}
              sx={{
                position: 'absolute',
                zIndex: 1,
                borderTopLeftRadius: valueOrUndefined(open, 0),
                borderTopRightRadius: valueOrUndefined(open, 0),
                borderTop: 'none',
                '&:hover': {
                  borderTop: 'none',
                  background: (theme) => theme.palette.background.paper,
                },
                transition: ' 0.2s all ease',
                background: (theme) => theme.palette.background.paper,
              }}
            />
          </ClickAwayListener>
        </Box>
      </Box>
    </>
  )
}
