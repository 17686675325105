import { useNavigate, useParams } from 'react-router-dom'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import { Heart, ShoppingBasket } from 'components/ArteelIcons'
import { useSelectWishlist } from 'shared/Wishlist/slice'
import { Product } from 'utils/api/Products/CatalogProduct'
import { displayPoints } from 'utils/displayPoints'
import { getProductName } from 'utils/getProductName'
import { getThumbnailPath } from 'utils/getThumbnail'
import { useAddItemToWishlist } from 'utils/hooks/api/Wishlist/useAddItemToWishlist'
import { useRemoveWishlistItem } from 'utils/hooks/api/Wishlist/useRemoveWishlistItem'
import { useIsLuckyBirdUser } from 'utils/hooks/useIsLuckyBirdUser'
import { usePointsName } from 'utils/hooks/usePointsName'

import { useStyles } from './useStyles'

type Props = {
  variant: Product
  handleAddItemToShoppingCart: (id: number) => void
}

export const Variant = ({ variant, handleAddItemToShoppingCart }: Props) => {
  const { cover, variantInfo, variantInfoWrapper, brand, name, shoppingCartInactive } = useStyles()
  const pointsName = usePointsName(variant?.price)
  const navigate = useNavigate()
  const { data: wishlistItems, isFetching } = useSelectWishlist()
  const { handleAddItemToWishlist, isLoading } = useAddItemToWishlist()
  const { categoryId } = useParams<{ categoryId: string }>()
  const availableCategoryId = categoryId || variant?.categories?.[0]?.id
  const { isSingleLogic } = useIsLuckyBirdUser()

  const { mutate: onRemoveWishlistItem } = useRemoveWishlistItem()

  const getWishlistStatus = (variant: Product) =>
    !!wishlistItems?.data?.data?.find((item) => item.product?.id === variant.id)

  const handleWishlistButton = (variant: Product) => {
    if (getWishlistStatus(variant)) {
      onRemoveWishlistItem(wishlistItems?.data?.data?.find((item) => item.product?.id === variant.id)?.id)
    } else {
      handleAddItemToWishlist(variant?.id)
    }
  }

  const productLink = () =>
    navigate(
      availableCategoryId
        ? `/catalog/categories/${availableCategoryId}/products/${variant.id}`
        : `/catalog/products/${variant.id}`
    )

  return (
    <Grid item xs={12} lg={10} xl={8}>
      <Card variant="outlined">
        <Grid container spacing={2}>
          <Grid item>
            <CardMedia
              className={cover}
              image={getThumbnailPath(variant.media?.find((media) => media.main)?.path)}
              onClick={productLink}
            />
          </Grid>
          <Grid item xs className={variantInfoWrapper} onClick={productLink}>
            <Grid container alignItems="center" className={variantInfo}>
              {variant?.brand?.name && (
                <Grid item xs={12} className={brand}>
                  {variant.brand.name}
                </Grid>
              )}
              <Grid item xs={12} className={name}>
                {getProductName(variant)}
              </Grid>
              {!isSingleLogic && (
                <Grid item xs={12}>
                  {displayPoints(variant?.price ?? 0)} {pointsName}
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <IconButton color="primary" onClick={() => handleAddItemToShoppingCart(variant.id)} size="large">
                  <ShoppingBasket className={shoppingCartInactive} />
                </IconButton>
              </Grid>
              {!isSingleLogic && (
                <Grid item>
                  <IconButton
                    color="primary"
                    onClick={() => handleWishlistButton(variant)}
                    size="large"
                    disabled={isFetching || isLoading}
                  >
                    <Heart active={getWishlistStatus(variant)} />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  )
}
