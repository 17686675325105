import { useQuery } from '@tanstack/react-query'
import { personalMessageKeys } from 'pages/PersonalMessages/api/keys'
import { Occasion } from 'pages/PersonalMessages/api/useGetPersonalTemplatesByOccasionId'
import { getRequest } from 'utils/api/apiRequest'
import { WithPagination } from 'utils/api/pagination'

export const getPersonalTemplates = () =>
  getRequest<WithPagination<Occasion>>(`/users/message-templates/grouped?limit=1000`)

export const useGetPersonalTemplates = () => {
  return useQuery({
    queryKey: personalMessageKeys.getPersonalTemplates([]),
    queryFn: () => getPersonalTemplates(),
  })
}
