import { useQuery } from '@tanstack/react-query'
import { getExpiresPointsHistory } from 'pages/MyAccount/api/getPointsHistory'
import { myAccountKeys } from 'pages/MyAccount/api/keys'

export const useGetExpiresPointsHistory = () => {
  return useQuery({
    queryKey: myAccountKeys.getExpiresPointsHistory([]),
    queryFn: (context) => getExpiresPointsHistory(),
  })
}
