import { ReactNode } from 'react'
import { stepConnectorClasses, StepIconProps, stepLabelClasses, styled, svgIconClasses } from '@mui/material'
import Collapse from '@mui/material/Collapse'
import Stack from '@mui/material/Stack'
import Step from '@mui/material/Step'
import StepConnector from '@mui/material/StepConnector'
import StepLabel from '@mui/material/StepLabel'
import MuiStepper from '@mui/material/Stepper'
import { fontFamilies, getResponsiveSize } from 'theme/styles.utils'

const Connector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.root}`]: {
    marginLeft: theme.spacing(3),
  },
  [`& .${stepConnectorClasses.line}`]: {
    minHeight: 50,
    width: 1,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
    borderLeft: `1px solid ${theme.palette.grey[800]}`,
  },
}))

const StepContentRoot = styled('div')(({ theme }) => ({
  borderLeft: `1px solid ${theme.palette.grey[800]}`,
  marginLeft: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  '&.last': {
    borderLeft: `none`,
  },
}))

const StyledStepIcon = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean }
}>(({ theme, ownerState }) => ({
  backgroundColor: '#fff',
  zIndex: 1,
  color: '#000',
  width: getResponsiveSize(52),
  height: getResponsiveSize(52),
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${theme.palette.grey[800]}`,
  ...(ownerState.active && {
    background: theme.palette.primary.main,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    color: '#fff',
    border: 'none',
  }),
  ...(ownerState.completed && {
    background: theme.palette.grey[800],
    color: '#fff',
    border: 'none',
  }),
  [`& .${svgIconClasses.root}`]: {
    fontSize: theme.typography.pxToRem(getResponsiveSize(32)),
  },
}))

const StyledStepper = styled(MuiStepper)(({ theme }) => ({
  [`& .${stepLabelClasses.label}`]: {
    fontSize: theme.typography.h1.fontSize,
    fontWeight: 'bold!important',
    fontFamily: fontFamilies.cond,
    color: theme.palette.grey[800],
  },
  [`& .${stepLabelClasses.labelContainer}`]: {
    fontSize: theme.typography.h4.fontSize,
    fontFamily: fontFamilies.cond,
    color: theme.palette.grey[800],
  },
}))

const StepIcon = (props: StepIconProps & { stepIcon: ReactNode }) => {
  const { active, completed, className, stepIcon } = props
  return (
    <StyledStepIcon ownerState={{ completed, active }} className={className}>
      {stepIcon}
    </StyledStepIcon>
  )
}

export type StepperProps = {
  steps: {
    id: number
    label: string
    optionalLabel?: string
    icon: ReactNode
    content: ReactNode
    lazyLoaded?: boolean
  }[]
  activeStep?: number
  loadedSteps?: number[]
}

export const Stepper = ({ steps, activeStep, loadedSteps }: StepperProps) => {
  const loadedStepsArray = steps.filter((step) => (step.lazyLoaded ? loadedSteps?.includes(step.id) : true))
  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <StyledStepper orientation="vertical" activeStep={activeStep || 0} connector={null}>
        {steps.map((step, index) => (
          <Step key={step.id}>
            <Collapse
              in={step.lazyLoaded ? loadedSteps?.includes(step.id) : true}
              mountOnEnter
              unmountOnExit
              key={step.label}
            >
              {index !== 0 && <Connector />}
              <StepLabel
                data-cy={step.label?.replaceAll(' ', '-')}
                optional={
                  <Collapse in mountOnEnter unmountOnExit>
                    {step.optionalLabel}
                  </Collapse>
                }
                StepIconComponent={(props) => <StepIcon {...props} stepIcon={step.icon} />}
              >
                {step.label}
              </StepLabel>
              <StepContentRoot className={loadedStepsArray[loadedStepsArray?.length - 1]?.id === step.id && 'last'}>
                {!step.lazyLoaded && step.content}
                {step.lazyLoaded && (
                  <Collapse in={step.id === activeStep} mountOnEnter unmountOnExit>
                    {step.content}
                  </Collapse>
                )}
              </StepContentRoot>
            </Collapse>
          </Step>
        ))}
      </StyledStepper>
    </Stack>
  )
}
