import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { drawerContext } from 'components/DrawerSelect/drawerContext'
import qs from 'query-string'
import { useIsLuckyBirdUser } from 'utils/hooks/useIsLuckyBirdUser'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  label: {
    '& span': {
      fontSize: theme.typography.h3.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.main,
    },
  },
  wrapper: {
    padding: theme.spacing(2, 0),
  },
}))

export const SortProduct = () => {
  const { closeDrawer } = useContext(drawerContext)
  const { label, wrapper } = useStyles()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = qs.parse(location.search.replace('?', ''))
  const sortParamUrl = Object.keys(params).find((p) => p.startsWith('sort'))
  const sortParam = sortParamUrl?.substring(5, sortParamUrl.length - 1)
  const sortByParam = params?.[sortParamUrl] || 'desc'
  const { isSingleLogic } = useIsLuckyBirdUser()

  const options = (
    [
      { value: 'product.shop_price=asc', label: 'sort.product.points.asc', hidden: isSingleLogic },
      { value: 'product.shop_price=desc', label: 'sort.product.points.desc', hidden: isSingleLogic },
      { value: 'translated_name=asc', label: 'sort.product.name.asc', hidden: false },
      { value: 'translated_name=desc', label: 'sort.product.name.desc', hidden: false },
    ] as const
  ).filter(({ hidden }) => !hidden)
  const centerSortOptions = options.length < 3

  const onChangeSort = (newSortParam?: string, newSortByParam?: string) => {
    if (sortParam) {
      delete params[`sort[${sortParam}]`]
      delete params.lang
    }
    if (params['']) delete params['']

    closeDrawer()
    navigate(
      `${location.pathname}?${qs.stringify(
        newSortParam
          ? {
              ...params,
              [`sort[${newSortParam}]`]: newSortByParam || 'desc',
              [`lang`]: i18n.language,
            }
          : {
              ...params,
            },
        { encode: false }
      )}`
    )
  }

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={10} lg={12} xl={10}>
        <RadioGroup className={wrapper} value={`${sortParam}=${sortByParam}` || 'translated_name=desc'}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent={centerSortOptions ? 'center' : 'space-between'}
          >
            {options.map((option) => (
              <Grid
                item
                xs={12}
                md={centerSortOptions ? 3 : 6}
                lg={centerSortOptions ? 3 : true}
                key={option.value}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <FormControlLabel
                  className={label}
                  value={option.value}
                  control={<Radio color="primary" />}
                  label={<span>{t(option.label)}</span>}
                  onClick={(e) => {
                    e.preventDefault()
                    const valueSplit = option.value.split('=')
                    return `${sortParam}=${sortByParam}` === `${valueSplit[0]}=${valueSplit[1]}`
                      ? onChangeSort()
                      : onChangeSort(valueSplit[0], valueSplit[1])
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
      </Grid>
    </Grid>
  )
}
