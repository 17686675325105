import { useCallback, useEffect, useRef, useState } from 'react'
import Confetti from 'react-confetti'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { captureException } from '@sentry/react'
import { AxiosResponse, isAxiosError } from 'axios'
import Loader from 'components/Loader/Loader'
import { useGetVoucherStatus } from 'utils/hooks/api/Vouchers/useGetVoucherStatus'
import { useReceiveVoucher } from 'utils/hooks/api/Vouchers/useReceiveVoucher'
import { useIsMobile } from 'utils/hooks/useBreakpoints'
import { isSafari } from 'utils/isSafari'
import { useDisplayDateInTimeZone } from 'utils/useDisplayDateInTimeZone'

export const ReceiveVoucher = () => {
  const [size, setSize] = useState({ width: 0, height: 0 })
  const [open, setOpen] = useState(false)
  const [isNotAvailable, setIsNotAvailable] = useState(false)
  const { token } = useParams<{ token?: string }>()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { displayDateInTimeZone } = useDisplayDateInTimeZone()
  const isMobile = useIsMobile()

  const ref = useRef<HTMLElement | null>(null)

  const { data, isLoading, refetch, status, error } = useGetVoucherStatus(token)
  const { mutate, isLoading: isReceiveVoucher } = useReceiveVoucher({
    onSuccess: (data: AxiosResponse<{ url: string }>) => {
      setOpen(true)
      if (isSafari()) {
        setTimeout(() => {
          window.open(data?.data?.url) || window.location.assign(data?.data?.url)
        }, 3000)
      } else {
        setTimeout(() => {
          window.open(data?.data?.url)
        }, 3000)
      }
      refetch()
    },
  })

  useEffect(() => {
    if (!error) return
    if (isAxiosError(error)) {
      if (error?.response?.status === 404) {
        setIsNotAvailable(true)
        return
      }
    }
    navigate(`/404`)
  }, [error])

  const handler = useCallback(() => {
    setSize({
      width: ref?.current?.offsetWidth,
      height: ref?.current?.offsetHeight,
    })
  }, [setSize])

  useEffect(() => {
    if (ref?.current !== null) {
      handler()
      window?.addEventListener('resize', handler)
    }
  }, [ref?.current])

  const voucherInfo = () => {
    try {
      return isNotAvailable
        ? t('receive_voucher.not_available')
        : data?.data?.downloaded_at
          ? t('receive_voucher.downloaded_at', {
              date: displayDateInTimeZone(data?.data?.downloaded_at) || '',
            })
          : t('receive_voucher.not_downloaded')
    } catch (e) {
      // TODO remove this in the future
      console.error(e)
      captureException(e)
      return null
    }
  }

  return (
    <Loader isLoading={isLoading}>
      <Box
        ref={ref}
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: 'calc(100vh - 80px)',
          width: '100%',
          overflow: 'hidden',
          '& > canvas': {
            position: 'absolute',
            right: '0 !important',
            marginTop: (theme) => theme.spacing(-3),
            marginLeft: (theme) => theme.spacing(-1),
            inset: 'unset !important',
          },
        }}
      >
        <Grid item>
          <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid item>
              <Typography variant="h4">{voucherInfo()}</Typography>
            </Grid>
            <Grid item>
              <LoadingButton
                variant="contained"
                onClick={() => mutate(token)}
                disabled={open || isReceiveVoucher || isNotAvailable}
              >
                {t('receive_voucher.download_voucher')}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
        <Box
          sx={{
            position: 'fixed',
            left: isMobile ? 0 : '25%',
            top: 0,
            ['& > canvas']: {
              width: '100vw',
              minHeight: '100vh',
            },
          }}
        >
          <Confetti
            width={isMobile ? size.width + 48 : size.width + 48}
            height={isMobile ? size.height : size.height - 72}
            numberOfPieces={open ? 500 : 0}
            gravity={0.05}
          />
        </Box>
      </Box>
    </Loader>
  )
}
