import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { CustomPopover } from 'components/CustomPopover/CustomPopover'
import { LanguageName } from 'components/LanguageName'
import { useChangeLanguage } from 'pages/MyAccount/hooks/useChangeLanguage'
import { Z_INDEX } from 'utils/styles/zIndex'

type Props = {
  editable?: boolean
}

export const LanguageSelector = ({ editable }: Props) => {
  const { selectedLanguage, languages, onChange } = useChangeLanguage()
  const { t } = useTranslation()

  if (!editable) {
    return (
      <Typography
        color="primary"
        component="span"
        sx={(theme) => ({
          fontSize: theme.typography.h4.fontSize,
          fontWeight: 'bold',
          lineBreak: 'anywhere',
        })}
      >
        {selectedLanguage
          ? languages?.data?.find((l) => l.language?.id === selectedLanguage)?.language?.name
          : t('profile.change_language.select_language')}
      </Typography>
    )
  }

  return (
    <Box display="inline-flex">
      <CustomPopover
        zIndex={Z_INDEX.DRAWER + 1}
        button={({ open }) => (
          <Tooltip title={t('profile.change_language.select_language')}>
            <Typography
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              color="primary"
              component="span"
              sx={(theme) => ({
                cursor: 'pointer',
                fontSize: theme.typography.h4.fontSize,
                fontWeight: 'bold',
                lineBreak: 'anywhere',
                textDecoration: 'underline',
              })}
            >
              {selectedLanguage
                ? languages?.data?.find((l) => l.language?.id === selectedLanguage)?.language?.name
                : t('profile.change_language.select_language')}
            </Typography>
          </Tooltip>
        )}
        content={({ closeHandler }) => (
          <MenuList>
            <MenuItem
              key="0"
              value={0}
              sx={{
                display: 'none',
              }}
            >
              {t('profile.change_language.select_language')}
            </MenuItem>
            {languages?.data?.map((l) => (
              <MenuItem
                key={l.language.code}
                onClick={() => {
                  closeHandler()
                  onChange(l.language.id)
                }}
                selected={selectedLanguage === l.language.id}
              >
                <LanguageName code={l.language.code} />
              </MenuItem>
            ))}
          </MenuList>
        )}
      />
    </Box>
  )
}
