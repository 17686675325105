import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { postServiceRequest } from 'utils/api/ServiceRequest/postServiceRequest'
import { postUploadFile } from 'utils/api/ServiceRequest/postUploadFile'

export const useSendServiceRequest = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  return useMutation(postServiceRequest, {
    onSuccess: (data) => {
      enqueueSnackbar(t('service_request.success'), { variant: 'success' })
    },
    onError: () => {
      enqueueSnackbar(t('service_request.fail'), { variant: 'error' })
    },
  })
}

export const useUploadFileServiceRequest = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  return useMutation(postUploadFile, {
    onError: () => {
      enqueueSnackbar(t('service_request.fail'), { variant: 'error' })
    },
  })
}
