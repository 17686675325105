import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useFormikContext } from 'formik'
import { CreateOrderType2 } from 'pages/CreateOrder/types'

export const ErrorVariant = () => {
  const { getFieldMeta, submitCount } = useFormikContext<CreateOrderType2>()

  const meta = getFieldMeta('delivery_address_type')

  return (
    <>
      {meta?.error && (meta.touched || submitCount > 0) && (
        <Grid item xs={12}>
          <Typography variant={'h3'} align={'center'} color={'error'}>
            {meta.error}
          </Typography>
        </Grid>
      )}
    </>
  )
}
