import { patchRequest } from 'utils/api/apiRequest'
import { SocialWallPost } from 'utils/api/SocialWall/types'

export type PatchRecognitionPayload = {
  description: string
  id: number
}

export type PatchRecognitionResponse = SocialWallPost
export const patchRecognition = ({ id, ...data }: PatchRecognitionPayload) =>
  patchRequest<PatchRecognitionResponse>(`/social-wall/recognitions/${id}`, data)
