import { MessageBaseProps } from 'components/Message/types'
import emojiRegex from 'emoji-regex'
import { fontFamilies, fontSizes } from 'theme/styles.utils'
import { makeSx } from 'utils/styles/makeSx'

export const LARGE_AVATAR = {
  SIZE: 50,
  FONT: 1.6,
}
export const SMALL_AVATAR = {
  SIZE: 35,
  FONT: 1.2,
}
export const MAX_AVATARS = 4

export const textLengthCounter = (text?: string): number => {
  if (typeof text !== 'string') return 0
  const allEmojis = [...text.matchAll(emojiRegex())]
  const trimmedTextLength = text.trim().length
  const emojisCharsLength = allEmojis.length
  const emojisStringLength = allEmojis.map((el) => el[0]).join('').length
  const emojisDiffLength = emojisStringLength - emojisCharsLength
  return trimmedTextLength - emojisDiffLength
}

export const sxText = makeSx((theme) => ({
  fontSize: fontSizes.body2,
  width: 'fit-content',
  wordBreak: 'break-word',
  lineHeight: 1.1,
  fontFamily: fontFamilies.default,
}))
export const sxSize = (size: Required<MessageBaseProps['size']>) =>
  makeSx(() => ({
    px: size === 'small' ? 2.5 : 4,
    py: size === 'small' ? 1 : 3,
  }))
