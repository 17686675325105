import {
  accordionSummaryClasses,
  alpha,
  autocompleteClasses,
  circularProgressClasses,
  inputAdornmentClasses,
  inputBaseClasses,
  inputLabelClasses,
  outlinedInputClasses,
  responsiveFontSizes,
  svgIconClasses,
  tablePaginationClasses,
  typographyClasses,
} from '@mui/material'
import { grey, red } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'
import { Theme as MuiTheme } from '@mui/material/styles/createTheme'
import { lighten } from '@mui/system'
import { useSubdomainInfo } from 'shared/Site/api'
import { SiteType } from 'shared/Site/siteType'
import {
  borderRadiusButton,
  borderRadiusInput,
  fontFamilies,
  fontSizes,
  getResponsiveSize,
  inputFontSize,
  inputLabelPos,
  inputPadding,
  XXL,
} from 'theme/styles.utils'
import { and, important, inside } from 'utils/styles/stylesUtils'

import {
  proximaNova,
  proximaNovaBlack,
  proximaNovaBold,
  proximaNovaCond,
  proximaNovaCondBlack,
  proximaNovaCondBold,
} from './fonts'

type Props = {
  primaryColor?: string
  primaryButtonText?: string
  defaultBackgroundColor?: string
  paperBackgroundColor?: string
  textColor?: string
}

declare module '@mui/material/styles/createPalette' {
  interface TypeText {
    primaryLight: string
  }
}
export type Theme = MuiTheme

// Consider using `createStyled` function instead
declare module '@mui/system' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const useArteelTheme = (props?: Props): Theme => {
  const { data: subdomainInfo } = useSubdomainInfo()
  const subscriberTheme = subdomainInfo?.data?.subscriber?.theme
  const isGlobalLuckyBird = subdomainInfo?.data?.type === SiteType.luckyBirdGlobal
  return responsiveFontSizes(
    muiTheme({
      primaryColor: subscriberTheme?.button_background_color,
      primaryButtonText: subscriberTheme?.button_foreground_color,
      defaultBackgroundColor: isGlobalLuckyBird ? '#F6F7F7' : subscriberTheme?.color1,
      paperBackgroundColor: subscriberTheme?.color2,
      textColor: subscriberTheme?.color3,
    })
  )
}

export const muiTheme = ({
  primaryColor = '#E52149',
  defaultBackgroundColor = '#ecede4',
  paperBackgroundColor = '#FFF',
  primaryButtonText = '#FFF',
  textColor = '#000',
}: Props): MuiTheme => {
  const theme = createTheme({
    palette: {
      tonalOffset: 0.2,
      primary: {
        main: primaryColor,
        light: lighten(primaryColor, 0.75),
        contrastText: primaryButtonText,
      },
      secondary: red,
      error: red,
      common: {
        black: textColor,
      },
      background: {
        default: defaultBackgroundColor,
        paper: paperBackgroundColor,
      },
      text: {
        primary: alpha(textColor, 0.87),
        primaryLight: grey[800],
        secondary: alpha(textColor, 0.54),
        disabled: alpha(textColor, 0.38),
      },
      grey: {
        50: '#fafafa',
        100: '#f5f5f5',
        200: '#eeeeee',
        300: '#e0e0e0',
        400: '#bdbdbd',
        500: '#8d8d8d',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
        A100: '#d5d5d5',
        A200: '#aaaaaa',
        A400: '#303030',
        A700: '#616161',
      },
      mode: 'light',
    },
    typography: {
      fontWeightLight: 100,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
      fontFamily: fontFamilies.cond,
      h1: { fontSize: fontSizes.h1, fontWeight: 400 },
      h2: { fontSize: fontSizes.h2, fontWeight: 400 },
      h3: { fontSize: fontSizes.h3, fontWeight: 400 },
      h4: { fontSize: fontSizes.h4, fontWeight: 400 },
      h5: { fontSize: fontSizes.h5, fontWeight: 400 },
      h6: { fontSize: fontSizes.h6, fontWeight: 400 },
      body1: { fontSize: fontSizes.body1, fontWeight: 400 },
      body2: { fontSize: fontSizes.body2, fontWeight: 400 },
    },
    breakpoints: {
      values: {
        xs: getResponsiveSize(0),
        sm: getResponsiveSize(600),
        md: getResponsiveSize(900),
        lg: getResponsiveSize(1200),
        xl: getResponsiveSize(1536),
      },
    },
    spacing: getResponsiveSize(8),
    components: {
      MuiAlert: {
        styleOverrides: {
          icon: {
            fontSize: getResponsiveSize(22, 'px'),
          },
        },
      },
    },
  })

  return createTheme(theme, {
    components: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [
            proximaNova,
            proximaNovaBlack,
            proximaNovaBold,
            proximaNovaCond,
            proximaNovaCondBlack,
            proximaNovaCondBold,
          ],
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            [and(inputBaseClasses.input)]: {
              display: 'flex',
              alignItems: 'center',
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          outline: '0',
          endAdornment: {
            top: `calc(50% - ${getResponsiveSize(15, 'px')})`,
            [inside(autocompleteClasses.popupIndicator)]: {
              position: important('relative'),
            },
            [inside(autocompleteClasses.clearIndicator)]: {
              padding: getResponsiveSize(7, 'px'),
              position: important('relative'),
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            [inside(inputLabelClasses.root)]: {
              display: 'flex',
              alignItems: 'center',
              transition: '0.2s all ease',
              lineHeight: 1,
              transformOrigin: 'top',
              top: inputLabelPos.topMedium,
              left: inputLabelPos.leftAlignLeft,
              transform: inputLabelPos.transformAlignLeft,
              fontSize: theme.typography.h3.fontSize,
              height: `calc(1.2 * ${inputFontSize.standard})`,
              [theme.breakpoints.up(XXL)]: {
                fontSize: theme.typography.h4.fontSize,
                height: `calc(1.2 * ${inputFontSize.xxl})`,
              },
              [and(inputLabelClasses.sizeSmall)]: {
                top: inputLabelPos.topSmall,
              },
              [and(inputLabelClasses.shrink)]: {
                height: 'auto',
                fontSize: theme.typography.h5.fontSize,
                top: inputLabelPos.topShrink,
                left: important(inputLabelPos.leftShrink),
                transform: important(inputLabelPos.transformShrink),
              },
            },
            '&& ::placeholder': {
              fontWeight: 400,
            },
          },
          sizeSmall: {
            borderRadius: borderRadiusInput.small,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            [and(inputBaseClasses.adornedEnd)]: {
              button: {
                position: 'absolute',
                right: theme.spacing(1),
              },
            },
            [and(outlinedInputClasses.root)]: {
              background: theme.palette.background.default,
              border: `1px solid ${theme.palette.grey[300]}`,
              [and(outlinedInputClasses.notchedOutline)]: {
                border: 'none',
              },
              [and(outlinedInputClasses.focused)]: {
                boxShadow: `0px 0px 4px ${theme.palette.grey[300]}`,
              },
              [and(outlinedInputClasses.error)]: {
                borderColor: theme.palette.error.main,
              },
              [and(outlinedInputClasses.disabled)]: {
                filter: 'grayscale(80%)',
              },
              legend: {
                width: 0,
              },
              ['& fieldset']: {
                borderWidth: important(0),
              },
            },
            fontFamily: fontFamilies.default,
            fontWeight: 'bold',
            borderRadius: important(borderRadiusInput.medium),

            padding: important(`${inputPadding.outer.medium.y} ${inputPadding.outer.medium.x}`),
          },
          sizeSmall: {
            borderRadius: important(borderRadiusInput.small),
            padding: important(`${inputPadding.outer.small.y} ${inputPadding.outer.small.x}`),
          },
          input: {
            padding: important(`${inputPadding.inner.y} ${inputPadding.inner.x}`),
            fontSize: inputFontSize.standard,
            height: 'auto',
            minHeight: important('auto'),
            [theme.breakpoints.up(XXL)]: {
              fontSize: inputFontSize.xxl,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'inherit',
            fontSize: theme.typography.h3.fontSize,
            fontFamily: fontFamilies.cond,
            padding: `${getResponsiveSize(0.4, 'rem')} ${getResponsiveSize(2, 'rem')}`,
            borderRadius: borderRadiusButton.medium,

            [inside(circularProgressClasses.svg)]: {
              margin: 0,
            },
            [inside(svgIconClasses.root)]: {
              fontSize: getResponsiveSize(1.25, 'rem'),
              marginLeft: getResponsiveSize(0.15, 'rem'),
            },
            [inside(svgIconClasses.fontSizeSmall)]: {
              fontSize: getResponsiveSize(1, 'rem'),
            },
          },
          outlined: {
            fontWeight: 'bold',
            borderWidth: important('2px'),
            borderColor: theme.palette.primary.main,
            boxShadow: `0 0 1px ${theme.palette.primary.main}`,
            [inside(svgIconClasses.root)]: {
              fontSize: getResponsiveSize(1.25, 'rem'),
            },
          },
          contained: {
            fontWeight: 'bold',
            boxShadow: 'none',
            border: `2px solid ${theme.palette.primary.main}`,
            [inside(svgIconClasses.root)]: {
              fontSize: getResponsiveSize(1.25, 'rem'),
            },
            '&:hover': {
              borderColor: theme.palette.primary.dark,
            },
          },

          sizeSmall: {
            borderRadius: borderRadiusButton.small,
            fontSize: theme.typography.h4.fontSize,
            padding: `${getResponsiveSize(0.6, 'rem')} ${getResponsiveSize(2, 'rem')}`,
            lineHeight: 1.15,
            [inside(svgIconClasses.root)]: {
              marginBottom: getResponsiveSize(2, 'px'),
            },
          },
          sizeLarge: {
            borderRadius: borderRadiusButton.large,
            padding: `${getResponsiveSize(0.6, 'rem')} ${getResponsiveSize(2, 'rem')}`,
            [inside(svgIconClasses.root)]: {
              marginBottom: getResponsiveSize(6, 'px'),
            },
          },
        },
      },

      MuiLink: {
        styleOverrides: {
          root: {
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontFamily: fontFamilies.default,
            fontSize: theme.typography.h5.fontSize,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontFamily: fontFamilies.default,
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            width: '100%',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: getResponsiveSize(1, 'rem'),
            minHeight: getResponsiveSize(2, 'rem'),
            textWrap: 'wrap',
          },
        },
      },

      MuiCheckbox: {
        styleOverrides: {
          root: {
            '&$checked': {
              color: important(theme.palette.primary.main),
            },
          },
        },
      },

      MuiSvgIcon: {
        styleOverrides: {
          fontSizeLarge: {
            fontSize: getResponsiveSize(1.7, 'rem'),
          },
          fontSizeMedium: {
            fontSize: getResponsiveSize(1.5, 'rem'),
          },
          fontSizeSmall: {
            fontSize: getResponsiveSize(0.8, 'rem'),
          },
          primaryColor: {
            color: theme.palette.primary.main,
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.grey[300],
            borderColor: theme.palette.grey[300],
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          borderRadius: getResponsiveSize(12),
          elevation1: {
            boxShadow: '0px 0px 3px 0px #cac8c7',
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: theme.typography.body1.fontSize,
          },
        },
      },
      MuiBadge: {
        styleOverrides: {
          badge: {
            fontSize: theme.typography.body1.fontSize,
            padding: `0 ${getResponsiveSize(6, 'px')}`,
            height: getResponsiveSize(20, 'px'),
            borderRadius: '100%',
          },
        },
      },
      MuiCircularProgress: {
        defaultProps: {
          size: getResponsiveSize(40),
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            padding: `${theme.spacing(1.5)}, ${theme.spacing(2)}`,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            minWidth: 74,
            [inside(outlinedInputClasses.root)]: {
              padding: `0!important`,
              margin: 0,
            },
            [inside(outlinedInputClasses.input)]: {
              padding: `8px 16px!important`,
            },
            [`${inside(inputAdornmentClasses.positionStart)} > svg`]: {
              fontSize: '1rem',
              marginLeft: '8px',
            },
            [inside(tablePaginationClasses.select)]: {
              fontSize: '1rem',
              fontWeight: 'normal',
              fontFamily: fontFamilies.cond,
              lineHeight: '1.43',
              padding: `0px 16px !important`,
            },
            [inside(typographyClasses.root)]: {
              fontSize: '1rem',
              fontWeight: 'normal',
              fontFamily: fontFamilies.cond,
              lineHeight: '1.43',
            },
            '& input': {
              fontSize: '1rem !important',
              paddingLeft: '0px !important',
            },
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            borderRadius: important(borderRadiusInput.small),
            [inside(accordionSummaryClasses.root)]: {
              borderRadius: important(borderRadiusInput.small),
              borderBottomLeftRadius: important(0),
              borderBottomRightRadius: important(0),
              border: important('none'),
              minHeight: important(getResponsiveSize(56, 'px')),
            },
            [inside(accordionSummaryClasses.content)]: {
              margin: 0,
            },
            [inside(accordionSummaryClasses.expandIconWrapper, svgIconClasses.root)]: {
              fontSize: getResponsiveSize(16, 'px'),
            },
          },
        },
      },
    },
  })
}
