import { useParams } from 'react-router-dom'
import { HorizontalSlider } from 'components/HorizontalSlider/HorizontalSlider'
import Loader from 'components/Loader/Loader'
import { ProductGridCard } from 'components/ProductGrid/ProductGridCard'
import { CatalogProduct } from 'utils/api/Products/CatalogProduct'

type Props = {
  catalogProducts: CatalogProduct[]
  isLoading?: boolean
}

export const PanelProductGrid = ({ catalogProducts, isLoading }: Props) => {
  const { productId } = useParams<{ productId: string; categoryId: string }>()
  return (
    <Loader isLoading={isLoading} objects={catalogProducts}>
      <HorizontalSlider lineGrid>
        {catalogProducts
          .filter((catalogProduct) => productId !== catalogProduct.product.id?.toString())
          .map((catalogProduct) => (
            <ProductGridCard catalogProduct={catalogProduct} key={catalogProduct.product.id} />
          ))}
      </HorizontalSlider>
    </Loader>
  )
}
