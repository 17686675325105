import { ReactNode } from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { Row } from 'pages/HomeRedemptionSite/CategoriesAndFilters/components/Row'
import { fontFamilies } from 'theme/styles.utils'
import { useIsMobile } from 'utils/hooks/useBreakpoints'

type Props = {
  title: string
  children: ReactNode
  isLoading?: boolean
}

export const FilterWrapper = ({ title, children, isLoading }: Props) => {
  const isMobile = useIsMobile()
  return (
    <Box display="grid" gap={1}>
      <Box>
        <Typography
          variant={isMobile ? 'h1' : 'h2'}
          fontWeight={900}
          fontFamily={fontFamilies.black}
          color="textPrimaryLight"
        >
          {title}
        </Typography>
      </Box>
      <Box>
        <Divider sx={{ height: 2, background: (theme) => theme.palette.grey[400], boxShadow: 'none' }} />
      </Box>
      <Box>
        {isLoading
          ? Array.from({ length: 10 }, (_, i) => i).map((row) => (
              <Row key={row}>
                <Skeleton variant="text" width="150px" />
              </Row>
            ))
          : children}
      </Box>
    </Box>
  )
}
