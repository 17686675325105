import { useLocation, useParams } from 'react-router-dom'
import { getCurrentCategoriesPath, Segment } from 'components/Breadcrumbs'
import { TFunction } from 'i18next'
import qs from 'query-string'
import { CategoryForBreadcrumbs } from 'utils/api/Categories/fetchCategoryBreadcrumbs'
import { getObjectTranslation } from 'utils/getObjectTranslation'
import { useBreadcrumbsCategories } from 'utils/hooks/api/useBreadcrumbsCategories'
import { useIsRoute } from 'utils/hooks/useIsRoute'

export const SEARCH_PRODUCT = 'searchWidget'
export const ROOT_CATEGORY_ID = 1

export const getHeading = (currentCategoriesPath: CategoryForBreadcrumbs[], defaultHeading: string) =>
  getObjectTranslation(currentCategoriesPath[currentCategoriesPath.length - 1]?.translation)?.name || defaultHeading

export const getBreadcrumbsSegments = (
  currentCategoriesPath: CategoryForBreadcrumbs[],
  t: TFunction,
  language: string
): Segment[] => {
  const catalog = {
    id: 'catalog',
    label: t('catalog'),
    url: '/catalog',
  }
  if (!currentCategoriesPath.length) return []
  if (currentCategoriesPath[0].id === ROOT_CATEGORY_ID) return [catalog]
  return [
    catalog,
    ...currentCategoriesPath.map((category, index) => {
      const segment: Segment = {
        id: category.id.toString(),
        label: category?.translation?.[language]?.name || category?.name || '-',
      }
      if (index !== currentCategoriesPath.length) {
        segment.url = `/catalog/categories/${category.id}`
      }
      return segment
    }),
  ]
}

export const useShowFiltersAndSorting = () => {
  const { isRoute } = useIsRoute(['/catalog/categories/:categoryId', '/catalog/brands/:id'])
  return { showFilters: isRoute }
}
export const useIsCategoriesPage = () => {
  const { isRoute } = useIsRoute(['/catalog', '/'])
  return { isCategoriesPage: isRoute }
}

export const useHomeRSUtils = () => {
  const { categoryId } = useParams<{ categoryId: string }>()
  const location = useLocation()
  const { isCategoriesPage } = useIsCategoriesPage()
  const { data: breadcrumbsCategories } = useBreadcrumbsCategories()

  const queryKeys = Object.keys(qs.parse(location?.search, { decode: true }))
  const searchEnabled = !!queryKeys.find((key) => key.includes(SEARCH_PRODUCT))
  const filtersEnabled = !!queryKeys.find((key) => key.includes('filters') || key.includes('"enough_points"'))
  const isRootCategory = categoryId === ROOT_CATEGORY_ID.toString()
  const productsCategoryId = isCategoriesPage || searchEnabled ? ROOT_CATEGORY_ID : Number(categoryId)
  const currentCategoriesPath = getCurrentCategoriesPath(productsCategoryId, breadcrumbsCategories?.data)

  return {
    currentCategoriesPath,
    isCategoriesPage,
    isRootCategory,
    filtersEnabled,
    searchEnabled,
    categoryId,
    productsCategoryId,
  }
}
