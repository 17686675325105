import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

export const PersonalDetails = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 28 28">
    <g transform="translate(-362.99 -326.465)">
      <g>
        <path
          d="M378.888,346.54a4.213,4.213,0,0,0-4.167-3.649,4.341,4.341,0,0,0-.569.039,4.212,4.212,0,0,0-3.61,4.737l.124.916a1.515,1.515,0,0,0,1.4,1.311l5.938-.8a1.516,1.516,0,0,0,1.005-1.636Z"
          fill="#fff"
        />
        <path d="M372.086,350.018a1.65,1.65,0,0,1-1.544-1.419l-.124-.916a4.336,4.336,0,0,1,8.594-1.16l.124.916a1.649,1.649,0,0,1-1.088,1.772Zm2.635-7a4.146,4.146,0,0,0-.553.038,4.086,4.086,0,0,0-3.5,4.6l.124.916a1.4,1.4,0,0,0,1.288,1.2l5.912-.8a1.4,1.4,0,0,0,.9-1.5l-.124-.916a4.087,4.087,0,0,0-4.043-3.54Z" />
      </g>
      <g>
        <path
          d="M383.909,351.246l.009,0a.491.491,0,0,0,.055-.017h0a.661.661,0,0,0,.438-.573v0c0-.018,0-.036,0-.054v-.012c0-.022,0-.044-.005-.066l-3.154-23.357a.66.66,0,0,0-.652-.571.677.677,0,0,0-.089.006l-16.829,2.272a.66.66,0,0,0-.564.741l3.153,23.357a.658.658,0,0,0,.741.565l16.829-2.272.066-.013Zm-17.123-19.1a.271.271,0,0,1-.049,0,.366.366,0,0,1-.049-.729l11.606-1.567a.366.366,0,1,1,.1.725Zm8.372,1.825-4.353.587a.268.268,0,0,1-.049,0,.366.366,0,0,1-.049-.729l4.353-.588a.366.366,0,0,1,.1.726ZM378.2,349.8a.35.35,0,0,1-.064.015l-6.006.811a.4.4,0,0,1-.049,0h-.017a2.247,2.247,0,0,1-2.126-1.947l-.124-.916a4.947,4.947,0,0,1,2.709-5.094,2.862,2.862,0,0,1-1.034-4.313,2.843,2.843,0,0,1,1.894-1.1,2.771,2.771,0,0,1,.386-.026,2.863,2.863,0,0,1,2.274,4.6,3.025,3.025,0,0,1-.393.421,4.947,4.947,0,0,1,3.961,4.192l.124.917a2.249,2.249,0,0,1-1.535,2.44Z"
          fill="#fff"
        />
        <path d="M366.926,353.662a.787.787,0,0,1-.776-.679L363,329.626a.784.784,0,0,1,.672-.882l16.829-2.272a.79.79,0,0,1,.881.672l3.154,23.357a.731.731,0,0,1,.006.08c0,.036,0,.056,0,.076l0,.022a.79.79,0,0,1-.437.631v.042h-.105a.358.358,0,0,1-.047.013l-.01,0c-.025.006-.05.011-.076.015l-16.829,2.272A.944.944,0,0,1,366.926,353.662ZM380.6,326.715a.615.615,0,0,0-.074.005L363.7,328.992a.534.534,0,0,0-.457.6L366.4,352.95a.538.538,0,0,0,.6.457l16.828-2.272.048-.009h0l.04-.011.014-.005a.533.533,0,0,0,.355-.464V350.6a.5.5,0,0,0,0-.062l-3.154-23.356A.535.535,0,0,0,380.6,326.715Zm-8.52,24.037a2.38,2.38,0,0,1-2.268-2.055l-.123-.916a5.073,5.073,0,0,1,2.566-5.113,2.986,2.986,0,1,1,3.886-.765,2.936,2.936,0,0,1-.236.274,5.067,5.067,0,0,1,3.829,4.248l.123.916a2.373,2.373,0,0,1-1.619,2.576h0a.534.534,0,0,1-.086.02l-6.007.81A.452.452,0,0,1,372.084,350.752Zm1.688-13.4a2.618,2.618,0,0,0-.369.025,2.736,2.736,0,0,0-.822,5.177l.229.112-.229.113a4.819,4.819,0,0,0-2.64,4.966l.123.916a2.124,2.124,0,0,0,2.009,1.838l.043,0,6.007-.81a.313.313,0,0,0,.042-.009h0a2.124,2.124,0,0,0,1.448-2.306l-.123-.916a4.822,4.822,0,0,0-3.861-4.087l-.25-.048.191-.168a2.815,2.815,0,0,0,.376-.4,2.738,2.738,0,0,0-2.174-4.4Zm-3.016-2.673a.492.492,0,0,1-.486-.425.491.491,0,0,1,.421-.552l4.352-.588a.491.491,0,0,1,.131.974l-4.352.587A.479.479,0,0,1,370.756,334.682Zm4.353-1.319-.032,0-4.353.588a.237.237,0,0,0-.16.093.235.235,0,0,0-.046.177.24.24,0,0,0,.238.209l.033,0,4.352-.587a.242.242,0,0,0,.206-.271.241.241,0,0,0-.238-.209Zm-8.372-1.092a.491.491,0,0,1-.066-.978l11.607-1.567a.491.491,0,0,1,.132.974L366.8,332.266A.512.512,0,0,1,366.737,332.271Zm11.607-2.3a.183.183,0,0,0-.033,0L366.7,331.541a.241.241,0,0,0,.064.478h0l11.606-1.567a.241.241,0,0,0,.206-.271.235.235,0,0,0-.093-.159A.24.24,0,0,0,378.344,329.971Z" />
      </g>
      <g>
        <path
          d="M389.422,329.377a.656.656,0,0,0-.45-.227l-6.777-.5,2.939,21.772c.006.047.01.094.012.14a.16.16,0,0,0,0,.022c0,.039,0,.079,0,.118v0a1.391,1.391,0,0,1-.925,1.213h0c-.037.013-.076.024-.115.034l-.02.006c-.045.011-.091.019-.138.026l-8.357,1.128,11.545.856a.66.66,0,0,0,.706-.608l1.743-23.5a.648.648,0,0,0-.157-.478Z"
          fill="#fff"
        />
        <path d="M387.179,354.1l-.057,0-12.739-.945,9.542-1.288c.043-.006.085-.014.127-.024s.088-.024.122-.036l.021-.007a1.277,1.277,0,0,0,.567-.42,1.26,1.26,0,0,0,.257-.676v-.015c0-.032,0-.065,0-.1a1.179,1.179,0,0,0-.011-.15l-2.96-21.923,6.931.514a.776.776,0,0,1,.536.27l.075.088-.006.006a.778.778,0,0,1,.118.476l-1.744,23.5A.785.785,0,0,1,387.179,354.1Zm-10.392-1.019,10.353.768a.53.53,0,0,0,.571-.493l1.744-23.505a.534.534,0,0,0-.128-.387l-.019-.021a.532.532,0,0,0-.346-.163l-6.623-.491,2.919,21.619c.007.051.011.1.013.152,0,.067,0,.111,0,.153v.019a1.515,1.515,0,0,1-.306.8,1.5,1.5,0,0,1-.7.511h0l-.016.006h0c-.035.012-.072.022-.108.032-.072.017-.122.027-.173.034Z" />
      </g>
      <g>
        <path
          d="M374.053,342.2a2.131,2.131,0,0,0-.281-4.243,2.159,2.159,0,0,0-.289.02,2.131,2.131,0,0,0-1.826,2.4,2.135,2.135,0,0,0,2.108,1.846A1.98,1.98,0,0,0,374.053,342.2Z"
          fill="#fff"
        />
        <path d="M373.765,342.348a2.256,2.256,0,0,1-.3-4.491,2.376,2.376,0,0,1,.3-.021,2.256,2.256,0,0,1,.3,4.492h0A2.189,2.189,0,0,1,373.765,342.348Zm.006-4.262a2.053,2.053,0,0,0-.271.019,2.006,2.006,0,0,0,.265,3.993,1.9,1.9,0,0,0,.271-.018h0a2.006,2.006,0,0,0-.266-3.994Z" />
      </g>
    </g>
  </SvgIcon>
)
