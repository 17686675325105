import { Locale } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import isEmpty from 'lodash/isEmpty'
import { dateWithTimeFormat } from 'utils/dateFormats'
import { timeZoneArray } from 'utils/timezoneArray'

export const createDateAsUTC = (date: Date) =>
  new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds())
  )

export const convertDateToUTC = (date: Date) =>
  new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  )

export const defaultTimeZone = (() => {
  const localStorageTimeZone = localStorage.getItem('timezone')

  if (localStorageTimeZone && timeZoneArray.includes(localStorageTimeZone)) {
    return localStorageTimeZone
  }

  return Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'Europe/Berlin'
})()

export type DisplayDateOptions = {
  formatTemplate?: string
  timeZone?: string
}

type DisplayDateInTimeZone = (dateString: string, options?: DisplayDateOptions, locale?: Locale) => string

export const displayDateInTimeZone: DisplayDateInTimeZone = (dateString, optionsProps, locale): string => {
  if (isEmpty(dateString)) {
    return 'n/a'
  }

  const date = new Date(dateString)
  if (!isDateObject(date)) {
    return 'invalid date format'
  }
  const options = { formatTemplate: dateWithTimeFormat, timeZone: defaultTimeZone, ...optionsProps }
  try {
    return formatInTimeZone(date, options?.timeZone, options?.formatTemplate, { locale })
  } catch (e) {
    return formatInTimeZone(date, options?.timeZone, options?.formatTemplate)
  }
}

export const isDateObject = (object: any): object is Date =>
  typeof object === 'object' && typeof (object as Date)?.getUTCDate?.() === 'number'
