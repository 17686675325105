import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import LoadingButton from '@mui/lab/LoadingButton'
import { dialogClasses, inputBaseClasses, inputLabelClasses, textFieldClasses } from '@mui/material'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { useGetAvailableCountries } from 'components/CountryContextDialog/hooks/useGetAvailableCountries'
import { TextField } from 'components/TextField/TextField'
import { useCountryContext } from 'store/useCountryContext'
import { inputLabelPos } from 'theme/styles.utils'
import { getObjectTranslation } from 'utils/getObjectTranslation'
import { useIsMobile } from 'utils/hooks/useBreakpoints'
import { and, inside } from 'utils/styles'

export const CountryContextDialog = () => {
  const { country, setCountry, isLoading } = useCountryContext()
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const [countryForm, setCountryForm] = useState<string | null>(country?.id ? String(country?.id) : null)

  const { data, isLoading: isLoadingCountries } = useGetAvailableCountries()

  return (
    <Dialog
      open={!country}
      sx={{
        [`& .${dialogClasses.paper}`]: {
          minWidth: { xs: '90%', sm: '600px', xl: '900px', borderRadius: 32 },
        },
      }}
    >
      <DialogTitle mx={{ xs: 1, md: 4 }} mt={{ xs: 4, md: 8 }}>
        <Typography variant="h1" align="center" fontWeight="bold" sx={{ padding: '0 !important' }}>
          {t('country_context.title')}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ mx: { xs: 1, md: 12 }, mt: 3 }}>
        <Typography variant="h3" align="center" mb={2} sx={{ padding: '0 !important' }}>
          {t('country_context.description')}
        </Typography>
        <Box sx={{ mt: { xs: 4, md: 6 }, mx: { xs: 1, md: 12 } }}>
          <TextField
            value={countryForm || ''}
            onChange={(event) => {
              setCountryForm(event.target.value)
            }}
            label={t('create_order.country')}
            select
            size="small"
            sx={{
              [and(textFieldClasses.root)]: {
                [inside(inputBaseClasses.input)]: {
                  textAlign: 'center',
                  // for proper "select" positioning
                  justifyContent: 'center',
                },
                [inside(inputLabelClasses.root)]: {
                  transform: inputLabelPos.transformAlignCenter,
                  left: inputLabelPos.leftAlignCenter,
                },
              },
            }}
            fullWidth={isMobile}
            InputProps={{
              endAdornment: isLoadingCountries && <CircularProgress size={20} />,
            }}
            disabled={isLoadingCountries}
          >
            {data?.data?.map((country) => (
              <MenuItem key={country.id} value={String(country.id)}>
                {getObjectTranslation(country?.translation)?.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', mb: 4 }}>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={() => setCountry(data?.data?.find((country) => String(country.id) === countryForm))}
          loading={isLoading}
          disabled={!countryForm}
        >
          {t('app.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
