import Person from '@mui/icons-material/Person'
import MuiAvatar from '@mui/material/Avatar'
import CircularProgress from '@mui/material/CircularProgress'
import isEmpty from 'lodash/isEmpty'
import { getResponsiveSize } from 'theme/styles.utils'

export const getNameInitials = (fullName?: string) => {
  if (isEmpty(fullName)) {
    return []
  }

  const nameInitials = fullName.split(' ').map((x) => x.charAt(0))
  if (nameInitials.length <= 2) {
    return nameInitials
  }

  return [nameInitials.slice(0, 1), nameInitials.slice(-1)]
}

type Props = {
  fullName?: string
  image?: string
  width?: number
  height?: number
  fontSize?: number
  zIndex?: number
  isLoading?: boolean
}

export const Avatar = ({
  fullName,
  image,
  width = 100,
  height = 100,
  fontSize = 3,
  zIndex,
  isLoading = false,
  ...props
}: Props) => {
  const nameInitials = getNameInitials(fullName).join('').toUpperCase()
  return (
    <MuiAvatar
      sx={{
        width: getResponsiveSize(width),
        height: getResponsiveSize(height),
        fontSize: getResponsiveSize(fontSize, 'rem'),
        zIndex,
        ['& svg']: {
          fontSize: getResponsiveSize(fontSize, 'rem'),
        },
      }}
      src={isLoading ? undefined : image}
      children={
        isLoading ? <CircularProgress data-cy="avatar-loading" /> : isEmpty(nameInitials) ? <Person /> : nameInitials
      }
      {...props}
    />
  )
}
