import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Zoom from '@mui/material/Zoom'
import { useQueryClient } from '@tanstack/react-query'
import { Bus, Document } from 'components/ArteelIcons'
import { useGetAvailableCountries } from 'components/CountryContextDialog/hooks/useGetAvailableCountries'
import { GoBackButton } from 'components/GoBackButton/GoBackButton'
import Loader from 'components/Loader/Loader'
import { ShoppingCartWarnings } from 'components/ShoppingCartWarnings/ShoppingCartWarnings'
import { WhitePaper } from 'components/WhitePaper/WhitePaper'
import { Form, Formik } from 'formik'
import isEmpty from 'lodash/isEmpty'
import { ButtonContinue } from 'pages/CreateOrder/components/ButtonContinue'
import { Overview } from 'pages/CreateOrder/components/Overview'
import { SelectAddress } from 'pages/CreateOrder/components/SelectAddress'
import { useDeliveryAddressPreset } from 'pages/CreateOrder/components/useDeliveryAddressPreset'
import { useDeliveryVariants } from 'pages/CreateOrder/components/useDeliveryVariants'
import { DeliveryVariant } from 'pages/CreateOrder/deliveryOptions'
import { getInitialValues2 } from 'pages/CreateOrder/getInitialValues'
import { DeliveryMethods, DeliveryTypes } from 'shared/ShoppingCart/api'
import { shoppingCartQueryKeys, useSelectShoppingCart } from 'shared/ShoppingCart/slice'
import borderImage from 'theme/images/borderImage.png'
import { getResponsiveSize } from 'theme/styles.utils'
import deliveryPresetAddressesQueryKeys from 'utils/api/DeliveryPresetAddress/deliveryPresetAddressesQueryKeys'
import deliveryTypesQueryKeys from 'utils/api/DeliveryType/deliveryTypesQueryKeys'
import { useChangeDelivery2 } from 'utils/hooks/api/Order/useChangeDelivery'
import { useIsMobile, useIsTablet } from 'utils/hooks/useBreakpoints'
import { useIsDisabledOrdering } from 'utils/hooks/useIsDisabledOrdering'
import { important } from 'utils/styles'
import { makeStyles } from 'utils/styles/makeStyles'
import * as Yup from 'yup'

import { CreateOrderType2 } from './types'

const stepsCssValues = {
  mobile: {
    icon: {
      svg: '45px',
      wrapper: 45,
      translate: 'translate(-50%, -72%)',
    },
    label: {
      paddingLeft: important('60px'),
    },
    container: {
      paddingX: '6px',
      marginLeft: '10px',
    },
  },
  desktop: {
    icon: {
      svg: getResponsiveSize(65, 'px'),
      wrapper: getResponsiveSize(70, 'px'),
      translate: 'translate(-50%, -65%)',
    },
  },
}

const useStyles = makeStyles((theme) => ({
  whitePaper: {
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(0),
    },
  },
  wrapper: {
    marginTop: theme.spacing(-3),
    padding: theme.spacing(0, 0, 0, 5),
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    },
  },
  container: {
    borderImage: `url(${borderImage}) 1 round`,
    borderLeft: `thin dashed ${theme.palette.grey[500]}`,
    '&:last-child': {
      borderLeft: 'none',
    },
    paddingLeft: theme.spacing(7),
    position: 'relative',
    transition: '0.3s all',
    '&.padding-b0': {
      paddingBottom: 2,
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: stepsCssValues.mobile.container.marginLeft,
      paddingLeft: stepsCssValues.mobile.container.paddingX,
      paddingRight: stepsCssValues.mobile.container.paddingX,
    },
  },
  iconStep: {
    transform: stepsCssValues.desktop.icon.translate,
    width: stepsCssValues.desktop.icon.wrapper,
    height: stepsCssValues.desktop.icon.wrapper,
    [theme.breakpoints.down('md')]: {
      transform: stepsCssValues.mobile.icon.translate,
      width: stepsCssValues.mobile.icon.wrapper,
      height: stepsCssValues.mobile.icon.wrapper,
    },
    position: 'absolute',
    top: theme.spacing(3),
    left: theme.spacing(0),
    overflow: 'hidden',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: '0.3s all',
    border: `${getResponsiveSize(5, 'px')} solid ${theme.palette.primary.main}`,
    color: theme.palette.common.white,
    '&.current': {
      background: theme.palette.primary.main,
    },
    '&.finished': {
      background: theme.palette.grey[800],
      borderColor: theme.palette.grey[800],
      color: theme.palette.common.white,
    },
    '&.not-begun': {
      background: theme.palette.background.paper,
      color: theme.palette.primary.main,
    },
    '& svg': {
      fontSize: stepsCssValues.desktop.icon.svg,
      [theme.breakpoints.down('md')]: {
        fontSize: stepsCssValues.mobile.icon.svg,
      },
    },
  },
  headerStep: {
    fontSize: theme.typography.h1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[800],
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: stepsCssValues.mobile.label.paddingLeft,
    },
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}))

export const CreateOrder = () => {
  const { data: shoppingCartItems, isInitialLoading } = useSelectShoppingCart()
  const [step, setStep] = useState(0)
  const { whitePaper, container, iconStep, wrapper, headerStep, divider } = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isTablet = useIsTablet()
  const { isInitialLoading: isFetchingCountries } = useGetAvailableCountries()
  const { isInitialLoading: isFetchingPreset } = useDeliveryAddressPreset()
  const { isInitialLoading: isFetchingDeliveryVariants } = useDeliveryVariants()
  const queryClient = useQueryClient()
  const isMobile = useIsMobile()

  const isDigitalDelivery = shoppingCartItems?.data?.delivery_type === DeliveryTypes.digital

  useEffect(() => {
    queryClient.invalidateQueries(shoppingCartQueryKeys.all)
    queryClient.invalidateQueries(deliveryPresetAddressesQueryKeys.all)
    queryClient.invalidateQueries(deliveryTypesQueryKeys.all)
  }, [])

  useEffect(() => {
    if (!isInitialLoading && isEmpty(shoppingCartItems?.data?.items)) {
      navigate(`/catalog`)
    }
  }, [shoppingCartItems])

  useEffect(() => {
    if (shoppingCartItems?.data?.delivery_method_name === DeliveryMethods.groupedAndDate && !isDigitalDelivery) {
      setStep(1)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [shoppingCartItems?.data?.delivery_method_name])

  const onSuccess = () => {
    setStep((prev) => prev + 1)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  const { mutate, isLoading } = useChangeDelivery2({ onSuccess })
  const onClickBack = () => navigate('/shopping-cart')
  const onClickBackToCatalog = () => navigate('/catalog')

  const handleChangeDelivery2 = (values: CreateOrderType2) => mutate(values)

  const getClassStatus = (stepId: number) => {
    if (stepId === step) {
      return `${iconStep} current`
    }
    if (stepId < step) {
      return `${iconStep} finished`
    }

    return `${iconStep} not-begun`
  }

  const physicalDeliveryAddressSchema = {
    street: Yup.string().label(t('create_order.street')).required().nullable().max(50),
    number: Yup.string().label(t('create_order.number')).required().nullable().max(10),
    zip: Yup.string().label(t('create_order.zip')).required().nullable().max(10),
    box: Yup.string().label(t('create_order.box')).notRequired().nullable().max(10),
    city: Yup.string().label(t('create_order.city')).required().nullable().max(30),
    is_company_address: Yup.boolean().notRequired().nullable(),
    company_name: Yup.string()
      .label(t('create_order.company_name'))
      .max(50)
      .when('is_company_address', ([is_company_address], Yup) =>
        is_company_address ? Yup.required().nullable() : Yup.notRequired().nullable()
      ),
  }

  const bPostDeliveryPointSchema = {
    bpost_delivery_point_id: Yup.string().label(t('create_order.bpost_delivery_point_id')).required(),
  }

  const presetAddressSchema = {
    preset_address_id: Yup.number()
      .label(t('create_order.preset_address_id'))
      .required()
      .positive(t('app.validations.required')?.replace('$path', t('create_order.preset_address_id')))
      .nullable(),
  }

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      first_name: Yup.string().label(t('create_order.first_name')).required().max(49),
      last_name: Yup.string().label(t('create_order.last_name')).required().max(50),
      email: Yup.string().label(t('create_order.email')).required(),
      phone_number: Yup.string()
        .label(t('create_order.phone_number'))
        .when('delivery_address_type', ([delivery_address_type], Yup) =>
          delivery_address_type === DeliveryVariant.digital ? Yup.notRequired().nullable() : Yup.required().nullable()
        ),
      delivery_address_type: Yup.number()
        .required()
        .notOneOf(
          [-1],
          t('app.validations.required')?.replace('$path', t('create_order.delivery_address')?.toLowerCase())
        ),
      [DeliveryVariant.digital]: Yup.object()
        .nullable()
        .when('delivery_address_type', ([delivery_address_type], Yup) =>
          delivery_address_type === DeliveryVariant.digital ? Yup.shape({}) : Yup.shape({})
        ),
      [DeliveryVariant.onetime]: Yup.object()
        .nullable()
        .when('delivery_address_type', ([delivery_address_type], Yup) =>
          delivery_address_type === DeliveryVariant.onetime ? Yup.shape(physicalDeliveryAddressSchema) : Yup.shape({})
        ),
      [DeliveryVariant.homeAddress]: Yup.object()
        .nullable()
        .when('delivery_address_type', ([delivery_address_type], Yup) =>
          delivery_address_type === DeliveryVariant.homeAddress
            ? Yup.shape(physicalDeliveryAddressSchema)
            : Yup.shape({})
        ),
      [DeliveryVariant.companyAddress]: Yup.object()
        .nullable()
        .when('delivery_address_type', ([delivery_address_type], Yup) =>
          delivery_address_type === DeliveryVariant.companyAddress
            ? Yup.shape(physicalDeliveryAddressSchema)
            : Yup.shape({})
        ),
      [DeliveryVariant.bpostParcelLocker]: Yup.object()
        .nullable()
        .when('delivery_address_type', ([delivery_address_type], Yup) =>
          delivery_address_type === DeliveryVariant.bpostParcelLocker
            ? Yup.shape({
                ...physicalDeliveryAddressSchema,
                ...bPostDeliveryPointSchema,
              })
            : Yup.shape({})
        ),
      [DeliveryVariant.bpostCollectionPoint]: Yup.object()
        .nullable()
        .when('delivery_address_type', ([delivery_address_type], Yup) =>
          delivery_address_type === DeliveryVariant.bpostCollectionPoint
            ? Yup.shape({
                ...physicalDeliveryAddressSchema,
                ...bPostDeliveryPointSchema,
              })
            : Yup.shape({})
        ),
      [DeliveryVariant.preset]: Yup.object()
        .nullable()
        .when('delivery_address_type', ([delivery_address_type], Yup) =>
          delivery_address_type === DeliveryVariant.preset
            ? Yup.shape({
                ...physicalDeliveryAddressSchema,
                ...presetAddressSchema,
              })
            : Yup.shape({})
        ),
    })
  )
  const initialValues2 = getInitialValues2()
  const isDisabledOrder = useIsDisabledOrdering()

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} xl={8} className={whitePaper}>
        <WhitePaper
          changeViewDisable
          headerAbsolute={!isTablet}
          showPointsDisable
          header={
            <Grid container alignItems="center" justifyContent="flex-end">
              {!isMobile && (
                <Grid item>
                  <GoBackButton
                    onClick={onClickBackToCatalog}
                    translation="shopping_cart.back_to_catalog"
                    onlyIcon={false}
                    dataCy={'go-back-to-catalog'}
                  />
                </Grid>
              )}
              <Grid item>
                <GoBackButton onClick={onClickBack} translation="create_order.back_to_shopping_cart" />
              </Grid>
            </Grid>
          }
        >
          <Loader isLoading={isInitialLoading || isFetchingCountries || isFetchingPreset || isFetchingDeliveryVariants}>
            <Formik
              initialValues={initialValues2}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={handleChangeDelivery2}
            >
              <Form data-cy="create-order-form">
                <Grid container className={wrapper}>
                  <Grid item xs={12} className={`${container}${step !== 0 ? ' padding-b0' : ''}`}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} className={headerStep}>
                        <div className={getClassStatus(0)}>
                          <Bus />
                        </div>
                        {t('create_order.delivery_address')}
                      </Grid>
                      {isDisabledOrder ? (
                        <Grid item xs={12}>
                          <Divider className={divider} />
                          <ShoppingCartWarnings />
                        </Grid>
                      ) : (
                        <Grid item xs={12}>
                          <Grid container spacing={4} justifyContent="center">
                            <Grid item xs={12}>
                              <SelectAddress
                                isSelected={step !== 0}
                                onBack={() => {
                                  setStep(0)
                                  window.scrollTo({ top: 0, behavior: 'smooth' })
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <ButtonContinue isFirstStep={step === 0} isLoading={isLoading} />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={container}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} className={headerStep}>
                        <div className={getClassStatus(1)}>
                          <Document />
                        </div>
                        {t('create_order.overview')}
                      </Grid>
                      <Zoom in={step === 1} unmountOnExit mountOnEnter timeout={300}>
                        <Grid item xs={12}>
                          <Overview />
                        </Grid>
                      </Zoom>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Formik>
          </Loader>
        </WhitePaper>
      </Grid>
    </Grid>
  )
}
