import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { Hide, Show } from 'components/ArteelIcons'
import { FormikButtons } from 'components/Formik/FormikButtons'
import { FormikTextField } from 'components/Formik/FormikTextField'
import { useFormikContext } from 'formik'
import qs from 'query-string'
import { parseError } from 'utils/api/parseError'

type Props = {
  isLoading: boolean
  isError: boolean
  error?: unknown
}

export const ActivateForm = ({ isLoading, error, isError }: Props) => {
  const [showPassword, setShowPassword] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const parsedError = parseError(error)
  const isVisibleError = parsedError?.message && !isLoading && isError
  const params = qs.parse(location.search.replace('?', ''))

  const { isSubmitting } = useFormikContext()

  const toggleShowPassword = () => setShowPassword((prev) => !prev)

  const endAdornment = (
    <IconButton size="large" color="primary" onClick={toggleShowPassword} disabled={isVisibleError}>
      {showPassword ? <Hide fontSize="large" /> : <Show fontSize="large" />}
    </IconButton>
  )

  useEffect(() => {
    if (parsedError.isCode('UNPROCESSABLE')) {
      navigate('/auth/login')
    }
  }, [parsedError.status])

  return (
    <>
      <Grid item>
        <Typography align="center" variant="h3" color="error">
          {t('auth.activate.title')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormikTextField
          variant="outlined"
          name="first"
          inputLabel="auth.activate.first"
          type={showPassword ? 'text' : 'password'}
          center
          size="small"
          disabled={isVisibleError}
          InputProps={{
            inputProps: { placeholder: 'password' },
            endAdornment,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormikTextField
          variant="outlined"
          name="second"
          inputLabel="auth.activate.second"
          type={showPassword ? 'text' : 'password'}
          center
          size="small"
          disabled={isVisibleError}
          InputProps={{
            inputProps: { placeholder: 'password' },
            endAdornment,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormikButtons
          gridLayout
          textButtonSubmit="auth.activate"
          disabledSubmit={isSubmitting || isVisibleError}
          isLoading={isSubmitting}
          onBack={() => navigate(`/auth/login?${qs.stringify({ ...params, token: undefined }, { encode: false })}`)}
        />
      </Grid>
      <Grid item xs={12} sx={{ pt: isVisibleError ? 2 : 0 }}>
        <Collapse in={isVisibleError}>
          <Alert severity="error" sx={{ borderRadius: 24 }}>
            {parsedError?.message}
          </Alert>
        </Collapse>
      </Grid>
    </>
  )
}
