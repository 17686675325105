import React, { Dispatch, SetStateAction, SyntheticEvent } from 'react'
import { accordionDetailsClasses, AccordionProps, AccordionSummaryProps } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import { ArrowDown } from 'components/ArteelIcons'
import { Occasion, PersonalTemplate } from 'pages/PersonalMessages/api/useGetPersonalTemplatesByOccasionId'
import { SubscriberTemplate } from 'pages/PersonalMessages/api/useGetSubscriberTemplates'
import { Template } from 'pages/PersonalMessages/Templates/Template'
import { getObjectTranslation } from 'utils/getObjectTranslation'
import { inside } from 'utils/styles'

type Props = {
  occasion: SubscriberTemplate | Occasion
  expanded: boolean | string
  setExpanded: Dispatch<SetStateAction<false | string>>
  setObjectToEdit?: Dispatch<SetStateAction<undefined | null | (PersonalTemplate & { occasionId: Occasion['id'] })>>
}

export const OccasionTemplates = ({ occasion, setExpanded, expanded, setObjectToEdit }: Props) => {
  const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const isUserTemplates = 'message_templates_user' in occasion
  const isSubscriberTemplates = 'message_templates' in occasion

  return (
    <Accordion
      {...getAccordionProps(
        isUserTemplates ? `user-${occasion.id}` : `subscriber-${occasion.id}`,
        expanded,
        handleChange
      )}
      key={occasion.id.toString()}
    >
      <AccordionSummary {...getAccordionSummaryProps(occasion.id.toString())}>
        <Typography
          sx={(theme) => ({
            fontSize: theme.typography.h3.fontSize,
            fontWeight: 'bold',
            minHeight: theme.spacing(5),
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.grey[800],
          })}
        >
          {getObjectTranslation(occasion?.translation)?.name}
        </Typography>
      </AccordionSummary>
      {isUserTemplates &&
        occasion?.message_templates_user?.map((template) => (
          <Template key={template.id} template={template} occasionId={occasion.id} setObjectToEdit={setObjectToEdit} />
        ))}
      {isSubscriberTemplates &&
        occasion?.message_templates?.map((template) => (
          <Template key={template.id} template={template} occasionId={occasion.id} />
        ))}
    </Accordion>
  )
}

const getAccordionProps = (
  name: string,
  expanded: boolean | string,
  handleChange: (name: string) => (event: React.SyntheticEvent, expanded: boolean) => void
): Partial<AccordionProps> => ({
  elevation: 0,
  TransitionProps: {
    unmountOnExit: false,
    mountOnEnter: false,
  },
  expanded: name === expanded,
  onChange: handleChange(name),
  sx: {
    border: (theme) => `1px solid ${theme.palette.divider}`,
    borderRadius: (theme) => theme.spacing(5),
    overflow: 'hidden',
    margin: (theme) => theme.spacing(2, 0),
    '&:first-of-type, &:last-of-type': {
      borderRadius: (theme) => theme.spacing(5),
    },
    '&:first-of-type': {
      marginTop: 0,
    },
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    [inside(accordionDetailsClasses.root)]: {
      padding: (theme) => theme.spacing(2, 2, 2, 5),
      borderTop: (theme) => `1px solid ${theme.palette.divider}`,
      '&:first-of-type': {
        borderTop: 0,
      },
    },
  },
})

const getAccordionSummaryProps = (name: string): AccordionSummaryProps => ({
  expandIcon: <ArrowDown sx={{ fontSize: 16 }} />,
  id: `${name}-header`,
  'aria-controls': `${name}-content`,
  sx: {
    borderBottom: 0,
    borderRadius: (theme) => theme.spacing(5),
    backgroundColor: (theme) => theme.palette.background.default,
    flexDirection: 'row-reverse',
    transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&.Mui-expanded': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      margin: 0,
      minHeight: (theme) => theme.spacing(7),
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      transform: 'rotate(-90deg)',
      color: (theme) => theme.palette.primary.main,
      marginLeft: (theme) => theme.spacing(3),
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(0deg)',
    },
    '& .MuiAccordionSummary-content': {
      margin: (theme) => theme.spacing(1, 0),
      paddingLeft: (theme) => theme.spacing(4),
      '&.Mui-expanded': {
        margin: (theme) => theme.spacing(0, 0),
      },
    },
  },
})
