import { FunctionComponent } from 'react'
import Grid from '@mui/material/Grid'
import { ResponsivePaper } from 'components/ResponsivePaper'
import { CmsSection } from 'pages/CMS/CMS.types'
import { useIsMobile } from 'utils/hooks/useBreakpoints'
import { useFilterVisibleContent } from 'utils/hooks/useFilterVisibleContent'
import { useSections } from 'utils/hooks/useSections'

import { ContentBox } from './ContentBox'
import { LogicBoxContent } from './ContentBox/LogicBoxContent'
import { MyScoreWidget } from './SocialWall/MyScoreWidget/MyScoreWidget'

type Props = {
  section: CmsSection
  socialWallColumns?: boolean
}

export const LeftColumn: FunctionComponent<Props> = ({ section, socialWallColumns = false }) => {
  const isMobile = useIsMobile()
  const { getLogicBoxContents, getNonLogicBoxContents } = useSections()
  const nonLogicBoxContents = getNonLogicBoxContents([section])
  const logicBoxContents = getLogicBoxContents([section])
  const filterContent = useFilterVisibleContent()

  return (
    <Grid container spacing={isMobile ? 2 : 4}>
      {!!logicBoxContents.length && (
        <Grid item xs={12}>
          <ResponsivePaper>
            <LogicBoxContent contents={logicBoxContents} sectionName={section?.name} />
          </ResponsivePaper>
        </Grid>
      )}
      {!!nonLogicBoxContents.length && (
        <Grid item xs={12}>
          <Grid container spacing={isMobile ? 2 : 4}>
            {nonLogicBoxContents.filter(filterContent).map((content) => (
              <Grid key={content.id} item xs={12}>
                <ContentBox content={content} sectionName={section?.name} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
      {socialWallColumns && (
        <Grid item xs={12}>
          <Grid container spacing={isMobile ? 2 : 4}>
            <Grid item xs={12}>
              <MyScoreWidget />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
