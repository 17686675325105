import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

export const Menu = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 30 30">
    <g xmlns="http://www.w3.org/2000/svg">
      <path d="M11.8,1.3H3.1c-1,0-1.8,0.9-1.8,1.8v8.7c0,1,0.9,1.8,1.8,1.8h8.7c1,0,1.8-0.9,1.8-1.8V3.1    C13.6,2,12.9,1.3,11.8,1.3L11.8,1.3z" />
      <path d="M26.9,1.3h-8.7c-1,0-1.8,0.9-1.8,1.8v8.7c0,1,0.9,1.8,1.8,1.8h8.7c1,0,1.8-0.9,1.8-1.8V3.1    C28.7,2,28,1.3,26.9,1.3L26.9,1.3z" />
      <path d="M11.8,16.4H3.1c-1,0-1.8,0.9-1.8,1.8v8.7c0,1,0.9,1.8,1.8,1.8h8.7c1,0,1.8-0.9,1.8-1.8v-8.7    C13.6,17.1,12.9,16.4,11.8,16.4L11.8,16.4z" />
      <path d="M26.9,16.4h-8.7c-1,0-1.8,0.9-1.8,1.8v8.7c0,1,0.9,1.8,1.8,1.8h8.7c1,0,1.8-0.9,1.8-1.8v-8.7    C28.7,17.1,28,16.4,26.9,16.4L26.9,16.4z" />
    </g>
  </SvgIcon>
)
