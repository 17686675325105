import { createContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import {
  availableCountriesKey,
  useGetAvailableCountries,
} from 'components/CountryContextDialog/hooks/useGetAvailableCountries'
import { usePatchUserCountry } from 'components/CountryContextDialog/hooks/usePatchUserCountry'
import { LoaderFullScreen } from 'components/LoaderFullScreen/LoaderFullScreen'
import { Country } from 'utils/api/country'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'
import { FCWithChildren } from 'utils/types/FCWithChildren'

type CountryContextType = {
  country: Country | null
  setCountry: (country: Country) => void
  isLoading: boolean
}

export const CountryContext = createContext<CountryContextType | null>(null)

export const CountryProvider: FCWithChildren = ({ children }) => {
  const user = useCurrentUser()
  const { data, isLoading: isLoadingCountries } = useGetAvailableCountries()
  const onlyOneCountry = data?.items === 1 ? data?.data[0] : null
  const [country, setCountry] = useState<Country | null>(user?.country)
  const { mutate, isLoading } = usePatchUserCountry()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (!user) {
      setCountry(null)
      queryClient.resetQueries({ queryKey: availableCountriesKey })
      return
    }
    if (!!onlyOneCountry && !user?.country) {
      onChangeCountry(onlyOneCountry)
    }
  }, [onlyOneCountry, user?.country, user])

  const onChangeCountry = (newCountry: Country) => {
    mutate({
      countryId: newCountry.id,
      onSuccess: () => {
        if (location.pathname.includes('/catalog') && !!country) {
          navigate('/catalog')
        }
        setCountry(newCountry)
      },
    })
  }
  if (isLoadingCountries) return <LoaderFullScreen />

  return (
    <CountryContext.Provider value={{ country, setCountry: onChangeCountry, isLoading }}>
      {children}
    </CountryContext.Provider>
  )
}
