import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import { ResponsivePaper } from 'components/ResponsivePaper'
import { ShowMoreButton } from 'components/ShowMoreButton/ShowMoreButton'
import { useCMSNotifications } from 'utils/hooks/api/useCMSNotifications'
import { infinityQueryMap } from 'utils/hooks/infinityQueryMap'
import { useIsMobile } from 'utils/hooks/useBreakpoints'

import { NotificationBox, useStyles } from './NotificationBox'

export const NotificationsBox = () => {
  const { buttonLabel, notificationFooter, notificationHeader } = useStyles()
  const { isFetching: isLoadingCMSNotifications, data, fetchNextPage, isInitialLoading } = useCMSNotifications()
  const notifications = infinityQueryMap(data)
  const isMobile = useIsMobile()

  return (
    <Grid container spacing={isMobile ? 2 : 4}>
      {isInitialLoading && (
        <Grid item xs={12}>
          <ResponsivePaper>
            <header className={notificationHeader}>
              <Skeleton variant="text" sx={{ fontSize: '32px' }} />
            </header>
          </ResponsivePaper>
        </Grid>
      )}
      {notifications?.data?.map((notification) => (
        <Grid key={notification.id} item xs={12}>
          <NotificationBox notification={notification} />
        </Grid>
      ))}
      <ShowMoreButton
        items={notifications?.page}
        allItems={notifications?.pages}
        isLoading={isLoadingCMSNotifications}
        onClick={() => fetchNextPage()}
      />
    </Grid>
  )
}
