import { useTranslation } from 'react-i18next'
import MaterialTableBase, { MaterialTableProps } from '@material-table/core'
import Box from '@mui/material/Box'
import { useQueryClient } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { WithPagination } from 'utils/api/pagination'
import { getOptionsWithoutPagination, queryBuilder } from 'utils/getOptionsTable'
import { makeSx } from 'utils/styles'

type Props<T extends object> = Omit<MaterialTableProps<T>, 'data'> & {
  queryKey: (args: string[]) => string[]
  queryFn: (params?: string) => Promise<AxiosResponse<WithPagination<T>>>
}

export const MaterialTable = <T extends object>({ queryKey, queryFn, ...props }: Props<T>) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const sx = makeSx((theme) => ({
    maxWidth: '100%',
    '& .MuiPaper-elevation2': {
      boxShadow: 'none',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 0,
    },
    '& .MuiTableCell-footer': {
      borderBottom: 'none',
    },
    '& .MuiTextField-root, .MuiSelect-select': {
      width: '100%',
    },
    '& .MuiOutlinedInput-input': {
      padding: '9px 16px',
      fontSize: '0.75rem',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '32px',
      '& input': {
        padding: '12px 16px',
        fontSize: '0.75rem',
      },
    },
    '& .MuiInputAdornment-positionStart': {
      display: 'none',
    },
  }))
  return (
    <Box sx={sx}>
      <MaterialTableBase
        data={queryBuilder<T>(queryKey, queryFn, props.columns, queryClient)}
        localization={{
          body: {
            emptyDataSourceMessage: t('app.no_elements'),
          },
        }}
        options={getOptionsWithoutPagination<T>()}
        {...props}
      />
    </Box>
  )
}
