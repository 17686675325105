export type BackendError = {
  code: number
  message: string
  errors?: Record<string, string> | string[]
}

export const isBackendError = (response: unknown): response is BackendError => {
  const backendResponse = response as BackendError
  return typeof backendResponse.code === 'number' && typeof backendResponse.message === 'string'
}
