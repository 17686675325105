import { FieldsExtended, FormFieldExtended } from 'components/JSONForm/types'
import { format, isDate } from 'date-fns'
import isObject from 'lodash/isObject'
import mapValues from 'lodash/mapValues'
import { dateFormat } from 'utils/dateFormats'

type InitTypes = string | number | null | Date | { [key: string]: InitTypes }
const getDateOrNull = (date?: string | null) => (date ? new Date(date) : null)

const unifyInitValues = <T extends FormFieldExtended>(f: T): InitTypes => {
  if (f.type === 'date') {
    return getDateOrNull(f.value)
  }
  if (f.type === 'object') {
    return mapValues(f.fields, unifyInitValues)
  }

  return f.value ?? ''
}
export const mapInitialValues = <T extends FieldsExtended>(fields: T) => mapValues(fields, unifyInitValues)

const unifySubmitValue = (v: InitTypes): InitTypes => {
  if (isDate(v)) return format(v as Date, dateFormat)
  if (typeof v === 'string') return v || null
  if (isObject(v)) {
    // @ts-ignore
    return mapValues(v, unifySubmitValue)
  }
  return v
}
export const mapSubmitValues = <T extends {}>(values: T) =>
  mapValues(values, unifySubmitValue) as unknown as Record<keyof T, unknown>
