import Typography from '@mui/material/Typography'
import { ShowPointsExpiryDate } from 'pages/CMS/components/ContentBox/ShowPointsExpiryDate'
import { Project } from 'shared/Site/api'
import { fontFamilies, getResponsiveSize } from 'theme/styles.utils'
import { displayPoints } from 'utils/displayPoints'
import { usePointsExpirationDate } from 'utils/hooks/usePointsExpirationDate'
import { usePointsName } from 'utils/hooks/usePointsName'

interface Props {
  project?: Project
  availablePoints?: number
}

export const MyPointsText = ({ project, availablePoints }: Props) => {
  const pointsValue = availablePoints || project?.available_points
  const pointsName = usePointsName(pointsValue)
  const { showExpirationDate, firstExpiredPoints, firstExpirationDate } = usePointsExpirationDate(project)

  return (
    <>
      <Typography
        sx={(theme) => ({
          lineHeight: 0.8,
          fontSize: getResponsiveSize(2.25, 'rem'),
          fontWeight: 900,
          color: theme.palette.grey['800'],
          display: 'block',
          textAlign: 'center',
          lineBreak: 'anywhere',
          textDecoration: 'none',
        })}
      >
        {displayPoints(pointsValue)}
      </Typography>
      <Typography
        align="center"
        sx={(theme) => ({
          fontSize: `${getResponsiveSize(1.5, 'rem')}!important`,
          fontWeight: 700,
          fontFamily: fontFamilies.default,
          color: theme.palette.grey['800'],
        })}
      >
        {pointsName}
      </Typography>
      {showExpirationDate && (
        <ShowPointsExpiryDate pointName={pointsName} points={firstExpiredPoints} date={firstExpirationDate} />
      )}
    </>
  )
}
