import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

export const GivePoints = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 100 100">
    <g>
      <path
        d="M98.7,49V39c0-1-1.2-1.7-2.1-1C86.5,46.1,68.3,50.2,50,50.2c-18,0-36.3-4.1-46.7-12.2c-0.8-0.6-2.1,0-2.1,1v10
		v0.8C1.3,61.4,23,71,50,71s48.7-9.5,48.7-21.4L98.7,49L98.7,49z"
      />
      <path
        d="M98.7,76.8v-10c0-1-1.2-1.7-2.1-1C86.5,73.9,68.3,78,50,78c-18,0-36.3-4.1-46.7-12.2c-0.8-0.6-2.1,0-2.1,1v10
		v0.8C1.3,89.2,23,98.7,50,98.7s48.7-9.5,48.7-21.4L98.7,76.8L98.7,76.8z"
      />
      <path d="M98.7,22.6C98.7,34.4,76.9,44,50,44S1.3,34.4,1.3,22.6S23.1,1.3,50,1.3S98.7,10.8,98.7,22.6" />
    </g>
  </SvgIcon>
)
