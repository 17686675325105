import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { MessageBaseProps } from 'components/Message/types'
import { sxText } from 'components/Message/utils'
import { fontFamilies } from 'theme/styles.utils'
import { makeSx } from 'utils/styles/makeSx'

type MessageReadOnlyProps = Pick<MessageBaseProps, 'size' | 'author' | 'value'>

export const MessageReadOnly = ({ author, value, size }: MessageReadOnlyProps) => {
  const sxAuthor = makeSx((theme) => ({
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontFamily: fontFamilies.cond,
  }))

  return (
    <Typography variant="body2" sx={sxText}>
      {!!author && (
        <Box component="span" sx={sxAuthor}>
          {`${author.profile.full_name} `}
        </Box>
      )}
      {value}
    </Typography>
  )
}
