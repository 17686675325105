import { useMutation, useQueryClient } from '@tanstack/react-query'
import { patchComment } from 'utils/api/SocialWall/patchComment'
import { PostsQueryData, updatePostQueryData, useSWQueryHelpers } from 'utils/hooks/api/SocialWall/utils'

type Props = {
  onSuccess?: () => void
  postId: number
}
export const useCommentUpdate = ({ onSuccess, postId }: Props) => {
  const { t, enqueueSnackbar, postsFilteredKey, invalidateAllPosts } = useSWQueryHelpers()
  const queryClient = useQueryClient()

  return useMutation(patchComment, {
    onSuccess: (response) => {
      const newComment = response.data
      onSuccess?.()
      queryClient.setQueryData<PostsQueryData>(
        postsFilteredKey,
        updatePostQueryData(postId, (postToUpdate) => ({
          comments: postToUpdate.comments.map((comment) => (comment.id === newComment.id ? newComment : comment)),
        }))
      )
      invalidateAllPosts()
      enqueueSnackbar(t('recognition_post.comment.update.success'), { variant: 'success' })
    },
    onError: () => {
      enqueueSnackbar(t('recognition_post.comment.update.fail'), { variant: 'error' })
    },
  })
}
