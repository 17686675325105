import { useTranslation } from 'react-i18next'
import { FieldInputProps, FieldMetaProps } from 'formik'
import isEmpty from 'lodash/isEmpty'

export const getTextFieldParams = (
  name: string,
  prefixTranslation: string,
  getFieldMeta: (name: string) => FieldMetaProps<any>,
  getFieldProps?: (nameOrOptions: any) => FieldInputProps<any>
) => {
  const { touched, error } = getFieldMeta(name)
  const props = getFieldProps ? getFieldProps(name) : {}
  const { t } = useTranslation()

  return {
    ...props,
    error: !isEmpty(error) && touched,
    helperText: touched && error && error,
    label: t(`${prefixTranslation}${name}`),
    fullWidth: true,
  }
}
