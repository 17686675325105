import { patchRequest } from 'utils/api/apiRequest'

export type Data = {
  token: string
  password: {
    first: string
    second: string
  }
}

export const patchResetPassword = ({ token, password }: Data) =>
  patchRequest(`/users/password/new?token=${token}`, {
    password,
  })
