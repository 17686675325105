import { useTranslation } from 'react-i18next'
import Checkbox from '@mui/material/Checkbox'
import Collapse from '@mui/material/Collapse'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { FormikButtons } from 'components/Formik/FormikButtons'
import { FormikTextField } from 'components/Formik/FormikTextField'
import { useFormikContext } from 'formik'
import { useGetPersonalMessage } from 'pages/PersonalMessages/api/useGetPersonalMessage'
import { PersonalMessageDetails } from 'pages/PersonalMessages/components/PersonalMessageDetails'
import { ReuseTemplates } from 'pages/PersonalMessages/Edit/ReuseTemplates'
import { FormValues } from 'pages/PersonalMessages/Edit/types'
import { PersonalMessage } from 'pages/PersonalMessages/types'
import { fontFamilies } from 'theme/styles.utils'

type Props = {
  triggerGift: PersonalMessage['triggered_gift']
  onBack: () => void
  isLoading: boolean
}

export const EditPersonalMessageForm = ({ triggerGift, onBack, isLoading }: Props) => {
  const { t } = useTranslation()
  const { values, setFieldValue } = useFormikContext<FormValues>()
  const { data } = useGetPersonalMessage({ isEdit: true })

  const notEditable = !data?.editable && data?.has_content

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h3" fontFamily={fontFamilies.black} fontWeight={900}>
          {t('personal_messages.add.recipient')}
        </Typography>
      </Grid>
      <PersonalMessageDetails triggerGift={triggerGift} />
      <ReuseTemplates occasionId={triggerGift?.occasion?.id} />
      <Grid item xs={12}>
        <FormikTextField
          multiline
          rows={4}
          fullWidth
          name="message"
          label={t('personal_messages.add.message')}
          disabled={notEditable}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          disabled={notEditable}
          label={
            <Typography variant="body2" fontFamily={fontFamilies.default} pt="3px">
              {t('personal_messages.save_as_template')}
            </Typography>
          }
          sx={(t) => ({ fontSize: t.typography.body1.fontSize })}
          control={
            <Checkbox
              name="saveAsTemplate"
              value={values.saveAsTemplate}
              onClick={() => setFieldValue('saveAsTemplate', !values.saveAsTemplate)}
            />
          }
        />
        <Collapse in={values.saveAsTemplate}>
          <FormikTextField name="name" size="small" inputLabel="personal_messages.templates.name" />
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        <FormikButtons onBack={onBack} isLoading={isLoading} disabled={notEditable} />
      </Grid>
    </Grid>
  )
}
