import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'

import { changeAvatar } from './changeAvatar'
import userQueryKeys from './userQueryKeys'

const isImage = (image: File) => /^image\/.*/.test(image.type)

export const useChangeAvatar = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation(
    ({ image }: { image: File | undefined }) => {
      if (!image || !isImage(image)) {
        throw new Error()
      }

      const formData = new FormData()
      formData.append('file', image)

      return changeAvatar({ formData })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(userQueryKeys.me())
        enqueueSnackbar(t('profile.change_avatar.success'), { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar(t('profile.change_avatar.fail'), { variant: 'error' })
      },
    }
  )
}
