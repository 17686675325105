import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

export const Close = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 30 30">
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M19.1,15l9.6-9.6c1.2-1.2,1.2-3,0-4.2c-1.2-1.2-3-1.2-4.2,0L15,10.8L5.4,1.2C4.2,0,2.4,0,1.2,1.2s-1.2,3,0,4.2   l9.6,9.6l-9.6,9.6c-1.2,1.2-1.2,3,0,4.2c0.5,0.5,1.4,0.8,2,0.8s1.5-0.3,2-0.8l9.6-9.6l9.6,9.6c0.5,0.5,1.4,0.8,2,0.8   c0.8,0,1.5-0.3,2-0.8c1.2-1.2,1.2-3,0-4.2L19.1,15z"
    />
  </SvgIcon>
)
