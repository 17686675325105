import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { postRecognitionComment } from 'utils/api/SocialWall/postRecognitionComment'
import { PostsQueryData, updatePostQueryData, useSWQueryHelpers } from 'utils/hooks/api/SocialWall/utils'

type Props = {
  onSuccess?: () => void
  postId: number
}

export const useCommentAdd = ({ onSuccess, postId }: Props) => {
  const { t, enqueueSnackbar, postsFilteredKey, invalidateAllPosts } = useSWQueryHelpers()
  const queryClient = useQueryClient()

  return useMutation(postRecognitionComment, {
    onSuccess: (response) => {
      onSuccess?.()
      const newComment = response.data
      queryClient.setQueryData<PostsQueryData>(
        postsFilteredKey,
        updatePostQueryData(postId, (postToUpdate) => ({
          comments: [newComment, ...postToUpdate.comments],
        }))
      )
      invalidateAllPosts()
      enqueueSnackbar(t('recognition_post.comment.add.success'), { variant: 'success' })
    },
    onError: (err: AxiosError<{ code: number }>) => {
      if (err?.response?.data?.code === 406) {
        enqueueSnackbar(t('recognition_post.comment.add.delay.fail'), { variant: 'error' })
      } else {
        enqueueSnackbar(t('recognition_post.comment.add.fail'), { variant: 'error' })
      }
    },
  })
}
