import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { Image } from 'components/Image/Image'
import { DEFAULT_LANGUAGE } from 'config/constants'
import { fontFamilies, getResponsiveSize } from 'theme/styles.utils'
import { VoucherOrder } from 'utils/api/OrderHistory/getVoucherOrders'
import { dateFormat } from 'utils/dateFormats'
import { makeStyles } from 'utils/styles/makeStyles'
import { useDisplayDateInTimeZone } from 'utils/useDisplayDateInTimeZone'

const useStyles = makeStyles((theme) => ({
  brand: {
    fontFamily: fontFamilies.cond,
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.2,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.h3.fontSize,
    },
  },
  reference: {
    fontFamily: fontFamilies.cond,
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.2,
    marginLeft: theme.spacing(-1),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.h3.fontSize,
    },
    '& .MuiButton-label': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  image: {
    width: getResponsiveSize(81),
    height: getResponsiveSize(81),
  },
}))

type Props = {
  voucherOrder: VoucherOrder
}

export const VoucherOrderRow = ({ voucherOrder }: Props) => {
  const { brand, reference, image } = useStyles()
  const { t, i18n } = useTranslation()
  const { displayDateInTimeZone } = useDisplayDateInTimeZone()

  return (
    <Grid container wrap="nowrap" spacing={4}>
      <Grid item>{displayDateInTimeZone(voucherOrder.order?.created_at, { formatTemplate: dateFormat })}</Grid>
      <Grid item xs>
        <Grid container direction="column">
          <Grid item>
            <Grid container spacing={4}>
              <Grid item xs>
                <Grid container direction="column">
                  <Grid item className={brand}>
                    {voucherOrder?.product?.brand?.name?.toUpperCase() || ''}
                  </Grid>
                  <Grid item>
                    {voucherOrder?.product?.translation[i18n.language]?.name ||
                      voucherOrder?.product?.translation[DEFAULT_LANGUAGE]?.name}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={image}>
                <Image
                  src={voucherOrder?.product?.media?.find((mediaItem) => mediaItem.main)?.path}
                  alt={
                    voucherOrder?.product?.translation[i18n.language]?.name ||
                    voucherOrder?.product?.translation[DEFAULT_LANGUAGE]?.name
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={reference}>
            <Button
              type="button"
              color="primary"
              onClick={() => window.open(voucherOrder?.voucher?.download_token ?? '#', '_blank').focus()}
            >
              {t('profile.voucher_orders.go_to_your_voucher')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
