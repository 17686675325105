import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

interface Props {
  direction?: 'left' | 'up' | 'down' | 'right'
}
export const Arrow = ({ active, direction = 'right', ...rest }: SvgIconProps & IconActive & Props) => {
  const deg = {
    left: 180,
    down: 90,
    up: 270,
    right: 0,
  }[direction]

  return (
    <SvgIcon {...rest} viewBox="0 0 20 20">
      <path
        style={{ transformOrigin: 'center', transform: `rotate(${deg}deg)` }}
        xmlns="http://www.w3.org/2000/svg"
        d="M3.8,1.5C3.4,2.1,3.5,2.9,4.1,3.3L13,10l-8.9,6.7c-0.6,0.4-0.7,1.3-0.3,1.8c0.4,0.6,1.2,0.7,1.8,0.3L15.9,11  c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1c0.1-0.2,0.1-0.3,0.1-0.5s0-0.3-0.1-0.5  c0,0,0-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1c0-0.1-0.1-0.1-0.2-0.2C16,9,16,9,15.9,9L5.6,1.3C5.1,0.8,4.3,0.9,3.8,1.5z"
      />
    </SvgIcon>
  )
}
