import { useQuery } from '@tanstack/react-query'
import deliveryPresetAddressesQueryKeys from 'utils/api/DeliveryPresetAddress/deliveryPresetAddressesQueryKeys'
import { getDeliveryPresetAddresses } from 'utils/api/DeliveryPresetAddress/getDeliveryPresetAddresses'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'

export const useDeliveryAddressPreset = () => {
  const user = useCurrentUser()

  return useQuery({
    queryKey: deliveryPresetAddressesQueryKeys.deliveryPresetAddressesList(),
    queryFn: getDeliveryPresetAddresses,
    enabled: !!user,
  })
}
