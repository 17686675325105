import { TypographyProps } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { getResponsiveSize } from 'theme/styles.utils'
import { valueOrUndefined } from 'utils/getValueOrUndefined'
import { useIsMobile } from 'utils/hooks/useBreakpoints'
import { important } from 'utils/styles/stylesUtils'

type Props = {
  text: string
  size?: 'medium' | 'large' | 'small'
  textAlign?: TypographyProps['textAlign']
  sx?: TypographyProps['sx']
  uppercase?: boolean
  color?: 'dark' | 'medium' | 'primary'
  rightComponent?: React.ReactNode
  leftComponent?: React.ReactNode
}

export const HeaderPage = ({
  text,
  size = 'large',
  textAlign = 'center',
  sx = {},
  uppercase = true,
  color = 'dark',
  rightComponent,
  leftComponent,
  ...props
}: Props) => {
  const isMobile = useIsMobile()
  const fontSize = size === 'large' ? 3.5 : size === 'medium' ? 2.5 : 1.75
  const fontSizeMobile = size === 'large' ? 2.4 : size === 'medium' ? 1.9 : 1.8

  return (
    <Box display="grid" gridTemplateColumns="1fr auto 1fr" columnGap={1}>
      <Box display="flex" alignItems="center">
        {leftComponent}
      </Box>
      <Typography
        variantMapping={{ body1: 'h2' }}
        textAlign={textAlign}
        fontWeight={900}
        lineHeight={1.75}
        fontSize={important(getResponsiveSize(isMobile ? fontSizeMobile : fontSize, 'rem'))}
        textTransform={valueOrUndefined(uppercase, 'uppercase')}
        color={color === 'primary' ? 'primary.main' : color === 'dark' ? 'textPrimary' : 'textPrimaryLight'}
        sx={sx}
        {...props}
      >
        {text}
      </Typography>
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        {rightComponent}
      </Box>
    </Box>
  )
}
