import { getRequest } from '../apiRequest'

export interface Department {
  code: string
  id: number
  lft: number
  lvl: number
  name: string
  rgt: number
  __children: Department[]
}

export type GetDepartmentTree = { departments_tree: Department[] }
export const fetchDepartmentTree = () => {
  return getRequest<GetDepartmentTree>('/departments/tree')
}
