import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Zoom from '@mui/material/Zoom'
import { HeaderPage } from 'components/HeaderPage/HeaderPage'
import Loader from 'components/Loader/Loader'
import { ResponsivePaper } from 'components/ResponsivePaper'
import { VoucherOrderRow } from 'pages/VoucherOrders/components/VoucherOrderRow'
import qs from 'query-string'
import { VoucherOrder } from 'utils/api/OrderHistory/getVoucherOrders'
import { useVoucherOrders } from 'utils/hooks/api/useVoucherOrders'
import { makeStyles } from 'utils/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3, 6),
  },
}))

export const VoucherOrders = () => {
  const { container } = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const limit = qs.parse(location.search.replace('?', ''))?.limit || '3'

  const { data, isLoading } = useVoucherOrders()
  const orders: VoucherOrder[] = data?.data?.data || []
  const areAllItems = data?.data?.items >= data?.data?.itemsPerPage

  return (
    <ResponsivePaper>
      <div className={container}>
        <Loader isLoading={isLoading} objects={orders}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <HeaderPage size="medium" text={t('profile.vouchers')} />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {orders?.map((voucherOrder) => (
              <Fragment key={`order-row-${voucherOrder.order.id}`}>
                <Grid item xs={12}>
                  <VoucherOrderRow voucherOrder={voucherOrder} />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Fragment>
            ))}
            <Grid item xs={12}>
              <Zoom in={areAllItems} unmountOnExit mountOnEnter>
                <Grid container justifyContent="center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate(`/my-account/voucher-orders?limit=${parseInt(limit as string, 10) + 3}`)}
                  >
                    {t('app.show_more')}
                  </Button>
                </Grid>
              </Zoom>
            </Grid>
          </Grid>
        </Loader>
      </div>
    </ResponsivePaper>
  )
}
