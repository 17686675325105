import { AxiosResponse } from 'axios'

import { getRequest } from '../apiRequest'

export type WidgetValue = {
  id: number
  percentage: number
  translation: {
    [key: string]: {
      name: string
    }
  }
}

interface Response {
  image: {
    translation: {
      [key: string]: {
        path: string
      }
    }
  }
  values: WidgetValue[]
}

export const fetchWidgetValues = (dateFrom: string): Promise<AxiosResponse<Response, any>> => {
  return getRequest<Response>(`/social-wall/values/summary?sort[position]=asc&date_from=${dateFrom}`)
}
