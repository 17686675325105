import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import { IconActive } from 'components/ArteelIcons/IconActive'

export const Heart = ({ active, ...rest }: SvgIconProps & IconActive) => (
  <SvgIcon {...rest} viewBox="0 0 100 100">
    {active ? (
      <g xmlns="http://www.w3.org/2000/svg">
        <path d="M50,94.9L50,94.9c-0.9,0-1.9-0.4-2.4-0.9L9.3,52.5H9.2C4.6,47.5,2,40.5,2,33.1c0-7.2,2.7-14,7.2-19.4l0.7-0.5   c4.6-5.3,11-8.3,17.6-8.3c6.8,0,13,2.8,17.9,8.3L50,18l4.6-4.9c4.7-5.3,11.1-8.3,17.8-8.3s13.1,2.8,17.9,8.3l0.5,0.5   c4.7,5.2,7.2,12.1,7.2,19.4s-2.6,14.1-7.2,19.2L52.4,93.7C51.8,94.5,50.9,94.9,50,94.9L50,94.9z" />
      </g>
    ) : (
      <g xmlns="http://www.w3.org/2000/svg">
        <g>
          <path d="M72.4,11.8c4.9,0,9.3,2,12.6,5.9l0.2,0.2l0.2,0.2l0.4,0.4c3.4,3.8,5.3,9,5.3,14.6c0,5.5-1.9,10.6-5.4,14.5    L49.9,86.1L14.4,47.8l-1.1-1.2C10.6,42.9,9,38.1,9,33.1c0-5,1.8-10.1,5-14.3l0.6-0.4l0.5-0.6c3.2-3.7,7.7-5.9,12.3-5.9    c4.9,0,9.2,2,12.7,6l0.1,0.1l0.1,0.1l4.6,4.8l5.1,5.3l5.1-5.4l4.6-4.9l0.1-0.1l0.1-0.1C63.2,13.9,67.7,11.8,72.4,11.8 M72.4,4.8    c-6.7,0-13.1,3-17.8,8.3L50,18l-4.6-4.8c-4.9-5.5-11.1-8.3-17.9-8.3c-6.6,0-13,3-17.6,8.3l-0.7,0.5C4.7,19.1,2,25.9,2,33.1    c0,7.4,2.6,14.4,7.2,19.4h0.1L47.6,94c0.5,0.5,1.5,0.9,2.4,0.9s1.8-0.4,2.4-1.2l38.4-41.5C95.4,47.1,98,40.3,98,33    c0-7.3-2.5-14.2-7.2-19.4l-0.5-0.5C85.5,7.6,79.1,4.8,72.4,4.8L72.4,4.8z" />
        </g>
      </g>
    )}
  </SvgIcon>
)
